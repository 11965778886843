import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CheckBoxIcon } from "../../assets/InsuranceImages/main/checkbox.svg";
import { ReactComponent as CheckBoxOutlineIcon } from "../../assets/InsuranceImages/main/checkboxoutline.svg";

interface DropDownCheckBoxProps {
    benefitId: number;
    benefitName: string;
    benefitArr: Set<string>;
    setBenefitArr: React.Dispatch<React.SetStateAction<Set<string>>>;
    selectErrMsg: boolean;
    setSelectErrMsg: React.Dispatch<React.SetStateAction<boolean>>;
    inputText: string;
    setInputText: React.Dispatch<React.SetStateAction<string>>;
}

const DropDownCheckBox = ({
    benefitId,
    benefitName,
    benefitArr,
    setBenefitArr,
    selectErrMsg,
    setSelectErrMsg,
    inputText,
    setInputText,
}: DropDownCheckBoxProps) => {
    const [selected, setSelected] = useState(false);
    const [selectError, setSelectError] = useState<boolean>(selectErrMsg);

    const handleBenefitArr = (benefitName: string, selected: boolean) => {
        if (selected) {
            if (benefitArr.size >= 3) {
                setSelectErrMsg(true);
                setSelectError(true);
            } else {
                setBenefitArr(
                    (prevArr: any) => new Set([...prevArr, benefitName])
                );
                setSelectError(false);
            }
        } else if (!selected && benefitArr.has(benefitName)) {
            setBenefitArr((prevArr: Set<string>) => {
                const newArr = new Set(prevArr);
                newArr.delete(benefitName);
                return newArr;
            });
            setSelectErrMsg(false);
            setSelectError(false);
        }
    };

    const handleClickCheckBox = (benefitName: string) => {
        if (!selected && benefitArr.size >= 3) {
            setSelectErrMsg(true);
            setSelectError(true);
        } else {
            const newSelected = !selected;
            setSelected(newSelected);
            handleBenefitArr(benefitName, newSelected);
        }
    };

    useEffect(() => {
        if (selectError) setSelectError(selectErrMsg);
        if (benefitArr.size === 0 && selected) {
            setSelected(false);
        }
        if (benefitArr.size !== 0 && benefitArr.has(benefitName)) {
            setSelected(true);
        }
        // eslint-disable-next-line
    }, [selectErrMsg, benefitArr]);

    return (
        <div>
            <DropDownCheckBoxContainer
                onClick={() => handleClickCheckBox(benefitName)}
                selected={selected}
                selectError={selectError}
            >
                <DropDownText>{benefitName}</DropDownText>
                {selected ? (
                    <CheckBoxIcon className="checked" />
                ) : (
                    <CheckBoxOutlineIcon className="unchecked" />
                )}
            </DropDownCheckBoxContainer>
        </div>
    );
};

export default DropDownCheckBox;

const DropDownCheckBoxContainer = styled.li<{
    selected?: boolean;
    selectError?: boolean;
    selectErrMsg?: boolean;
}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.7rem 1.5rem 1.4rem;
    list-style: none;
    border-bottom: 1px solid #ffeb99;
    cursor: pointer;

    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;

    :hover {
        ${({ selectError }) => !selectError && `background-color: #fff5ce;`};
    }

    ${({ selected }) => selected && `background-color: #fff5ce;`};
    ${({ selectError }) => selectError && `background-color: #E4E4E6;`};

    .checked {
        margin-bottom: 1px;
    }
    .checked,
    .unchecked {
        width: 2.5rem;
        height: 2.5rem;
    }
`;

const DropDownText = styled.div``;
