import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SocialLink, socialList } from "./SocialLink";

const footerList = [
    {
        text: "회사소개",
        url: "https://corp.bemypet.kr/",
    },
    {
        text: "제휴제안",
        url: "https://corp.bemypet.kr/business/",
    },
    {
        text: "이용약관",
        url: "https://corp.bemypet.kr/tos/",
    },
    {
        text: "개인정보처리방침",
        url: "https://corp.bemypet.kr/privacy-policy/",
    },
    {
        text: "크리에이터 신청",
        url: "https://bemypet.kr/partnership",
    },
];

const Footer = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);
    return (
        <>
            {!isLoading && (
                <FooterContainer>
                    <FooterList>
                        {footerList.map(data => (
                            <FooterText href={data.url} key={data.text}>
                                {data.text}
                            </FooterText>
                        ))}
                    </FooterList>
                    <SocialButtonWrapper>
                        {socialList.map(({ url, social, icon }) => (
                            <SocialLink
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={social}
                            >
                                <SocialIcon src={icon} alt="logo" />
                            </SocialLink>
                        ))}
                    </SocialButtonWrapper>
                </FooterContainer>
            )}
        </>
    );
};

export default Footer;

const FooterContainer = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 50px);
    padding: 20px 25px;
    border-top: 1px solid #cfcece;

    a {
        color: inherit;
    }

    @media only screen and (max-width: 546px) {
        width: calc(100vw - 56px);
        flex-direction: column;
        gap: 16px;
        padding: 20px 28px;
        border-top: 1px solid #e4e4e6;
    }
`;

const FooterList = styled.div`
    display: flex;
    gap: 1.2rem;

    @media only screen and (max-width: 546px) {
        gap: 2rem;
    }
    @media only screen and (max-width: 390px) {
        gap: 1.6rem;
    }
`;

const FooterText = styled.a`
    font-size: 12px;
    line-height: 14px;

    text-decoration: none;
    color: black;

    @media only screen and (max-width: 768px) {
        font-size: 9px;
        line-height: 11px;
    }
`;

const SocialButtonWrapper = styled.div`
    white-space: nowrap;
`;

const SocialIcon = styled.img`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
`;
