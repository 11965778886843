import React from "react";
import styled from "styled-components";


interface FormImageSubLabelProps {
  label?: string;
}

export const FormImageSubLabel = ({
  label,
}: FormImageSubLabelProps) => {
  return (
    <FormImageSubLabelWrapper>
      {label}
    </FormImageSubLabelWrapper>
  );
};

const FormImageSubLabelWrapper = styled.div`
  font-size: 16px;
  color: #868688;
`;