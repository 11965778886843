import React, { useContext } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Tab, Tabs } from "components/Tab";
import { LoginContext } from "Router";

const calculatorList = [
    {
        title: "홈",
        url: "/calculator",
    },
    {
        title: "사료칼로리",
        url: "/calculator/food-calorie",
    },
    {
        title: "권장칼로리",
        url: "/calculator/recommend-calorie",
    },
    {
        title: "비만도",
        url: "/calculator/bmi",
    },
    {
        title: "나이",
        url: "/calculator/age",
    },
];

const HeaderDepth2 = () => {
    const pathName = useLocation().pathname;
    const splitPathName = pathName.split("/");
    const lastPath = splitPathName[splitPathName.length - 1];

    const calculatorPath =
        lastPath === "calculator" ? `/${lastPath}` : `/calculator/${lastPath}`;

    const insurancePath =
        lastPath === "insurance" ? `/${lastPath}` : `/insurance/${lastPath}`;
    const insuranceDetailPath =
        splitPathName.length > 4
            ? `/${splitPathName[splitPathName.length - 4]}/${
                  splitPathName[splitPathName.length - 3]
              }`
            : splitPathName.length > 3
            ? `/${splitPathName[splitPathName.length - 3]}`
            : "";

    const adoptionPath =
        lastPath === "adoption" ? `/${lastPath}` : `/adoption/${lastPath}`;
    const adoptDetailPath =
        splitPathName.length > 2
            ? `/${splitPathName[splitPathName.length - 3]}/${
                  splitPathName[splitPathName.length - 2]
              }`
            : "";

    const { isLoggedIn } = useContext(LoginContext);

    const insuranceList = [
        {
            title: "펫보험 찾기",
            url: "/insurance",
            url1: "/insurance/benefits",
            url2: "/insurance/compare",
            url3: "/insurance/insurances",
        },
        {
            title: "관심 펫보험",
            url: isLoggedIn
                ? "/insurance/favorite"
                : `${process.env.REACT_APP_MAIN_URL_DEV}login?returnURL=${process.env.REACT_APP_DOMAIN_URL_DEV}insurance/favorite`,
        },
    ];

    const adoptionList = [
        {
            title: "유기동물 입양",
            url: "/adoption",
            url1: "/adoption/adopt",
            url2: "/adoption/notfound",
        },
        {
            title: "이름 짓기",
            url: "/adoption/name-generator",
            url1: "/adoption/name-generator-result",
        },
        {
            title: "동물등록 번호 검색",
            url: "/adoption/registration-verification",
        },

        {
            title: "나의 관심동물",
            url: isLoggedIn
                ? "/adoption/favorite"
                : `${process.env.REACT_APP_MAIN_URL_DEV}login?returnURL=${process.env.REACT_APP_DOMAIN_URL_DEV}adoption/favorite`,
        },
    ];

    const subMenu = (menu: string) => {
        if (menu === "calculator") {
            return (
                <HeaderContainer id="headerSection_2">
                    <Tabs>
                        {calculatorList.map(list => (
                            <Tab
                                label={list.title}
                                url={list.url}
                                key={list.title}
                                selected={calculatorPath === list.url}
                            />
                        ))}
                    </Tabs>
                </HeaderContainer>
            );
        } else if (menu === "adoption") {
            return (
                <HeaderContainer id="headerSection_2">
                    <Tabs>
                        {adoptionList.map(list => (
                            <Tab
                                label={list.title}
                                url={list.url}
                                key={list.title}
                                selected={
                                    list.title !== "유기동물 입양"
                                        ? adoptionPath === list.url ||
                                          adoptionPath === list.url1
                                        : adoptionPath === list.url ||
                                          adoptionPath === list.url1 ||
                                          adoptionPath === list.url2 ||
                                          adoptDetailPath === list.url1
                                }
                            />
                        ))}
                    </Tabs>
                </HeaderContainer>
            );
        } else if (menu === "insurance") {
            return (
                <HeaderContainer id="headerSection_2">
                    <Tabs>
                        {insuranceList.map(list => (
                            <Tab
                                label={list.title}
                                url={list.url}
                                key={list.title}
                                selected={
                                    insurancePath === list.url ||
                                    insurancePath === list.url1 ||
                                    insurancePath === list.url2 ||
                                    insuranceDetailPath === list.url ||
                                    insuranceDetailPath === list.url3
                                }
                            />
                        ))}
                    </Tabs>
                </HeaderContainer>
            );
        }
    };

    return <div>{subMenu(splitPathName[1])}</div>;
};

export default HeaderDepth2;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    border-bottom: 1px solid #e4e4e6;
    background-color: white;
`;
