import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { ToolsHeaderTab } from "components/ToolsHeader";
import { mainHeaderList } from "./HeaderList";

const HeaderDepth1 = () => {
    const pathName = useLocation().pathname;
    const splitPathName = pathName.split("/");
    const splitPathOne = `/${splitPathName[1]}`;
    return (
        <div>
            <ToolsHeader>
                <ToolsHeaderUl>
                    {mainHeaderList.map((list: any) => (
                        <ToolsHeaderTab
                            label={list.title}
                            url={list.url}
                            key={list.title}
                            selected={
                                list.title === "툴즈" ||
                                splitPathOne === list.url ||
                                splitPathOne === list.url2
                            }
                        />
                    ))}
                </ToolsHeaderUl>
            </ToolsHeader>
        </div>
    );
};

export default HeaderDepth1;

const ToolsHeader = styled.div`
    border-top: 1px solid #e4e4e6;
    border-bottom: 1px solid #e4e4e6;
`;

const ToolsHeaderUl = styled.ul`
    display: flex;
    gap: 50px;
    text-align: center;
    background-color: white;
    align-items: center;
    justify-content: center;
    height: 46px;
    margin: 0;
    padding: 0;

    @media only screen and (max-width: 768px) {
        gap: 35px;
        height: 38.5px;
    }
    @media only screen and (max-width: 448px) {
        gap: 30px;
    }
    @media only screen and (max-width: 384px) {
        gap: 24px;
    }
`;
