import React from "react";
import styled from "styled-components";
import StaticUrl from "libs/urls";
import { searchIcon, storeIcon } from "assets";

const ShoppingPopper = () => {
    return (
        <div>
            <ShoppingPopperContainer>
                <ShoppingPopperWrapper>
                    <ShoppingPopperTab href={StaticUrl.Shopping}>
                        <ShoppingPopperIcon
                            src={searchIcon}
                            alt="popper logo"
                        />
                        <ShoppingPopperText>제품 탐색</ShoppingPopperText>
                    </ShoppingPopperTab>
                    <ShoppingPopperHr />
                    <ShoppingPopperTab
                        href={StaticUrl.bemypetstore}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ShoppingPopperIcon src={storeIcon} alt="popper logo" />
                        <ShoppingPopperText>비마이펫 스토어</ShoppingPopperText>
                    </ShoppingPopperTab>
                </ShoppingPopperWrapper>
            </ShoppingPopperContainer>
        </div>
    );
};

export default ShoppingPopper;

export const ShoppingPopperContainer = styled.div`
    position: absolute;
    top: 30px;
    display: none;
`;
const ShoppingPopperWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 120px;
    padding: 0 15px;
    background-color: #fffdf4;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

const ShoppingPopperTab = styled.a`
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    height: 100%;
`;

const ShoppingPopperIcon = styled.img`
    width: 24px;
    height: 24px;
`;

const ShoppingPopperText = styled.div`
    margin: 0;
`;

const ShoppingPopperHr = styled.hr`
    margin: 0;
    width: 100%;
    border: 1px solid #fcd11e;
`;
