import { atom } from "recoil";
import { localStorageEffect } from "../localStorage";

export const userTokenState = atom({
    key: "userTokenState",
    default: "",
    effects: [localStorageEffect("accessToken")],
});

export const adoptPetTypeState = atom({
    key: "adoptPetTypeState",
    default: "dog",
});

export const adoptBreedState = atom({
    key: "adoptBreedState",
    default: new Set(),
});

export const adoptRegionState = atom({
    key: "adoptRegionState",
    default: "",
});

export const adoptStatusState = atom({
    key: "adoptStatusState",
    default: "보호",
});

export const adoptAgeState = atom({
    key: "adoptAgeState",
    default: new Set(),
});

export const adoptGenderState = atom({
    key: "adoptGenderState",
    default: "전체",
});

export const adoptNeuterState = atom({
    key: "adoptNeuterState",
    default: "전체",
});

export const adoptCurrentPageState = atom({
    key: "adoptCurrentPageState",
    default: 1,
});
