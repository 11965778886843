import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { catSelector, dogSelector } from "assets";
import { colors } from "theme";
import { AnimalKind } from "libs/models";

const Select = styled.select`
    width: 250px;
    padding: 8px;
    border-radius: 5px;
    font-size: 15px;
    border: solid gray 1px;
    margin: 10px;
`;

export interface OptionData {
    image: string;
    label?: string;
    value: any;
}

interface ImageSelectBoxProps {
    options: OptionData[];
    onClick: (value: any, index: number) => void;
    selectedIndex?: number;
    dense?: boolean;
    value?: any;
}

interface SelectItemProps {
    selected?: boolean;
    dense?: boolean;
}

const ImageSelectBox = ({
    options,
    onClick,
    dense,
    value,
}: ImageSelectBoxProps) => {
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    useEffect(() => {
        if (!!value) {
            const index = options.findIndex(x => x.value === value);
            setSelectedIndex(index);
        }
    }, [value, setSelectedIndex, options]);

    const handleOnClick = (index: number) => () => {
        const { value } = options[index];
        onClick(value, index);
        setSelectedIndex(index);
    };

    return (
        <SelectList>
            {options.map((option, index) => (
                <SelectItem
                    key={`${index}image`}
                    selected={selectedIndex === index}
                    onClick={handleOnClick(index)}
                    dense={dense}
                >
                    <SelectOptionImage
                        src={option.image}
                        alt="Option Image"
                        dense
                    />
                    {!!option.label && (
                        <SelectOptionLabel selected={selectedIndex === index}>
                            {option.label}
                        </SelectOptionLabel>
                    )}
                </SelectItem>
            ))}
        </SelectList>
    );
};

const SelectList = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 360px;
`;

const SelectItem = styled.div<SelectItemProps>`
    border-radius: 10px;
    background-color: ${colors.grey200};
    border: 5px solid ${colors.grey200};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: ${({ dense }) => (dense ? 110 : 170)}px;
    height: ${({ dense }) => (dense ? 110 : 150)}px;
    box-sizing: border-box;
    color: ${colors.grey400};
    margin-right: ${({ dense }) => (dense ? "15px" : "20px")};

    ${({ selected }) =>
        selected &&
        `
    background-color: ${colors.grey100};
    border-color: ${colors.primary100};
    color: ${colors.grey700};

    img {
      opacity: 1;
    }
  `}

    :nth-child(3n) {
        margin-right: 0;
    }

    :nth-child(4),
    :nth-child(5) {
        margin-top: 15px;
    }

    :last-child {
        margin-right: 0;
    }

    :hover {
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        width: 150px;
        height: 150px;
        margin-right: 20px;
        margin-top: 20px;

        :nth-child(3n) {
            margin-right: 20px;
        }

        :nth-child(4),
        :nth-child(5) {
            margin-top: 20px;
        }

        :nth-child(2n) {
            margin-right: 0;
        }
    }
`;

const SelectOptionImage = styled.img<SelectItemProps>`
    width: 110px;
    height: 110px;
    background: transparent;
    opacity: 0.35;
    mix-blend-mode: multiply;
`;

const SelectOptionLabel = styled.p<SelectItemProps>`
    text-align: center;
    font-weight: bold;
    font-family: JalnanOTF;
    margin: 0;
    color: inherit;
`;

export { Select, ImageSelectBox };

const animalOptions = [
    {
        image: dogSelector,
        label: "강아지",
        value: AnimalKind.Dog,
    },
    {
        image: catSelector,
        label: "고양이",
        value: AnimalKind.Cat,
    },
];

interface AnimalSelectProps {
    value?: AnimalKind;
    onChange: (value: AnimalKind) => void;
}

export const AnimalSelect: React.FC<AnimalSelectProps> = ({
    value,
    onChange,
}) => {
    return (
        <ImageSelectBox
            options={animalOptions}
            value={value}
            onClick={onChange}
        />
    );
};
