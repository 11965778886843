import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
    ResultSection,
    PaddingContainer,
    ResultCard,
} from "components/ResultSection";
import { PostSection, PostCard } from "components/PostSection";
import { Divider } from "components/Divider";
import PieChart from "components/PieChart";
import {
    Heading2,
    Heading4,
    Body6,
    InlineColor,
    PointColorText,
} from "components/Typography";
import { colors } from "theme";
import { percentFormatter } from "libs/helpers";
import { PostModel } from "libs/models";
import { httpService } from "libs/services";

import { Nutrient } from "./nutrientProfile";
import FoodCalorie from "./FoodCalorie";
import DisplayAds from "components/DisplayAds";

interface FeedResultProps {
    form: FoodCalorie;
}

const FoodCalorieResult = ({ form }: FeedResultProps) => {
    const [contents, setContents] = useState<PostModel[]>([]);

    const {
        dmProteinCalorie,
        dmFatCalorie,
        dmCarbohydrateCalorie,
        totalCalories,
        hasOptionalData,
    } = form;

    const { minValues, maxValues, suitableValues } = form.getAAFCOResult();

    const getContents = useCallback(async () => {
        const keyword = form.isCat ? "고양이사료" : "강아지사료";
        const posts = await httpService.getPosts({
            keyword,
            size: 4,
            search_type: "hashtag",
            ordering: "random",
        });
        setContents(posts);
    }, [form]);

    useEffect(() => {
        getContents();
    }, [getContents]);

    const chartData = {
        labels: ["단백질", "탄수화물", "지방"],
        datasets: [
            {
                data: [dmProteinCalorie, dmCarbohydrateCalorie, dmFatCalorie],
                backgroundColor: ["#cbccba", "#98bc73", colors.primary100],
            },
        ],
    };

    return (
        <>
            <ResultSection>
                <Heading2>
                    <InlineColor color={colors.olive100}>
                        사료칼로리 계산
                    </InlineColor>{" "}
                    결과
                </Heading2>
                <ResultCard backgroundColor={colors.olive200}>
                    <InnerResultSection>
                        <Heading4>
                            총 칼로리{" "}
                            <PointColorText color={colors.olive100}>
                                {totalCalories}
                            </PointColorText>
                            kcal (100g 기준)
                        </Heading4>
                        <Body6 color={colors.grey500}>칼로리 기준 비율</Body6>
                        <PieChartContainer>
                            <PieChart
                                data={chartData}
                                width={234}
                                totalCalories={totalCalories}
                            />
                        </PieChartContainer>
                    </InnerResultSection>
                    <Divider />
                    <InnerResultSection>
                        {!hasOptionalData ? (
                            <Heading4>
                                반려동물 상태를 입력하고
                                <br />
                                정확한 결과를 알아보세요!
                            </Heading4>
                        ) : (
                            <>
                                <PaddingContainer marginBottom={24}>
                                    <Heading4>
                                        2020 미국사료협회(AAFCO) 기준
                                    </Heading4>
                                </PaddingContainer>
                                {suitableValues.length > 0 ? (
                                    <Heading4>
                                        {suitableValues
                                            .filter(
                                                x =>
                                                    x !==
                                                    Nutrient.CalciumPerPhosphorus
                                            )
                                            .join(", ")}{" "}
                                        <InlineColor color={colors.grey500}>
                                            함유량이 기준 최소치를{" "}
                                        </InlineColor>
                                        <InlineColor color={colors.olive100}>
                                            충족합니다.
                                        </InlineColor>
                                    </Heading4>
                                ) : null}
                                {suitableValues.includes(
                                    Nutrient.CalciumPerPhosphorus
                                ) ? (
                                    <Heading4>
                                        {suitableValues
                                            .filter(
                                                x =>
                                                    x ===
                                                    Nutrient.CalciumPerPhosphorus
                                            )
                                            .join(", ")}{" "}
                                        <InlineColor color={colors.grey500}>
                                            함유량 비율이 기준 최소치를{" "}
                                        </InlineColor>
                                        <InlineColor color={colors.olive100}>
                                            충족합니다.
                                        </InlineColor>
                                    </Heading4>
                                ) : null}
                                {minValues.length > 0 ? (
                                    <Heading4>
                                        {minValues
                                            .filter(
                                                x =>
                                                    x !==
                                                    Nutrient.CalciumPerPhosphorus
                                            )
                                            .join(", ")}{" "}
                                        <InlineColor color={colors.grey500}>
                                            함유량이 기준 최소치를{" "}
                                        </InlineColor>
                                        <InlineColor color={colors.red}>
                                            충족하지 않습니다.
                                        </InlineColor>
                                    </Heading4>
                                ) : null}
                                {minValues.includes(
                                    Nutrient.CalciumPerPhosphorus
                                ) ? (
                                    <Heading4>
                                        {minValues
                                            .filter(
                                                x =>
                                                    x ===
                                                    Nutrient.CalciumPerPhosphorus
                                            )
                                            .join(", ")}{" "}
                                        <InlineColor color={colors.grey500}>
                                            함유량 비율이 기준 최소치를{" "}
                                        </InlineColor>
                                        <InlineColor color={colors.red}>
                                            충족하지 않습니다.
                                        </InlineColor>
                                    </Heading4>
                                ) : null}
                                {maxValues.length > 0 ? (
                                    <Heading4>
                                        {maxValues
                                            .filter(
                                                x =>
                                                    x !==
                                                    Nutrient.CalciumPerPhosphorus
                                            )
                                            .join(", ")}{" "}
                                        <InlineColor color={colors.grey500}>
                                            함유량이 권장량 최대치를{" "}
                                        </InlineColor>
                                        <InlineColor color={colors.red}>
                                            초과합니다.
                                        </InlineColor>
                                    </Heading4>
                                ) : null}
                                {maxValues.includes(
                                    Nutrient.CalciumPerPhosphorus
                                ) ? (
                                    <Heading4>
                                        {maxValues
                                            .filter(
                                                x =>
                                                    x ===
                                                    Nutrient.CalciumPerPhosphorus
                                            )
                                            .join(", ")}{" "}
                                        <InlineColor color={colors.grey500}>
                                            함유량 비율이 권장량 최대치를{" "}
                                        </InlineColor>
                                        <InlineColor color={colors.red}>
                                            초과합니다.
                                        </InlineColor>
                                    </Heading4>
                                ) : null}
                            </>
                        )}
                    </InnerResultSection>
                    <Divider />
                    <InnerResultSection>
                        <PaddingContainer marginBottom={8}>
                            <Heading4>DM 기준</Heading4>
                        </PaddingContainer>
                        <PaddingContainer marginBottom={24}>
                            <Body6 color={colors.grey500}>
                                DM 기준은 수분 함량을 제외한 영양 성분 수치로
                                미국사료협회(AAFCO)가 사료 성분 분석 시 사용하는
                                기준입니다.
                            </Body6>
                        </PaddingContainer>
                        <DmResultContainer>
                            {form.result.map(nutrient => (
                                <DmResultWrapper key={nutrient.key}>
                                    <Heading4>{nutrient.label}</Heading4>
                                    <Heading4>
                                        <InlineColor color={colors.olive100}>
                                            {percentFormatter(
                                                nutrient.value as number,
                                                2
                                            )}
                                        </InlineColor>
                                        {nutrient.key !== "calciumPerPhosphorus"
                                            ? "%"
                                            : ""}
                                    </Heading4>
                                </DmResultWrapper>
                            ))}
                        </DmResultContainer>
                    </InnerResultSection>
                </ResultCard>
            </ResultSection>
            <DisplayAds animalType={form.animal} />
            {contents.length > 0 && (
                <PostSection
                    title={
                        <>
                            <InlineColor color={colors.primary100}>
                                비마이펫{" "}
                            </InlineColor>
                            맞춤 콘텐츠
                        </>
                    }
                    backgroundImage={colors.oliveGradient}
                >
                    {contents.map((post: PostModel, index) => (
                        <PostCard key={index} post={post} />
                    ))}
                </PostSection>
            )}
        </>
    );
};

export default FoodCalorieResult;

const InnerResultSection = styled.div`
    padding-top: 50px;
    padding-bottom: 50px;

    :first-child {
        padding-top: 0;
    }

    :last-child {
        padding-bottom: 0;
    }
`;

const PieChartContainer = styled.div`
    padding: 24px 0px 50px;
    display: flex;
    justify-content: center;
`;

const DmResultContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0px 28px;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
        justify-content: center;
    }
`;

const DmResultWrapper = styled.div`
    width: 202px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @media only screen and (max-width: 768px) {
        padding: 0px 31px;
    }
`;
