import React from "react";
import styled from "styled-components";
import { ReactComponent as TipCloseIcon } from "../../assets/InsuranceImages/main/tip_close.svg";

interface SingleTipProps {
    filter: string;
    setFilter: React.Dispatch<React.SetStateAction<string>>;
}

const SingleTip = ({ filter, setFilter }: SingleTipProps) => {
    return (
        <>
            {filter !== "" && filter !== "전체" && (
                <TipButton>
                    {filter}
                    <TipCloseIcon
                        className="delete"
                        onClick={() => setFilter("전체")}
                    />
                </TipButton>
            )}
        </>
    );
};

export default SingleTip;

const TipButton = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 2px 0.8rem;
    font-size: 0.87rem;
    line-height: 1.7rem;
    font-weight: 500;
    border-radius: 25px;
    background-color: #f0e9f7;

    .delete {
        width: 1rem;
        height: 1rem;
        margin-bottom: 0.2rem;
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        font-size: 1.1rem;
    }
`;
