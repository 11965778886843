import { compareLili, compareLiliM, fishSamsek, writeLili, writeLiliM } from "assets";
import axios from "axios";
import Layout from "components/Layout";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import InsuranceFavoriteCard from "./InsuranceFavoriteCard";
import { useRecoilValue } from "recoil";
import { userTokenState } from "states";
import { useNavigate } from "react-router-dom";
import { ReactComponent as InfoIcon } from "../../../assets/InsuranceImages/detail/info.svg";
import AdoptFavoriteNoResults from "adoptComponents/favorite/AdoptFavoriteNoResults";
import InsuranceFavoriteAlert from "./InsuranceFavoriteAlert";
// import NotFound from "pages/notfound/NotFound";

SwiperCore.use([Navigation, Pagination]);

const InsuranceFavorite = () => {
    const navigate = useNavigate();
    const userToken = useRecoilValue(userTokenState);

    const [favoriteList, setFavoriteList] = useState<any>([]);
    const [favoriteListCount, setFavoriteListCount] = useState<number>(-1);
    const [editSelectList, setEditSelectList] = useState<Set<number>>(new Set());

    const [closed, setClosed] = useState<boolean>(false);
    const [compareOn, setCompareOn] = useState<boolean>(false);
    const [showAlertDiff, setShowAlertDiff] = useState<boolean>(false);
    const [showAlertOver, setShowAlertOver] = useState<boolean>(false);

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const getFavoriteList = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL_DEV}insurance/favorite/`, {
                headers: {
                    Authorization: userToken ? `Bearer ${userToken}` : ``,
                },
            })
            .then(res => {
                // console.log(res.data);
                setFavoriteListCount(res.data.length);
                setFavoriteList(res.data);
                setClosed(false);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const onHandleCompare = async () => {
        if (editSelectList.size === 2) {
            const compareId1 = Array.from(editSelectList)[0];
            const compareId2 = Array.from(editSelectList)[1];
            const compareRes1 = await axios.get(
                `${process.env.REACT_APP_API_URL_DEV}insurance/insurances/${compareId1}/`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const compareRes2 = await axios.get(
                `${process.env.REACT_APP_API_URL_DEV}insurance/insurances/${compareId2}/`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (compareRes1.data.animal_type === compareRes2.data.animal_type) {
                navigate(
                    `/insurance/compare?animal_type=${compareRes1.data.animal_type.toLowerCase()}&id1=${compareId1}&id2=${compareId2}`
                );
            }
        }
    };

    useEffect(() => {
        getFavoriteList();
        // eslint-disable-next-line
    }, [closed, userToken]);

    useEffect(() => {
        // console.log(editSelectList);
        if (editSelectList.size === 2) {
            setCompareOn(true);
        } else {
            setCompareOn(false);
        }
        // eslint-disable-next-line
    }, [editSelectList]);

    return (
        <>
            <Layout>
                {/* {userToken === null || userToken === "" ? (
                    <NotFound />
                ) : ( */}
                    <>
                        {favoriteListCount !== 0 ? (
                            <>
                                {favoriteListCount !== -1 && (
                                    <InsuranceFavoriteContainer>
                                        <InsuranceFavoriteTitle>관심 펫보험</InsuranceFavoriteTitle>
                                        <FavoriteDescriptionContainer>
                                            <DescriptionImage src={fishSamsek} alt="삼색이" />
                                            <DescriptionText>
                                                관심 펫보험에 담은 보험을 비교해 보고 싶다면,
                                                {isMobile && <br />} 해당 보험을 선택한 후
                                                {isMobile && <br />} 하단의{" "}
                                                <DescriptionButtonImage>
                                                    <img
                                                        src={isMobile ? compareLiliM : compareLili}
                                                        alt="1:1 비교"
                                                    />
                                                </DescriptionButtonImage>{" "}
                                                버튼을 눌러 비교해 보세요!.
                                            </DescriptionText>
                                        </FavoriteDescriptionContainer>
                                        <FavoriteCardContainer>
                                            <SwiperContainer>
                                                <Swiper
                                                    spaceBetween={0}
                                                    slidesPerView={isMobile ? 2 : "auto"}
                                                    pagination={{
                                                        clickable: true,
                                                        dynamicBullets: true,
                                                    }}
                                                    modules={[Pagination]}
                                                    threshold={5}
                                                >
                                                    {favoriteList?.map((data: any) => (
                                                        <SwiperSlide key={data.id}>
                                                            <InsuranceFavoriteCard
                                                                insuranceData={data}
                                                                editSelectList={editSelectList}
                                                                setEditSelectList={
                                                                    setEditSelectList
                                                                }
                                                                setClosed={setClosed}
                                                                setShowAlertDiff={setShowAlertDiff}
                                                                setShowAlertOver={setShowAlertOver}
                                                            />
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                            </SwiperContainer>
                                        </FavoriteCardContainer>
                                        {!isMobile && (
                                            <BottomDescriptionContainer>
                                                <InfoIcon className="infoIcon" />
                                                가입 가능 나이란? 해당 펫보험에 가입 가능한
                                                반려동물의 나이
                                            </BottomDescriptionContainer>
                                        )}
                                        <ComapareButtonContainer>
                                            {!isMobile && (
                                                <CompareImage
                                                    src={writeLili}
                                                    alt="리리"
                                                ></CompareImage>
                                            )}
                                            <CompareButton
                                                compareOn={compareOn}
                                                onClick={onHandleCompare}
                                            >
                                                1:1 비교하기
                                            </CompareButton>
                                            {isMobile && (
                                                <CompareImageMobile
                                                    src={writeLiliM}
                                                    alt="리리"
                                                ></CompareImageMobile>
                                            )}
                                        </ComapareButtonContainer>
                                        {showAlertDiff && (
                                            <InsuranceFavoriteAlert
                                                alertType="diff"
                                                setShowAlert={setShowAlertDiff}
                                            />
                                        )}
                                        {showAlertOver && (
                                            <InsuranceFavoriteAlert
                                                alertType="over"
                                                setShowAlert={setShowAlertOver}
                                            />
                                        )}
                                    </InsuranceFavoriteContainer>
                                )}
                            </>
                        ) : (
                            <AdoptFavoriteNoResults props="insurance" />
                        )}
                    </>
                {/* )} */}
            </Layout>
        </>
    );
};

export default InsuranceFavorite;

const InsuranceFavoriteContainer = styled.div`
    /* max-width: 64rem; */
    margin: 0 auto;
`;

const InsuranceFavoriteTitle = styled.div`
    padding: 3rem 0 1.5rem;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 600;
    text-align: center;

    @media only screen and (max-width: 768px) {
        padding: 4rem 0 2.5rem;
        font-size: 1.7rem;
        line-height: 2rem;
    }
    @media only screen and (max-width: 448px) {
        padding: 4rem 0 2rem;
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
`;

const FavoriteDescriptionContainer = styled.div`
    display: flex;
    align-items: center;
    max-width: 1024px;
    margin: 0 auto;

    @media only screen and (max-width: 768px) {
        margin-left: 0;
        margin-bottom: 3rem;
        gap: 1.5rem;
        text-align: center;
        justify-content: center;
    }
    @media only screen and (max-width: 448px) {
        gap: 1.2rem;
    }
`;

const DescriptionImage = styled.img`
    width: 5rem;
    height: 5rem;
    margin-left: 30px;

    @media only screen and (max-width: 768px) {
        margin-left: 0;
    }
`;

const DescriptionText = styled.div`
    display: inline-block;
    padding: 10px 14px 9px;
    margin-left: 1.25rem;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;

    border-radius: 25px;
    background-color: #f6f6f9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

    @media only screen and (max-width: 892px) {
        font-size: 12px;
        line-height: 14px;
    }

    @media only screen and (max-width: 768px) {
        padding: 1.4rem 1.4rem;
        margin: 0;
        font-size: 1.1rem;
        line-height: 1.7rem;
    }
`;

const DescriptionButtonImage = styled.div`
    position: relative;
    display: inline-block;
    width: 80px;
    height: 0rem;
    margin: 0 5px;
    img {
        position: absolute;
        bottom: -7px;
        width: 80px;
        height: 35px;

        @media only screen and (max-width: 768px) {
            left: 0;
            width: 71.5px;
            height: 19.5px;
            margin-bottom: 3px;
        }
        @media only screen and (max-width: 512px) {
            margin-bottom: 1px;
        }
    }

    @media only screen and (max-width: 768px) {
        width: 70px;
        margin: 0 2px;
    }
`;

const FavoriteCardContainer = styled.div`
    margin: 20px auto 0;
    overflow: hidden;
    width: calc(100vw - 10px);
    max-width: 1014px;
    padding-left: 10px;

    @media only screen and (max-width: 768px) {
        width: 100vw;
        padding: 0;
        margin: 2rem auto 2.5rem;
    }

    .swiper-container {
        width: calc(100vw - 10px);
        max-width: 1014px;

        @media only screen and (max-width: 768px) {
            width: 100vw;
        }
    }

    .swiper-slide {
        width: 264px !important;

        @media only screen and (max-width: 768px) {
            width: 50vw !important;
        }
    }

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        width: 100vw;
        max-width: 1014px;
        bottom: 20px;

        @media only screen and (max-width: 768px) {
            bottom: 2.2rem;
        }
        @media only screen and (max-width: 640px) {
            bottom: 2.2rem;
        }
        @media only screen and (max-width: 384px) {
            bottom: 3.2rem;
        }
    }

    .swiper-backface-hidden .swiper-slide {
        display: flex;
        justify-content: center;
    }

    .swiper .swiper-pagination-bullet {
        background-color: #868688;
        width: 10px;
        height: 10px;

        @media only screen and (max-width: 768px) {
            width: 0.6rem;
            height: 0.6rem;
        }
        @media only screen and (max-width: 448px) {
            width: 0.7rem;
            height: 0.7rem;
        }
    }
    .swiper .swiper-pagination-bullet-active {
        background-color: #fcd11e;
        width: 10px;
        height: 10px;

        @media only screen and (max-width: 768px) {
            width: 0.6rem;
            height: 0.6rem;
        }
        @media only screen and (max-width: 448px) {
            width: 0.7rem;
            height: 0.7rem;
        }
    }
`;

const SwiperContainer = styled.div`
    margin: 0 auto;
`;

const BottomDescriptionContainer = styled.div`
    display: flex;
    gap: 5px;
    max-width: 1024px;
    margin: 0 auto;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    padding-left: 10px;

    .infoIcon {
        width: 14px;
        height: 14px;
        margin-left: 10px;
    }
`;

const ComapareButtonContainer = styled.div`
    position: relative;
    margin: 2rem auto 100px;
    text-align: center;

    @media only screen and (max-width: 768px) {
        margin: 0 auto 100px;
    }
`;

const CompareImage = styled.img`
    position: absolute;
    top: -1.95rem;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0%);
    width: 4.4rem;
    height: 2.9rem;
`;

const CompareButton = styled.div<{ compareOn?: boolean }>`
    display: inline-block;

    padding: 0.6rem 2rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 600;

    border-radius: 21px;
    background-color: #fcd11e;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

    cursor: pointer;

    ${({ compareOn }) =>
        !compareOn &&
        `pointer-events: none;
        `};
`;

const CompareImageMobile = styled.img`
    display: block;
    margin: 4rem auto 0;
    width: 29rem;
    height: 19.6rem;
`;
