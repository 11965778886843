import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

SwiperCore.use([Navigation, Pagination]);

const AdoptBanner = ({ adoptData }: any) => {
    const [adsData, setAdsData] = useState<any>([]);

    useEffect(() => {
        if (adoptData !== undefined) {
            const animalType =
                adoptData.pet_type === "DOG" ? `강아지` : `고양이`;
            axios
                .get(
                    `${process.env.REACT_APP_API_URL_DEV}partners/ad/display-ads/?channel=ALL&pet_type=${animalType}&asset_type=LANDSCAPE`
                )
                .then(res => {
                    setAdsData(res.data);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [adoptData]);

    return (
        <>
            <AdoptBannerContainer>
                <SwiperContainer>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        pagination={{
                            clickable: true,
                            renderBullet: function (index, className) {
                                return (
                                    '<span class="' +
                                    className +
                                    '"><img class="pagination-bullet" /></span>'
                                );
                            },
                        }}
                    >
                        {adsData?.map((data: any) => (
                            <SwiperSlide key={data.id}>
                                <BannerImage>
                                    <a
                                        href={data.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={data?.asset?.source_url}
                                            alt="광고 배너"
                                        />
                                    </a>
                                </BannerImage>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </SwiperContainer>
            </AdoptBannerContainer>
        </>
    );
};

export default AdoptBanner;

const AdoptBannerContainer = styled.div`
    max-width: 64rem;
    margin: 3rem auto;

    @media only screen and (max-width: 768px) {
        margin: 2rem auto 2.5rem;
    }

    .swiper-container {
        width: 1014px;
    }

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 20px;

        @media only screen and (max-width: 768px) {
            bottom: 1rem;
        }
        @media only screen and (max-width: 384px) {
            bottom: 1.2rem;
        }
    }

    .swiper-backface-hidden .swiper-slide {
        display: flex;
        justify-content: center;
    }

    .swiper .swiper-pagination-bullet {
        background-color: #868688;
        width: 10px;
        height: 10px;

        @media only screen and (max-width: 768px) {
            width: 0.4rem;
            height: 0.4rem;
        }
        @media only screen and (max-width: 384px) {
            width: 0.6rem;
            height: 0.6rem;
        }
    }
    .swiper .swiper-pagination-bullet-active {
        background-color: #fcd11e;
        width: 10px;
        height: 10px;

        @media only screen and (max-width: 768px) {
            width: 0.4rem;
            height: 0.4rem;
        }
        @media only screen and (max-width: 384px) {
            width: 0.6rem;
            height: 0.6rem;
        }
    }
`;

const SwiperContainer = styled.div`
    margin: 0 auto;
`;

const BannerImage = styled.div`
    width: 63.3rem;
    height: 15.8rem;
    margin: 10px;
    border-radius: 20px;
    overflow: hidden;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        width: 40rem;
        height: 10rem;
    }
    @media only screen and (max-width: 640px) {
        width: 36rem;
        height: 9rem;
    }
    @media only screen and (max-width: 400px) {
        width: 32rem;
        height: 8rem;
    }
`;
