export default class StaticUrl {
    public static get homepage(): string {
        return "https://tools.mypetlife.co.kr";
    }

    public static get bemypetlife(): string {
        return "https://mypetlife.co.kr";
    }

    public static get bemypetcreators(): string {
        return "https://creators.mypetlife.co.kr";
    }

    public static get bemypetcomebackhome(): string {
        return "https://cbh.bemypet.kr/";
    }

    public static get bemypetstore(): string {
        return "https://store.bemypet.kr";
    }

    public static get facebook(): string {
        return "https://www.facebook.com/bemypetkr";
    }

    public static get youtube(): string {
        return "https://www.youtube.com/channel/UClljZ2pw3YZFVGq6EQgJVyQ";
    }

    public static get instagram(): string {
        return "https://www.instagram.com/bemypet.official/";
    }

    public static get playStore(): string {
        return "https://play.google.com/store/apps/details?id=kr.bemypet.bemypet_main_app";
    }

    public static get appStore(): string {
        return "https://apps.apple.com/kr/app/%EB%B9%84%EB%A7%88%EC%9D%B4%ED%8E%AB-%EB%B0%98%EB%A0%A4%EB%8F%99%EB%AC%BC-%EC%A7%80%EC%8B%9D%EC%A0%95%EB%B3%B4-%ED%94%8C%EB%9E%AB%ED%8F%BC/id1506523332?&utm_source=mainwebsite&utm_campaign=initial";
    }

    public static get Home(): string {
        return "https://bemypet.kr/";
    }

    public static get Shopping(): string {
        return "https://bemypet.kr/shopping/situation?pet_type=dog&q=1&p=20";
    }

    public static get Tools(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}`
    }

    public static get Content(): string {
        return "https://bemypet.kr/content?p=2";
    }

    public static get Community(): string {
        return "https://bemypet.kr/community?p=1";
    }

    public static get Creator(): string {
        return "https://bemypet.kr/partnership";
    }

    public static get QnA(): string {
        return "https://bemypet.kr/qapage/detail/";
    }

    public static get ToolsHome(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}`
    }

    public static get ToolsInsurances(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}insurance`;
    }

    public static get FavoriteInsurance(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}insurance/favorite`
    }

    public static get ToolsAdopt(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}adoption/adopt`;
    }

    public static get Naming(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}adoption/name-generator`;
    }

    public static get RegisterSearch(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}adoption/registration-verification`;
    }

    public static get FavoriteAnimal(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}adoption/favorite`;
    }

    public static get ToolsCalculate(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}calculator`;
    }

    public static get FoodCalc(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}calculator/food-calorie`;
    }

    public static get RecommendCalc(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}calculator/recommend-calorie`;
    }

    public static get CalcBMI(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}calculator/bmi`;
    }

    public static get CalcAge(): string {
        return `${process.env.REACT_APP_DOMAIN_URL_DEV}calculator/age`;
    }
}
