import { adoptNoFavorite } from "assets";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

type textDataObjType = {
    [index: string]: {
        title: string;
        description: string;
        buttonText: string;
        navigateLink: string;
    };
};

const AdoptFavoriteNoResults = ({ props }: { props: string }) => {
    const navigate = useNavigate();

    const textDataObj: textDataObjType = {
        adoption: {
            title: "나의 관심동물",
            description: "아직 내가 관심 깊게 지켜본 아이들이 없어요",
            buttonText: "관심동물 찾으러 가기",
            navigateLink: "/adoption/adopt",
        },
        insurance: {
            title: "관심 펫보험",
            description: "아직 내가 관심 가진 펫보험이 없어요",
            buttonText: "펫보험 찾으러 가기",
            navigateLink: "/insurance",
        },
    };

    const textData = textDataObj[props];

    return (
        <>
            <AdoptFavoriteNoResultsContainer>
                <AdoptFavoriteTitleContainer>
                    <AdoptFavoriteTitle>{textData.title}</AdoptFavoriteTitle>
                    <AdoptFavoriteDescription>{textData.description}</AdoptFavoriteDescription>
                </AdoptFavoriteTitleContainer>
                <AdoptNavigateButton
                    onClick={() => {
                        navigate(textData.navigateLink);
                        // setTimeout(() => {
                        //     window.scrollTo({
                        //         top: 880,
                        //         behavior: "smooth",
                        //     });
                        // }, 0);
                    }}
                >
                    {textData.buttonText}
                </AdoptNavigateButton>
                <AdoptNoFavoriteImage>
                    <img src={adoptNoFavorite} alt="삼색이 리리" />
                </AdoptNoFavoriteImage>
            </AdoptFavoriteNoResultsContainer>
        </>
    );
};

export default AdoptFavoriteNoResults;

const AdoptFavoriteNoResultsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 22rem;
    align-items: center;

    @media only screen and (max-width: 768px) {
        gap: 19rem;
    }
`;

const AdoptFavoriteTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    margin: 0 auto;
    z-index: 1;

    @media only screen and (max-width: 768px) {
        margin-top: 1.1rem;
    }
`;

const AdoptFavoriteTitle = styled.div`
    padding: 3rem 0 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 600;
    text-align: center;

    @media only screen and (max-width: 768px) {
        font-size: 2.1rem;
        line-height: 2.6rem;
    }
`;

const AdoptFavoriteDescription = styled.div`
    padding: 0.75rem 0;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
    text-align: center;

    @media only screen and (max-width: 768px) {
        font-size: 1.55rem;
        line-height: 1.9rem;
    }
`;

const AdoptNoFavoriteImage = styled.div`
    position: absolute;
    top: 0;
    width: 40rem;
    height: 40rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media only screen and (max-width: 768px) {
        width: 33rem;
        height: 33rem;
        margin-top: 4rem;
    }
`;

const AdoptNavigateButton = styled.div`
    padding: 0.6rem 1.4rem;
    margin: 0.5rem 0 8rem;
    font-size: 1.375rem;
    line-height: 1.625rem;
    font-weight: 600;

    border-radius: 21px;
    background-color: #fcd11e;
    z-index: 1;
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
    }

    @media only screen and (max-width: 768px) {
        padding: 1rem 2.2rem;
        font-size: 1.55rem;
        line-height: 1.9rem;
    }
`;
