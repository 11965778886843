import React from "react";
import styled from "styled-components";
import CheckedIcon from "components/icons/CheckedIcon";
import { colors } from "theme";

interface CheckBoxProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  onChange?: (value: any) => void;
}

const CheckBoxButton = ({ label, value, checked, onChange }: CheckBoxProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!onChange) {
      onChange(e.target.checked);
    }
  };
  return (
    <CheckBoxWrapper>
      <CheckBoxLabel color={checked ? colors.grey700 : colors.grey400}>
        <BaseCheckBoxButton
          type="checkbox"
          value={value}
          checked={checked}
          onChange={handleOnChange}
        />
        {checked && (
          <IconWrapper>
            <CheckedIcon />
          </IconWrapper>
        )}
        {label}
      </CheckBoxLabel>
    </CheckBoxWrapper>
  );
};

const CheckBoxWrapper = styled.div<CheckBoxProps>`
  position: relative;
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  height: 20px;
`;

const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${({ color }) => !!color && color};
  padding: 0px;
  margin: 0px;

  font-family: JalnanOTF;
  font-size: 14px;
  line-height: 1.86;

  :hover {
    cursor: pointer;
  }
`;

const BaseCheckBoxButton = styled.input`
  padding: 3px 0px;
  background-color: ${colors.grey200};
  margin: 0px 10px 0px 0px;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  outline: none;

  &:checked {
    background-color: ${colors.primary100};
  }
`;

export { CheckBoxButton };
