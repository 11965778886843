import React from "react";
import styled from "styled-components";


interface FormWarningLabelProps {
  label?: string;
}

export const FormWarningLabel = ({
  label,
}: FormWarningLabelProps) => {
  return (
    <FormWarningLabelWrapper>
      {label}
    </FormWarningLabelWrapper>
  );
};

const FormWarningLabelWrapper = styled.div`
  font-size: 12px;
  color: #F24147;
`;