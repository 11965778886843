import React from "react";
import styled from "styled-components";
import { checkIcon, cancelIcon } from "assets";
import { ReactComponent as NewWindowIcon } from "../../assets/DrawerImages/NEW_window.svg";
import { useMediaQuery } from "react-responsive";

const CompareBody = ({ insuranceData, benefitData }: any) => {
    const isMobile = useMediaQuery({
        query: "(max-width:512px)",
    });

    return (
        <>
            <CompareBodyContainer>
                <InsuranceLogoContainer
                    href={insuranceData?.action_link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <InsuranceLogo
                        src={insuranceData?.insurance_company?.landscape_logo}
                        alt={insuranceData?.insurance_company?.name}
                    />
                    <IconContainer>
                        {isMobile ? (
                            <NewWindowIcon width={9} height={9} />
                        ) : (
                            <NewWindowIcon />
                        )}
                    </IconContainer>
                    <InsuranceNameContainer>
                        <InsuranceName>{insuranceData?.name}</InsuranceName>
                    </InsuranceNameContainer>
                </InsuranceLogoContainer>
                <InsuranceDivider />
                <CompareBodyList>
                    {benefitData?.map((data: any) => (
                        <ContentContainer
                            key={data.category_id}
                            check={data.description === "없음"}
                        >
                            {data.description === "없음" ? (
                                <>
                                    <ContentLogo
                                        src={cancelIcon}
                                        alt="none"
                                    ></ContentLogo>
                                </>
                            ) : (
                                <>
                                    <ContentLogo
                                        src={checkIcon}
                                        alt="check"
                                    ></ContentLogo>
                                    <ContentText>
                                        {data.description}
                                    </ContentText>
                                </>
                            )}
                        </ContentContainer>
                    ))}
                    <TextAge>만 {insuranceData?.join_age_limit}세까지</TextAge>
                    <TextAge>
                        만 {insuranceData?.refresh_age_limit}세까지
                    </TextAge>
                    <TextAge>
                        {insuranceData?.deductible?.toLocaleString()}원
                    </TextAge>
                    <TextAge>{insuranceData?.guarantee_rate * 100}%</TextAge>
                    <TextPrice>
                        {insuranceData?.price
                            ? `월 ${insuranceData?.price?.toLocaleString()}원`
                            : "확인 필요"}
                    </TextPrice>
                </CompareBodyList>
            </CompareBodyContainer>
        </>
    );
};

export default CompareBody;

const CompareBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CompareBodyList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    @media only screen and (max-width: 512px) {
        gap: 15px;
    }
`;

const InsuranceLogoContainer = styled.a`
    position: relative;
    width: 100%;
    height: 92px;
    margin-bottom: 7px;
    text-align: center;

    :active {
        opacity: 0.7;
    }

    @media only screen and (max-width: 512px) {
        height: 45px;
    }
`;

const InsuranceLogo = styled.img`
    width: 100%;
    object-fit: cover;

    @media only screen and (max-width: 896px) {
        padding-top: 10px;
    }
    @media only screen and (max-width: 768px) {
        padding-top: 20px;
    }
    @media only screen and (max-width: 512px) {
        padding-top: 7px;
    }
`;

const IconContainer = styled.div`
    position: absolute;
    top: 32px;
    right: 27px;

    @media only screen and (max-width: 896px) {
        right: 20px;
    }
    @media only screen and (max-width: 768px) {
        right: 7px;
    }
    @media only screen and (max-width: 512px) {
        top: 15px;
        right: 7px;
    }
`;

const InsuranceNameContainer = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;

    @media only screen and (max-width: 512px) {
        bottom: -7px;
    }
`;

const InsuranceName = styled.div`
    font-size: 13px;
    line-height: 23px;
    font-weight: 700;
    color: #1c1b1f;
    text-align: center;
    text-decoration: none;

    @media only screen and (max-width: 512px) {
        font-size: 8px;
        line-height: 23px;
        font-weight: 600;
    }
`;

const InsuranceDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #e4e4e6;
`;

const ContentContainer = styled.div<{ check?: boolean }>`
    height: 62px;
    padding: 15px 0;
    text-align: center;

    ${({ check }) =>
        check &&
        `height: 30px;
    padding: 15px 0 47px;`};

    @media only screen and (max-width: 512px) {
        height: 35px;
        padding: 11px 0 12px;

        ${({ check }) =>
            check &&
            `height: 20px;
    padding: 11px 0 27px;`};
    }
`;

const ContentLogo = styled.img`
    width: 30px;
    height: 30px;
    margin-top: 5px;

    @media only screen and (max-width: 512px) {
        width: 20px;
        height: 20px;
    }
`;

const ContentText = styled.div`
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    word-break: keep-all;
    padding: 0 8px;

    @media only screen and (max-width: 512px) {
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        word-break: keep-all;
    }
`;

const TextAge = styled.div`
    padding: 37px 0 36px;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;

    @media only screen and (max-width: 512px) {
        padding: 23px 0 22px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 600;
    }
`;

const TextPrice = styled.div`
    padding: 36px 0 30px;
    text-align: center;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;

    @media only screen and (max-width: 512px) {
        padding: 23px 0 22px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 600;
    }
`;
