import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactGA from "react-ga";
import { ageMain, socialAge } from "assets";
import CoverSection from "components/CoverSection";
import Layout from "components/Layout";
import { InlineColor } from "components/Typography";
import { colors } from "theme";

import AgeCalculator from "./AgeCalculator";
import AgeCalculatorForm from "./AgeCalculatorForm";
import AgeCalculatorResult from "./AgeCalculatorResult";

const AgeCalculatorPage = () => {
    const [form] = useState<AgeCalculator>(new AgeCalculator());
    const [isResultOpen, setIsResultOpen] = useState<boolean>(false);
    const [, setState] = useState<any>({});

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    useEffect(() => {
        ReactGA.event({
            category: "나이 계산기",
            action: "페이지 접근",
        });
    }, []);

    const handleOnChange = (fieldName: keyof AgeCalculator, value: any) => {
        form.update({
            [fieldName]: value,
        });
        setState({ ...form });
    };

    const handleOnSubmit = async () => {
        setIsResultOpen(false);
        setState({ ...form }); // for update state
        const isValid = await form.validate();
        if (isValid) {
            setIsResultOpen(true);
            ReactGA.event({
                category: "나이 계산기",
                action: "계산",
                label: form.animal,
            });
        }
    };

    return (
        <AgeMainContainer isLoading={isLoading} style={{ display: "none" }}>
            <Layout
                header={{
                    title: "강아지, 고양이 나이 계산기",
                    description:
                        "고양이 나이 계산기 ,강아지 나이 계산기 - 우리 집 강아지/고양이의 나이는 사람 나이로 몇 살인지 알아보세요.",
                    ogImage: socialAge,
                    ogUrl: "/calculator/age",
                }}
            >
                <CoverSection
                    image={ageMain}
                    title={
                        <>
                            <InlineColor color={colors.orange100}>
                                나이계산기
                            </InlineColor>
                            란?
                        </>
                    }
                    description={
                        <>
                            우리 아이의 나이,
                            <br />
                            사람나이로는 몇살일까요?
                        </>
                    }
                />
                <AgeCalculatorForm
                    form={form}
                    onChange={handleOnChange}
                    onSubmit={handleOnSubmit}
                />
                {isResultOpen && <AgeCalculatorResult form={form} />}
            </Layout>
        </AgeMainContainer>
    );
};

export default AgeCalculatorPage;

const AgeMainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;
