import React from "react";
import styled from "styled-components";
import { Button } from "components/Button";
import DropDown, { DropDownOption } from "components/DropDown";
import { Form, FormField, FormButtonSection } from "components/Form";
import { AnimalSelect } from "components/Select";
import { Input } from "components/Input";
import { colors } from "theme";

import CalorieRecommendation, {
  DogStatus,
  CatStatus,
  CalorieRecommendationData,
} from "./CalorieRecommendation";

interface RecommentCalorieFormProps {
  form: CalorieRecommendation;
  onChange: (fieldName: keyof CalorieRecommendationData, value: any) => void;
  onSubmit: () => void;
}

const RecommentCalorieForm: React.FC<RecommentCalorieFormProps> = ({
  form,
  onChange,
  onSubmit,
}) => {
  const handleOnChange = (fieldName: keyof CalorieRecommendationData) => (
    value: any,
  ) => {
    onChange(fieldName, value);
  };

  let options: DropDownOption[] = [];
  if (form.isDog) {
    options = Object.values(DogStatus).map(
      (x) => ({ label: x, value: x } as DropDownOption),
    );
  } else if (form.isCat) {
    options = Object.values(CatStatus).map(
      (x) => ({ label: x, value: x } as DropDownOption),
    );
  }

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      {/** 동물 종류 */}
      <FormField label={"반려동물 종류"} errorMessage={form.errors.animal}>
        <AnimalSelect value={form.animal} onChange={handleOnChange("animal")} />
      </FormField>

      {/** 동물 몸무게 */}
      <FormField label={"반려동물 몸무게"} errorMessage={form.errors.weight}>
        <Input
          name="weight"
          type="number"
          placeholder="소수점 둘째자리까지 입력가능"
          value={form.weight || ""}
          onChange={handleOnChange("weight")}
          trailing={<Trailing>kg</Trailing>}
          step="0.01"
          decimal={2}
          invalid={!!form.errors.weight}
        />
      </FormField>

      {/** 동물 상태 */}
      <FormField label={"반려동물 상태"} errorMessage={form.errors.status}>
        <DropDown
          placeholder="반려동물 상태를 선택해주세요."
          options={options}
          onClick={handleOnChange("status")}
          value={form.status}
          disabled={!form.animal}
          invalid={!!form.errors.status}
        />
      </FormField>

      {/** Submit */}
      <FormButtonSection>
        <Button type="submit" variant="primary">
          결과보기
        </Button>
      </FormButtonSection>
    </Form>
  );
};

const Trailing = styled.div`
  width: 26px;
  height: 26px;
  font-family: JalnanOTF;
  font-size: 14px;
  line-height: 1.86;
  color: ${colors.grey700};
`;

export default RecommentCalorieForm;
