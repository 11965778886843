import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BriefCheck from "./BriefCheck/BriefCheck";
import Information from "./Information/Information";
import QnA from "./QnA/QnA";
import Popular from "./Popular/Popular";
import Description from "./Description/Description";
import QnATitle from "./QnA/QnATitle";
import Layout from "components/Layout";
import { ogImage } from "assets";

const MainComponent = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <>
            <MainContainer isLoading={isLoading} style={{ display: "none" }}>
                <Layout
                    header={{
                        title: "펫보험 간편 비교",
                        description:
                            "펫보험 간편 비교. 펫보험 가격 및 추천 정보를 간편하게 알아보세요",
                        ogImage: ogImage,
                        ogUrl: "/",
                    }}
                >
                    <BriefCheck />
                    <Popular />
                    <Description />
                    <QnATitle />
                    <QnA />
                    <Information />
                </Layout>
            </MainContainer>
        </>
    );
};

export default MainComponent;

const MainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;
