import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { liliMini, samsekMini } from "assets";
import InputAutoComplete from "insuranceComponents/main/InputAutoComplete";
import { useNavigate } from "react-router-dom";

const CompareSelect = () => {
    const navigate = useNavigate();
    const submitRef = useRef<HTMLInputElement | null>(null);
    const [petType, setPetType] = useState<string>("");
    const [inputValue1, setInputValue1] = useState<{
        id: number;
        name: string;
    }>({ id: -1, name: "" });
    const [inputValue2, setInputValue2] = useState<{
        id: number;
        name: string;
    }>({ id: -1, name: "" });
    const [inputError, setInputError] = useState<boolean | undefined>(false);
    const [submitError, setSubmitError] = useState<boolean>(false);

    useEffect(() => {
        setInputValue1({ id: -1, name: "" });
        setInputValue2({ id: -1, name: "" });
        setSubmitError(false);
    }, [petType]);

    useEffect(() => {
        if (inputValue1.id !== -1 && inputValue2.id !== -1)
            setSubmitError(false);
    }, [inputValue1, inputValue2]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                submitRef.current &&
                !submitRef.current.contains(event.target as Node)
            ) {
                setSubmitError(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const submitCompare = () => {
        if (inputValue1.id === -1 || inputValue2.id === -1)
            setSubmitError(true);
        else
            navigate(
                `/insurance/compare?animal_type=${petType}&id1=${inputValue1.id}&id2=${inputValue2.id}`
            );
    };

    return (
        <div>
            <QuickCheck>
                <QuickRadioWrapper>
                    <QuickRadioButton
                        selected={petType === "dog"}
                        onClick={() => {
                            setPetType("dog");
                            setInputError(false);
                        }}
                    >
                        <img src={liliMini} alt="강아지" />
                        강아지
                    </QuickRadioButton>
                    <QuickRadioButton
                        selected={petType === "cat"}
                        onClick={() => {
                            setPetType("cat");
                            setInputError(false);
                        }}
                    >
                        <img src={samsekMini} alt="고양이" />
                        고양이
                    </QuickRadioButton>
                </QuickRadioWrapper>
                <SelectContainer>
                    {inputError && (
                        <InputErrorMessage>
                            강아지, 고양이를 먼저 선택해주세요.
                        </InputErrorMessage>
                    )}
                    <InputAutoComplete
                        petType={petType}
                        inputError={inputError}
                        inputValue={inputValue1}
                        setInputError={setInputError}
                        setInputValue={setInputValue1}
                        otherInput={inputValue2}
                    />
                    <InputAutoComplete
                        petType={petType}
                        inputError={inputError}
                        inputValue={inputValue2}
                        setInputError={setInputError}
                        setInputValue={setInputValue2}
                        otherInput={inputValue1}
                    />
                    <QuickButtonContainer>
                        {submitError && (
                            <SubmitErrorMessage>
                                {inputValue1.id !== -1 || inputValue2.id !== -1
                                    ? "하나의 옵션을 더 선택해주세요"
                                    : "옵션을 먼저 선택해주세요"}
                            </SubmitErrorMessage>
                        )}
                        <QuickButton
                            onClick={submitCompare}
                            submitError={submitError}
                            ref={submitRef}
                        >
                            비교하기
                        </QuickButton>
                    </QuickButtonContainer>
                </SelectContainer>
            </QuickCheck>
        </div>
    );
};

export default CompareSelect;

const QuickCheck = styled.div`
    padding: 2.65rem 2.19rem;
    background-color: white;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    column-gap: 20%;
    gap: 2.5rem 0;
`;

const QuickRadioWrapper = styled.div`
    display: flex;
`;

const QuickRadioButton = styled.div<{ selected?: boolean }>`
    width: 4.5rem;
    height: 4.5rem;
    margin-top: 5px;
    margin-right: 26px;
    background-color: #fffdf4;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    font-size: 0.81rem;
    line-height: normal;
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    ${({ selected }) =>
        selected &&
        `background-color: #fff5ce;
    box-shadow: 0px 30px 12px rgba(0, 0, 0, 0.01),
            0px 17px 10px rgba(0, 0, 0, 0.05), 0px 7px 7px rgba(0, 0, 0, 0.09),
            0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);`};

    img {
        width: 2.5rem;
        margin: 0.69rem 1rem 0.38rem;
    }

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
    }
`;

const SelectContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2.25rem 0;
`;

const InputErrorMessage = styled.div`
    position: absolute;
    top: -18px;
    left: 4px;
    font-size: 0.68rem;
    line-height: 0.81rem;
    font-weight: 400;
    color: #f24147;
`;

const QuickButtonContainer = styled.div`
    position: relative;
    width: 100%;
    height: 3.25rem;
    text-align: center;
`;

const SubmitErrorMessage = styled.div`
    position: absolute;
    width: fit-content;
    top: -1.25rem;
    left: 8.625rem;
    font-size: 0.68rem;
    line-height: 0.81rem;
    font-weight: 400;
    color: #f24147;
`;

const QuickButton = styled.div<{ submitError?: boolean }>`
    font-family: "Apple SD Gothic Neo";
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 10.88rem;
    padding: 0.88rem 0;
    margin: 0 auto;
    background-color: #fcd11e;
    color: black;
    font-size: 1.25rem;
    line-height: normal;
    font-weight: 500;
    border-radius: 25px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    ${({ submitError }) => submitError && `background-color: #BDBDBD;`};
`;
