import React from "react";
import styled, { keyframes } from "styled-components";
import { spinnerLili, spinnerSamsek } from "assets";

const CustomSpinner = ({ petType }: any) => {
    return (
        <>
            <SpinnerCircle>
                <SpinnerOuro petType={petType}>
                    <SpinnerLeft>
                        <SpinnerLeftAnim></SpinnerLeftAnim>
                    </SpinnerLeft>
                    <SpinnerRight>
                        <SpinnerRightAnim></SpinnerRightAnim>
                    </SpinnerRight>
                </SpinnerOuro>
            </SpinnerCircle>
        </>
    );
};

export default CustomSpinner;

const UiSpinnerRotateRight = keyframes`
            0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(180deg);
        }
        50% {
            transform: rotate(180deg);
        }
        75% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(360deg);
        }
`;
const UiSpinnerRotateLeft = keyframes`
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(180deg);
        }
        75% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(360deg);
        }
`;

const SpinnerCircle = styled.div`
    text-align: center;
    margin: auto;
    padding-top: 30px;
`;

const SpinnerOuro = styled.span<{ petType: any }>`
    position: relative;
    display: inline-block;
    width: 226px;
    height: 226px;
    margin: 1em;
    border-radius: 50%;
    background: none repeat scroll 0 0 #f6f6f9;
    overflow: hidden;
    box-shadow: 0px 1.61448px 3.22896px rgba(0, 0, 0, 0.15);

    :after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 190px;
        height: 190px;
        background: none repeat scroll 0 0 #ffffff;
        border-radius: 50%;
        box-shadow: 0px 1.61448px 3.22896px rgba(0, 0, 0, 0.15);
        ${props =>
            props.petType === "강아지"
                ? `background-image: url(${spinnerLili});`
                : `background-image: url(${spinnerSamsek});`}
        background-repeat: no-repeat;
        background-position: center;
        background-size: 120px 75px;
    }

    > span {
        position: absolute;
        height: 100%;
        width: 50%;
        overflow: hidden;
    }
`;

const SpinnerLeft = styled.span`
    left: 0;
`;

const SpinnerLeftAnim = styled.span`
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 999px;
    background: none repeat scroll 0 0 #fcd11e !important;
    opacity: 0.8;
    -webkit-animation: ${UiSpinnerRotateLeft} 5s infinite;
    animation: ${UiSpinnerRotateLeft} 5s infinite;
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;

    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    -webkit-animation-delay: 0s;
    -webkit-animation-duration: 5s;
    -webkit-animation-timing-function: linear;
    animation-delay: 0s;
    animation-duration: 5s;
    animation-timing-function: linear;
`;

const SpinnerRight = styled.span`
    right: 0;
`;

const SpinnerRightAnim = styled.span`
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 999px;
    background: none repeat scroll 0 0 #fcd11e;
    opacity: 0.8;
    -webkit-animation: ${UiSpinnerRotateLeft} 5s infinite;
    animation: ${UiSpinnerRotateLeft} 5s infinite;
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;

    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    left: -100%;
    -webkit-transform-origin: 100% 50% 0;
    transform-origin: 100% 50% 0;

    -webkit-animation-delay: 0s;
    -webkit-animation-duration: 5s;
    -webkit-animation-timing-function: linear;
    animation-delay: 0s;
    animation-duration: 5s;
    animation-timing-function: linear;

    -webkit-animation-name: ${UiSpinnerRotateRight};
    -webkit-animation-delay: 0;
    -webkit-animation-delay: 2.5s;
    animation-name: ${UiSpinnerRotateRight};
    animation-delay: 0;
    animation-delay: 2.5s;
`;
