import {
    petPersonalityCat1,
    petPersonalityCat2,
    petPersonalityCat3,
    petPersonalityCat4,
    petHairCat1,
    petHairCat2,
    petHairCat3,
    petHairCat4,
    petColorCat1,
    petColorCat2,
    petColorCat3,
    petColorCat4,
    petPersonalityDog1,
    petPersonalityDog2,
    petPersonalityDog3,
    petPersonalityDog4,
    petHairDog1,
    petHairDog2,
    petHairDog3,
    petHairDog4,
    petColorDog1,
    petColorDog2,
    petColorDog3,
    petColorDog4,
    petGenderFemale,
    petGenderMale,
    petTypeCat,
    petTypeDog,
} from "assets";

export const petTypeData = {
    title: "내 반려동물은?",
    image_size: {
        width_w: 224,
        height_w: 224,
        width_m: 165,
        height_m: 165,
    },
    dataList: [
        {
            img: petTypeDog,
            text: "강아지",
            value: "강아지",
        },
        {
            img: petTypeCat,
            text: "고양이",
            value: "고양이",
        },
    ],
};

export const petGenderData = {
    title: "내 반려동물의 성별은?",
    image_size: {
        width_w: 200,
        height_w: 288,
        width_m: 159,
        height_m: 229,
    },
    dataList: [
        {
            img: petGenderMale,
            text: "남아",
            value: "수컷",
        },
        {
            img: petGenderFemale,
            text: "여아",
            value: "암컷",
        },
    ],
};

export const petPersonalityData = {
    title: "내 반려동물의 성향은?",
    dogDataList: [
        {
            id: 1,
            img: petPersonalityDog1,
            keyword: "소심한",
            description: "무서운 것이 많은 친구인가요?",
            value: "소심한",
        },
        {
            id: 2,
            img: petPersonalityDog2,
            keyword: "새침한",
            description: "자주 새침해지는 멍멍이",
            value: "새침한",
        },
        {
            id: 3,
            img: petPersonalityDog3,
            keyword: "활발한",
            description: "에너지가 넘치는 우리 아이!",
            value: "활발한",
        },
        {
            id: 4,
            img: petPersonalityDog4,
            keyword: "듬직한",
            description: "함께 다니면 정말 듬직해요!",
            value: "듬직한",
        },
    ],
    catDataList: [
        {
            id: 1,
            img: petPersonalityCat1,
            keyword: "사람이 좋은",
            description: "집사와 함께라면 행복해",
            value: "사람을 좋아하는",
        },
        {
            id: 2,
            img: petPersonalityCat2,
            keyword: "사냥꾼",
            description: "사냥감을 절대 놓지지 않아!",
            value: "사냥꾼",
        },
        {
            id: 3,
            img: petPersonalityCat3,
            keyword: "예민한",
            description: "겁이 많아서 경계하는 거예요",
            value: "예민한",
        },
        {
            id: 4,
            img: petPersonalityCat4,
            keyword: "호기심이 많은",
            description: "처음 보는 고양이와 사람도 좋아!",
            value: "호기심이 많은",
        },
    ],
};

export const petHairData = {
    title: "내 반려동물의 털은?",
    dogDataList: [
        {
            id: 1,
            img: petHairDog1,
            keyword: "이중모 / 직모",
            description: "쭉쭉 뻗은 내 털!",
            value: "이중모에 직모이고",
        },
        {
            id: 2,
            img: petHairDog2,
            keyword: "고불 고불",
            description: "동글 동글 고불 고불한 내 털!",
            value: "곱슬이고",
        },
        {
            id: 3,
            img: petHairDog3,
            keyword: "장모",
            description: "길고 멋진 내 털!",
            value: "길고",
        },
        {
            id: 4,
            img: petHairDog4,
            keyword: "단모",
            description: "미용이 필요없는 내 털!",
            value: "짧고",
        },
    ],
    catDataList: [
        {
            id: 1,
            img: petHairCat1,
            keyword: "단모",
            description: "쭉쭉 뻗은 내 털!",
            value: "짧고",
        },
        {
            id: 2,
            img: petHairCat2,
            keyword: "장모",
            description: "길고 멋진 내 털!!",
            value: "길고",
        },
        {
            id: 3,
            img: petHairCat3,
            keyword: "고불 고불",
            description: "동글 동글 고불 고불한 내 털!",
            value: "곱슬이고",
        },
        {
            id: 4,
            img: petHairCat4,
            keyword: "털이 없는",
            description: "생각보다 부드러운 내 피부!",
            value: "없고",
        },
    ],
};

export const petColorData = {
    title: "내 반려동물은 무슨 색인가요?",
    dogDataList: [
        {
            id: 1,
            img: petColorDog1,
            keyword: "블랙 계열",
            description: "시크하고 멋진 블랙!",
            value: "블랙 계열",
        },
        {
            id: 2,
            img: petColorDog2,
            keyword: "브라운 / 베이지 계열",
            description: "초코인듯 캐러멜인듯!",
            value: "브라운&베이지 계열",
        },
        {
            id: 3,
            img: petColorDog3,
            keyword: "크림 / 화이트 계열",
            description: "밝은 크림과 화이트!",
            value: "크림&화이트 계열",
        },
        {
            id: 4,
            img: petColorDog4,
            keyword: "기타 색상",
            description: "내 털색은 다음 페이지에 적어달라멍!",
            value: "기타 색상",
        },
    ],
    catDataList: [
        {
            id: 1,
            img: petColorCat1,
            keyword: "블랙 / 그레이 계열",
            description: "시크하고 멋진 블랙!",
            value: "블랙&그레이 계열",
        },
        {
            id: 2,
            img: petColorCat2,
            keyword: "오렌지 계열",
            description: "치즈 고양이라고 불린다냥!",
            value: "오렌지 계열",
        },
        {
            id: 3,
            img: petColorCat3,
            keyword: "크림 / 화이트 계열",
            description: "밝은 크림과 화이트!",
            value: "크림&화이트 계열",
        },
        {
            id: 4,
            img: petColorCat4,
            keyword: "기타 색상",
            description: "내 털색은 다음 페이지에 적어달라냥!",
            value: "기타 색상",
        },
    ],
};
