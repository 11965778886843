import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DropdownIcon } from "../../assets/InsuranceImages/main/dropdown_arrow.svg";
import { ReactComponent as CloseRoundedIcon } from "../../assets/DrawerImages/close.svg";
import InputBreedMultiSelect from "./InputBreedMultiSelect";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { adoptBreedState } from "states";

interface DropDownBreedProps {
    petType?: string;
    setDropDownBreed: React.Dispatch<React.SetStateAction<boolean>>;
    allBreed: boolean;
    setAllBreed: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropDownBreed = ({
    petType,
    setDropDownBreed,
    allBreed,
    setAllBreed,
}: DropDownBreedProps) => {
    const [dropDownList, setDropDownList] = useState<boolean>(false);
    const [inputText, setInputText] = useState<string>("");
    const setAdoptBreed = useSetRecoilState(adoptBreedState);
    const [breedData, setBreedData] = useState<any>([]);
    const [allBreedData, setAllBreedData] = useState<any>([]);

    const handleInputChange = (e: any) => {
        setInputText(e.target.value);
        setDropDownList(true);
    };

    const handleClickAll = () => {
        setAdoptBreed(new Set());
        setAllBreed(true);
        setDropDownList(false);
    };

    const handleSubmitButton = () => {
        setDropDownBreed(false);
        setInputText("");
    };

    const fetchBreed = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}pet-adoption/breeds/?pet_type=${petType}`,
                {}
            )
            .then(res => {
                setBreedData(res.data);
                setAllBreedData(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (petType !== "" && allBreedData.length === 0) {
            fetchBreed();
        }
        if (inputText !== "" && allBreedData.length > 0) {
            setBreedData(
                allBreedData.filter((e: any) => {
                    return e?.name?.includes(inputText);
                })
            );
        }
        if (inputText === "" && allBreedData.length > 0) {
            setBreedData(
                allBreedData.filter((e: any) => {
                    return e?.name?.includes(inputText);
                })
            );
        }
        // eslint-disable-next-line
    }, [inputText, allBreedData]);

    useEffect(() => {
        if (petType !== "") {
            setDropDownList(false);
            fetchBreed();
        }
        // eslint-disable-next-line
    }, [petType]);

    return (
        <>
            <DropDownTypeContainer>
                <InputContainer>
                    <InputBox dropDownList={dropDownList}>
                        <InputText
                            placeholder="전체"
                            value={inputText}
                            onClick={() => setDropDownList(true)}
                            onChange={handleInputChange}
                        />
                        {inputText !== "" ? (
                            <CloseRoundedIcon
                                onClick={() => {
                                    setInputText("");
                                }}
                                className="icon"
                            />
                        ) : (
                            <DropdownIcon
                                className="icon"
                                onClick={() => setDropDownList(prev => !prev)}
                            />
                        )}
                    </InputBox>
                    {dropDownList && (
                        <InputListContainer noData={breedData.length === 0}>
                            <>
                                {inputText === "" && (
                                    <InputList
                                        selected={allBreed}
                                        onClick={handleClickAll}
                                    >
                                        전체
                                    </InputList>
                                )}

                                {breedData.map((data: any) => (
                                    <InputBreedMultiSelect
                                        key={data.name}
                                        breedData={data}
                                        // breedArr={breedArr}
                                        // setBreedArr={setAdoptBreed}
                                    />
                                ))}
                            </>
                        </InputListContainer>
                    )}
                </InputContainer>
                <SubmitButton onClick={handleSubmitButton}>적용</SubmitButton>
            </DropDownTypeContainer>
        </>
    );
};

export default DropDownBreed;

const DropDownTypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 17.5rem;
    padding: 1.25rem 0.75rem;
    background-color: #fffdf4;
    border: 1px solid #fcd11e;
    border-radius: 10px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); */

    @media only screen and (max-width: 768px) {
        width: 24.6rem;
        padding: 1.25rem 1rem;
    }
`;

const InputContainer = styled.div``;

const InputBox = styled.div<{ dropDownList?: boolean }>`
    position: relative;

    .icon {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        z-index: 3;
        transition: 0.3s;

        ${({ dropDownList }) =>
            dropDownList && `transform: scaleY(-1); transition: .3s; `};

        @media only screen and (max-width: 768px) {
            top: 1rem;
            right: 1rem;
            width: 1.4rem;
            height: 1.4rem;
        }
    }
`;

const InputListContainer = styled.div<{ noData?: boolean }>`
    margin-top: 5px;
    background-color: #ffffff;
    border: 1px solid #e4e4e6;
    border-radius: 5px;

    max-height: 11rem;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 4px;
    }
    ::-webkit-scrollbar-thumb {
        background: #fcd11e;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
        background-color: #cfcece;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    ${({ noData }) => noData && `display: none; `};

    @media only screen and (max-width: 768px) {
        width: 24.3rem;
        max-height: 11.4rem;
    }
`;

const InputList = styled.div<{ selected?: boolean }>`
    padding: 0.75rem;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;
    color: #000000;

    border-bottom: 1px solid #e4e4e6;
    cursor: pointer;

    :last-child {
        border: none;
    }

    :hover {
        /* background-color: #fff5ce; */
    }

    /* ${({ selected }) => selected && `background-color: #fff5ce;`}; */

    @media only screen and (max-width: 768px) {
        padding: 1rem;
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
`;

const InputText = styled.input`
    width: 14.5rem;
    padding: 0.75rem;
    padding-right: 2rem;
    background-color: #ffffff;
    border: 1px solid #e4e4e6;
    border-radius: 5px;

    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;
    color: #868688;

    :focus {
        outline: none;
        background-color: white;
        border: 1px solid #e4e4e6;
    }

    @media only screen and (max-width: 768px) {
        width: 20.5rem;
        padding: 1rem;
        padding-right: 2.7rem;
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
`;

const SubmitButton = styled.div`
    width: 1.5rem;
    margin: 0 auto;
    padding: 0.56rem 1.1rem;

    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 600;
    word-break: keep-all;
    text-align: center;

    border-radius: 21px;
    background-color: #fcd11e;
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
    }

    @media only screen and (max-width: 768px) {
        width: 1.9rem;
        padding: 0.6rem 1.5rem;
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;
