import { OkButtonImage } from "assets";
import React from "react";
import styled from "styled-components";


interface FormOkButtonProps {
  label?: string;
}

export const FormOkButton = ({
  label,
}: FormOkButtonProps) => {
  return (
    <FormOkButtonWrapper>
      {label}
      <FormOkButtonImg src={OkButtonImage}/>
    </FormOkButtonWrapper>
  );
};

const FormOkButtonWrapper = styled.div`
  width: 100%;
  max-width: 180px;
  height: 48px;
  background-color: #FCD11E;
  font-size: 22px;
  font-weight: 600;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const FormOkButtonImg = styled.img`
  position: absolute;
  width: 81px;
  height: 45px;
  bottom: 32px;
`