import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as CloseRoundedIcon } from "../../assets/DrawerImages/close.svg";
import ConvertToHttps from "adoptComponents/utils/ConvertToHttps";
import { imageNotFound } from "assets";

interface AdoptZoomInProps {
    adoptData: any;
    setImageZoomIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdoptZoomIn = ({ adoptData, setImageZoomIn }: AdoptZoomInProps) => {
    const handleImageError = (e: any) => {
        e.target.src = imageNotFound;
    };

    useEffect(() => {
        document.body.style.cssText = `
              position: fixed; 
              top: -${window.scrollY}px;
              overflow-y: scroll;
              width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = "";
            window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
    }, []);

    return (
        <>
            <AdoptZoomInContainer>
                <CloseIconWrapper onClick={() => setImageZoomIn(false)}>
                    <CloseRoundedIcon />
                </CloseIconWrapper>
                <AdoptZoomInImage>
                    <img
                        src={ConvertToHttps(adoptData.profile_url)}
                        alt={adoptData?.adopt_breed_text}
                        onError={handleImageError}
                    />
                </AdoptZoomInImage>
            </AdoptZoomInContainer>
        </>
    );
};

export default AdoptZoomIn;

const AdoptZoomInContainer = styled.div<{ imageZoomIn?: boolean }>`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #000;
    z-index: 9999;
`;

const AdoptZoomInImage = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;

    margin: 0 auto;

    img {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 768px;
        height: 768px;
        object-fit: contain;

        @media only screen and (max-width: 768px) {
            width: 100%;
            height: inherit;
            max-height: 80vh;
        }
    }
`;

const CloseIconWrapper = styled.button`
    position: absolute;
    top: 10px;
    right: 6px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    color: #868688;

    z-index: 999999999;

    :hover {
        cursor: pointer;
    }
`;
