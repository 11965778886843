import React from "react";
import { Button } from "components/Button";
import { Form, FormField, FormButtonSection } from "components/Form";
import { Input } from "components/Input";
import { RadioButton, RadioGroup } from "components/Radio";
import { AnimalSelect } from "components/Select";
import CalenderIcon from "components/icons/CalenderIcon";
import { colors } from "theme";

import AgeCalculator, { AgeCalculatorData, DogSize } from "./AgeCalculator";

interface AgeCalculatorFormProps {
  form: AgeCalculator;
  onChange: (fieldName: keyof AgeCalculatorData, value: any) => void;
  onSubmit: () => void;
}

const AgeCalculatorForm = ({
  form,
  onChange,
  onSubmit,
}: AgeCalculatorFormProps) => {
  const handleOnChange = (fieldName: keyof AgeCalculatorData) => (
    value: any,
  ) => {
    onChange(fieldName, value);
  };

  const handleOnDateChange = (value: any) => {
    if (value.length > 10) {
      return;
    }
    let newValue = value.replace(new RegExp(/\./, "g"), "");
    newValue = newValue.replace(/[^\d]/g, "");
    if (newValue.length > 4 && newValue.length <= 6) {
      newValue = newValue.replace(new RegExp(/(\d{4})(\d{1,2})/), "$1.$2");
    } else if (newValue.length > 6 && newValue.length <= 8) {
      newValue = newValue.replace(
        new RegExp(/(\d{4})(\d{2})(\d{1,2})/),
        "$1.$2.$3",
      );
    }
    onChange("date", newValue);
  };

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <FormField label={"반려동물 종류"} errorMessage={form.errors.animal}>
        <AnimalSelect value={form.animal} onChange={handleOnChange("animal")} />
      </FormField>
      <FormField label={"생년월일"} errorMessage={form.errors.date}>
        <Input
          placeholder="0000 . 00 . 00"
          leading={<CalenderIcon color={colors.grey400} />}
          onChange={handleOnDateChange}
          value={form.date || ""}
          invalid={!!form.errors.date}
        />
      </FormField>
      {form.isDog && (
        <FormField label="반려동물 크기" errorMessage={form.errors.dogSize}>
          <RadioGroup>
            {Object.values(DogSize).map((x) => (
              <RadioButton
                key={x}
                value={x}
                label={x}
                checked={form.dogSize === x}
                onChange={handleOnChange("dogSize")}
              />
            ))}
          </RadioGroup>
        </FormField>
      )}
      <FormButtonSection>
        <Button type="submit" variant="primary">
          결과보기
        </Button>
      </FormButtonSection>
    </Form>
  );
};

export default AgeCalculatorForm;
