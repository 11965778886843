import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { MainHeaderTab } from "./MainHeader";

const toolsHeaderList = [
    {
        title: "홈",
        url: "/",
        url2: "/notfound",
    },
    {
        title: "펫보험",
        url: "/insurance",
    },
    {
        title: "입양",
        url: "/adoption",
    },
    {
        title: "건강 계산기",
        url: "/calculator",
    },
];

const MobileHeader = () => {
    const pathName = useLocation().pathname;
    const splitPathName = pathName.split("/");
    const splitPathOne = `/${splitPathName[1]}`;

    return (
        <div id="headerSection_3">
            <MobileHeaderContainer>
                <MobileHeaderUl>
                    {toolsHeaderList.map((list: any) => (
                        <MainHeaderTab
                            label={list.title}
                            url={list.url}
                            key={list.title}
                            selected={
                                splitPathOne === list.url ||
                                splitPathOne === list.url2 ||
                                splitPathOne === list.url3
                            }
                        />
                    ))}
                </MobileHeaderUl>
            </MobileHeaderContainer>
        </div>
    );
};

export default MobileHeader;

const MobileHeaderContainer = styled.div`
    padding-top: 50px;
    background-color: white;

    border-bottom: 1px solid #e4e4e6;

    @media only screen and (max-width: 768px) {
        border-top: none;
    }

    margin-top: 0px;
`;

const MobileHeaderUl = styled.ul`
    display: flex;
    gap: 50px;
    text-align: center;
    background-color: white;
    align-items: center;
    justify-content: center;
    height: 46px;
    margin: 0;
    border-top: 1px solid #e4e4e6;

    @media only screen and (max-width: 768px) {
        border-top: none;
        gap: 35px;
        height: 35px;
        padding-top: 50px;
    }
    @media only screen and (max-width: 448px) {
        gap: 30px;
        padding-left: 0px;
    }
`;
