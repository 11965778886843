import { AnimalKind } from "libs/models";

export enum DogStatus {
  PuppyUnder4M = "성장기 (4개월미만)",
  Puppy = "성장기 (4-12개월)",
  Adult = "미중성 성견",
  NeuteredOrSpayed = "중성화 완료 성견",
  NeedWeightLoss = "체중 감량 필요 성견",
  NeedWeightGain = "체중 증량 필요 성견",
  LightActivityMinistryDog = "가벼운 활동량의 사역견",
  MediumActivityMinistryDog = "중간 활동량의 사역견",
  HardActivityMinistryDog = "높은 활동량의 사역견",
}

export enum CatStatus {
  Infancy = "유아기 (4개월미만)",
  RapidGrowthPeriodKitten = "고속 성장기 (4-6개월)",
  Kitten = "고속 성장기 (7-12개월)",
  NeuteredOrSpayed = "중성화 완료 성묘",
  General = "일반 성묘",
  NeedWeightLoss = "체중 감량 필요 성묘",
  HardActivityCat = "운동량이 많은 성묘",
  Old = "노묘 (n세 이상)",
}

export interface CalorieRecommendationData {
  animal?: AnimalKind;
  weight?: number;
  status?: DogStatus | CatStatus;
}

// models
export default class CalorieRecommendation
  implements CalorieRecommendationData {
  constructor(defaultValues?: CalorieRecommendationData) {
    if (!!defaultValues) {
      this.update(defaultValues);
    }
  }

  private fields: { [key: string]: any } = {
    animal: null,
    weight: null,
    status: null,
  };

  public errors: { [key: string]: any } = {
    animal: null,
    weight: null,
    status: null,
  };

  get animal(): AnimalKind | undefined {
    return this.fields.animal;
  }

  get isDog(): boolean {
    return !!this.fields.animal && this.fields.animal === AnimalKind.Dog;
  }

  get isCat(): boolean {
    return !!this.fields.animal && this.fields.animal === AnimalKind.Cat;
  }

  get weight(): number | undefined {
    return this.fields.weight;
  }

  get status(): DogStatus | CatStatus | undefined {
    return this.fields.status;
  }

  get statusMultiplier(): number {
    switch (this.status) {
      // 강아지 상태 별 승수
      case DogStatus.PuppyUnder4M:
        return 3;
      case DogStatus.Puppy:
        return 2;
      case DogStatus.Adult:
        return 1.8;
      case DogStatus.NeuteredOrSpayed:
        return 1.6;
      case DogStatus.NeedWeightLoss:
        return 1;
      case DogStatus.NeedWeightGain:
        return 1.7;
      case DogStatus.LightActivityMinistryDog:
        return 2;
      case DogStatus.MediumActivityMinistryDog:
        return 3;
      case DogStatus.HardActivityMinistryDog:
        return 6;
      // 고양이 상태 별 승수
      case CatStatus.Infancy:
        return 3;
      case CatStatus.RapidGrowthPeriodKitten:
        return 2.5;
      case CatStatus.Kitten:
        return 2.0;
      case CatStatus.NeuteredOrSpayed:
        return 1.2;
      case CatStatus.General:
        return 1.4;
      case CatStatus.NeedWeightLoss:
        return 0.8;
      case CatStatus.HardActivityCat:
        return 1.6;
      case CatStatus.Old:
        return 0.7;
      default:
        return 0;
    }
  }

  // 휴식기 에너지 요구량, Resting Energy Requirement
  get RER(): number {
    return !!this.weight ? 70 * 0.75 * this.weight : 0;
  }

  // 최소 에너지 요구량, Minimum Energy Requirement
  get MER(): number {
    return this.RER * this.statusMultiplier;
  }

  public async validate(): Promise<boolean> {
    this.errors = {};

    if (!this.animal) {
      this.errors["animal"] = "값을 입력하거나 선택해주셔야 합니다.";
    }

    if (!this.weight) {
      this.errors["weight"] = "값을 입력하거나 선택해주셔야 합니다.";
    } else if (isNaN(this.weight)) {
      this.errors["weight"] = "숫자만 입력해주세요.";
    }

    if (!this.status) {
      this.errors["status"] = "값을 입력하거나 선택해주셔야 합니다.";
    }

    return this.isValid;
  }

  get isValid(): boolean {
    return Object.keys(this.errors).filter((field) => !!field).length === 0;
  }

  public update(changes: any): void {
    for (const key of Object.keys(changes)) {
      if (this.fields[key] === undefined) {
        throw new Error(`Cannot find the key in the form: ${key}`);
      }
      this.fields[key] = changes[key];
    }
  }
}
