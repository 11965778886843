import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as NewWindowIcon } from "../../assets/DrawerImages/NEW_window.svg";
import { checkIcon, cancelIcon } from "assets";
import axios from "axios";

const Guarantee = ({ insuranceData }: any) => {
    const [categories, setCategories] = useState<any>([]);
    const [sortedInsuranceData, setSortedInsuranceData] = useState<any>([]);
    const categoriesList = [
        "가입 가능 나이",
        "갱신 가능 나이",
        "자기부담금",
        "보상 비율",
        "보험 금액",
    ];

    const filterCategoriesByBenefits = (
        categories: any,
        insuranceBenefits: any
    ) => {
        const filteredCategories = categories
            .filter((category: any) =>
                insuranceBenefits.some(
                    (benefit: any) => benefit.category_id === category.id
                )
            )
            .sort((a: any, b: any) => a.category_id - b.category_id);
        return filteredCategories;
    };

    const filterInsuranceData = (insuranceData: any) => {
        const sortedData = insuranceData.sort(
            (a: any, b: any) => a.category_id - b.category_id
        );
        return sortedData;
    };

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}insurance/benefit-categories/?animal_type=${insuranceData.animal_type}`,
                {}
            )
            .then(res => {
                setCategories(
                    filterCategoriesByBenefits(
                        res.data,
                        insuranceData?.insurance_benefits
                    )
                );
                setSortedInsuranceData(
                    filterInsuranceData(insuranceData?.insurance_benefits)
                );
            })
            .catch(err => {
                console.log(err);
            });
    }, [insuranceData?.insurance_benefits, insuranceData.animal_type]);

    return (
        <>
            <GuaranteeContainer>
                <GuaranteeTitle>보장 내용 상세</GuaranteeTitle>
                <GuaranteeContent>
                    <InsuranceNameContainer>
                        <InsuranceLogo
                            src={
                                insuranceData?.insurance_company?.landscape_logo
                            }
                        ></InsuranceLogo>
                        <InsuranceLinkContainer
                            href={insuranceData?.action_link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <InsuranceLink>바로가기</InsuranceLink>
                            <NewWindowIcon />
                        </InsuranceLinkContainer>
                    </InsuranceNameContainer>
                    <GuaranteeTableContainer>
                        <GuaranteeHeadContainer>
                            {" "}
                            <GuaranteeHeadList>
                                {categories?.map((data: any) => (
                                    <GuaranteeHead key={data.id}>
                                        {data.name}
                                    </GuaranteeHead>
                                ))}
                                {categoriesList?.map((data: string) => (
                                    <GuaranteeHead key={data}>
                                        {data}
                                    </GuaranteeHead>
                                ))}
                            </GuaranteeHeadList>
                        </GuaranteeHeadContainer>
                        <GuaranteeBodyContainer>
                            <GuaranteeBodyList>
                                {sortedInsuranceData?.map((data: any) => (
                                    <ContentContainer
                                        check={data.description === "없음"}
                                        key={data.category_id}
                                    >
                                        {data.description === "없음" ? (
                                            <>
                                                <ContentLogo
                                                    src={cancelIcon}
                                                    alt="none"
                                                ></ContentLogo>
                                            </>
                                        ) : (
                                            <>
                                                <ContentLogo
                                                    src={checkIcon}
                                                    alt="check"
                                                ></ContentLogo>
                                                <ContentText>
                                                    {data.description}
                                                </ContentText>
                                            </>
                                        )}
                                    </ContentContainer>
                                ))}
                                <TextAge>
                                    만 {insuranceData?.join_age_limit}세까지
                                </TextAge>
                                <TextAge>
                                    만 {insuranceData?.refresh_age_limit}세까지
                                </TextAge>
                                <TextAge>
                                    {insuranceData?.deductible?.toLocaleString()}
                                    원
                                </TextAge>
                                <TextAge>
                                    {insuranceData?.guarantee_rate * 100}%
                                </TextAge>
                                <TextPrice>
                                    {insuranceData?.price
                                        ? `월 ${insuranceData?.price?.toLocaleString()}원`
                                        : "확인 필요"}
                                </TextPrice>
                            </GuaranteeBodyList>
                        </GuaranteeBodyContainer>
                    </GuaranteeTableContainer>
                </GuaranteeContent>
                <ExtraDescription>
                    반려동물 상태(나이, 질병, 중성화 등), 가입 경로, 보험 가입
                    시 선택 내용(보상 비율, 특약 등)에 따라 보험 금액이 달라질
                    수 있습니다.
                </ExtraDescription>
            </GuaranteeContainer>
        </>
    );
};

export default Guarantee;

const GuaranteeContainer = styled.div`
    margin: 90px 146px;

    @media only screen and (max-width: 768px) {
        margin: 90px 100px;
    }
    @media only screen and (max-width: 512px) {
        margin: 50px 50px;
    }
`;

const GuaranteeTitle = styled.div`
    width: 347px;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 30px 0;
    border-radius: 10px;
    border: 2px solid #ffeb99;
    background-color: #fffdf4;

    font-size: 32px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
    align-items: center;

    @media only screen and (max-width: 768px) {
        width: 300px;
        padding: 20px 0;
        font-size: 24px;
        line-height: 26px;
    }
    @media only screen and (max-width: 512px) {
        width: 190px;
        padding: 18px 0;
        font-size: 16px;
        line-height: 14px;
        letter-spacing: 0.3px;
    }
`;

const GuaranteeContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const GuaranteeTableContainer = styled.div`
    display: flex;
`;

const InsuranceNameContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 600px;
    height: 93px;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #fcd11e;

    @media only screen and (max-width: 768px) {
        width: 480px;
        height: 64px;
    }
    @media only screen and (max-width: 512px) {
        width: 311px;
        height: 48px;
    }
`;

const InsuranceLogo = styled.img`
    width: 326px;
    height: 93px;

    @media only screen and (max-width: 768px) {
        width: 214px;
        height: 60px;
        margin-top: 4px;
    }
    @media only screen and (max-width: 512px) {
        width: 160px;
        height: 43px;
        margin-top: 5px;
    }
`;

const InsuranceLinkContainer = styled.a`
    display: flex;
    margin-right: 30px;
    align-items: center;
`;

const InsuranceLink = styled.div`
    margin-right: 8px;
    line-height: 22px;
    font-weight: 500;
    color: black;
    text-decoration: underline;

    @media only screen and (max-width: 512px) {
        font-size: 10px;
        line-height: 11px;
    }
`;

const GuaranteeHeadContainer = styled.div`
    width: 252px;
    background-color: #ffeb99;
    border: 1px solid #fcd11e;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;

    @media only screen and (max-width: 768px) {
        width: 189px;
    }
    @media only screen and (max-width: 512px) {
        width: 119px;
    }
`;

const GuaranteeHeadList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 49px;

    @media only screen and (max-width: 768px) {
        padding: 0 22px;
    }
    @media only screen and (max-width: 512px) {
        padding: 0 18px;
    }
`;

const GuaranteeHead = styled.div`
    display: flex;
    height: 92px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    align-items: center;

    @media only screen and (max-width: 768px) {
        font-size: 13px;
        line-height: 16px;
        height: 64px;
    }
    @media only screen and (max-width: 512px) {
        font-size: 9px;
        line-height: 10px;
        height: 48px;
    }
`;

const GuaranteeBodyContainer = styled.div`
    width: 346px;
    background-color: white;
    border: 1px solid #fcd11e;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media only screen and (max-width: 768px) {
        width: 288px;
    }
    @media only screen and (max-width: 512px) {
        width: 190px;
    }
`;

const GuaranteeBodyList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
`;

const ContentContainer = styled.div<{ check?: boolean }>`
    height: 56px;
    padding: 18px 0;
    text-align: center;

    ${({ check }) =>
        check &&
        `height: 30px;
    padding: 31px 0;`};

    @media only screen and (max-width: 768px) {
        height: 48px;
        padding: 8px 0;

        ${({ check }) =>
            check &&
            `height: 20px;
    padding: 22px 0;`};
    }

    @media only screen and (max-width: 512px) {
        height: 32px;
        padding: 8px 0;

        ${({ check }) =>
            check &&
            `height: 16px;
    padding: 16px 0;`};
    }
`;

const ContentLogo = styled.img`
    width: 30px;
    height: 30px;

    @media only screen and (max-width: 768px) {
        width: 24px;
        height: 24px;
    }
    @media only screen and (max-width: 512px) {
        width: 16px;
        height: 16px;
    }
`;

const ContentText = styled.div`
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    margin-top: 7px;

    @media only screen and (max-width: 768px) {
        font-size: 11px;
        line-height: 13px;
        margin-top: 5px;
    }
    @media only screen and (max-width: 512px) {
        font-size: 7px;
        line-height: 8px;
        margin-top: 3px;
    }
`;

const TextAge = styled.div`
    padding: 37px 0 36px;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
        padding: 25px 0 23px;
        font-size: 13px;
        line-height: 16px;
    }
    @media only screen and (max-width: 512px) {
        padding: 20px 0 18px;
        font-size: 9px;
        line-height: 10px;
    }
`;

const TextPrice = styled.div`
    padding: 36px 0 30px;
    text-align: center;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
        padding: 25px 0 23px;
        font-size: 13px;
        line-height: 16px;
    }
    @media only screen and (max-width: 512px) {
        padding: 20px 0 18px;
        font-size: 9px;
        line-height: 10px;
    }
`;

const ExtraDescription = styled.div`
    width: fit-content;
    margin: 30px auto;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;

    @media only screen and (max-width: 768px) {
        padding: 23px 0 22px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 600;
    }
`;
