import React from "react";
import styled from "styled-components";
import { QuizFormCard } from "assets";
import RadioBox from "./RadioBox";
import { RadioButton, RadioBoxBody } from "./RadioBox";
import { ReactComponent as ExpandMoreIcon } from "../../../../assets/InsuranceImages/main/expand_more.svg";

const BriefCheck = () => {
    return (
        <>
            <BriefCheckContainer2>
                <BriefCheckTitle>
                    펫보험, 간단한 정보로 바로 확인!
                </BriefCheckTitle>
                <BriefCheckBrowser>
                    <QuizBoxWrapper>
                        <QuizBoxContainer>
                            <img src={QuizFormCard} alt="QuizFormCard" />
                            <QuizButtonContainer>
                                <QuizButton
                                    href="https://smore.im/quiz/R7UCx7Cx25"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    퀴즈시작 <ExpandMoreIcon />
                                </QuizButton>
                            </QuizButtonContainer>
                        </QuizBoxContainer>
                    </QuizBoxWrapper>

                    <RadioBoxWrapper>
                        <RadioBoxContainer>
                            <RadioBox />
                        </RadioBoxContainer>
                    </RadioBoxWrapper>
                </BriefCheckBrowser>
            </BriefCheckContainer2>
        </>
    );
};

export default BriefCheck;

const BriefCheckContainer2 = styled.div`
    max-width: 1024px;
    margin: 0 auto;
`;

const BriefCheckTitle = styled.div`
    padding: 10px;
    margin: 3.2rem auto;
    text-align: center;
    font-size: 2rem;
    line-height: normal;
    font-weight: 600;
`;

const BriefCheckBrowser = styled.div`
    display: flex;
    margin: 0 auto;
    margin-bottom: 100px;
    align-items: stretch;

    @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
    }
`;

const QuizBoxContainer = styled.div`
    position: relative;
    width: 28.69rem;
    height: 29.5rem;
    margin: 0 auto;
    margin-top: 40px;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 22px 0px 0px 22px;

    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    :hover {
        box-shadow: 0px 50px 20px rgba(0, 0, 0, 0.01),
            0px 28px 17px rgba(0, 0, 0, 0.05), 0px 12px 12px rgba(0, 0, 0, 0.09),
            0px 3px 7px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 768px) {
        margin-bottom: 50px;
    }
`;

const QuizButtonContainer = styled.div`
    position: absolute;
    left: 28%;
    bottom: 3rem;
    height: 3.25rem;
    text-align: center;
    justify-content: center;
`;

const QuizButton = styled.a`
    display: flex;
    gap: 10px;
    font-family: "Apple SD Gothic Neo";
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 10.88rem;
    padding: 0.88rem 0;
    padding-left: 17px;
    background-color: white;
    border: none;
    font-size: 1.25rem;
    line-height: normal;
    font-weight: 500;
    border-radius: 25px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color: black;
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
`;

const RadioBoxContainer = styled.div`
    width: 28.63rem;
    height: 31.69rem;
    margin: 0 auto;
    background-color: white;
    border-radius: 10px 10px 10px 10px;

    :hover ${RadioBoxBody} {
        box-shadow: 0px 50px 20px rgba(0, 0, 0, 0.01),
            0px 28px 17px rgba(0, 0, 0, 0.05), 0px 12px 12px rgba(0, 0, 0, 0.09),
            0px 3px 7px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    :hover ${RadioButton} {
        box-shadow: 0px 50px 20px rgba(0, 0, 0, 0.01),
            0px 28px 17px rgba(0, 0, 0, 0.05), 0px 12px 12px rgba(0, 0, 0, 0.09),
            0px 3px 7px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 768px) {
        margin-bottom: 3.5rem;
    }
`;

const QuizBoxWrapper = styled.div`
    width: 50%;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const RadioBoxWrapper = styled.div`
    width: 50%;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;
