import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as AlertIcon } from "../assets/NamingImages/result/error_outline.svg";

interface ToastMessageProps {
    retryCount: number;
    setToastMessageOn: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToastMessage = ({ retryCount, setToastMessageOn }: ToastMessageProps) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            setToastMessageOn(false);
        }, 5000);
        return () => {
            clearTimeout(timer);
        };
    }, [setToastMessageOn]);

    return (
        <>
            <ToastMessageContainer>
                <AlertIcon className="alertIcon" />
                <AlertText>
                    {`이름 짓기 로딩에 실패하였습니다. 재시도하겠습니다. (${retryCount}회)`}
                </AlertText>
            </ToastMessageContainer>
        </>
    );
};

export default ToastMessage;

const ToastMessageContainer = styled.div`
    display: flex;
    gap: 0.75rem;
    width: 26rem;
    align-items: center;
    padding: 0.8rem 1.25rem;
    margin: 1rem auto 0;
    border-radius: 6px;
    background-color: #f24147;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

    .alertIcon {
        width: 1.5rem;
        height: 1.5rem;
    }

    @media only screen and (max-width: 768px) {
        width: 27.5rem;
        padding: 1.05rem 1.5rem;
    }
`;

const AlertText = styled.div`
    font-size: 0.9rem;
    line-height: 1.1rem;
    font-weight: 400;
    letter-spacing: 0.1px;
    color: #ffffff;

    @media only screen and (max-width: 512px) {
        font-size: 1.05rem;
        line-height: 1.2rem;
    }
`;
