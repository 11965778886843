import NamingProvider from "namingComponents/NamingProvider";
import React from "react";
import NamingMain from "./NamingMain";

const NamingMainProvider = () => {
    return (
        <>
            <NamingProvider>
                <NamingMain />
            </NamingProvider>
        </>
    );
};

export default NamingMainProvider;
