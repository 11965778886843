import React, { useEffect, useState, useRef, useCallback } from "react";
import { ReactComponent as CloseRoundedIcon } from "../../assets/DrawerImages/close.svg";
import { ReactComponent as DropdownIcon } from "../../assets/InsuranceImages/main/dropdown_arrow.svg";
import styled from "styled-components";
import axios from "axios";

interface AutoCompleteProps {
    petType?: string | undefined;
    inputError?: boolean | undefined;
    inputValue?: { id: number; name: string };
    setInputError?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setInputValue?: React.Dispatch<
        React.SetStateAction<{ id: number; name: string }>
    >;
    otherInput?: { id: number; name: string };
}

const InputAutoComplete = ({
    petType,
    inputError,
    inputValue,
    setInputError,
    setInputValue,
    otherInput,
}: AutoCompleteProps) => {
    const containerRef = useRef<HTMLInputElement | null>(null);
    const [dropdown, setDropdown] = useState<boolean>(false);
    const [optionError, setOptionError] = useState<boolean>(false);
    const [options, setOptions] = useState<any>([]);
    const [allOptions, setAllOptions] = useState<any>([]);

    const checkCorrectOption = useCallback(
        (inputValue: string | undefined) => {
            if (inputValue === "") return true;
            else
                return options.some(
                    (option: any) => option.name === inputValue
                );
        },
        [options]
    );

    const fetchInsurances = async (petType: string | undefined) => {
        let allResults: any[] = [];
        let nextUrl = `${process.env.REACT_APP_API_URL_DEV}insurance/insurances/?animal_type=${petType}`;

        while (nextUrl) {
            const response = await axios.get(nextUrl);
            allResults = allResults.concat(response.data.results);
            nextUrl = response.data.next;
        }
        setOptions(allResults);
        setAllOptions(allResults);
    };

    useEffect(() => {
        if (petType !== "") {
            fetchInsurances(petType);
            setOptionError(false);
        }
    }, [petType]);

    useEffect(() => {
        if (petType !== "" && allOptions.length === 0) {
            fetchInsurances(petType);
        }
        if (inputValue?.name !== "" && allOptions.length > 0) {
            setOptions(
                allOptions.filter((e: any) => {
                    return (
                        e.name.includes(inputValue?.name) &&
                        e.name !== otherInput?.name
                    );
                })
            );
        }
        if (inputValue?.name === "" && allOptions.length > 0) {
            if (otherInput?.name !== "") {
                setOptions(
                    allOptions.filter((e: any) => {
                        return e.name !== otherInput?.name;
                    })
                );
            } else {
                setOptions(
                    allOptions.filter((e: any) => {
                        return e.name.includes(inputValue?.name);
                    })
                );
            }
        }
        const handleClickOutside = (event: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target as Node)
            ) {
                setDropdown(false);
                if (checkCorrectOption(inputValue?.name)) {
                    setOptionError(false);
                } else {
                    setOptionError(true);
                }
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line
    }, [petType, inputValue, otherInput, allOptions]);

    const handleInputChange = (e: any) => {
        setInputValue?.({ id: -1, name: e.target.value });
        setDropdown(true);
    };

    const handleContainerClick = () => {
        if (petType === "") {
            setInputError?.(true);
        }
    };

    const handleInputValue = (data: any) => {
        setInputValue?.({ id: data.id, name: data.name });
        setDropdown(false);
        if (checkCorrectOption(data.name)) {
            setOptionError(false);
        } else {
            setOptionError(true);
        }
    };

    const handleDropdownIcon = (e: any) => {
        if (petType !== "") {
            setDropdown(prev => !prev);
        }
    };

    return (
        <>
            <InputContainer
                onClick={handleContainerClick}
                ref={containerRef}
                dropdown={dropdown}
            >
                <InsuranceInput
                    onChange={handleInputChange}
                    value={inputValue?.name}
                    onClick={() => setDropdown(true)}
                    disabled={petType === ""}
                    inputError={inputError}
                    optionError={optionError}
                    placeholder="펫보험 상품 선택"
                />
                {inputValue?.name !== "" ? (
                    <CloseRoundedIcon
                        onClick={() => setInputValue?.({ id: -1, name: "" })}
                        className="icon"
                    />
                ) : (
                    <DropdownIcon
                        onClick={handleDropdownIcon}
                        className="icon"
                    />
                )}
                {optionError && (
                    <OptionErrorMessage>
                        올바른 옵션을 선택해주세요.
                    </OptionErrorMessage>
                )}
                {dropdown && (
                    <DropDownContainer>
                        <ul>
                            {options?.map((data: any) => (
                                <DropDownList
                                    key={data.id}
                                    onClick={() => handleInputValue(data)}
                                    disable={data.name === otherInput?.name}
                                >
                                    {data.insurance_company.name} {data.name}
                                </DropDownList>
                            ))}
                        </ul>
                        {inputValue?.name !== "" && options.length === 0 && (
                            <InputErrorMessage>
                                일치하는 옵션이 없습니다.
                            </InputErrorMessage>
                        )}
                    </DropDownContainer>
                )}
            </InputContainer>
        </>
    );
};

export default InputAutoComplete;

const InputContainer = styled.div<{ dropdown?: boolean }>`
    position: relative;

    .icon {
        position: absolute;
        top: 1.4rem;
        right: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        z-index: 3;

        ${({ dropdown }) => dropdown && `transform: scaleY(-1)`};
    }
`;

const DropDownContainer = styled.div`
    position: absolute;
    top: 4.625rem;
    left: 1px;
    width: 24.25rem;
    min-height: 4.3rem;
    max-height: 23rem;
    overflow-y: scroll;
    background-color: #fffdf4;
    border-radius: 6px;
    box-shadow: 0px 1.44px 2.88px rgba(0, 0, 0, 0.3),
        0px 2.88px 8.63999px 2.88px rgba(0, 0, 0, 0.15);
    z-index: 9;

    ul {
        padding: 0;
    }
`;

const DropDownList = styled.li<{
    disable?: boolean;
}>`
    padding: 1.4rem 1.4rem;
    list-style: none;
    cursor: pointer;

    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;

    :hover {
        background-color: #fff5ce;
    }
`;

const InsuranceInput = styled.input<{
    disabled?: boolean;
    inputError?: boolean;
    optionError?: boolean;
}>`
    position: relative;
    width: 22.05rem;
    height: 4.5rem;
    padding: 0 1.1rem;
    border-radius: 10px;
    border: none;
    background-color: #fff5ce;
    border: 1px solid #fff5ce;

    font-size: 1.1rem;
    line-height: 1.3rem;
    font-weight: 500;

    :focus {
        outline: none;
        background-color: white;
        border: 1px solid #fcd11e;
    }

    ${({ inputError }) =>
        inputError && `background-color: #E4E4E6; border: 1px solid #E4E4E6;`};

    ${({ optionError }) =>
        optionError && `background-color: white; border: 1px solid #F24147;`};
`;

const OptionErrorMessage = styled.div`
    position: absolute;
    top: 5.1rem;
    left: 2px;
    font-size: 0.68rem;
    line-height: 0.81rem;
    font-weight: 400;
    color: #f24147;
`;

const InputErrorMessage = styled.div`
    position: absolute;
    top: 1.6rem;
    left: 1.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: #f24147;
`;
