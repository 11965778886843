import React from "react";
import { catFatImages, dogFatImages } from "assets";
import { Button } from "components/Button";
import { Form, FormButtonSection, FormField } from "components/Form";
import { ImageSelectBox, OptionData, AnimalSelect } from "components/Select";

import BmiCalculator, { BmiCalculatorData } from "./BmiCalculator";

interface BmiCalculatorFormProps {
  form: BmiCalculator;
  onChange: (fieldName: keyof BmiCalculatorData, value: any) => void;
  onSubmit: () => void;
}

const BmiCalculatorForm = ({
  form,
  onChange,
  onSubmit,
}: BmiCalculatorFormProps) => {
  const bmiImages = form.isDog ? dogFatImages : catFatImages;
  const bmiOptions: OptionData[] = bmiImages.map((image, index) => {
    return { image, value: index };
  });

  const handleOnChange = (fieldName: keyof BmiCalculatorData) => (
    value: any,
  ) => {
    onChange(fieldName, value);
  };

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Form onSubmit={handleOnSubmit}>
      <FormField label={"반려동물 종류"} errorMessage={form.errors.animal}>
        <AnimalSelect value={form.animal} onChange={handleOnChange("animal")} />
      </FormField>
      {!!form.animal && (
        <>
          <FormField
            label={`우리 아이와 닮은\n사진을 골라주세요.`}
            errorMessage={form.errors.bmiLevel}
          >
            <ImageSelectBox
              options={bmiOptions}
              value={form.bmiLevel}
              onClick={handleOnChange("bmiLevel")}
              dense
            />
          </FormField>
        </>
      )}
      <FormButtonSection>
        <Button type="submit" variant="primary">
          결과보기
        </Button>
      </FormButtonSection>
    </Form>
  );
};

export default BmiCalculatorForm;
