import React, { ReactNode } from "react";
import styled from "styled-components";
import { Heading1, Body2 } from "components/Typography";
import { colors } from "theme";

interface CoverSectionProps {
  image?: string;
  title: ReactNode;
  description: ReactNode;
}

export default function CoverSection({
  image,
  title,
  description,
}: CoverSectionProps) {
  return (
    <CoverSectionWrapper>
      <CoverTextWrapper>
        <CoverTitle>{title}</CoverTitle>
        <CoverDescription>{description}</CoverDescription>
      </CoverTextWrapper>
      <CoverImage src={image} />
    </CoverSectionWrapper>
  );
}

const CoverSectionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 620px;
  position: relative;
  height: 400px;

  @media only screen and (max-width: 768px) {
    margin: 40px 20px;
    max-width: none;
    height: 160px;
  }
`;

const CoverTextWrapper = styled.div`
  padding-top: 93px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    padding-top: 0px;
  }
`;

const CoverTitle = styled(Heading1)`
  margin: 0;
  white-space: nowrap;

  @media only screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 38px;
  }
`;

const CoverDescription = styled(Body2)`
  font-size: 16px;
  margin: 0px;
  white-space: nowrap;
  color: ${colors.grey500};

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 1.5;
  }
`;

const CoverImage = styled.img`
  width: 400px;
  height: 400px;

  @media only screen and (max-width: 768px) {
    width: 160px;
    height: 160px;
  }
`;
