import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckBoxIcon } from "../../assets/AdoptImages/check_box_purple.svg";
import { ReactComponent as CheckBoxOutlineIcon } from "../../assets/AdoptImages/check_box_outline_s.svg";
import { useSetRecoilState } from "recoil";
import { adoptCurrentPageState } from "states";

interface FilterMultiSelectProps {
    activeData: Set<string>;
    setActiveData: React.Dispatch<React.SetStateAction<Set<string>>>;
    listData: string[];
}

const FilterMultiSelect = ({
    activeData,
    setActiveData,
    listData,
}: FilterMultiSelectProps) => {
    const setAdoptCurrentPage = useSetRecoilState(adoptCurrentPageState);

    const handleSelect = (data: string) => {
        if (activeData && activeData.has(data)) {
            setActiveData((prevArr: Set<string>) => {
                const newArr = new Set(prevArr);
                newArr.delete(data);
                return newArr;
            });
        } else if (activeData && !activeData.has(data)) {
            setActiveData((prevArr: any) => new Set([...prevArr, data]));
        }
        setAdoptCurrentPage(1);
    };

    return (
        <FilterMultiSelectContainer>
            <MultiSelectList>
                {listData &&
                    listData.map((data: string) => (
                        <MultiSelect
                            key={data}
                            active={activeData && activeData.has(data)}
                            onClick={() => handleSelect(data)}
                        >
                            {activeData && activeData.has(data) ? (
                                <CheckBoxIcon className="checkedIcon" />
                            ) : (
                                <CheckBoxOutlineIcon className="uncheckedIcon" />
                            )}
                            {data}
                        </MultiSelect>
                    ))}
            </MultiSelectList>
        </FilterMultiSelectContainer>
    );
};

export default FilterMultiSelect;

const FilterMultiSelectContainer = styled.div``;

const MultiSelectList = styled.div`
    width: 7.2rem;
    border-radius: 5px;
    border: 1px solid #e4e4e6;
    background-color: white;
`;

const MultiSelect = styled.div<{ active?: boolean }>`
    display: flex;
    gap: 6px;
    align-items: center;
    padding: 0.75rem 0.8rem;

    border-bottom: 1px solid #e4e4e6;

    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 400;
    text-align: center;
    cursor: pointer;

    :last-child {
        border: none;
    }

    .checked {
        margin-left: 4px;
    }

    ${({ active }) => active && `color: #6D2CB6`};

    .checkedIcon,
    .uncheckedIcon {
        width: 1rem;
        height: 1rem;
    }

    @media only screen and (max-width: 768px) {
        .checkedIcon,
        .uncheckedIcon {
            width: 1.2rem;
            height: 1.2rem;
        }
    }
`;
