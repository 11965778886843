import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import HeaderDepth2 from "./HeaderDepth2";
import MobileHeader from "./HeaderMobile";
import { useLocation } from "react-router-dom";
import { getUA } from "react-device-detect";

const Main = styled.main<{
    isNameGenerator?: boolean;
}>`
    flex: 1;
    padding-top: 30px;
    position: relative;

    ${({ isNameGenerator }) =>
        isNameGenerator &&
        `
        background-color: #fffdf4;
    `}

    @media only screen and (max-width: 768px) {
        padding-top: 0px;
    }
`;

interface LayoutProps {
    children?: React.ReactNode;
    header?: {
        title: string;
        description: string;
        ogUrl: string;
        ogImage: string;
    };
}

export default function Layout({ children, header }: LayoutProps) {
    const pathName = useLocation().pathname;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const splitPathName = pathName.split("/");
    const lastPath = splitPathName[splitPathName.length - 1];
    const isMainApp = getUA === "kr.bemypet.bemypet_main_app";
    
    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <>
            {!isLoading && (
                <>
                    {!isMainApp && <Header header={header} />}
                    <Main
                        isNameGenerator={
                            lastPath === "name-generator" ||
                            lastPath === "name-generator-result"
                        }
                    >
                        {!isMainApp && (
                            <Fragment>
                                <MobileHeader />
                                <HeaderDepth2 />
                            </Fragment>
                        )}
                        {children}
                    </Main>
                    <Footer />
                </>
            )}
        </>
    );
}
