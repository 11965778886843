import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as StarIcon } from "../../assets/AdoptImages/star.svg";
import axios from "axios";
import { imageNotFound } from "assets";

const AdoptContents = ({ adoptData }: any) => {
    const [contentList, setContentList] = useState<any>([]);

    const handleImageError = (e: any) => {
        e.target.src = imageNotFound;
    };

    useEffect(() => {
        let isMounted = true;

        if (adoptData !== undefined) {
            const searchKeyword =
                adoptData.pet_type === "DOG" ? `강아지 입양` : `고양이 입양`;
            axios
                .get(
                    `${process.env.REACT_APP_API_URL_DEV}wordpress/posts/?keyword=${searchKeyword}&search_type=hashtag`,
                    {}
                )
                .then(res => {
                    if (isMounted) {
                        setContentList(
                            res.data.results
                                .sort(() => 0.5 - Math.random())
                                .slice(0, 3)
                        );
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
        return () => {
            isMounted = false;
        };
    }, [adoptData]);

    return (
        <>
            <AdoptContentsContainer>
                <AdoptContentsTitleContainer>
                    <StarIcon className="starIcon" />
                    비마이펫 추천 콘텐츠
                </AdoptContentsTitleContainer>
                <AdoptContentsListContainer>
                    {contentList?.map((data: any) => (
                        <ContentsContentContainer key={data.id}>
                            <ContentsTextContainer>
                                <ContentsCategory>
                                    {data.categories[0].name}
                                </ContentsCategory>
                                <ContentsLink href={data.guid}>
                                    <ContentsTitle>{data.title}</ContentsTitle>
                                    <ContentsContent>
                                        {data.content
                                            .replace(/<[^>]*>?/g, "")
                                            // eslint-disable-next-line
                                            .replace(/\&nbsp;/g, " ")}
                                    </ContentsContent>
                                </ContentsLink>
                            </ContentsTextContainer>
                            <ContentsLink
                                href={data.guid}
                                aria-label={data.title}
                            >
                                <ContentsImage
                                    src={data?.featured_media}
                                    alt={data.title}
                                    onError={handleImageError}
                                ></ContentsImage>
                            </ContentsLink>
                        </ContentsContentContainer>
                    ))}
                </AdoptContentsListContainer>
            </AdoptContentsContainer>
        </>
    );
};

export default AdoptContents;

const AdoptContentsContainer = styled.div`
    max-width: 64rem;
    margin: 2.5rem auto;
`;

const AdoptContentsTitleContainer = styled.div`
    display: flex;
    padding: 0.9rem 1rem 0.7rem;
    align-items: center;
    background-color: #fcd11e;

    font-size: 1.6rem;
    line-height: 1.8rem;
    font-weight: 500;

    .starIcon {
        width: 2.1rem;
        height: 2.1rem;
        margin-right: 10px;
        margin-bottom: 4px;
    }

    @media only screen and (max-width: 768px) {
        padding: 0.5rem 1rem 0.3rem;
        font-weight: 600;

        .starIcon {
            width: 2.3rem;
            height: 2.3rem;
            margin-right: 5px;
            margin-bottom: 4px;
        }
    }
`;

const AdoptContentsListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1.5rem 1rem 2.5rem;

    @media only screen and (max-width: 768px) {
        margin: 0.5rem 1.5rem 2.5rem;
    }
`;

const ContentsContentContainer = styled.div`
    display: flex;
    padding: 1.25rem 0;
    border-bottom: 1.287px solid #e4e4e6;

    :last-child {
        border: none;
    }

    @media only screen and (max-width: 768px) {
        padding: 1.75rem 0;
    }
`;

const ContentsTextContainer = styled.div``;

const ContentsCategory = styled.div`
    display: inline-flex;
    text-align: center;
    align-items: center;
    padding: 0.25rem 1rem;
    margin: 0.75rem 0 1rem;
    background-color: #ffeb99;
    border-radius: 30px;

    font-size: 0.75rem;
    line-height: 0.9rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
        padding: 0.4rem 1.5rem;
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;

const ContentsLink = styled.a`
    text-decoration: none;
    color: black;
`;

const ContentsTitle = styled.div`
    margin-bottom: 0.8rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
    cursor: pointer;

    :hover {
        color: #fcd11e;
    }

    @media only screen and (max-width: 768px) {
        width: calc(100vw - 14.5rem);
        font-size: 1.6rem;
        line-height: 1.9rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const ContentsImage = styled.img`
    width: 14.4rem;
    min-width: 14.4rem;
    height: 9.5rem;
    border-radius: 19.3px;
    box-shadow: 0px 2.574px 5.148px rgba(0, 0, 0, 0.15);
    margin-left: 2.2rem;
    object-fit: cover;
    /* margin-right: 36px; */

    @media only screen and (max-width: 768px) {
        width: 10rem;
        min-width: 10rem;
        height: 10rem;
        margin-left: 1.5rem;
        margin-right: 0;
        border-radius: 10px;
    }
`;

const ContentsContent = styled.div`
    font-size: 1rem;
    line-height: 1.7rem;
    font-weight: 400;
    color: #090909;
    cursor: pointer;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-wrap: break-word;

    @media only screen and (max-width: 768px) {
        font-size: 1.25rem;
        line-height: 1.6rem;
    }
`;
