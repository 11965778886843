import AdoptFiltering from "adoptComponents/filtering/AdoptFiltering";
import AdoptInput from "adoptComponents/input/AdoptInput";
import AdoptList from "adoptComponents/list/AdoptList";
import { adoptOgImage } from "assets";
import Layout from "components/Layout";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const AdoptMain = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <AdoptMainPageContainer
            isLoading={isLoading}
            style={{ display: "none" }}
        >
            <Layout
                header={{
                    title: "유기동물 입양 - 유기견 & 유기묘 입양",
                    description:
                        "반려동물 분양. 보호자를 찾는 유기동물들을 입양하세요. 가족을 기다리는 반려견 & 반려묘를 유기동물 보호센터에서 입양하세요",
                    ogImage: adoptOgImage,
                    ogUrl: "/adoption/adopt",
                }}
            >
                <AdoptMainContainer>
                    <AdoptMainPadding>
                        <AdoptMainTitle>유기동물 입양</AdoptMainTitle>
                        <AdoptInput />
                        <AdoptFiltering />
                        <AdoptList />
                    </AdoptMainPadding>
                </AdoptMainContainer>
            </Layout>
        </AdoptMainPageContainer>
    );
};

export default AdoptMain;

const AdoptMainPageContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const AdoptMainContainer = styled.div`
    max-width: 61rem;
    margin: 0 auto;
    padding: 0 1.5rem;
`;

const AdoptMainPadding = styled.div`
    overflow-x: hidden;
`;

const AdoptMainTitle = styled.div`
    padding: 2.5rem;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 600;
    text-align: center;

    @media only screen and (max-width: 512px) {
        padding: 3.5rem;
        font-size: 1.9rem;
        line-height: 2.2rem;
    }
`;
