import styled from "styled-components";
import { colors } from "theme";

interface ButtonProps {
  width?: number;
  disabled?: boolean;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  variant?: "default" | "primary";
}

const Button = styled.button<ButtonProps>`
  width: ${({ width }) => (!!width ? `${width}px` : "100%")};
  height: 48px;
  border-radius: 14px;
  color: ${colors.grey500};
  background-color: ${colors.grey300};
  border: none;
  outline: none;
  font-family: JalnanOTF;
  ${({ marginTop }) => !!marginTop && `margin-top:${marginTop}px;`}
  ${({ marginBottom }) => !!marginBottom && `margin-bottom:${marginBottom}px;`}
  ${({ marginLeft }) => !!marginLeft && `margin-left:${marginLeft}px;`}
  ${({ marginRight }) => !!marginRight && `margin-right:${marginRight}px;`}

  ${({ variant }) =>
    variant === "primary" &&
    `
    color: ${colors.grey700};
    background-color: ${colors.primary100};
  
  `}

  :disabled {
    color: ${colors.grey400};
    background-color: ${colors.grey200};
    cursor: not-allowed;
  }

  :hover {
    cursor: pointer;
  }
`;

export { Button };
