import styled from "styled-components";
import StaticUrl from "libs/urls";
import {
    FacebookIcon,
    YoutubeIcon,
    InstagramIcon,
    PlayStoreIcon,
    AppStoreIcon,
} from "assets";

export interface SocialListType {
    url: string;
    social: string;
    icon: any;
}

const SocialLink = styled.a`
    padding: 0px 8px;
`;

const socialList: SocialListType[] = [
    {
        url: StaticUrl.instagram,
        social: "instagram",
        icon: InstagramIcon,
    },
    {
        url: StaticUrl.youtube,
        social: "youtube",
        icon: YoutubeIcon,
    },
    {
        url: StaticUrl.facebook,
        social: "facebook",
        icon: FacebookIcon,
    },
    {
        url: StaticUrl.playStore,
        social: "playStore",
        icon: PlayStoreIcon,
    },
    {
        url: StaticUrl.appStore,
        social: "appStore",
        icon: AppStoreIcon,
    },
];

export { SocialLink, socialList };
