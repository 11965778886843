import { petDescriptionCat, petDescriptionDog } from "assets";
import { NameContext } from "pages/naming/NamingMain";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const NamingExplanationForm = () => {
    const navigate = useNavigate();
    const { dataList, updateList } = useContext(NameContext);
    const [textCount, setTextCount] = useState<number>(0);
    const [explanation, setExplanation] = useState<string>("");

    const onInputHandler = (e: any) => {
        setExplanation(e.target.value);
        setTextCount(e.target.value.length);
    };

    const handleContinueButton = (data: string) => {
        updateList(data);
        const updatedList = [...dataList, data];
        navigate("/adoption/name-generator-result", { state: updatedList });
    };

    return (
        <>
            <NamingExplanationFormContainer>
                <NamingFormTitle>
                    내 반려동물에 대해 설명해주세요!
                </NamingFormTitle>
                <NamingExplanationBox>
                    <NamingExplanationImage>
                        <img
                            src={
                                dataList[1] === "강아지"
                                    ? petDescriptionDog
                                    : petDescriptionCat
                            }
                            alt={dataList[1]}
                        />
                    </NamingExplanationImage>
                    <ExplanationContainer>
                        <ExplanationTitle>
                            반려동물에 대해 설명해주세요.
                            <br />
                            자세할수록 마음에 드는
                            <br />
                            결과를 얻을 거에요!
                        </ExplanationTitle>
                        <ExplanationTextArea
                            placeholder={
                                dataList[1] === "강아지"
                                    ? "최대 150자 (공백 포함)\n예시) 털은 하얀색에 귀는 갈색이에요. 산책을 매우 좋아하고 애교가 아주 많아요"
                                    : "최대 150자 (공백 포함)\n예시) 고등어색에 줄무늬가 있고, 입이랑 배는 흰 색이에요. 사냥놀이를 좋아하고 츄르를 사랑해요"
                            }
                            maxLength={150}
                            onChange={onInputHandler}
                            textCount={textCount}
                        />
                        <TextAreaCount textCount={textCount}>
                            {textCount}/150
                        </TextAreaCount>
                    </ExplanationContainer>
                </NamingExplanationBox>
                <ContinueButton
                    disable={textCount === 0 || textCount > 150}
                    onClick={() => handleContinueButton(explanation)}
                >
                    다음
                </ContinueButton>
            </NamingExplanationFormContainer>
        </>
    );
};

export default NamingExplanationForm;

const NamingExplanationFormContainer = styled.div`
    background-color: #fffdf4;
    text-align: center;
    min-height: calc(100vh - 235px);

    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - 244px);
    }
    @media only screen and (max-width: 546px) {
        min-height: calc(100vh - 270px);
    }
`;

const NamingFormTitle = styled.div`
    padding: 70px 0 50px;
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    text-align: center;

    @media only screen and (max-width: 768px) {
        padding: 70px 0 50px;
        font-size: 28px;
    }
    @media only screen and (max-width: 500px) {
        padding: 40px 0;
        font-size: 22px;
    }
`;

const NamingExplanationBox = styled.div`
    display: flex;
    width: 466.5px;
    height: 300px;
    margin: 0 auto;

    border-radius: 11.5px;
    background-color: #ffffff;
    box-shadow: 0px 25.92px 28.08px rgba(7, 0, 59, 0.04);

    @media only screen and (max-width: 512px) {
        width: 336px;
        height: 256px;
    }
`;

const NamingExplanationImage = styled.div`
    margin: 22px 20px 42px;

    img {
        width: 168px;
        height: 206px;
        padding: 14px 9px;
        border-radius: 11px;
        background-color: #f0e9f7;
    }

    @media only screen and (max-width: 512px) {
        margin: 24px 15px;

        img {
            width: 121px;
            height: 148.3px;
            padding: 24px 6.5px;
        }
    }
`;

const ExplanationContainer = styled.div`
    margin-top: 27px;

    @media only screen and (max-width: 512px) {
        margin-top: 22px;
    }
`;

const ExplanationTitle = styled.div`
    width: 206px;
    margin-left: 4px;
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #292525;
    text-align: left;

    @media only screen and (max-width: 512px) {
        width: 161px;
        margin-bottom: 5px;
        font-size: 13px;
        line-height: 22px;
    }
`;

const ExplanationTextArea = styled.textarea<{ textCount?: number }>`
    float: left;
    width: 200px;
    height: 120px;
    padding: 10px;
    margin-bottom: 12px;

    font-family: "Apple SD Gothic Neo", sans-serif;
    ::placeholder {
        font-size: 11px;
        line-height: 18px;
        font-weight: 500;
        color: #868688;
    }

    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #090909;

    background-color: #f6f6f9;

    border: 1px solid #f6f6f9;
    resize: none;

    outline-color: transparent;

    :focus {
        border: 1px solid #ffeb99;
        background-color: #ffeb99;
    }

    ${({ textCount }) =>
        textCount !== undefined &&
        textCount > 150 &&
        `
        border: 1px solid #F24147;
        outline-color: #F24147;
    `}

    @media only screen and (max-width: 512px) {
        width: 144.5px;
        height: 110px;
        padding: 7px;
        margin-bottom: 8px;
    }
`;

const TextAreaCount = styled.div<{ textCount?: number }>`
    float: right;
    margin-right: 20px;
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #868688;

    ${({ textCount }) =>
        textCount !== undefined &&
        textCount > 150 &&
        `
        color: #F24147;
    `}

    @media only screen and (max-width: 768px) {
        margin-right: 15px;
    }
`;

const ContinueButton = styled.div<{ disable?: boolean }>`
    display: inline-block;
    padding: 14px 125px;
    margin: 70px auto;

    background-color: #fcd11e;
    box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68);
    border-radius: 50px;

    font-size: 22px;
    line-height: 18px;
    font-weight: 700;

    cursor: pointer;

    :hover {
        box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68),
            inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    ${({ disable }) =>
        disable &&
        `
    background-color: #CFCECE;
    pointer-events: none;
    `}

    @media only screen and (max-width: 768px) {
        padding: 14px 100px;
        margin: 69px auto 96px;
        font-size: 18px;
        line-height: 13px;
    }
`;
