import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { liliMini, samsekMini } from "assets";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import InsuranceCard from "insuranceComponents/main/InsuranceCard";
import Layout from "components/Layout";
import { useParams } from "react-router-dom";
import Pagination from "insuranceComponents/insurances/Pagination";
import { ogImage } from "assets";
import ReactGA from "react-ga";
import NotFound from "pages/notfound/NotFound";
import { useRecoilValue } from "recoil";
import { userTokenState } from "states";

const Insurances = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [insuranceData, setInsuranceData] = useState<any>([]);
    const { animalType } = useParams<"animalType">();

    const userToken = useRecoilValue(userTokenState);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const pageSize = 10;

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const getInsuranceData = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}insurance/insurances/?offset=${
                    (currentPage - 1) * pageSize
                }&size=${pageSize}&animal_type=${animalType}`,
                {
                    headers: {
                        Authorization: userToken ? `Bearer ${userToken}` : ``,
                    },
                }
            )
            .then(res => {
                setInsuranceData(res.data.results);
                setTotalCount(res.data.count);
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (animalType === "dog" || animalType === "cat") {
            getInsuranceData();
        }

        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        ReactGA.event({
            category: "보험 리스트",
            action: "페이지 접근",
        });

        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <>
            <InsurancesMainContainer isLoading={isLoading} style={{ display: "none" }}>
                <Layout
                    header={{
                        title: `${
                            animalType === "dog"
                                ? "강아지 "
                                : `${animalType === "cat" ? "고양이 " : ""}`
                        }펫보험 종류 알아보기`,
                        description: `${
                            animalType === "dog"
                                ? "강아지 "
                                : `${animalType === "cat" ? "고양이 " : ""}`
                        }펫보험 종류 알아보기. 다양한 펫보험 종류를 알아보세요`,
                        ogImage: ogImage,
                        ogUrl: `${
                            typeof window !== "undefined" && animalType
                                ? `/insurance/insurances/${animalType}`
                                : ""
                        }`,
                    }}
                >
                    {animalType !== "dog" && animalType !== "cat" ? (
                        <NotFound />
                    ) : (
                        <InsurancesContainer>
                            <InsurancesPageTitle>
                                반려인들 사이에서 {isMobile && <br />}
                                인기 펫보험을 알아보아요!
                            </InsurancesPageTitle>
                            <InsurancesAnimalTypeContainer>
                                <InsurancesAnimalTypeButton>
                                    <InsurancesAnimalTypeLogo
                                        src={animalType === "dog" ? liliMini : samsekMini}
                                        alt={animalType || undefined}
                                    ></InsurancesAnimalTypeLogo>
                                    <InsurancesAnimalTypeText>
                                        {animalType === "dog" ? "강아지" : "고양이"}
                                    </InsurancesAnimalTypeText>
                                </InsurancesAnimalTypeButton>
                            </InsurancesAnimalTypeContainer>
                            <ExtraDescription>
                                {animalType === "dog" ? "말티즈 " : "코숏 "}
                                3세, 남아, 중성화O 기준의 데이터입니다.
                            </ExtraDescription>
                            <InsuranceCardWrapper>
                                {insuranceData?.map((data: any) => (
                                    <InsuranceCard
                                        key={data.id}
                                        data={data}
                                        noBorder={false}
                                    ></InsuranceCard>
                                ))}
                            </InsuranceCardWrapper>
                            <PaginationButtonContainer>
                                <Pagination
                                    totalCount={totalCount}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </PaginationButtonContainer>
                        </InsurancesContainer>
                    )}
                </Layout>
            </InsurancesMainContainer>
        </>
    );
};

export default Insurances;

const InsurancesMainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const InsurancesContainer = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: 52px;
    background-color: white;
`;

const InsurancesPageTitle = styled.div`
    padding-top: 70px;
    margin-bottom: 52px;
    text-align: center;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
        margin-top: 55px;
        margin-bottom: 32px;
    }
`;

const InsurancesAnimalTypeContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 22px;
    margin-bottom: 52px;

    @media only screen and (max-width: 768px) {
        margin-bottom: 35px;
    }
`;

const InsurancesAnimalTypeButton = styled.div`
    display: flex;
    width: 123px;
    height: 47px;
    justify-content: center;
    align-items: center;
    background-color: #fcd11e;
    border-radius: 36px;
`;

const InsurancesAnimalTypeLogo = styled.img`
    width: 36px;
    height: 27px;
    margin-right: 6px;
`;

const InsurancesAnimalTypeText = styled.div`
    margin-right: 2px;
    font-size: 19px;
    line-height: 23px;
    font-weight: 700;
`;

const InsuranceCardWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 70px 36px;
    width: 832px;
    margin: 0 auto;
    padding-bottom: 70px;

    @media only screen and (max-width: 896px) {
        gap: 50px 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        width: 690px;
        gap: 40px 10px;
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 30px;
        width: 100vw;
        padding-bottom: 50px;
    }
`;

const ExtraDescription = styled.div`
    margin: 52px auto;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #090909;
    text-align: center;
`;

const PaginationButtonContainer = styled.div`
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 62px;
`;
