import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactGA from "react-ga";
import { recommendMain, socialRecommand } from "assets";
import Layout from "components/Layout";
import { InlineColor } from "components/Typography";
import CoverSection from "components/CoverSection";
import { colors } from "theme";

import CalorieRecommendation from "./CalorieRecommendation";
import RecommendCalorieForm from "./RecommendCalorieForm";
import RecommendCalorieResult from "./RecommendCalorieResult";

export default function RecommentCalorieCalculator() {
    const [form] = useState<CalorieRecommendation>(new CalorieRecommendation());
    const [isResultOpen, setIsResultOpen] = useState<boolean>(false);
    const [, setState] = useState<any>({});

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    useEffect(() => {
        ReactGA.event({
            category: "비만도 계산기",
            action: "페이지 접근",
        });
    }, []);

    const handleOnChange = (
        fieldName: keyof CalorieRecommendation,
        value: any
    ) => {
        form.update({
            [fieldName]: value,
        });
        setState({ ...form });
    };

    const handleOnSubmit = async () => {
        setIsResultOpen(false);
        setState({ ...form }); // for update state
        const isValid = await form.validate();
        if (isValid) {
            setIsResultOpen(true);

            ReactGA.event({
                category: "권장 칼로리 계산기",
                action: "계산",
                label: form.animal,
            });
        }
    };

    return (
        <RecommendMainContainer
            isLoading={isLoading}
            style={{ display: "none" }}
        >
            <Layout
                header={{
                    title: "강아지, 고양이 권장 칼로리 계산기",
                    description:
                        "강아지 권장 칼로리 계산기, 고양이 권장 칼로리 계산기. 강아지, 고양이가 먹어야 하는 적정한 칼로리/열량을 계산해보세요.",
                    ogImage: socialRecommand,
                    ogUrl: "/calculator/recommend-calorie",
                }}
            >
                <CoverSection
                    image={recommendMain}
                    title={
                        <>
                            <InlineColor color={colors.purple100}>
                                권장칼로리
                            </InlineColor>
                            란?
                        </>
                    }
                    description={
                        <>
                            우리 아이의 몸무게, 상태에 따라
                            <br />
                            권장되는 칼로리를 계산해보세요.
                        </>
                    }
                />
                <RecommendCalorieForm
                    form={form}
                    onChange={handleOnChange}
                    onSubmit={handleOnSubmit}
                />
                {isResultOpen && <RecommendCalorieResult form={form} />}
            </Layout>
        </RecommendMainContainer>
    );
}

const RecommendMainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;
