import React, { useState, useEffect, Fragment } from 'react'
import styled from 'styled-components'

const KEY_WORD_MENU = ['비마이펫 툴즈', '펫보험 추천', '건강 계산기']
const TYPING_SPEED = 100

const TypingText: React.FC = () => {
  const [text, setText] = useState('')
  const [textIndex, setTextIndex] = useState(0)
  const [isTyping, setIsTyping] = useState(true)

  useEffect(() => {
    const typeText = () => {
      const currentMenu = KEY_WORD_MENU[textIndex]
      const prevText = text

      if (isTyping) {
        if (prevText.length < currentMenu.length) {
          setText(prevText + currentMenu[prevText.length])
        } else {
          clearInterval(interval)
          setTimeout(() => {
            setIsTyping(false)
          }, 1000)
        }
      } else {
        if (prevText.length > 0) {
          setText(prevText.slice(0, -1))
        } else {
          clearInterval(interval)
          setTextIndex((prevIndex) =>
            prevIndex === KEY_WORD_MENU.length - 1 ? 0 : prevIndex + 1,
          )
          setIsTyping(true)
        }
      }
    }

    const interval = setInterval(typeText, TYPING_SPEED)

    return () => clearInterval(interval)
  }, [text, textIndex, isTyping])

  return (
    <Fragment>
      <TypingArea>
        <span>{text}</span>
        <Cursor />
      </TypingArea>
      <PostPosition>
        {text === '입양' || text === '펫보험 추천' ? '으로' : '로'}
      </PostPosition>
      <br />
    </Fragment>
  );
}

export default TypingText

const TypingArea = styled.div`
  display: inline-block;
  position: relative;
`;

const Cursor = styled.div`
  display: inline;
  border-right: 2px solid #939393;
  animation: blink 0.7s infinite;

  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
`;

const PostPosition = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: black;
  display: inline;

  @media screen and (max-width: 838px) {
    font-size: 31px;
  }

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }

  @media screen and (max-width: 684px) {
    font-size: 22px;
  }

  @media screen and (max-width: 578px) {
    font-size: 18px;
  }

  @media screen and (max-width: 448px) {
    font-size: 22px;
  }
`;
