import React from "react";
import styled from "styled-components";
import { searchImage, privacyImage, communityImage } from "assets";

const Description = () => {
    return (
        <>
            <DescriptionContainer>
                <DescriptionTitle>펫보험 간편 비교</DescriptionTitle>
                <ImageContainer>
                    <ImageWrapper>
                        <DescriptionImage
                            src={searchImage}
                            alt="search"
                        ></DescriptionImage>
                        <DescriptionText>
                            쉽고 간편하게
                            <br />
                            펫보험 상품 비교를!
                        </DescriptionText>
                    </ImageWrapper>
                    <ImageWrapper>
                        <DescriptionImage
                            src={privacyImage}
                            alt="privacy"
                        ></DescriptionImage>
                        <DescriptionText>
                            개인정보 입력은
                            <br />
                            필요하지 않습니다.
                        </DescriptionText>
                    </ImageWrapper>
                    <ImageWrapper>
                        <DescriptionImage
                            src={communityImage}
                            alt="community"
                        ></DescriptionImage>
                        <DescriptionText>
                            궁금한 점은 커뮤니티로
                            <br />
                            질문해보세요!
                        </DescriptionText>
                    </ImageWrapper>
                </ImageContainer>
            </DescriptionContainer>
        </>
    );
};

export default Description;

const DescriptionContainer = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    background: linear-gradient(
        180deg,
        #fcd11e 0%,
        rgba(255, 255, 255, 0) 100%
    );
`;

const DescriptionTitle = styled.div`
    padding: 85px 0 93px;
    text-align: center;
    font-size: 2rem;
    line-height: normal;
    font-weight: 700;
`;

const ImageContainer = styled.div`
    display: flex;
    gap: 40px;
    justify-content: center;
    padding-bottom: 208px;

    @media only screen and (max-width: 768px) {
        gap: 36px;
    }
    @media only screen and (max-width: 532px) {
        gap: 30px;
    }
    @media only screen and (max-width: 448px) {
        gap: 24px;
    }
`;

const ImageWrapper = styled.div`
    text-align: center;
`;

const DescriptionImage = styled.img`
    width: 223px;
    height: 223px;
    margin-bottom: 10px;

    @media only screen and (max-width: 768px) {
        width: 150px;
        height: 150px;
    }
    @media only screen and (max-width: 532px) {
        width: 100px;
        height: 100px;
    }
    @media only screen and (max-width: 448px) {
        width: 75px;
        height: 75px;
    }
`;

const DescriptionText = styled.div`
    display: block;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;

    @media only screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
    }
    @media only screen and (max-width: 532px) {
        font-size: 13px;
        line-height: 20px;
    }
    @media only screen and (max-width: 448px) {
        font-size: 11px;
        line-height: 16px;
    }
`;
