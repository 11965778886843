import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { unscrapIcon, scrapIcon } from "assets";
import { useRecoilValue } from "recoil";
import { userTokenState } from "states";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

interface InsuranceCardProps {
    data: any;
    noBorder: boolean;
}

const InsuranceCard = ({ data, noBorder }: InsuranceCardProps) => {
    const navigate = useNavigate();
    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    const userToken = useRecoilValue(userTokenState);

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const handleToggleFavorite = (isFavorite: boolean) => {
        axios
            .put(
                `${process.env.REACT_APP_API_URL_DEV}insurance/favorite/${data.id}/`,
                { is_favorite: !isFavorite },
                {
                    headers: {
                        Authorization: userToken ? `Bearer ${userToken}` : ``,
                    },
                }
            )
            .then(res => {
                // console.log(res.data);
                setIsFavorite(prev => !prev);
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (data !== undefined) {
            setIsFavorite(data.is_favorite);
        }
    }, [data]);

    const handleSeeMore = (insuraceId: number) => {
        navigate(`/insurance/${insuraceId}/`);
    };
    return (
        <>
            <InsuranceCardContainer noBorder={noBorder}>
                <InsuranceTitleContainer>
                    <InsuranceLogo
                        src={data.insurance_company.logo}
                        alt={data.insurance_company.name}
                    ></InsuranceLogo>
                    <InsuranceTitle>
                        <CompanyName>{data.insurance_company.name}</CompanyName>
                        <InsuranceName>{data.name}</InsuranceName>
                        <InsuranceDescription
                            dangerouslySetInnerHTML={{
                                __html: data.description,
                            }}
                        />
                    </InsuranceTitle>
                </InsuranceTitleContainer>
                <InsuranceTextContainer>
                    <InsuranceSubTitle>비마이펫 TIP</InsuranceSubTitle>
                    <InsuranceSummary
                        dangerouslySetInnerHTML={{
                            __html: data.bemypet_summary,
                        }}
                    />
                    <PriceContainer>
                        {data?.price ? (
                            <>
                                <MonthText>월</MonthText>
                                <InsurancePrice>{data?.price?.toLocaleString()}원</InsurancePrice>
                            </>
                        ) : (
                            <NoPrice>확인 필요</NoPrice>
                        )}
                    </PriceContainer>
                    <InsuranceMore onClick={() => handleSeeMore(data.id)}>
                        자세히 보기
                    </InsuranceMore>
                </InsuranceTextContainer>
                <InsuranceScrapButtonContainer>
                    {userToken === "" || userToken === null ? (
                        <a
                            aria-label="통합 로그인"
                            href={`${process.env.REACT_APP_MAIN_URL_DEV}login?returnURL=${process.env.REACT_APP_DOMAIN_URL_DEV}insurance/${data.id}/`}
                        >
                            <InsuranceScrapButton
                                active={false}
                                isMobile={isMobile}
                            ></InsuranceScrapButton>
                        </a>
                    ) : (
                        <InsuranceScrapButton
                            onClick={() => {
                                handleToggleFavorite(isFavorite);
                            }}
                            active={isFavorite}
                            isMobile={isMobile}
                        ></InsuranceScrapButton>
                    )}
                </InsuranceScrapButtonContainer>
            </InsuranceCardContainer>
        </>
    );
};

export default InsuranceCard;

const InsuranceCardContainer = styled.div<{ noBorder: boolean }>`
    position: relative;
    width: 398px;
    height: 406px;
    margin: 0 auto;
    background-color: white;
    border-radius: 12px;
    border: 1px solid ${({ noBorder }) => (noBorder ? `#f6f6f9` : `transparent`)};
    background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(
            60deg,
            rgba(255, 206, 0, 1) 0%,
            rgba(255, 241, 184, 1) 35%,
            rgba(252, 209, 30, 1) 100%
        );
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    :hover {
        box-shadow: 0px 86px 34px rgba(0, 0, 0, 0.01), 0px 48px 29px rgba(0, 0, 0, 0.05),
            0px 21px 21px rgba(0, 0, 0, 0.09), 0px 5px 12px rgba(0, 0, 0, 0.1),
            0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        width: 322px;
        height: 324px;
    }
`;

const InsuranceTitleContainer = styled.div`
    display: flex;
    margin: 30px 32.5px 36px;

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        margin: 28px;
        margin-bottom: 16px;
    }
`;

const InsuranceLogo = styled.img`
    width: 48px;
    height: 48px;
    margin: 0;
    margin-top: 16px;
    margin-right: 16px;
    border-radius: 50%;
    box-shadow: 0px 2.2px 4.4px rgba(0, 0, 0, 0.15);

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        width: 40px;
        height: 40px;
        margin: 0;
        margin-top: 8px;
        margin-right: 12px;
    }
`;

const InsuranceTitle = styled.div``;

const CompanyName = styled.div`
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #1c1b1f;

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        margin-bottom: 4px;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
`;

const InsuranceName = styled.div`
    margin-bottom: 8px;
    font-size: 22px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #1c1b1f;

    display: block;
    width: 270px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        margin-bottom: 6px;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.4px;
        width: 220px;
    }
`;

const InsuranceDescription = styled.div`
    height: 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;
    color: #49454f;

    p {
        margin: 0;
        display: block;
        width: 270px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        margin-bottom: 7px;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.2px;

        p {
            width: 220px;
        }
    }
`;

const InsuranceTextContainer = styled.div`
    margin: 0 33px;
`;

const InsuranceSubTitle = styled.div`
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    color: #090909;

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
    }
`;

const InsuranceSummary = styled.div`
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;
    color: #868688;

    p {
        margin: 0;
    }

    ul {
        margin-top: 12px;
        padding: 0 23px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media only screen and (min-width: 768px) and (max-width: 832px),
            only screen and (max-width: 448px) {
            padding-left: 23px;
            padding-right: 0;
        }
    }
    li {
        margin-bottom: 10px;

        @media only screen and (min-width: 768px) and (max-width: 832px),
            only screen and (max-width: 448px) {
            margin-bottom: 8px;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.2px;
        margin-bottom: 18px;
    }
`;

const PriceContainer = styled.div`
    display: flex;
    justify-content: right;
`;

const MonthText = styled.div`
    margin-right: 8px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.2px;
    }
`;

const NoPrice = styled.div`
    margin-right: 8px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    color: #868688;

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.2px;
    }
`;

const InsurancePrice = styled.div`
    font-size: 28px;
    line-height: 20px;
    font-weight: 600;

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        font-size: 23px;
        line-height: 16px;
        letter-spacing: 0.2px;
    }
`;

const InsuranceMore = styled.div`
    display: inline-block;
    float: right;
    margin: 26px 0;
    padding: 10px 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    border-radius: 100px;
    background-color: #fcd11e;
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        margin: 18px 0;
        padding: 9px 20px;
        font-size: 13px;
        line-height: 16px;
    }
`;

const InsuranceScrapButtonContainer = styled.div`
    position: absolute;
    top: -1px;
    right: 30px;
    width: 40px;
    height: 50.5px;
`;

const InsuranceScrapButton = styled.div<{ active: boolean; isMobile: boolean }>`
    width: 40px;
    height: 50.5px;

    background-image: url(${({ active }) => (active ? scrapIcon : unscrapIcon)});
    background-repeat: no-repeat;
    background-size: cover;

    will-change: filter;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));

    cursor: pointer;

    transition: 0.1s;

    :hover {
        ${({ isMobile }) =>
            !isMobile &&
            `background-image: url(${scrapIcon});
        `};
    }

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        width: 32px;
        height: 40px;
    }
`;
