import { adoptModalLogo, adoptSpinnerLili } from "assets";
import axios from "axios";
import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { userTokenState } from "states";
import styled from "styled-components";

interface ConfirmDeleteAllProps {
    setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsAllDeleted: React.Dispatch<React.SetStateAction<boolean>>;
    setAdoptTotalCount: React.Dispatch<React.SetStateAction<number>>;
}

const AdoptConfirmDeleteAll = ({
    setShowConfirmModal,
    setIsAllDeleted,
    setAdoptTotalCount,
}: ConfirmDeleteAllProps) => {
    const userToken = useRecoilValue(userTokenState);

    const handleDeleteAll = () => {
        axios
            .delete(
                `${process.env.REACT_APP_API_URL_DEV}pet-adoption/favorite/delete/`,
                {
                    data: { is_delete_all: true },
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then(res => {
                // console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            });
        setShowConfirmModal(false);
        setIsAllDeleted(true);
        setAdoptTotalCount(-1);
    };

    useEffect(() => {
        document.body.style.cssText = `
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = "";
            window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
    }, []);

    return (
        <>
            <AdoptConfirmDeleteAllContainer>
                <ConfirmModalContainer>
                    <LogoImage src={adoptModalLogo} alt="bemypet" />
                    <ConfirmText>관심동물을 모두 삭제하시겠습니까?</ConfirmText>
                    <LoadingSpinner src={adoptSpinnerLili} alt="리리" />
                    <ConfirmButtonContainer>
                        <ConfirmButton confirm={true} onClick={handleDeleteAll}>
                            네
                        </ConfirmButton>
                        <ConfirmButton
                            confirm={false}
                            onClick={() => setShowConfirmModal(false)}
                        >
                            아니요
                        </ConfirmButton>
                    </ConfirmButtonContainer>
                </ConfirmModalContainer>
            </AdoptConfirmDeleteAllContainer>
        </>
    );
};

export default AdoptConfirmDeleteAll;

const AdoptConfirmDeleteAllContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9999;
    display: flex;
`;

const ConfirmModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    align-items: center;
    width: 20rem;
    height: 13.2rem;
    margin: auto;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #fcd11e;
    box-shadow: 0px 139.228px 55.5831px rgba(0, 0, 0, 0.01),
        0px 78.2481px 46.9488px rgba(0, 0, 0, 0.05),
        0px 35.0767px 35.0767px rgba(0, 0, 0, 0.09),
        0px 8.63427px 19.4271px rgba(0, 0, 0, 0.1),
        0px 0px 0px rgba(0, 0, 0, 0.1);
`;

const LogoImage = styled.img`
    width: 3rem;
    height: 1.875rem;
    margin-top: 1rem;
`;

const ConfirmText = styled.div`
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 600;
`;

const LoadingSpinner = styled.img`
    width: 7.56rem;
    height: 2.06rem;
`;

const ConfirmButtonContainer = styled.div`
    display: flex;
    gap: 1.625rem;
`;

const ConfirmButton = styled.div<{ confirm?: boolean }>`
    padding: 0.5rem 1.5rem;
    font-size: 0.75rem;
    line-height: 0.9rem;
    font-weight: 600;

    border-radius: 25px;
    cursor: pointer;

    background-color: ${({ confirm }) => (confirm ? `#fcd11e;` : `#F6F6F9;`)};

    :hover {
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
    }
`;
