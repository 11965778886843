import { loudspeakerMSamsekLili, loudspeakerSamsekLili } from "assets";
import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const AdoptDetailStatus = ({ adoptData }: any) => {
    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const getAnnouncement = (processState: string) => {
        if (processState === "공고중")
            return (
                <>
                    해당 동물은 ‘공고 중'으로, 주인을 찾는 중이에요.
                    <br />
                    공고 기간이 종료된 후 ‘보호 중'으로 변경된 동물은 입양이
                    가능해요.
                </>
            );
        else if (processState === "보호중")
            return (
                <>
                    해당 동물은 ‘보호 중'이에요.
                    <br />
                    공고 기간이 종료되어 입양이 가능한 상태예요.
                </>
            );
        else
            return (
                <>
                    해당 동물은 보호가 종료되었어요.
                    <br />
                    입양이 불가능한 상태예요.
                </>
            );
    };

    return (
        <div>
            <HeadContainer>
                <HeadImage
                    src={
                        isMobile
                            ? loudspeakerMSamsekLili
                            : loudspeakerSamsekLili
                    }
                    alt="확성기 삼색 리리"
                    isMobile={isMobile}
                />
                <HeadAnnouncement>
                    {getAnnouncement(adoptData?.adopt_status)}
                </HeadAnnouncement>
            </HeadContainer>
        </div>
    );
};

export default AdoptDetailStatus;

const HeadContainer = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    padding: 1.6rem 0 1.9rem;

    @media only screen and (max-width: 768px) {
        position: relative;
        padding: 0;
        margin: 2.1rem 1.5rem 0.4rem;
    }
`;

const HeadImage = styled.img<{ isMobile?: boolean }>`
    width: 6.6rem;
    height: 6.6rem;

    ${({ isMobile }) =>
        isMobile &&
        `    position: absolute;
    top: -3.5rem;
    left: 0.3rem;
    width: 6rem;
    height: 3.5rem;
    z-index: 2;

        `};
`;

const HeadAnnouncement = styled.div`
    height: fit-content;
    padding: 0.9rem 8rem;

    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 600;
    text-align: center;

    border-radius: 10px;
    background-color: #fff5ce;

    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 1rem;

        font-size: 1.1rem;
        line-height: 1.5rem;
        word-break: keep-all;
        letter-spacing: -0.2px;

        z-index: 1;
    }
`;
