import styled from "styled-components";
import { colors, ColorType } from "theme";

interface DividerProps {
  color?: ColorType;
  margin?: string;
}

const Divider = styled.hr<DividerProps>`
  border: none;
  background-color: ${({ color }) => (!!color ? color : colors.grey300)};
  height: 1px;
  margin: 0px;
  margin: ${({ margin }) => (!!margin ? margin : "0")};
`;

export { Divider };
