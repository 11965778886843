import styled from "styled-components";
import { colors } from "theme";

interface HeadingProps {
  color?: string;
}

interface Body1Props {
  color?: string;
}

export const Heading1 = styled.h1<HeadingProps>`
  color: ${({ color }) => (!!color ? color : colors.grey700)};
  font-size: 38px;
  line-height: 1.315em;
  font-family: JalnanOTF;
  margin: 0;
`;

export const Heading2 = styled.h2<HeadingProps>`
  color: ${({ color }) => (!!color ? color : colors.grey700)};
  font-size: 28px;
  line-height: 1.35em;
  font-family: JalnanOTF;
  margin: 0;
`;

export const Heading3 = styled.h3<HeadingProps>`
  color: ${({ color }) => (!!color ? color : colors.grey700)};
  font-size: 24px;
  line-height: 34px;
  font-family: JalnanOTF;
  margin: 0;
`;

export const Heading4 = styled.h4<HeadingProps>`
  color: ${({ color }) => (!!color ? color : colors.grey700)};
  font-size: 16px;
  line-height: 1.416em;
  font-family: JalnanOTF;
  margin: 0;
`;

export const Heading5 = styled.h5<HeadingProps>`
  color: ${({ color }) => (!!color ? color : colors.grey700)};
  font-size: 14px;
  line-height: 26px;
  font-family: JalnanOTF;
  margin: 0px;
`;

export const Body1 = styled.p<Body1Props>`
  color: ${({ color }) => (!!color ? color : colors.grey700)};
  font-size: 16px;
  line-height: 1.75em;
  font-family: Apple SD Gothic Neo;
  font-weight: bold;
`;

export const Body2 = styled.p<Body1Props>`
  color: ${({ color }) => (!!color ? color : colors.grey700)};
  font-size: 16px;
  line-height: 1.75em;
  font-family: Apple SD Gothic Neo;
`;

export const Body3 = styled.p<Body1Props>`
  color: ${({ color }) => (!!color ? color : colors.grey700)};
  font-size: 14px;
  line-height: 1.85em;
  font-family: Apple SD Gothic Neo;
  font-weight: bold;
`;

export const Body4 = styled.p<Body1Props>`
  color: ${({ color }) => (!!color ? color : colors.grey700)};
  font-size: 14px;
  line-height: 1.85em;
  font-family: Apple SD Gothic Neo;
`;

export const Body5 = styled.p<Body1Props>`
  color: ${({ color }) => (!!color ? color : colors.grey700)};
  font-size: 12px;
  line-height: 1.5em;
  font-family: Apple SD Gothic Neo;
  font-weight: bold;
`;

export const Body6 = styled.p<Body1Props>`
  color: ${({ color }) => (!!color ? color : colors.grey700)};
  font-size: 12px;
  line-height: 1.5em;
  font-family: Apple SD Gothic Neo;
`;

export const InlineColor = styled.span<{
  color: string;
  fontSize?: number;
  lineHeight?: number;
  noWrap?: boolean;
}>`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => (!!fontSize ? `${fontSize}px` : "inherit")};
  line-height: ${({ lineHeight }) =>
    !!lineHeight ? `${lineHeight}` : "inherit"};
  white-space: ${({ noWrap }) => (noWrap === false ? "pre-line" : "nowrap")};
`;

export const PointColorText = styled(InlineColor)<{ color: string }>`
  font-size: 28px;
  line-height: 38px;
  color: ${({ color }) => color};
`;
