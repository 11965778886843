import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { liliMini, samsekMini } from "assets";
import axios from "axios";
import InsuranceCard from "insuranceComponents/main/InsuranceCard";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userTokenState } from "states";

const Popular = () => {
    const navigate = useNavigate();
    const [animalType, setAnimalType] = useState<string>("dog");
    const [insuranceData, setInsuranceData] = useState<any>([]);
    const [moreData, setMoreData] = useState<any>([]);
    const [moreButton, setMoreButton] = useState<boolean>(true);
    const [morePage, setMorePage] = useState<boolean>(true);

    const userToken = useRecoilValue(userTokenState);

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const handleMoreButton = () => {
        if (moreButton) {
            setInsuranceData((prevData: any) => [...prevData, ...moreData]);
            setMoreButton(false);
        } else {
            navigate(`/insurance/insurances/${animalType}/`);
        }
    };

    useEffect(() => {
        setMoreButton(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}insurance/insurances/?animal_type=${animalType}`,
                {
                    headers: {
                        Authorization: userToken ? `Bearer ${userToken}` : ``,
                    },
                }
            )
            .then(res => {
                const data = res.data.results.slice(0, 6);
                setMoreData(res.data.results.slice(6));
                setInsuranceData(data);
                if (res.data.next === null) setMorePage(false);
            })
            .catch(err => {
                navigate("/notfound");
            });
        // eslint-disable-next-line
    }, [animalType]);

    return (
        <div>
            <PopularContainer>
                <PopularTitle>
                    반려인들 사이에서 {isMobile && <br />}
                    인기 펫보험을 알아보아요!
                </PopularTitle>
                <AnimalTypeContainer>
                    <AnimalTypeButton
                        selected={animalType === "dog"}
                        onClick={() => setAnimalType("dog")}
                    >
                        <AnimalTypeLogo src={liliMini} alt="dog"></AnimalTypeLogo>
                        <AnimalTypeText>강아지</AnimalTypeText>
                    </AnimalTypeButton>
                    <AnimalTypeButton
                        selected={animalType === "cat"}
                        onClick={() => setAnimalType("cat")}
                    >
                        <AnimalTypeLogo src={samsekMini} alt="cat"></AnimalTypeLogo>
                        <AnimalTypeText>고양이</AnimalTypeText>
                    </AnimalTypeButton>
                </AnimalTypeContainer>
                <ExtraDescription>
                    {animalType === "dog" ? "말티즈 " : "코숏 "}
                    3세, 남아, 중성화O 기준의 데이터입니다.
                </ExtraDescription>
                <InsuranceCardContainer>
                    {insuranceData?.map((data: any) => (
                        <InsuranceCard key={data.id} data={data} noBorder={false}></InsuranceCard>
                    ))}
                </InsuranceCardContainer>
                {moreData.length !== 0 && (
                    <ButtonContainer>
                        <MoreButton
                            onClick={handleMoreButton}
                            moreButton={moreButton}
                            morePage={morePage}
                        >
                            더보기
                        </MoreButton>
                    </ButtonContainer>
                )}
            </PopularContainer>
        </div>
    );
};

export default Popular;

const PopularContainer = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: 52px;
    background-color: #fff5ce;
`;

const PopularTitle = styled.div`
    padding-top: 70px;
    margin-bottom: 52px;
    text-align: center;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
        margin-top: 55px;
        margin-bottom: 32px;
    }
`;

const AnimalTypeContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 22px;
    margin-bottom: 52px;

    @media only screen and (max-width: 768px) {
        margin-bottom: 35px;
    }
`;

const AnimalTypeButton = styled.div<{ selected?: boolean }>`
    display: flex;
    width: 123px;
    height: 47px;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f9;
    border-radius: 36px;
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    ${({ selected }) =>
        selected && `background-color: #fcd11e; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15)`};
`;

const AnimalTypeLogo = styled.img`
    width: 36px;
    height: 27px;
    margin-right: 6px;
`;

const AnimalTypeText = styled.div`
    margin-right: 2px;
    font-size: 19px;
    line-height: 23px;
    font-weight: 700;
`;

const InsuranceCardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 70px 36px;
    width: 832px;
    margin: 0 auto;
    padding-bottom: 117px;

    @media only screen and (max-width: 896px) {
        gap: 50px 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        width: 690px;
        gap: 40px 10px;
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 30px;
        width: 100vw;
        padding-bottom: 50px;
    }
`;

const ExtraDescription = styled.div`
    margin: 52px auto;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #090909;
    text-align: center;
`;

const ButtonContainer = styled.div`
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 62px;
`;

const MoreButton = styled.button<{ moreButton?: boolean; morePage?: boolean }>`
    position: relative;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: black;

    padding: 9px 146px;
    border: none;
    border-radius: 25px;
    background-color: #fcd11e;
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 448px) {
        font-size: 11px;
        line-height: 13px;
        padding: 8px 71px;
    }

    ${({ moreButton, morePage }) => !moreButton && !morePage && `display: none;`};
`;
