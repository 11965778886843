import React from "react";
import styled from "styled-components";
import { colors } from "theme";
import { Heading2 } from "components/Typography";
import { PostModel, TagModel } from "libs/models";

interface PostSectionProps {
    title?: React.ReactNode;
    backgroundImage?: string;
    children: React.ReactNode;
}

export const PostSection: React.FC<PostSectionProps> = ({
    title,
    backgroundImage,
    children,
}) => {
    return (
        <PostSectionWrapper backgroundImage={backgroundImage}>
            <Heading2 color={colors.grey100}>{title}</Heading2>
            <ContentsWrapper>{children}</ContentsWrapper>
        </PostSectionWrapper>
    );
};

const PostSectionWrapper = styled.div<{ backgroundImage?: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0px;
    box-sizing: border-box;
    ${({ backgroundImage }) =>
        !!backgroundImage && `background-image: ${backgroundImage};`}

    > h2 {
        margin: 0;
    }

    @media only screen and (max-width: 768px) {
        padding: 40px 20px;
    }
`;

const ContentsWrapper = styled.div`
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media only screen and (max-width: 961px) {
        max-width: 500px;
    }
`;

export const PostCard = ({ post }: { post: PostModel }) => {
    const { category, title, guid, tags, thumbnail } = post;
    return (
        <CardContainer href={guid} target="_blank" rel="noopener noreferrer">
            <ContentWrapper>
                <ContentCategory>{category}</ContentCategory>
                <ContentText>{title}</ContentText>
                <TagWrapper>
                    {!!tags &&
                        tags.length > 0 &&
                        tags.map((tag: TagModel, index) => (
                            <TagList key={index}>#{tag.name}</TagList>
                        ))}
                </TagWrapper>
            </ContentWrapper>
            <ThumbnailWrapper>
                {!!thumbnail && (
                    <ThumbnailImage src={thumbnail} alt="thumnail" />
                )}
            </ThumbnailWrapper>
        </CardContainer>
    );
};

const CardContainer = styled.a`
    overflow: hidden;
    width: 220px;
    border-radius: 14px;
    background-color: ${colors.grey100};
    text-decoration: none;
    color: ${colors.grey700};
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    :hover {
        cursor: pointer;
    }

    :last-child {
        margin-right: 0;
    }

    @media only screen and (max-width: 961px) {
        :nth-child(1),
        :nth-child(2) {
            margin-bottom: 20px;
        }

        :nth-child(2n) {
            margin-right: 0;
        }
    }

    @media only screen and (max-width: 768px) {
        width: 320px;
        display: flex;
        flex-direction: row-reverse;
        margin-right: 0;
        margin-bottom: 20px;

        :last-child {
            margin-bottom: 0;
        }
    }
`;

const ContentWrapper = styled.div`
    padding: 20px;

    @media only screen and (max-width: 768px) {
        padding: 16px;
    }
`;

const ThumbnailImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const ThumbnailWrapper = styled.div`
    height: 98px;
    background-color: ${colors.grey300};

    @media only screen and (max-width: 768px) {
        width: 98px;
        height: 100%;
    }
`;

const ContentCategory = styled.p`
    width: fit-content;
    padding: 0px 6px;
    margin: 0px;
    line-height: 18px;
    border-radius: 4px;
    font-size: 11px;
    background-color: ${colors.primary100};
    color: ${colors.grey100};
`;

const ContentText = styled.p`
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;

    @media only screen and (max-width: 768px) {
        max-width: 190px;
    }
`;

const TagWrapper = styled.div`
    margin-top: 17px;

    @media only screen and (max-width: 768px) {
        max-width: 190px;
    }
`;

const TagList = styled.span`
    background-color: ${colors.grey200};
    color: ${colors.grey500};
    padding: 1px 4px;
    margin-right: 8px;
    font-size: 11px;
    white-space: nowrap;
`;
