import React from "react";
import styled from "styled-components";
import { colors } from "theme";

interface RadioProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  onChange?: (value: any) => void;
}

export const RadioButton = ({
  label,
  value,
  checked,
  onChange,
}: RadioProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!!onChange) {
      onChange(value);
    }
  };

  return (
    <RadioWrapper>
      <RadioLabel color={checked ? colors.grey700 : colors.grey400}>
        <BaseRadioButton
          type="radio"
          value={value}
          checked={checked}
          onChange={handleOnChange}
        />
        {label}
      </RadioLabel>
    </RadioWrapper>
  );
};

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
  min-width: 70px;
  padding: 0px;
  margin: 0px;

  font-family: JalnanOTF;
  font-size: 14px;
  line-height: 1.86;
  :hover {
    cursor: pointer;
  }
`;

const BaseRadioButton = styled.input`
  padding: 3px 0px;
  background-color: ${colors.grey200};
  margin: 0px 10px 0px 0px;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 14px;
  outline: none;

  &:checked {
    border: 5px solid ${colors.primary100};
    background-color: ${colors.grey100};
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 16px;
  }
`;
