import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { ResultSection, ResultCard } from "components/ResultSection";
import { InlineColor, Heading2, Heading4 } from "components/Typography";
import { PostSection, PostCard } from "components/PostSection";
import { colors } from "theme";
import { httpService } from "libs/services";
import { PostModel, AnimalKind } from "libs/models";

import CalorieRecommendation from "./CalorieRecommendation";
import { percentFormatter } from "libs/helpers";
import DisplayAds from "components/DisplayAds";

interface RecommendCalorieResultProps {
    form: CalorieRecommendation;
}

const RecommendCalorieResult: React.FC<RecommendCalorieResultProps> = ({
    form,
}) => {
    const [contents, setContents] = useState<PostModel[]>([]);
    const { animal } = form;

    const getContents = useCallback(async () => {
        const keyword =
            animal === AnimalKind.Cat ? " 고양이비만" : "강아지비만";
        const posts = await httpService.getPosts({
            keyword,
            size: 4,
            search_type: "hashtag",
            ordering: "random",
        });
        setContents(posts);
    }, [animal]);

    useEffect(() => {
        getContents();
    }, [getContents]);

    return (
        <>
            <ResultSection>
                <Heading2>
                    <InlineColor color={colors.purple100}>
                        권장칼로리
                    </InlineColor>{" "}
                    결과
                </Heading2>
                <ResultCard backgroundColor={colors.purple200}>
                    <ResultRow>
                        <ResultColumn>
                            <Heading4>1일 권장 칼로리</Heading4>
                            <Heading4 color={colors.grey700}>
                                <PointColorText color={colors.purple100}>
                                    {percentFormatter(form.MER, 2)}
                                </PointColorText>{" "}
                                kcal
                            </Heading4>
                        </ResultColumn>
                        <ResultColumn>
                            <Heading4>1일 기초 대사량</Heading4>
                            <Heading4 color={colors.grey700}>
                                <PointColorText color={colors.purple100}>
                                    {percentFormatter(form.RER, 2)}
                                </PointColorText>{" "}
                                kcal
                            </Heading4>
                        </ResultColumn>
                    </ResultRow>
                    <p>
                        건강 상태, 비만도에 따라 달라질 수 있으니
                        <br />
                        정확한 섭취량은 수의사와 상담해보세요.
                    </p>
                </ResultCard>
            </ResultSection>
            <DisplayAds animalType={form.animal} />
            {contents.length > 0 && (
                <PostSection
                    title={
                        <>
                            <InlineColor color={colors.primary100}>
                                비마이펫{" "}
                            </InlineColor>
                            맞춤 콘텐츠
                        </>
                    }
                    backgroundImage={colors.purpleGradient}
                >
                    {contents.map((post: PostModel, index) => (
                        <PostCard key={index} post={post} />
                    ))}
                </PostSection>
            )}
        </>
    );
};

export default RecommendCalorieResult;

const ResultRow = styled.div`
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-bottom: 30px;
    justify-content: center;
`;

const ResultColumn = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;

    :last-child {
        margin-left: 60px;
    }
`;

const PointColorText = styled(InlineColor)`
    font-size: 28px;
    line-height: 38px;
`;
