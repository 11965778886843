const colors: ColorInterface = {
  grey100: "#ffffff",
  grey200: "#f6f7f8",
  grey300: "#e4e4e6",
  grey400: "#cfcece",
  grey500: "#868688",
  grey600: "#383941",
  grey700: "#090909",
  primary100: "#fcd11e",
  primary200: "#ffeb99",
  secondary100: "#6d2cb6",
  secondary200: "#f0e9f7",
  olive100: "#4e7f1c",
  olive200: "#fefaee",
  purple100: "#5843be",
  purple200: "#f7f4ff",
  blue100: "#3b74c8",
  blue200: "#ecf6ff",
  orange100: "#f29833",
  orange200: "#fff3e5",
  green: "#13bd7f",
  red: "#f24147",
  opacityShadow: "rgba(222, 222, 224, 0.65)",
  oliveGradient: "linear-gradient(65deg, #91af73, #4e7f1c)",
  blueGradient: "linear-gradient(65deg, #6e9de1, #3b74c8)",
  orangeGradient: "linear-gradient(65deg, #f6c895, #f29833)",
  purpleGradient: "linear-gradient(65deg, #9178e0, #5843be)",
};

export type ColorType = keyof typeof colors;

export { colors };

interface ColorInterface {
  grey100: string;
  grey200: string;
  grey300: string;
  grey400: string;
  grey500: string;
  grey600: string;
  grey700: string;
  primary100: string;
  primary200: string;
  secondary100: string;
  secondary200: string;
  olive100: string;
  olive200: string;
  purple100: string;
  purple200: string;
  blue100: string;
  blue200: string;
  orange100: string;
  orange200: string;
  green: string;
  red: string;
  opacityShadow: string;
  oliveGradient: string;
  blueGradient: string;
  orangeGradient: string;
  purpleGradient: string;
}
