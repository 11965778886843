import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactGA from "react-ga";
import { feedMain, socialFeed } from "assets";
import Layout from "components/Layout";
import CoverSection from "components/CoverSection";
import { InlineColor } from "components/Typography";
import { colors } from "theme";

import FoodCalorie from "./FoodCalorie";
import FoodCalorieCalculatorForm from "./FoodCalorieCalculatorForm";
import FoodCalorieCalculatorResult from "./FoodCalorieCalculatorResult";

export default function FoodCalorieCalculatorPage() {
    const [form, setForm] = useState<FoodCalorie>(new FoodCalorie());
    const [isResultOpen, setIsResultOpen] = useState<boolean>(false);
    const [, setState] = useState<any>({});

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    useEffect(() => {
        ReactGA.event({
            category: "사료 칼로리 계산기",
            action: "페이지 접근",
        });
    }, []);

    const handleOnChange = (fieldName: keyof FoodCalorie, value: any) => {
        form.update({
            [fieldName]: value,
        });
        setState({ ...form });
    };

    const handleOnSubmit = async () => {
        setIsResultOpen(false);
        setState({ ...form }); // for update state
        const isValid = await form.validate();
        if (isValid) {
            setIsResultOpen(true);

            ReactGA.event({
                category: "사료 칼로리 계산기",
                action: "계산",
                label: form.animal,
            });
        }
    };

    const handleOnReset = () => {
        setForm(new FoodCalorie());
    };

    return (
        <FoodMainContainer isLoading={isLoading} style={{ display: "none" }}>
            <Layout
                header={{
                    title: "강아지, 고양이 사료 칼로리 계산기",
                    description:
                        "강아지 사료 열량/칼로리 계산기, 고양이 사료 열량/칼로리 계산기. 우리 아이가 먹고 있는 사료의 열량 및 영양소 비율을 계산해보세요.",
                    ogImage: socialFeed,
                    ogUrl: "/calculator/food-calorie",
                }}
            >
                <CoverSection
                    image={feedMain}
                    title={
                        <>
                            <InlineColor color={colors.olive100} noWrap={false}>
                                {`사료칼로리\n계산기`}
                            </InlineColor>
                            란?
                        </>
                    }
                    description={
                        <>
                            사료 성분표를 DM기준(수분0%)으로
                            <br />
                            변환하는 계산기입니다.
                        </>
                    }
                />
                <FoodCalorieCalculatorForm
                    form={form}
                    onChange={handleOnChange}
                    onSubmit={handleOnSubmit}
                    onReset={handleOnReset}
                    isResultOpen={isResultOpen}
                />
                {isResultOpen && <FoodCalorieCalculatorResult form={form} />}
            </Layout>
        </FoodMainContainer>
    );
}

const FoodMainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;
