import React from "react";
import { hydrate, render } from "react-dom";
import ReactGA from "react-ga";
import { createGlobalStyle } from "styled-components";
import Router from "./Router";
import "./index.css";
import { HelmetProvider } from "react-helmet-async";
import { RecoilRoot } from "recoil";

// ReactGA.initialize("UA-154718930-3");
ReactGA.pageview(window.location.pathname + window.location.search);

const GlobalStyle = createGlobalStyle`
html {
  /* font-size: 80%; */


  @media only screen and (max-width: 1024px) {
    font-size: 90%;
    }
  @media only screen and (max-width: 896px) {
    font-size: 80%;
    }
    @media only screen and (max-width: 832px) {
      font-size: 75%;
    }
    @media only screen and (max-width: 768px) {
      font-size: 100%;
    }
    @media only screen and (max-width: 640px) {
      font-size: 90%;
    }
    @media only screen and (max-width: 512px) {
      font-size: 80%;
    }
    @media only screen and (max-width: 448px) {
      font-size: 70%;
    }
    @media only screen and (max-width: 384px) {
      font-size: 65%;
    }
}
  body {
    padding: 0px;
    margin: 0px;
    font-family: "Apple SD Gothic Neo", sans-serif;    
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

const rootElement = document.getElementById("root");

const app = (
    <React.StrictMode>
        <HelmetProvider>
            <RecoilRoot>
                <GlobalStyle />
                <Router />
            </RecoilRoot>
        </HelmetProvider>
    </React.StrictMode>
);

if (!!rootElement && rootElement.hasChildNodes()) {
    hydrate(app, rootElement);
} else {
    render(app, rootElement);
}
