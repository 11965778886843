import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { etcCategoryIcon, liliMini, samsekMini } from "assets";
import StaticUrl from "libs/urls";
const QnA = () => {
    const [qnaData, setQnaData] = useState<any>([]);
    const [moreData, setMoreData] = useState<any>([]);
    const [moreButton, setMoreButton] = useState<boolean>(true);

    const formatDate = (dateString: string) => {
        return dateString.replace(/(\d{4})-(\d{2})-(\d{2}).+/, "$1.$2.$3");
    };

    const handleMoreButton = () => {
        if (moreButton) {
            setQnaData((prevData: any) => [...prevData, ...moreData]);
            setMoreButton(false);
        } else {
            window.location.assign("https://bemypet.kr/community?q=펫보험&p=1");
        }
    };

    const getQnACategory = (category: string) => {
        let animalTypeLogo;

        if (category === "강아지") {
            animalTypeLogo = liliMini;
        } else if (category === "고양이") {
            animalTypeLogo = samsekMini;
        } else {
            animalTypeLogo = etcCategoryIcon;
        }

        return (
            <>
                <AnimalTypeLogo src={animalTypeLogo} alt={category} />
                <AnimalTypeText>{category}</AnimalTypeText>
            </>
        );
    };

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}search/questions/?search=펫보험&force=true`,
                {}
            )
            .then(res => {
                const data = res.data.results.slice(0, 6);
                setMoreData(res.data.results.slice(6));
                setQnaData(data);
            })
            .catch(err => {
                console.log(err);
            });

        return () => {
            setMoreData([]);
            setQnaData([]);
        };
    }, []);

    return (
        <>
            <QnAContainer>
                <QnABoxContainer>
                    {qnaData?.map((data: any) => (
                        <QnAList key={data.id}>
                            <QnAContentContainer>
                                <QnATextContainer>
                                    <QnAAnimalType>
                                        {getQnACategory(data.category.name)}
                                    </QnAAnimalType>
                                    <QnALink href={`${StaticUrl.QnA}${data.id}`}>
                                        <QnATitle>{data.title}</QnATitle>
                                        <QnAContent>{data.content}</QnAContent>
                                    </QnALink>
                                </QnATextContainer>
                                {data?.featured_media && (
                                    <QnAImage
                                        src={data?.featured_media?.source_url}
                                        alt={data.title}
                                    ></QnAImage>
                                )}
                            </QnAContentContainer>
                            <QnASubContent>
                                <QnAAnswer>답변 : {data.answer_count}</QnAAnswer>
                                <QnANickname>{data.author.name}</QnANickname>
                                <QnADate>{formatDate(data.created_at)}</QnADate>
                            </QnASubContent>
                            <QnATagContainer>
                                {data?.tags?.map((tag: any) => (
                                    <QnATag key={tag.id}>#{tag.name}</QnATag>
                                ))}
                            </QnATagContainer>
                        </QnAList>
                    ))}
                </QnABoxContainer>
                <ButtonContainer>
                    <MoreButton onClick={handleMoreButton} moreButton={moreButton}>
                        더보기
                    </MoreButton>
                </ButtonContainer>
            </QnAContainer>
        </>
    );
};

export default QnA;

const QnAContainer = styled.div`
    max-width: 1024px;
    margin: 0 auto;
`;

const QnABoxContainer = styled.div`
    margin-bottom: 60px;
    padding: 0 26px;

    @media only screen and (max-width: 512px) {
        padding: 0 16px;
    }
`;

const QnAList = styled.div`
    margin: 30px 17px 15px 28px;
    border-bottom: 1px solid #e4e4e6;

    @media only screen and (max-width: 512px) {
        margin: 20px 16px 15px;
    }
`;

const QnAContentContainer = styled.div`
    display: flex;
`;

const QnATextContainer = styled.div``;

const QnAAnimalType = styled.div`
    display: inline-flex;
    height: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 21px;
    background-color: #ffeb99;
    border-radius: 26px;

    @media only screen and (max-width: 768px) {
        padding: 0 7px;
        height: 26px;
    }
`;

const AnimalTypeLogo = styled.img`
    width: 26px;
    height: 19px;
    margin-right: 4px;
`;

const AnimalTypeText = styled.div`
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;

    @media only screen and (max-width: 768px) {
        font-size: 9px;
        line-height: 12px;
    }
`;

const QnALink = styled.a`
    text-decoration: none;
    color: black;
`;

const QnATitle = styled.div`
    margin-bottom: 9px;
    font-size: 19px;
    line-height: 22px;
    font-weight: 600;
    cursor: pointer;

    :hover {
        color: #fcd11e;
    }
`;

const QnAImage = styled.img`
    width: 120px;
    min-width: 120px;
    height: 120px;
    border-radius: 17.5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    margin-left: 8.5px;
    margin-right: 36px;

    @media only screen and (max-width: 768px) {
        width: 105px;
        min-width: 105px;
        height: 105px;
        margin-right: 0;
        border-radius: 10px;
    }
`;

const QnAContent = styled.div`
    margin-bottom: 19px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #090909;
    cursor: pointer;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-wrap: break-word;

    @media only screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 19px;
    }
`;

const QnASubContent = styled.div`
    display: flex;
    gap: 52px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;

    @media only screen and (max-width: 768px) {
        font-size: 10px;
        line-height: 16px;
    }
`;

const QnAAnswer = styled.div`
    color: #090909;
`;

const QnANickname = styled.div`
    color: #868688;
`;

const QnADate = styled.div`
    color: #868688;
`;

const QnATagContainer = styled.div`
    display: flex;
    gap: 6px;
    margin-bottom: 15px;
    overflow-x: auto;
    white-space: nowrap;
`;

const QnATag = styled.div`
    padding: 6px 9px;
    border-radius: 36px;
    background-color: #f6f6f9;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;

    @media only screen and (max-width: 768px) {
        padding: 4px 9px;
        font-size: 8px;
        line-height: 10px;
    }
`;

const ButtonContainer = styled.div`
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 62px;
`;

const MoreButton = styled.button<{ moreButton?: boolean }>`
    position: relative;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: black;

    padding: 9px 146px;
    border: none;
    border-radius: 25px;
    background-color: #fcd11e;
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 448px) {
        font-size: 11px;
        line-height: 13px;
        padding: 8px 71px;
    }
`;
