import { 
  insuranceSearch, 
  favoriteInsurance, 
  foodKcal,
  adopt, 
  favoriteAnimal, 
  naming, 
  registerSearch, 
  renderImg, 
  updateLabel,
  recommendKcal,
  calcBMI,
  calcAge,
} from "assets";
import React,{ useContext, useEffect, useState } from "react";
import styled from "styled-components";
import TypingText from "components/TypingText";
import StaticUrl from "libs/urls";
import { LoginContext } from "Router";

interface TypingTextProps {
  text?: string;
  speed?: Number;
  fontSize?: string;
  color?: string;
};

// 툴즈 홈 데스크탑뷰
const DesktopHomePage = ({
  text,
  speed,
}:TypingTextProps) => {
  const { isLoggedIn } = useContext(LoginContext);

  // 각 카드 하위 메뉴
  const CARD_LIST = [
    {
      title: '펫보험',
      infos: [
        {
          img: insuranceSearch,
          menu: '펫보험 찾기',
          url: StaticUrl.ToolsInsurances
        },
        {
          img: favoriteInsurance,
          menu: '관심 펫보험',
          url: isLoggedIn ? StaticUrl.FavoriteInsurance : `${process.env.REACT_APP_MAIN_URL_DEV
          }login?returnURL=${StaticUrl.FavoriteInsurance}`
        },
      ],
    },
    {
      title: '입양',
      infos: [
        {
          img: adopt,
          menu: '유기동물 입양',
          url: StaticUrl.ToolsAdopt
        },
        {
          img: naming,
          menu: '이름 짓기',
          url: StaticUrl.Naming
        },
        {
          img: registerSearch,
          menu: '동물등록 검색',
          url: StaticUrl.RegisterSearch
        },
        {
          img: favoriteAnimal,
          menu: '나의 관심동물',
          url: isLoggedIn ? StaticUrl.FavoriteAnimal : `${process.env.REACT_APP_MAIN_URL_DEV
          }login?returnURL=${StaticUrl.FavoriteAnimal}`
        },
      ],
    },
    {
      title: '건강 계산기',
      infos: [
        {
          img: foodKcal,
          menu: <>사료 칼로리 <br/>계산기</>,
          url: StaticUrl.FoodCalc
        },
        {
          img: recommendKcal,
          menu: <>권장 칼로리 <br/>계산기</>,
          url: StaticUrl.RecommendCalc
        },
        {
          img: calcBMI,
          menu: '비만도 계산기',
          url: StaticUrl.CalcBMI
        },
        {
          img: calcAge,
          menu: '나이 계산기',
          url: StaticUrl.CalcAge
        },
      ],
    },
  ]

  const [ , setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    window.onload = () => {
        setIsLoading(false);
    };

    const handleLoad = () => {
        setIsLoading(true);
    };

    if (document.readyState === "complete") {
        // 이미 로드된 상태일 경우
        setIsLoading(false);
    } else {
        // 로드되기 전인 경우
        document.addEventListener("load", handleLoad);
    }

    return () => {
        // 컴포넌트가 unmount 될 때 이벤트 제거
        document.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <Wrapper>
      {/* Top */}
      <WrapperInfo>
        <InfoText>
          <TextBig>
            <KeyWord>
              <TypingText></TypingText>
            </KeyWord>
            <MainText>
              더 편한 <br/>
              반려 라이프를 즐기세요
            </MainText>
          </TextBig>
          <TextSmall>
            우리 아이의 시간을 더 행복하게, 반려인의 삶을 더 편하게
          </TextSmall>
        </InfoText>
        <InfoImg src={renderImg} />
      </WrapperInfo>
      {/* Bottom */}
      <WrapperMenu>
      {CARD_LIST.map((card) => (
        <CardList key={card.title}>
          {card.title === "입양" && <UpdateLabel src={updateLabel} />}
          <Category>{card.title}</Category>
          <Menu>
            {card.infos.map(info => (
              <MenuCard key={info.url} href={info.url}>
                <MenuImgBox>
                  <MenuImg src={info.img} />
                </MenuImgBox>
                <MenuText>{info.menu}</MenuText>
              </MenuCard>
            ))}
          </Menu>
        </CardList>
      ))}
      </WrapperMenu>
    </Wrapper>
  )
};

export default DesktopHomePage

// Top
const Wrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  margin: auto;
`

const WrapperInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 56px 68px;
  padding-bottom: 92px;

  @media screen and (max-width: 768px) {
    padding: 30px 68px;
    padding-bottom: 36px;
  }

  @media screen and (max-width: 578px) {
    padding: 20px 48px;
    padding-bottom: 20px;
  }
`

const InfoText = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    line-height: 30px;
  }

  @media screen and (max-width: 578px) {
    line-height: 20px;
  }
`

const TextBig = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: black;
`

const KeyWord = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: #FCD11E;
  display: inline;

  @media screen and (max-width: 838px) {
    font-size: 31px;
  }

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }

  @media screen and (max-width: 684px) {
    font-size: 22px;
  }

  @media screen and (max-width: 578px) {
    font-size: 18px;
  }
`

const MainText = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: black;
  display: inline;

  @media screen and (max-width: 838px) {
    font-size: 31px;
  }

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }

  @media screen and (max-width: 684px) {
    font-size: 22px;
  }

  @media screen and (max-width: 578px) {
    font-size: 18px;
  }
`

const TextSmall = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: #868688;
  // 한 줄로 표시
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 756px) {
    font-size: 12px;
  }

  @media screen and (max-width: 712px) {
    font-size: 11px;
    line-height: 20px;
  }

  @media screen and (min-width: 768px) {
    // 여유가 없는 경우 여러 줄로 표시
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
  }
`

const InfoImg = styled.img`
  width: 426px;
  height: 357px;

  @media screen and (max-width: 973px) {
    width: 290px;
    height: 238px;
  }

  @media screen and (max-width: 838px) {
    width: 280px;
    height: 228px;
  }

  @media screen and (max-width: 634px) {
    width: 250px;
    height: 198px;
  }

  @media screen and (max-width: 550px) {
    max-width: 200px;
    max-height: 156px;
  }
`

// Bottom
const WrapperMenu = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 68px 112px;
  gap: 49px;

  @media screen and (max-width: 1004px) {
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    gap: 30px;
  }

  @media screen and (max-width: 756px) {
    gap: 25px;
  }

  @media screen and (max-width: 695px) {
    gap: 30px;
  }

  @media screen and (max-width: 578px) {
    padding: 20px 48px;
    padding-bottom: 56px;
  }

  @media screen and (max-width: 469px) {
    padding: 20px 20px;
    gap: 15px;
  }
`

const CardList = styled.div`
  width: 100%;
  max-width: 268px;
  height: 100%;
  min-height: 355px;
  display: grid;
  grid-template-rows: 25% 75%;
  place-items: center;
  border: 1px solid #E4E4E6;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  padding: 29px 29px;
  position: relative;

  @media screen and (max-width: 838px) {
    max-width: 200px;
    padding: 20px 20px;
  }

  @media screen and (max-width: 756px) {
    max-width: 190px;
    padding: 10px;
    min-height: 320px;
  }

  @media screen and (max-width: 589px) {
    max-width: 170px;
    min-height: 300px;
  }

  @media screen and (max-width: 550px) {
    max-width: 150px;
    min-height: 260px;
  }
`

const UpdateLabel = styled.img`
  position: absolute;
  top: -6px;
  right: -9px;

  @media screen and (max-width: 589px) {
    max-width: 100px;
    max-height: 100px;
  }

  @media screen and (max-width: 550px) {
    max-width: 80px;
    max-height: 80px;
    right: -7px;
  }
`

const Category = styled.div`
  width: 118.74px;
  padding-bottom: 13px;
  border-bottom: 2px solid #FCD11E;
  font-size: 22px;
  font-weight: 600;
  color: black;
  text-align: center;
  margin-bottom: 21px;

  @media screen and (max-width: 838px) {
    font-size: 20px;
  }

  @media screen and (max-width: 756px) {
    width: 100px;
    padding-bottom: 11px;
    margin-bottom: 0px;
  }

  @media screen and (max-width: 695px) {
    width: 90px;
    font-size: 18px;
    padding-bottom: 9px;
  }

  @media screen and (max-width: 578px) {
    font-size: 16px;
  }
`

const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 27px;
  height: fit-content;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }

  @media screen and (max-width: 756px) {
    gap: 18px;
  }

  @media screen and (max-width: 695px) {
    gap: 15px;
  }

  @media screen and (max-width: 756px) {
    gap: 0px;
  }
`

const MenuCard = styled.a`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  width: 85px;
  text-decoration-line: none;

  @media screen and (max-width: 550px) {
    width: 70px;
  }
`

const MenuImgBox = styled.div`
  width: 100%;
  max-width: 85px;
  height: 85px;
  background-color: #FDF6E7;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  :hover {
    background-color: #FFEB99;
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 838px) {
    max-width: 70px;
    height: 70px;
  }

  @media screen and (max-width: 589px) {
    max-width: 60px;
    height: 60px;
  }

  @media screen and (max-width: 550px) {
    max-width: 50px;
    height: 50px;
    border-radius: 10px;
  }
`

const MenuImg = styled.img`
  max-width: 55px;
  height: 55px;

  @media screen and (max-width: 838px) {
    max-width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 589px) {
    max-width: 30px;
    height: 30px
  }
`

const MenuText = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #616161;
  padding-top: 7px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 756px) {
    font-size: 13px;
    padding-bottom: 10px;
  }

  @media screen and (max-width: 695px) {
    font-size: 12px;
  }

  @media screen and (max-width: 550px) {
    padding-bottom: 10px;
    font-size: 9px;
  }
`

