import { toolsOgImage } from "assets"
import Layout from "components/Layout"
import React, { Fragment, useEffect, useState } from "react"
import DesktopHomePage from "./DesktopHomePage"
import MobileHomePage from "./MobileHomePage"
import { useMediaQuery } from "react-responsive"
import styled from "styled-components"

const HomePage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isMobile = useMediaQuery({
    query: "(max-width:448px)",
});

  useEffect(() => {
    window.onload = () => {
        setIsLoading(false);
    };

    const handleLoad = () => {
        setIsLoading(true);
    };

    if (document.readyState === "complete") {
        // 이미 로드된 상태일 경우
        setIsLoading(false);
    } else {
        // 로드되기 전인 경우
        document.addEventListener("load", handleLoad);
    }

    return () => {
        // 컴포넌트가 unmount 될 때 이벤트 제거
        document.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <Fragment>
      <MainContainer isLoading={isLoading} style={{ display: "none" }}>
        <Layout 
          header={{
              title: "비마이펫 툴즈 - 펫보험 비교, 유기동물 입양, 건강 계산기",
              description: "펫보험 비교, 반려동물 / 유기동물 입양, 건강 계산기까지! 비마이펫 툴즈로 더 편한 반려 라이프를 즐겨보세요",
              ogImage: toolsOgImage,
              ogUrl: "/",
          }}
        >
          <Fragment>
              { isMobile
              ? <MobileHomePage/>
              : <DesktopHomePage/>
              }
          </Fragment>
        </Layout>
      </MainContainer>
    </Fragment>
  );
};

export default HomePage

const MainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;