import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "components/Button";
import { colors } from "theme";

interface MainLinkBoxProps {
  title: React.ReactNode;
  to: string;
  color?: string;
  backgroundColor: string;
}

export default function MainCard({
  title,
  color,
  backgroundColor,
  to,
}: MainLinkBoxProps) {
  return (
    <MainCardWrapper backgroundColor={backgroundColor}>
      {title}
      <Link to={to}>
        <LinkButton color={color}>{">"}</LinkButton>
      </Link>
    </MainCardWrapper>
  );
}

const MainCardWrapper = styled.div<{ backgroundColor: string }>`
  padding: 32px 30px;
  margin-right: 20px;
  ${({ backgroundColor }) => `background-color:${backgroundColor};`}
  width: 220px;
  height: 300px;
  border-radius: 14px;
  position: relative;
  z-index: 1;
  box-shadow: 0 30px 20px -10px ${colors.opacityShadow};
  box-sizing: border-box;

  @media only screen and (max-width: 961px) {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 36px;
    margin-right: 0;
    padding: 30px;
    width: auto;
    height: 160px;
  }
`;

const LinkButton = styled(Button)`
  width: 48px;
  height: 48px;
  border-radius: 14px;
  ${({ color }) => `background-color: ${color};`}
  position: absolute;
  right: 30px;
  bottom: 30px;
  color: ${colors.grey100};
  font-size: 25px;
`;
