import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "components/Layout";
import { useSearchParams } from "react-router-dom";
import AdoptDetailInfo from "../../adoptComponents/detail/AdoptDetailInfo";
import axios from "axios";
import AdoptContents from "adoptComponents/detail/AdoptContents";
import AdoptBanner from "adoptComponents/detail/AdoptBanner";
import OtherPets from "adoptComponents/detail/OtherPets";
import { useRecoilValue } from "recoil";
import { userTokenState } from "states";
import NotFound from "pages/notfound/NotFound";
import { adoptOgImage } from "assets";

const AdoptDetail = () => {
    const [searchParams] = useSearchParams();
    const adoptId = searchParams.get("id");
    const [adoptData, setAdoptData] = useState<any>();
    const [noAdoptData, setNoAdoptData] = useState<boolean>(false);

    const userToken = useRecoilValue(userTokenState);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchAdoptData = async () => {
        await axios
            .get(`${process.env.REACT_APP_API_URL_DEV}pet-adoption/adopt/${adoptId}/`, {
                headers: {
                    Authorization: userToken ? `Bearer ${userToken}` : ``,
                },
            })
            .then(res => {
                // console.log(res.data);
                setAdoptData(res.data);
            })
            .catch(err => {
                setNoAdoptData(true);
            });
    };

    useEffect(() => {
        fetchAdoptData();
        // eslint-disable-next-line
    }, [adoptId]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [adoptData]);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <AdoptDetailPageContainer isLoading={isLoading} style={{ display: "none" }}>
            <Layout
                header={{
                    title: "유기동물 입양 - 유기견 & 유기묘 입양",
                    description:
                        "반려동물 분양. 보호자를 찾는 유기동물들을 입양하세요. 가족을 기다리는 반려견 & 반려묘를 유기동물 보호센터에서 입양하세요",
                    ogImage: adoptOgImage,
                    ogUrl: `/adoption/adopt/detail?id=${adoptData?.desertion_no}`,
                }}
            >
                {!noAdoptData ? (
                    <AdoptDetailContainer>
                        <InformationContainer>
                            <AdoptDetailInfo adoptData={adoptData} />
                        </InformationContainer>
                        <OtherPets adoptData={adoptData} />
                        <AdoptBanner adoptData={adoptData} />
                        <AdoptContents adoptData={adoptData} />
                    </AdoptDetailContainer>
                ) : (
                    <NotFound />
                )}
            </Layout>
        </AdoptDetailPageContainer>
    );
};

export default AdoptDetail;

const AdoptDetailPageContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const AdoptDetailContainer = styled.div`
    /* max-width: 64rem; */
    margin: 0 auto;
`;

const InformationContainer = styled.div`
    /* margin: 0 3.6rem; */
    max-width: 64rem;
    margin: 0 auto;

    @media only screen and (max-width: 768px) {
        margin: 0;
    }
`;
