import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "components/Layout";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { cryingLili } from "assets";
import BenefitCard from "insuranceComponents/benefits/BenefitCard";
import { ogImage } from "assets";
import Pagination from "insuranceComponents/insurances/Pagination";
import ReactGA from "react-ga";
import NotFound from "pages/notfound/NotFound";

const InsuranceBenefits = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();
    const animalType = searchParams.get("animal_type");
    const idArr = [
        searchParams.get("id1"),
        searchParams.get("id2"),
        searchParams.get("id3"),
    ]
        .filter((benefit: any) => benefit !== null)
        .map(Number);
    const [noData, setNoData] = useState<boolean>(false);
    const [insuranceData, setInsuranceData] = useState<any>([]);
    const [benefits, setBenefits] = useState<any>([]);

    const idString = idArr.join(",");

    const urlParams = idArr
        .map((benefit: any, index: number) => `id${index + 1}=${benefit}`)
        .join("&");

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const pageSize = 10;

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const fetchInsurances = async () => {
        await axios
            .get(
                `${
                    process.env.REACT_APP_API_URL_DEV
                }insurance/insurances/?offset=${
                    (currentPage - 1) * pageSize
                }&size=${pageSize}&animal_type=${animalType}&benefit_category_ids=${idString}`
            )
            .then(res => {
                setInsuranceData(res.data.results);
                setTotalCount(res.data.count);
            })
            .catch(err => {
                console.log(err);
            });
    };

    function filterCategoriesById(categories: any, idArr: any) {
        return categories.filter((category: any) =>
            idArr.includes(category.id)
        );
    }

    const getBenefitCategories = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}insurance/benefit-categories/?animal_type=${animalType}`,
                {}
            )
            .then(res => {
                setBenefits(
                    filterCategoriesById(
                        res.data,
                        idArr.filter((benefit: any) => benefit !== null)
                    )
                );
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (animalType !== null && idArr.length !== 0) {
            window.scrollTo(0, 0);
            getBenefitCategories();
            fetchInsurances();
            if (insuranceData.length === 0) setNoData(true);
        }
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        ReactGA.event({
            category: "보장 혜택 검색",
            action: "페이지 접근",
        });

        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <>
            <InsuranceBenefitContainer
                isLoading={isLoading}
                style={{ display: "none" }}
            >
                <Layout
                    header={{
                        title: `${
                            typeof window !== "undefined" && idArr.length !== 0
                                ? `${benefits
                                      ?.map((benefit: any) => benefit.name)
                                      .join(", ")} 관련 펫보험 알아보기`
                                : "선택한 혜택 관련 펫보험 알아보기"
                        }`,
                        description: `${
                            typeof window !== "undefined" && idArr.length !== 0
                                ? `${benefits
                                      ?.map((benefit: any) => benefit.name)
                                      .join(
                                          ", "
                                      )}를 보장해주는 펫보험들을 알아보고 가격을 확인해보세요`
                                : "선택한 혜택들을 보장해주는 펫보험들을 알아보고 가격을 확인해보세요"
                        }`,
                        ogImage: ogImage,
                        ogUrl: `${
                            typeof window !== "undefined" &&
                            animalType &&
                            idArr.length !== 0
                                ? `/insurance/benefits?animal_type=${animalType}&${urlParams}`
                                : ""
                        }`,
                    }}
                >
                    {animalType === null ||
                    idArr.length === 0 ||
                    idArr[0] === 0 ? (
                        <NotFound />
                    ) : (
                        <BenefitContainer>
                            <BenefitHeader>
                                <BenefitTitle>
                                    선택하신 보장 혜택을 {isMobile && <br />}
                                    포함하는
                                    {noData
                                        ? " 상품이에요!"
                                        : " 상품이 없어요!"}
                                </BenefitTitle>
                                <BenefitList>
                                    {benefits?.map((benefit: any) => (
                                        <BenefitBlock
                                            key={benefit.id}
                                            noData={insuranceData.length === 0}
                                        >
                                            {benefit.name}
                                        </BenefitBlock>
                                    ))}
                                </BenefitList>
                                {noData ? (
                                    <ExtraDescription>
                                        {animalType === "dog"
                                            ? "말티즈 "
                                            : "코숏 "}
                                        3세, 남아, 중성화O 기준의 데이터입니다.
                                    </ExtraDescription>
                                ) : (
                                    <NoResultImg
                                        src={cryingLili}
                                        alt="no result found"
                                    />
                                )}
                            </BenefitHeader>
                            <InsuranceCardContainer>
                                {insuranceData?.map((data: any) => (
                                    <InsuranceCardWrapper key={data.id}>
                                        <BenefitCard
                                            data={data}
                                            selectedBenefits={benefits}
                                        />
                                    </InsuranceCardWrapper>
                                ))}
                            </InsuranceCardContainer>
                            <PaginationContainer>
                                <Pagination
                                    totalCount={totalCount}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </PaginationContainer>
                        </BenefitContainer>
                    )}
                </Layout>
            </InsuranceBenefitContainer>
        </>
    );
};

export default InsuranceBenefits;

const InsuranceBenefitContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const BenefitContainer = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    margin-bottom: 100px;
`;

const BenefitHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 70px;
    margin: 80px 0 70px;

    @media only screen and (max-width: 768px) {
        gap: 40px;
        margin-bottom: 40px;
    }
`;

const BenefitTitle = styled.div`
    padding: 10px;
    font-size: 32px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0.25px;
    text-align: center;

    @media only screen and (max-width: 768px) {
        font-size: 28px;
        line-height: 38px;
    }
    @media only screen and (max-width: 448px) {
        font-size: 22px;
        line-height: 28px;
    }
`;

const BenefitList = styled.div`
    display: flex;
    gap: 25px;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        gap: 20px;
    }
    @media only screen and (max-width: 546px) {
        gap: 8px;
    }
`;

const BenefitBlock = styled.div<{ noData?: boolean }>`
    padding: 4.5px 15px;

    font-size: 20px;
    line-height: 24px;
    font-weight: 600;

    background-color: #ffeb99;
    border-radius: 31.5px;

    ${({ noData }) => noData && `background-color: #E0E0E0;`};

    @media only screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 19px;
    }
    @media only screen and (max-width: 546px) {
        font-size: 11px;
        line-height: 16px;
    }
    @media only screen and (max-width: 360px) {
        font-size: 10px;
        line-height: 16px;
    }
`;

const ExtraDescription = styled.div`
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #090909;
    text-align: center;

    @media only screen and (max-width: 360px) {
        font-size: 11px;
        line-height: 16px;
    }
`;

const NoResultImg = styled.img`
    width: 375px;
    height: 308px;
    margin: 0 auto;
    margin-top: 65px;

    @media only screen and (max-width: 768px) {
        width: 308px;
        height: 253px;
    }
    @media only screen and (max-width: 360px) {
        width: 241px;
        height: 198px;
        margin-top: 35px;
    }
`;

const InsuranceCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`;

const InsuranceCardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 70px 36px;
    width: 832px;
    margin: 0 auto;
    padding-bottom: 70px;

    @media only screen and (max-width: 896px) {
        gap: 50px 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        width: 690px;
        gap: 40px 10px;
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 30px;
        width: 100vw;
    }
`;

const PaginationContainer = styled.div`
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 62px;
`;
