export interface PostModel {
  category?: React.ReactNode;
  title?: React.ReactNode;
  content?: string;
  tags?: TagModel[];
  guid?: string;
  thumbnail?: string;
  meta?: { [key: string]: string };
}

export interface TagModel {
  id: number;
  name: string;
  slug: string;
}

export enum AnimalKind {
  Dog = "강아지",
  Cat = "고양이",
}

export enum AnimalGrowth {
  Grow = "성장기",
  Adult = "성견/성묘",
}
