import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as BubbleIcon } from "../../assets/AdoptImages/bubble_chart.svg";
import AdoptListCard from "./AdoptListCard";
import axios from "axios";
import { useRecoilValue } from "recoil";
import {
    adoptAgeState,
    adoptBreedState,
    adoptCurrentPageState,
    adoptGenderState,
    adoptNeuterState,
    adoptPetTypeState,
    adoptRegionState,
    adoptStatusState,
    userTokenState,
} from "states";
import AdoptPagination from "adoptComponents/utils/AdoptPagination";
import AdoptSpinner from "adoptComponents/utils/AdoptSpinner";
import AdoptNoResults from "./AdoptNoResults";

const AdoptList = () => {
    const adoptPetType = useRecoilValue(adoptPetTypeState);
    const adoptBreed = useRecoilValue(adoptBreedState);
    const adoptRegion = useRecoilValue(adoptRegionState);

    const adoptStatus = useRecoilValue(adoptStatusState);
    const adoptAge = useRecoilValue(adoptAgeState);
    const adoptGender = useRecoilValue(adoptGenderState);
    const adoptNeuter = useRecoilValue(adoptNeuterState);

    const adoptCurrentPage = useRecoilValue(adoptCurrentPageState);
    // eslint-disable-next-line
    const [loadingSpinner, setLoadingSpinner] = useState<boolean>(true);
    const [breedData, setBreedData] = useState<any>([]);
    const [adoptDataList, setAdoptDataList] = useState<any>();
    const [adoptTotalCount, setAdoptTotalCount] = useState<number>(-1);
    const pageSize = 12;

    const userToken = useRecoilValue(userTokenState);

    const getBreed = async () => {
        let breedString: string = "";
        if (breedData.length === 0) {
            await axios
                .get(
                    `${process.env.REACT_APP_API_URL_DEV}pet-adoption/breeds/?pet_type=${adoptPetType}`,
                    {}
                )
                .then(res => {
                    setBreedData(res.data);
                    for (const breed of res.data) {
                        if (adoptBreed.has(breed.name)) {
                            breedString += "&breed_id=" + breed.id;
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            for (const breed of breedData) {
                if (adoptBreed.has(breed.name)) {
                    breedString += "&breed_id=" + breed.id;
                }
            }
        }
        return breedString;
    };

    const getRegion = () => {
        if (adoptRegion === "전체") return "";
        else return adoptRegion;
    };

    const getStatus = () => {
        if (adoptStatus === "전체" || adoptStatus === "") return "";
        else if (adoptStatus === "보호") return "protect";
        else if (adoptStatus === "공고") return "notice";
    };

    const getAge = () => {
        let ageString: string = "";

        if (adoptAge.has("1살 미만")) ageString += "&age=Puppy";
        if (adoptAge.has("1살 ~ 5살")) ageString += "&age=Young";
        if (adoptAge.has("6살 ~ 9살")) ageString += "&age=Adult";
        if (adoptAge.has("10살 이상")) ageString += "&age=Senior";

        return ageString;
    };

    const getGender = () => {
        if (adoptGender === "전체" || adoptGender === "") return "";
        else if (adoptGender === "여아") return "F";
        else if (adoptGender === "남아") return "M";
    };

    const getNeuter = () => {
        if (adoptNeuter === "전체" || adoptNeuter === "") return "";
        else if (adoptNeuter === "완료") return "Y";
        else if (adoptNeuter === "미완료") return "N";
        else if (adoptNeuter === "알 수 없음") return "U";
    };

    const fetchBreed = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}pet-adoption/breeds/?pet_type=${adoptPetType}`,
                {}
            )
            .then(res => {
                setBreedData(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchBreed();
        // eslint-disable-next-line
    }, [adoptPetType]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoadingSpinner(true);
        fetchAdoptList();
        // eslint-disable-next-line
    }, [adoptCurrentPage]);

    useEffect(() => {
        setLoadingSpinner(true);
        // setAdoptCurrentPage(1);
        fetchAdoptList();
        // eslint-disable-next-line
    }, [
        adoptBreed,
        adoptRegion,
        adoptStatus,
        adoptAge,
        adoptGender,
        adoptNeuter,
    ]);

    const fetchAdoptList = async () => {
        const breedParam = await getBreed();
        await axios
            .get(
                `${
                    process.env.REACT_APP_API_URL_DEV
                }pet-adoption/adopt/?offset=${
                    (adoptCurrentPage - 1) * pageSize
                }&size=${pageSize}&pet_type=${adoptPetType}${breedParam}${getAge()}&region=${getRegion()}&status=${getStatus()}&gender=${getGender()}&neutered=${getNeuter()}`,
                {
                    headers: {
                        Authorization: userToken ? `Bearer ${userToken}` : ``,
                    },
                }
            )
            .then(res => {
                // console.log(res.data);
                setAdoptDataList(res.data.results);
                setAdoptTotalCount(res.data.count);
                setTimeout(() => {
                    setLoadingSpinner(false);
                }, 500);
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <>
            <AdoptListPageContainer>
                {adoptTotalCount !== 0 ? (
                    <>
                        {loadingSpinner && <AdoptSpinner />}
                        {adoptTotalCount !== -1 && (
                            <>
                                <AdoptListTitleContainer>
                                    <BubbleIcon className="bubbleIcon" />
                                    <AdoptTitleNum>
                                        {adoptTotalCount.toLocaleString()}
                                    </AdoptTitleNum>
                                    <AdoptTitleText>
                                        마리의 아이들이 보호자를 기다리고 있어요
                                    </AdoptTitleText>
                                </AdoptListTitleContainer>
                                <AdoptListContainer>
                                    {adoptDataList?.map((data: any) => (
                                        <AdoptListCard
                                            key={data.desertion_no}
                                            adoptData={data}
                                            isFavoriteCard={false}
                                            setShowCard={undefined}
                                        />
                                    ))}
                                </AdoptListContainer>
                                <PaginationContainer>
                                    <AdoptPagination
                                        totalCount={adoptTotalCount}
                                        pageSize={pageSize}
                                    />
                                </PaginationContainer>
                            </>
                        )}
                    </>
                ) : (
                    <AdoptNoResults />
                )}
            </AdoptListPageContainer>
        </>
    );
};

export default AdoptList;

const AdoptListPageContainer = styled.div`
    width: fit-content;
    margin: 0 auto;
`;

const AdoptListTitleContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 0.6rem 0;

    .bubbleIcon {
        width: 1.5rem;
        height: 1.5rem;
    }
`;

const AdoptTitleNum = styled.div`
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
`;

const AdoptTitleText = styled.div`
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400;
`;

const AdoptListContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2.5rem 2rem;
    padding: 1.25rem 0.4rem;

    @media only screen and (max-width: 926px) {
        gap: 2.5rem 1.3rem;
    }
    @media only screen and (max-width: 768px) {
        gap: 2.5rem 2rem;
        grid-template-columns: 1fr 1fr;
    }
`;

const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 70px auto 0;
`;
