import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "components/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as ResultTitleIcon } from "../../assets/NamingImages/result/result_title_icon.svg";
import {
    kakaoBubble,
    namingResultIcon,
    namingThumbnail,
    namingResultImage,
    buttonFlyingLili,
    buttonFlyingSamsek,
} from "assets";
import NamingResultCard from "namingComponents/NamingResultCard";
import CustomLoading from "namingComponents/CustomLoading";
import ToastMessage from "namingComponents/ToastMessage";
import ShareKakao from "./ShareKakao";

declare global {
    interface Window {
        Kakao: any;
    }
}

const NamingResult = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [resultData, setResultData] = useState<any>([]);
    const [spinner, setSpinner] = useState<boolean>(true);
    const [resultString, setResultString] = useState<string>("");
    const [toastMessageOn, setToastMessageOn] = useState<boolean>(false);
    const [retryCount, setRetryCount] = useState<number>(1);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleAgainButton = () => {
        requestChatGPT(1);
    };

    const handleRedoButton = () => {
        navigate("/adoption/name-generator");
    };

    const generateDescription = (resData: any) => {
        setResultData(resData);
        setSpinner(false);
        setResultString(
            "우리 " +
                location.state[1] +
                "의 특성을 담은 이름은?\n" +
                resData
                    .map((data: any) => {
                        return data.name;
                    })
                    .join(", ")
                    .concat(" 입니다!")
        );
    };

    const namingRetry = (tries: number) => {
        setRetryCount(tries);
        setToastMessageOn(true);
        requestChatGPT(tries + 1);
    };

    const requestChatGPT = (tries: number) => {
        if (tries >= 7) {
            navigate("/adoption/notfound");
            return;
        }
        setSpinner(true);
        axios
            .post(`${process.env.REACT_APP_API_URL_DEV}pet-adoption/name-generator/`, {
                pet_type: location.state[1],
                pet_gender: location.state[2],
                pet_personality: location.state[3],
                pet_fur_type: location.state[4],
                pet_fur_color: location.state[5],
                pet_explanation: location.state[6],
            })
            .then(res => {
                let temp = res.data;
                try {
                    if (typeof res.data === "string") {
                        const tempJson = JSON.parse(res.data);
                        temp = tempJson.data;
                    } else if (typeof res.data === "object" && res.data.length === 4) {
                        temp = res.data;
                    } else {
                        namingRetry(tries);
                    }
                } catch (error) {
                    namingRetry(tries);
                }
                if (typeof temp === "object" && temp.length === 4) {
                    sessionStorage.setItem("nameResultData", JSON.stringify(temp));
                    generateDescription(temp);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (window.Kakao) {
            if (!window.Kakao.isInitialized()) {
                window.Kakao.init(process.env.REACT_APP_KAKAO_JS_KEY);
            }
        }

        if (location.state === null) navigate("/adoption/name-generator");
        else {
            if (sessionStorage.getItem("nameResultData") === null) {
                requestChatGPT(1);
            } else {
                const storageData = sessionStorage.getItem("nameResultData");
                if (storageData !== null) {
                    generateDescription(JSON.parse(storageData));
                }
            }
        }

        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };

        // eslint-disable-next-line
    }, []);

    return (
        <NamingResultPageContainer isLoading={isLoading} style={{ display: "none" }}>
            <Layout
                header={{
                    title: "강아지, 고양이 이름 짓기 & 만들기",
                    description:
                        "강아지, 고양이 이름 작명소. 우리 집 강아지, 고양이의 특징에 맞는 이름을 지어 드립니다",
                    ogImage: namingThumbnail,
                    ogUrl: "/adoption/name-generator",
                }}
            >
                <ResultPageWrapper>
                    {spinner ? (
                        location.state && (
                            <>
                                <CustomLoading petType={location.state[1]} />
                                {toastMessageOn && (
                                    <ToastMessageContainer>
                                        <ToastMessage
                                            retryCount={retryCount}
                                            setToastMessageOn={setToastMessageOn}
                                        />
                                    </ToastMessageContainer>
                                )}
                            </>
                        )
                    ) : (
                        <NamingResultContainer>
                            <NamingResultTitle>
                                <ResultTitleIcon className="titleIcon" />
                                추천 이름이 완성되었습니다!
                            </NamingResultTitle>
                            <NamingResultSubTitle>
                                반려동물의 특성을 담은 추천 이름이 생성되었어요!
                            </NamingResultSubTitle>
                            <NamingResultCardContainer>
                                {resultData.map((data: any, index: number) => (
                                    <NamingResultCard data={data} index={index} key={index} />
                                ))}
                            </NamingResultCardContainer>
                            <AgainButton onClick={handleAgainButton}>
                                <img
                                    src={
                                        location.state && location.state[1] === "강아지"
                                            ? buttonFlyingLili
                                            : buttonFlyingSamsek
                                    }
                                    alt={location.state && location.state[1]}
                                />
                                추천 이름 다시 받기
                            </AgainButton>
                            <br />
                            <NamingResultImage src={namingResultIcon} alt="icon" />
                            <br />
                            <ShareButtonContainer onClick={() => ShareKakao(resultString)}>
                                <KakaoBubbleIcon src={kakaoBubble} alt="kakao" />
                                <ShareButton>카카오톡으로 공유하기</ShareButton>
                            </ShareButtonContainer>
                            <RedoButton onClick={handleRedoButton}>다시하기</RedoButton>
                            <br />
                            <SamsekLiliImage src={namingResultImage} alt="삼색이 리리" />
                        </NamingResultContainer>
                    )}
                </ResultPageWrapper>
            </Layout>
        </NamingResultPageContainer>
    );
};

export default NamingResult;

const NamingResultPageContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const NamingResultContainer = styled.div`
    width: 100vw;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    background-color: white;
`;

const NamingResultTitle = styled.div`
    position: relative;
    display: inline-block;
    margin: 50px 0 20px;
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    color: #090909;
    text-align: center;

    .titleIcon {
        position: absolute;
        left: -42px;

        @media only screen and (max-width: 664px) {
            width: 20px;
            height: 22.77px;
            top: 5px;
            left: -28px;
        }
    }

    @media only screen and (max-width: 768px) {
        margin: 54px 0 20px;
    }
    @media only screen and (max-width: 664px) {
        font-size: 26px;
        line-height: 32px;
    }
    @media only screen and (max-width: 448px) {
        margin: 30px 0 14px;
        font-size: 22px;
        line-height: 29px;
    }
`;

const NamingResultSubTitle = styled.div`
    font-size: 13px;
    line-height: 22px;
    font-weight: 400;
    color: #090909;
    text-align: center;

    @media only screen and (max-width: 664px) {
        font-size: 12px;
        line-height: 16px;
    }
`;

const NamingResultCardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
    width: 800px;
    margin: 50px auto 70px;
    justify-content: center;

    @media only screen and (max-width: 832px) {
        gap: 20px;
        width: 640px;
        margin: 85px auto 88px;
    }
    @media only screen and (max-width: 664px) {
        width: 386px;
        grid-template-columns: 1fr 1fr;
        gap: 0 24px;
        margin: 50px auto 60px;
    }
    @media only screen and (max-width: 448px) {
        width: 316px;
        margin: 40px auto 60px;
    }
`;

const AgainButton = styled.div`
    position: relative;
    display: inline-block;
    width: 317px;
    padding: 21px 0;
    margin: 30px auto;

    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68);
    border-radius: 12px;

    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    color: white;

    cursor: pointer;

    img {
        position: absolute;
        top: -30px;
        left: 18px;
        width: 60px;
        height: 38px;

        @media only screen and (max-width: 832px) {
            top: -22px;
            left: 15px;
            width: 43px;
            height: 27px;
        }
    }

    @media only screen and (max-width: 832px) {
        width: 230px;
        padding: 15px 0;
        margin: 20px auto;
        font-size: 15px;
        line-height: 13px;
    }
`;

const NamingResultImage = styled.img`
    width: 96.5px;
    height: 18px;
`;

const ShareButtonContainer = styled.div`
    position: relative;
    display: flex;
    width: 269px;
    padding: 20px 24px;
    margin: 20px auto 15px;

    background-color: #fee500;
    box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68);
    border-radius: 12px;

    align-items: center;

    cursor: pointer;

    :hover {
        box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68),
            inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 832px) {
        width: 190px;
        padding: 14.5px 20px;
        margin: 20px auto 10px;
    }
`;

const ShareButton = styled.div`
    width: 247px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);

    @media only screen and (max-width: 832px) {
        width: 174px;
        font-size: 15px;
        line-height: 13px;
    }
`;

const KakaoBubbleIcon = styled.img`
    width: 22px;

    @media only screen and (max-width: 832px) {
        width: 16px;
    }
`;

const RedoButton = styled.div`
    display: inline-block;
    width: 317px;
    padding: 21px 0;
    margin: 0 auto 180px;

    background-color: #e4e4e6;
    box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68);
    border-radius: 12px;

    font-size: 18px;
    line-height: 18px;
    font-weight: 600;

    cursor: pointer;

    :hover {
        box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68),
            inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 832px) {
        width: 230px;
        padding: 15px 0;
        margin: 0 auto 180px;
        font-size: 15px;
        line-height: 13px;
    }
    @media only screen and (max-width: 664px) {
        margin: 0 auto 250px;
    }
    @media only screen and (max-width: 448px) {
        margin: 0 auto 210px;
    }
`;

const SamsekLiliImage = styled.img`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 405px;
    height: 226px;

    @media only screen and (max-width: 664px) {
        width: 320px;
        height: 178.5px;
    }
`;

const ResultPageWrapper = styled.div`
    position: relative;
    min-height: calc(100vh - 236px);

    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - 244px);
    }
    @media only screen and (max-width: 546px) {
        min-height: calc(100vh - 270px);
    }
`;

const ToastMessageContainer = styled.div`
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
`;
