import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CustomSpinner from "./CustomSpinner";

const CustomLoading = ({ petType }: any) => {
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [currentText, setCurrentText] = useState("");

    const loadingText = [
        "추천 이름을 분석하는 중 . . .",
        "우리 아이 털 색에 어울리는 이름 찾는 중 . . .",
        "우리 아이 성격에 어울리는 이름 찾는 중 . . .",
        "우리 아이 특성을 파악하는 중 . . .",
        "특성에 맞는 이름 생성하는 중 . . .",
        petType === "강아지"
            ? "조금만 더 기다려달라 멍 !"
            : "조금만 더 기다려달라 냥 !",
    ];

    useEffect(() => {
        if (petType !== null) {
            const interval = setInterval(() => {
                const text = loadingText[currentTextIndex];
                const nextChar = text[currentText.length];

                if (nextChar !== undefined) {
                    setCurrentText(prevText => prevText + nextChar);
                }

                if (currentText.length === text.length) {
                    clearInterval(interval);
                    setTimeout(() => {
                        setCurrentTextIndex(
                            prevIndex => (prevIndex + 1) % loadingText.length
                        );
                        setCurrentText("");
                    }, 1200);
                }
            }, 150);

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line
    }, [currentTextIndex, currentText]);

    return (
        <>
            <Spinner>
                <CustomSpinner petType={petType} />
                <LoadingText>{currentText || ""}</LoadingText>
            </Spinner>
        </>
    );
};

export default CustomLoading;

const Spinner = styled.div`
    padding-top: calc(50vh - 370px);
    text-align: center;

    @media only screen and (max-width: 768px) {
        padding-top: calc(50vh - 350px);
    }
`;

const LoadingText = styled.div`
    padding: 30px 0;
    font-size: 26px;
    line-height: 31px;
    font-weight: 400;

    @media only screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 26px;
    }
`;
