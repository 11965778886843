import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as DropdownIcon } from "../../assets/InsuranceImages/main/dropdown_arrow.svg";
import InputGuSingleSelect from "./InputGuSingleSelect";
import { useRecoilState, useSetRecoilState } from "recoil";
import { adoptCurrentPageState, adoptRegionState } from "states";
import InputCitySingleSelect from "./InputCitySingleSelect";
import regionCityList from "../utils/sido.json";
import regionGuList from "../utils/sigungu.json";

interface DropDownRegionProps {
    petType?: string;
    setDropDownRegion: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropDownRegion = ({
    petType,
    setDropDownRegion,
}: DropDownRegionProps) => {
    const [dropDownCity, setDropDownCity] = useState<boolean>(false);
    const [dropDownGu, setDropDownGu] = useState<boolean>(false);
    const [regionCity, setRegionCity] = useState<any>({
        orgCd: "0000000",
        orgdownNm: "전체",
    });
    const [regionGu, setRegionGu] = useState<any>({
        uprCd: "0000000",
        orgCd: "0000000",
        orgdownNm: "전체",
    });
    const [adoptRegion, setAdoptRegion] = useRecoilState(adoptRegionState);
    const [filteredGuList, setFilteredGuList] = useState<Array<any>>([]);
    const setAdoptCurrentPage = useSetRecoilState(adoptCurrentPageState);

    const getGuList = () => {
        if (regionCity) {
            setFilteredGuList(
                regionGuList.filter(item => item.uprCd === regionCity?.orgCd)
            );
        }
    };

    const resetRegion = (resetCity: boolean, resetGu: boolean) => {
        if (resetCity)
            setRegionCity({
                orgCd: "0000000",
                orgdownNm: "전체",
            });
        if (resetGu)
            setRegionGu({
                uprCd: "0000000",
                orgCd: "0000000",
                orgdownNm: "전체",
            });
    };

    const handleInputCity = () => {
        const tempDropDown = dropDownCity;
        setDropDownCity(!tempDropDown);
        if (!tempDropDown) setDropDownGu(false);
    };

    const handleInputGu = () => {
        getGuList();
        const tempDropDown = dropDownGu;
        setDropDownGu(!tempDropDown);
        if (!tempDropDown) setDropDownCity(false);
    };

    const handleClickAllCity = () => {
        resetRegion(true, false);
        setAdoptRegion("전체");
        setDropDownCity(false);
    };

    const handleClickAllGu = () => {
        resetRegion(false, true);
        setAdoptRegion(regionCity.orgdownNm);
        setDropDownGu(false);
    };

    // 시도가 전체면 군구도 전체, region 텍스트값 설정
    const handleSubmitButton = () => {
        if (regionCity?.orgdownNm === "전체") {
            setAdoptRegion("전체");
            resetRegion(false, true);
        } else if (regionCity?.orgdownNm !== "전체") {
            if (regionGu?.orgdownNm === "전체") {
                setAdoptRegion(regionCity?.orgdownNm);
            } else if (regionGu?.orgdownNm !== "전체") {
                setAdoptRegion(
                    `${regionCity?.orgdownNm} ${regionGu?.orgdownNm}`
                );
            }
        }
        setAdoptCurrentPage(1);
        setDropDownRegion(false);
    };

    // 시도가 전체면 군구도 전체
    useEffect(() => {
        getGuList();
        if (regionCity?.orgdownNm === "전체") resetRegion(false, true);
        // eslint-disable-next-line
    }, [regionCity]);

    // 펫타입 변경시 초기화
    useEffect(() => {
        if (petType !== "") {
            setDropDownCity(false);
            setDropDownGu(false);
            resetRegion(true, true);
        }
        // eslint-disable-next-line
    }, [petType]);

    const findCityObject = (orgdownNm: string) => {
        return regionCityList.find(obj => obj.orgdownNm === orgdownNm);
    };
    const findGuObject = (orgdownNm: string) => {
        return regionGuList.find(obj => obj.orgdownNm === orgdownNm);
    };

    // region 값으로 regionCity와 regionGu 값 세팅
    useEffect(() => {
        if (adoptRegion !== "") {
            const regionArr = adoptRegion.split(" ");
            if (regionArr.length === 1) {
                if (regionArr[0] === "전체") {
                    resetRegion(true, true);
                } else {
                    const temp = findCityObject(regionArr[0]);
                    setRegionCity(temp);
                    resetRegion(false, true);
                }
            } else if (regionArr.length === 2) {
                const tempCity = findCityObject(regionArr[0]);
                setRegionCity(tempCity);
                const tempGu = findGuObject(regionArr[1]);
                setRegionGu(tempGu);
            }
        }
        // eslint-disable-next-line
    }, [adoptRegion]);

    return (
        <>
            <DropDownRegionContainer>
                <InputContainer>
                    <InputTitle>시/도</InputTitle>
                    <InputBox dropDown={dropDownCity} onClick={handleInputCity}>
                        <InputText>
                            {regionCity?.orgdownNm !== "전체"
                                ? regionCity?.orgdownNm
                                : "전체"}
                        </InputText>
                        <DropdownIcon
                            className="icon"
                            onClick={() => {
                                setDropDownCity(prev => !prev);
                            }}
                        />
                    </InputBox>
                    {dropDownCity && (
                        <InputListContainer>
                            <>
                                {
                                    <InputList
                                        selected={
                                            regionCity?.orgdownNm === "전체"
                                        }
                                        onClick={handleClickAllCity}
                                    >
                                        전체
                                    </InputList>
                                }

                                {regionCityList.map((data: any) => (
                                    <InputCitySingleSelect
                                        key={data.orgCd}
                                        regionData={data}
                                        currRegion={regionCity}
                                        setRegion={setRegionCity}
                                        setDropDown={setDropDownCity}
                                        setRegionGu={setRegionGu}
                                    />
                                ))}
                            </>
                        </InputListContainer>
                    )}
                </InputContainer>
                <InputContainer>
                    <InputTitle>군/구</InputTitle>
                    <InputBox
                        dropDown={dropDownGu}
                        disabled={
                            regionCity?.orgdownNm === "" ||
                            regionCity?.orgdownNm === "전체"
                        }
                        onClick={handleInputGu}
                    >
                        <InputText
                            disabled={
                                regionCity?.orgdownNm === "" ||
                                regionCity?.orgdownNm === "전체"
                            }
                        >
                            {regionGu?.orgdownNm !== "전체"
                                ? regionGu?.orgdownNm
                                : "전체"}
                        </InputText>
                        <DropdownIcon
                            className="icon"
                            onClick={() => setDropDownGu(prev => !prev)}
                        />
                    </InputBox>
                    {dropDownGu && (
                        <InputListContainer>
                            <>
                                {
                                    <InputList
                                        selected={
                                            regionGu?.orgdownNm === "전체"
                                        }
                                        onClick={handleClickAllGu}
                                    >
                                        전체
                                    </InputList>
                                }

                                {filteredGuList?.map((data: any) => (
                                    <InputGuSingleSelect
                                        key={data.orgCd}
                                        regionData={data}
                                        regionGu={regionGu}
                                        setDropDown={setDropDownGu}
                                        setRegionGu={setRegionGu}
                                    />
                                ))}
                            </>
                        </InputListContainer>
                    )}
                </InputContainer>
                <SubmitButton onClick={handleSubmitButton}>적용</SubmitButton>
            </DropDownRegionContainer>
        </>
    );
};

export default DropDownRegion;

const DropDownRegionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 17.5rem;
    padding: 1.25rem 0.75rem;
    background-color: #fffdf4;
    border: 1px solid #fcd11e;
    border-radius: 10px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); */

    @media only screen and (max-width: 768px) {
        width: 24.6rem;
        gap: 1.5rem;
        padding: 1.5rem 1rem;
    }
`;

const InputContainer = styled.div``;

const InputTitle = styled.div`
    margin-bottom: 10px;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;

const InputBox = styled.div<{ dropDown?: boolean; disabled?: boolean }>`
    position: relative;

    .icon {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        z-index: 3;

        ${({ dropDown }) =>
            dropDown && `transform: scaleY(-1); transition: .3s; `};

        @media only screen and (max-width: 768px) {
            top: 1rem;
            right: 1rem;
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    ${({ disabled }) => disabled && `pointer-events: none;`};
`;

const InputText = styled.div<{ disabled?: boolean }>`
    width: 15.4rem;
    padding: 0.75rem 0.95rem;
    background-color: #ffffff;
    border: 1px solid #e4e4e6;
    border-radius: 5px;

    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;

    cursor: pointer;

    ${({ disabled }) => disabled && `background-color: #F6F6F9`};

    @media only screen and (max-width: 768px) {
        width: 22.4rem;
        padding: 1rem;
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
`;

const SubmitButton = styled.div`
    width: 1.5rem;
    margin: 0 auto;
    padding: 0.56rem 1.1rem;

    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 600;
    word-break: keep-all;
    text-align: center;

    border-radius: 21px;
    background-color: #fcd11e;
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
    }

    @media only screen and (max-width: 768px) {
        width: 1.9rem;
        padding: 0.6rem 1.5rem;
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;

const InputListContainer = styled.div`
    margin-top: 5px;
    background-color: #ffffff;
    border: 1px solid #e4e4e6;
    border-radius: 5px;

    max-height: 8.4rem;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 4px;
    }
    ::-webkit-scrollbar-thumb {
        background: #fcd11e;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
        background-color: #cfcece;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    @media only screen and (max-width: 768px) {
        max-height: 11.4rem;
    }
`;

const InputList = styled.div<{ selected?: boolean }>`
    padding: 0.75rem;

    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;
    color: #000000;

    border-bottom: 1px solid #e4e4e6;
    cursor: pointer;

    :last-child {
        border: none;
    }

    :hover {
        background-color: #fff5ce;
    }

    ${({ selected }) => selected && `background-color: #fff5ce;`};

    @media only screen and (max-width: 768px) {
        padding: 1rem;
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
`;
