import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Information = () => {
    const [infoData, setInfoData] = useState<any>([]);
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}search/posts/?search=펫보험`,
                {}
            )
            .then(res => {
                const sliceInfoData = res.data.results.slice(0, 5);
                setInfoData(sliceInfoData);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    return (
        <>
            <InformationContainer>
                <InformationTitle>비마이펫 펫보험 지식정보</InformationTitle>
                <InfoCardContainer>
                    {infoData?.map((data: any) => (
                        <InfoCard href={data.guid} key={data.id}>
                            <InfoCardImage
                                src={data.featured_media}
                                alt={data.title}
                            ></InfoCardImage>
                            <InfoCardTextWrapper>
                                <InfoCardText>{data.title}</InfoCardText>
                            </InfoCardTextWrapper>
                        </InfoCard>
                    ))}
                </InfoCardContainer>
            </InformationContainer>
        </>
    );
};

export default Information;

const InformationContainer = styled.div`
    max-width: 1408px;
    padding: 120px 16px;
    margin: 0 auto;
    text-align: center;
    background-color: #fff5ce;

    @media only screen and (max-width: 512px) {
        padding: 70px 16px;
    }
`;

const InformationTitle = styled.div`
    padding-bottom: 65px;
    font-size: 2rem;
    line-height: normal;
    font-weight: 700;
`;

const InfoCardContainer = styled.div`
    display: flex;
    gap: 3rem;
    max-width: 1024px;
    height: 15.75rem;
    margin: 0 auto;
    padding: 1.25rem 4rem 2.5rem 2rem;
    overflow-x: scroll;

    @media only screen and (max-width: 768px) {
        padding: 1.25rem 1.5rem 2.5rem;
    }
`;

const InfoCard = styled.a`
    display: flex;
    flex-direction: column;
    width: 17.63rem;
    height: 15.75rem;
    border-radius: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
    color: black;
    cursor: pointer;

    :hover {
        box-shadow: 0px 30px 12px rgba(0, 0, 0, 0.01),
            0px 17px 10px rgba(0, 0, 0, 0.05), 0px 7px 7px rgba(0, 0, 0, 0.09),
            0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    }
`;

const InfoCardImage = styled.img`
    width: 17.63rem;
    height: 11.5rem;
    border-radius: 15px 15px 0 0;
`;

const InfoCardTextWrapper = styled.div`
    width: 17.63rem;
    height: 4.2rem;
    background-color: white;
    border-radius: 0 0 15px 15px;
`;

const InfoCardText = styled.div`
    padding: 0.87rem 1.5rem;
    font-size: 1rem;
    line-height: normal;
    font-weight: 500;
    text-align: left;
`;
