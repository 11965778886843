import axios from "axios";
import Layout from "components/Layout";
import CompareBody from "insuranceComponents/compare/CompareBody";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ogImage } from "assets";
import ReactGA from "react-ga";
import NotFound from "pages/notfound/NotFound";

const InsuranceCompare = () => {
    const [searchParams] = useSearchParams();
    const animalType = searchParams.get("animal_type");
    const insuranceId1 = searchParams.get("id1");
    const insuranceId2 = searchParams.get("id2");
    const [insuranceData1, setInsuranceData1] = useState<any>([]);
    const [insuranceData2, setInsuranceData2] = useState<any>([]);

    const [categories, setCategories] = useState<any>([]);
    const categoriesList = [
        "가입 가능 나이",
        "갱신 가능 나이",
        "자기부담금",
        "보상 비율",
        "보험 금액",
    ];
    const [loadingDim, setLoadingDim] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getResults = (benefitData: any) => {
        const result: any = [];
        categories?.forEach((category: any) => {
            const matchedBenefit = benefitData.find(
                (benefit: any) => benefit.category_id === category.id
            );
            if (matchedBenefit) {
                result.push(matchedBenefit);
            } else {
                result.push({
                    name: category.name,
                    description: "없음",
                    priority: category.priority,
                    category_id: category.id,
                });
            }
        });
        return result;
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const [res1, res2, res3] = await Promise.all([
                    axios.get(
                        `${process.env.REACT_APP_API_URL_DEV}insurance/insurances/${insuranceId1}/`
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_URL_DEV}insurance/insurances/${insuranceId2}/`
                    ),
                    axios.get(
                        `${process.env.REACT_APP_API_URL_DEV}insurance/benefit-categories/?animal_type=${animalType}`
                    ),
                ]);
                setInsuranceData1(res1.data);
                setInsuranceData2(res2.data);
                setLoadingDim(false);
                setCategories(res3.data);
            } catch (err) {
                console.log(err);
            }
        };
        setLoadingDim(true);
        if (
            animalType !== "" &&
            animalType !== null &&
            insuranceId1 !== "" &&
            insuranceId1 !== null &&
            insuranceId2 !== "" &&
            insuranceId2 !== null
        ) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [insuranceId1, insuranceId2, animalType]);

    useEffect(() => {
        ReactGA.event({
            category: "보험 비교",
            action: "페이지 접근",
        });

        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <InsuranceCompareContainer
                isLoading={isLoading}
                style={{ display: "none" }}
            >
                <Layout
                    header={{
                        title: `${
                            insuranceId1 && insuranceId2
                                ? `${insuranceData1.name} 및 ${insuranceData2.name} 비교하기`
                                : "보험 상품 비교하기"
                        }`,
                        description: `${
                            typeof window !== "undefined" &&
                            insuranceId1 &&
                            insuranceId2
                                ? `펫보험 비교하기. ${insuranceData1.name} 그리고 ${insuranceData2.name}`
                                : "펫보험 비교하기"
                        }`,
                        ogImage: ogImage,
                        ogUrl: `${
                            typeof window !== "undefined" &&
                            animalType &&
                            insuranceId1 &&
                            insuranceId2
                                ? `/insurance/compare?animal_type=${animalType}&id1=${insuranceId1}&id2=${insuranceId2}`
                                : ""
                        }`,
                    }}
                >
                    {animalType === "" ||
                    animalType === null ||
                    insuranceId1 === "" ||
                    insuranceId1 === null ||
                    insuranceId2 === "" ||
                    insuranceId2 === null ? (
                        <NotFound />
                    ) : (
                        <>
                            <IsLoadingDim
                                loadingDim={loadingDim}
                            ></IsLoadingDim>
                            <CompareContainer>
                                <CompareWrapper>
                                    <CompareTitle>1:1 비교 결과</CompareTitle>
                                    <DataDescription>
                                        {animalType === "dog"
                                            ? "말티즈 "
                                            : "코숏 "}
                                        3세, 남아, 중성화O 기준의 데이터입니다.
                                    </DataDescription>
                                    <CompareTableContainer>
                                        <CompareTableHead>
                                            <CompareHeadList>
                                                {categories?.map(
                                                    (data: any) => (
                                                        <CompareHead
                                                            key={data.id}
                                                        >
                                                            {data.name}
                                                        </CompareHead>
                                                    )
                                                )}
                                                {categoriesList?.map(
                                                    (data: string) => (
                                                        <CompareHead key={data}>
                                                            {data}
                                                        </CompareHead>
                                                    )
                                                )}
                                            </CompareHeadList>
                                        </CompareTableHead>
                                        <CompareTableBody>
                                            <CompareBody
                                                insuranceData={insuranceData1}
                                                benefitData={getResults(
                                                    insuranceData1?.insurance_benefits
                                                )}
                                            ></CompareBody>
                                        </CompareTableBody>
                                        <CompareTableBody>
                                            <CompareBody
                                                insuranceData={insuranceData2}
                                                benefitData={getResults(
                                                    insuranceData2?.insurance_benefits
                                                )}
                                            ></CompareBody>
                                        </CompareTableBody>
                                    </CompareTableContainer>
                                    <ExtraDescription>
                                        반려동물 상태(나이, 질병, 중성화 등),
                                        가입 경로, 보험 가입 시 선택 내용(보상
                                        비율, 특약 등)에 따라 보험 금액이 달라질
                                        수 있습니다.
                                    </ExtraDescription>
                                </CompareWrapper>
                            </CompareContainer>
                        </>
                    )}
                </Layout>
            </InsuranceCompareContainer>
        </>
    );
};

export default InsuranceCompare;

const InsuranceCompareContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const CompareContainer = styled.div`
    max-width: 1024px;
    margin: 0 auto;
`;

const IsLoadingDim = styled.div<{ loadingDim?: boolean }>`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: white;
    opacity: 0.8;
    z-index: 5;

    display: none;

    ${({ loadingDim }) => loadingDim && `display: block;`};
`;

const CompareWrapper = styled.div`
    width: fit-content;
    margin: 57px auto 32px;

    @media only screen and (max-width: 768px) {
        margin: 57px calc((100vw - 521px) / 2) 32px;
    }
    @media only screen and (max-width: 512px) {
        margin: 57px calc((100vw - 317px) / 2) 32px;
    }
`;

const CompareTitle = styled.div`
    display: inline-block;
    margin-bottom: 3px;
    padding: 10px;
    font-size: 26px;
    line-height: 31px;
    font-weight: 700;
`;

const DataDescription = styled.div`
    padding: 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #090909;

    @media only screen and (max-width: 512px) {
        font-size: 11px;
        line-height: 16px;
    }
`;

const CompareTableContainer = styled.div`
    display: flex;
`;

const CompareTableHead = styled.div`
    background-color: #fcd11e;
    border-radius: 10px;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.15);
    z-index: 1;
`;

const CompareHeadList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding: 100px 37px 24px;

    @media only screen and (max-width: 896px) {
        padding: 100px 27px 24px;
    }
    @media only screen and (max-width: 768px) {
        padding: 100px 12px 24px;
    }
    @media only screen and (max-width: 512px) {
        gap: 15px;
        padding: 52px 5px 24px 10px;
    }
`;

const CompareHead = styled.div`
    display: flex;
    align-items: center;
    height: 92px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    word-break: keep-all;

    @media only screen and (max-width: 512px) {
        height: 58px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 600;
    }
`;

const CompareTableBody = styled.div`
    width: 326px;
    border-radius: 10px;
    border: 1px solid #e4e4e6;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.15);

    @media only screen and (max-width: 896px) {
        width: 256px;
    }
    @media only screen and (max-width: 768px) {
        width: 186px;
    }
    @media only screen and (max-width: 512px) {
        width: 112px;
    }
`;

const ExtraDescription = styled.div`
    margin-top: 9px;
    padding: 20px 10px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;

    @media only screen and (max-width: 512px) {
        font-size: 9px;
        line-height: 17px;
        font-weight: 400;
    }
`;
