import React from "react";
import { colors } from "theme";
import IconProps from ".";

const DownIcon = ({
  color = colors.grey400,
  width = 24,
  height = 24,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{ width, height }}
  >
    <g fill="none">
      <path d="M0 0H1260V1948H0z" transform="translate(-208 -1824)" />
      <g>
        <path
          d="M0 0H24V24H0z"
          transform="translate(-208 -1824) translate(208 1824)"
        />
        <path
          fill={color}
          d="M6.442 10.594l4.434 4.878c.406.435.765.528 1.108.528.36 0 .734-.093 1.14-.528l4.434-4.878c.625-.684.562-1.523-.031-2.113-.593-.575-1.561-.699-2.139-.015L12 12.427 8.612 8.466c-.578-.684-1.546-.56-2.139.015-.593.59-.656 1.43-.031 2.113z"
          transform="translate(-208 -1824) translate(208 1824)"
        />
      </g>
    </g>
  </svg>
);

export default DownIcon;
