import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseRoundedIcon } from "../../assets/DrawerImages/close.svg";
import { ReactComponent as DropdownIcon } from "../../assets/InsuranceImages/main/dropdown_arrow.svg";
import { ReactComponent as TipDeleteIcon } from "../../assets/InsuranceImages/main/tip_close.svg";
import DropDownCheckBox from "./DropDownCheckBox";
import axios from "axios";

interface CheckDropdownProps {
    petType?: string | undefined;
    inputError?: boolean | undefined;
    setInputError?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    benefitArr: Set<string>;
    setBenefitArr: React.Dispatch<React.SetStateAction<Set<string>>>;
    allCategories: any;
    setAllCategories: React.Dispatch<React.SetStateAction<any>>;
}

const InputCheckDropdown = ({
    petType,
    inputError,
    setInputError,
    benefitArr,
    setBenefitArr,
    allCategories,
    setAllCategories,
}: CheckDropdownProps) => {
    const [dropdown, setDropdown] = useState<boolean>(false);
    const [categories, setCategories] = useState<any>([]);
    const [inputText, setInputText] = useState<string>("");
    const [selectErrMsg, setSelectErrMsg] = useState(false);

    const handleContainerClick = () => {
        if (petType === "") {
            setInputError?.(true);
        }
    };

    const handleDropdownIcon = (e: any) => {
        if (petType !== "") {
            setDropdown(prev => !prev);
            setBenefitArr(new Set());
        }
    };

    const handleInputChange = (e: any) => {
        setInputText(e.target.value);
        setDropdown(true);
    };

    const handleDeleteBenefit = (data: any) => {
        if (benefitArr.size !== 0 && benefitArr.has(data)) {
            setBenefitArr((prevArr: Set<string>) => {
                const newArr = new Set(prevArr);
                newArr.delete(data);
                return newArr;
            });
        }
    };

    const handleCompleteSelect = () => {
        setDropdown(false);
        setSelectErrMsg(false);
        setInputText("");
    };

    const fetchBenefits = async (petType: string | undefined) => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}insurance/benefit-categories/?animal_type=${petType}`,
                {}
            )
            .then(res => {
                setCategories(res.data);
                setAllCategories(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (petType !== "") {
            setDropdown(false);
            fetchBenefits(petType);
            setSelectErrMsg(false);
            setInputText("");
        }
        // eslint-disable-next-line
    }, [petType]);

    useEffect(() => {
        if (petType !== "" && allCategories.length === 0) {
            fetchBenefits(petType);
        }
        if (inputText !== "" && allCategories.length > 0) {
            setCategories(
                allCategories.filter((e: any) => {
                    return e.name.includes(inputText);
                })
            );
        }
        if (inputText === "" && allCategories.length > 0) {
            setCategories(
                allCategories.filter((e: any) => {
                    return e.name.includes(inputText);
                })
            );
        }

        // eslint-disable-next-line
    }, [petType, inputText, allCategories]);

    return (
        <>
            <InputContainer onClick={handleContainerClick} dropdown={dropdown}>
                <InsuranceInput
                    value={inputText}
                    onChange={handleInputChange}
                    onClick={() => setDropdown(true)}
                    disabled={petType === ""}
                    inputError={inputError}
                    dropdown={dropdown}
                    placeholder="보장 혜택 최대 3개 선택"
                />
                {dropdown && inputText !== "" ? (
                    <CloseRoundedIcon
                        onClick={() => {
                            setInputText("");
                        }}
                        className="icon"
                    />
                ) : (
                    <DropdownIcon
                        onClick={handleDropdownIcon}
                        className="icon"
                    />
                )}
                {dropdown && (
                    <DropDownContainer>
                        <DropDownListContainer selectErrMsg={selectErrMsg}>
                            <ul>
                                {categories?.map((data: any) => (
                                    <DropDownCheckBox
                                        key={data.id}
                                        benefitId={data.id}
                                        benefitName={data.name}
                                        benefitArr={benefitArr}
                                        setBenefitArr={setBenefitArr}
                                        selectErrMsg={selectErrMsg}
                                        setSelectErrMsg={setSelectErrMsg}
                                        inputText={inputText}
                                        setInputText={setInputText}
                                    />
                                ))}
                            </ul>
                            {inputText !== "" && categories.length === 0 && (
                                <OptionErrorMessage>
                                    일치하는 옵션이 없습니다.
                                </OptionErrorMessage>
                            )}
                        </DropDownListContainer>
                        {selectErrMsg && (
                            <SelectErrorMessage>
                                <ErrorText>
                                    보장 혜택은 최대 3개까지 선택 가능합니다.
                                </ErrorText>
                                <IconContainer>
                                    <CloseRoundedIcon
                                        onClick={() => setSelectErrMsg(false)}
                                    />
                                </IconContainer>
                            </SelectErrorMessage>
                        )}
                        <CompleteContainer>
                            <CompleteButton onClick={handleCompleteSelect}>
                                완료
                            </CompleteButton>
                        </CompleteContainer>
                    </DropDownContainer>
                )}
                <TipButtonListContainer>
                    {Array.from(benefitArr)?.map(data => (
                        <TipButtonWrapper key={data}>
                            <TipButton>{data}</TipButton>
                            <TipDeleteIcon
                                className="deleteIcon"
                                onClick={() => handleDeleteBenefit(data)}
                            />
                        </TipButtonWrapper>
                    ))}
                </TipButtonListContainer>
            </InputContainer>
        </>
    );
};

export default InputCheckDropdown;

const InputContainer = styled.div<{ dropdown?: boolean }>`
    position: relative;

    .icon {
        position: absolute;
        top: 1.4rem;
        right: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
        z-index: 3;

        ${({ dropdown }) => dropdown && `transform: scaleY(-1)`};
    }
`;

const DropDownContainer = styled.div`
    position: absolute;
    top: 4.625rem;
    left: 1px;
    width: 24.25rem;
    min-height: 5.625rem;
    max-height: 28.125rem;
    border-radius: 6px;
    box-shadow: 0px 1.44px 2.88px rgba(0, 0, 0, 0.3),
        0px 2.88px 8.63999px 2.88px rgba(0, 0, 0, 0.15);
    z-index: 9;
`;

const DropDownListContainer = styled.div<{ selectErrMsg?: boolean }>`
    min-height: 4.5rem;
    max-height: 22.5rem;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 6px;
        margin: 0 2px;
    }
    ::-webkit-scrollbar-thumb {
        background: #fcd11e;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
        background-color: #e4e4e6;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    background-color: #fffdf4;
    border-radius: 6px 6px 0 0;
    ul {
        margin: 0;
        padding: 0;
    }

    ${({ selectErrMsg }) => selectErrMsg && `overflow-y: hidden;`};
    ${({ selectErrMsg }) => selectErrMsg && `pointer-events: none;`};
`;

const CompleteContainer = styled.div`
    height: 5.625rem;
    background-color: white;
    border-radius: 0 0 6px 6px;

    text-align: center;
`;

const CompleteButton = styled.div`
    display: inline-block;
    margin: 1.375rem 0 1.625rem;
    padding: 12px 65px;

    font-size: 1rem;
    line-height: 1.18rem;
    font-weight: 700;

    border-radius: 21px;
    background-color: #fcd11e;
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
`;

const InsuranceInput = styled.input<{
    disabled?: boolean;
    inputError?: boolean;
    optionError?: boolean;
    dropdown?: boolean;
}>`
    position: relative;
    width: 20.05rem;
    height: 4.5rem;
    padding: 0 1.1rem;
    padding-right: 3.1rem;
    border-radius: 10px;
    border: none;
    background-color: #fff5ce;
    border: 1px solid #fff5ce;
    cursor: pointer;

    font-size: 1.1rem;
    line-height: 1.3rem;
    font-weight: 500;

    :focus {
        outline: none;
        background-color: white;
        border: 1px solid #fcd11e;
    }

    ${({ dropdown }) =>
        dropdown &&
        `outline: none;
        background-color: white;
        border: 1px solid #fcd11e;`};

    ${({ inputError }) =>
        inputError && `background-color: #E4E4E6; border: 1px solid #E4E4E6;`};

    ${({ optionError }) =>
        optionError && `background-color: white; border: 1px solid #F24147;`};
`;

const OptionErrorMessage = styled.div`
    position: absolute;
    top: 1.7rem;
    left: 1.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: #f24147;
`;

const SelectErrorMessage = styled.div`
    position: absolute;
    top: 15rem;
    left: 1.4rem;
    display: flex;
    justify-content: space-between;
    width: 19.5rem;
    padding: 0.875rem 1rem;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
        0px 1px 3px rgba(0, 0, 0, 0.3);

    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;

    align-items: center;
`;

const ErrorText = styled.div`
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
`;

const IconContainer = styled.div`
    height: 1.5rem;
    cursor: pointer;
`;

const TipButtonListContainer = styled.div`
    position: absolute;
    display: flex;
    gap: 0.75rem;
    padding: 0.62rem 0.93rem;
`;

const TipButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0.31rem 0.62rem;
    border-radius: 21px;
    background-color: #f6f6f9;

    .deleteIcon {
        cursor: pointer;
    }
`;

const TipButton = styled.div`
    margin-right: 0.3rem;
    font-size: 0.81rem;
    line-height: 1rem;
    font-weight: 600;
    word-break: keep-all;
`;
