import { namingThumbnail } from "assets";

const ShareKakao = (resultString: string) => {
    window.Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
            title: "강아지, 고양이 이름 짓기",
            description: `${resultString}`,
            imageUrl: `${process.env.REACT_APP_URL_DEV}${namingThumbnail}`,
            imageWidth: 1200,
            imageHeight: 630,
            link: {
                mobileWebUrl: `${process.env.REACT_APP_URL_DEV}adoption/name-generator`,
                webUrl: `${process.env.REACT_APP_URL_DEV}adoption/name-generator`,
            },
        },
        buttons: [
            {
                title: "이름 추천받기",
                link: {
                    mobileWebUrl: `${process.env.REACT_APP_URL_DEV}adoption/name-generator`,
                    webUrl: `${process.env.REACT_APP_URL_DEV}adoption/name-generator`,
                },
            },
        ],
    });
};

export default ShareKakao;
