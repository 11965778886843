export const scrollTo = (offsetTop: number) => {
  window.scrollTo(0, offsetTop);
};
// ref 위치로 스크롤

export const percentFormatter = (value: number, fix: number): string => {
  if (!value || isNaN(Number(value))) {
    return "";
  }
  if (typeof value === "string") {
    return Number(value).toFixed(fix);
  }
  return value.toFixed(fix);
};
