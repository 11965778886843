import React from "react";
import styled from "styled-components";


interface FormTextFieldProps {
  label?: string;
}

export const FormTextField = ({
  label,
}: FormTextFieldProps) => {
  return (
    <FormTextFieldWrapper placeholder={label} />
  );
};

const FormTextFieldWrapper = styled.textarea`
  width: 100%;
  max-width: 332px;
  height: 150px;
  padding: 18px 18px;
  font-size: 14px;
  border-radius: 15px;
  border: 1px solid #E4E4E6;
  resize: none;
  ::placeholder {
    color: #868688;
    font-size: 13px;
  }
  outline: none;
`;