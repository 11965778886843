import React from "react";
import styled from "styled-components";
import { ReactComponent as PhoneIcon } from "../../assets/AdoptImages/phone_android.svg";

const AdoptDetailCare = ({ adoptData }: any) => {
    return (
        <>
            <CareDataContainer>
                <CareDataWrapper column={adoptData?.care_name.length > 11}>
                    <CareList isCareTel={false}>
                        <CareKey>보호소</CareKey>
                        <CareValue>{adoptData?.care_name}</CareValue>
                    </CareList>
                    <CareList isCareTel={true}>
                        <CareKey>보호소 연락처</CareKey>
                        <CareValue>
                            <PhoneIcon className="phoneIcon" />
                            <a href={`tel:${adoptData?.care_phone}`}>
                                {adoptData?.care_phone}
                            </a>
                        </CareValue>
                    </CareList>
                </CareDataWrapper>
                <CareList isCareTel={false}>
                    <CareKey>보호주소</CareKey>
                    <CareValue>{adoptData?.care_address}</CareValue>
                </CareList>
            </CareDataContainer>
        </>
    );
};

export default AdoptDetailCare;

const CareDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.2rem 0 0;

    font-size: 0.95rem;
    line-height: 1.3rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
        gap: 0.7rem;
        padding: 1.8rem 0;
        margin: 0 1.5rem 1rem;
        font-size: 1.4rem;
        line-height: 1.9rem;
    }
`;

const CareDataWrapper = styled.div<{ column?: boolean }>`
    display: flex;
    gap: 2rem;

    ${({ column }) =>
        column &&
        `flex-direction: column;
            gap: 0.6rem;
        `};

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 0.8rem;
    }
`;

const CareList = styled.div<{ isCareTel?: boolean }>`
    display: flex;
    gap: ${({ isCareTel }) => (isCareTel ? `0.8rem` : `1rem`)};
`;

const CareKey = styled.div`
    color: #868688;
    word-break: keep-all;
`;

const CareValue = styled.div`
    display: flex;
    gap: 5px;

    color: black;
    word-break: keep-all;

    .phoneIcon {
        width: 1.1rem;
        height: 1.1rem;
    }

    a {
        color: black;
        text-decoration: none;

        :hover {
            color: #fcd11e;
        }
    }

    @media only screen and (max-width: 768px) {
        .phoneIcon {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
`;
