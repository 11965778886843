import React, { useState } from "react";
import styled from "styled-components";
import CompareSelect from "./CompareSelect";
import BenefitCheck from "./BenefitCheck";

const RadioBox = () => {
    const [radioSelect, setRadioSelect] = useState<string>("BenefitCheck");

    return (
        <div>
            <RadioButtonWrapper>
                <RadioButton
                    selected={radioSelect === "BenefitCheck"}
                    onClick={() => setRadioSelect("BenefitCheck")}
                >
                    내가 원하는 펫보험 찾기
                </RadioButton>
                <RadioButton
                    selected={radioSelect === "Compare"}
                    onClick={() => setRadioSelect("Compare")}
                >
                    펫보험 1:1 비교
                </RadioButton>
            </RadioButtonWrapper>
            <RadioBoxBody>
                {radioSelect === "BenefitCheck" ? (
                    <BenefitCheck />
                ) : (
                    <CompareSelect />
                )}
            </RadioBoxBody>
        </div>
    );
};

export default RadioBox;

const RadioButtonWrapper = styled.div`
    display: flex;
    height: 2.44rem;
`;

export const RadioButton = styled.div<{ selected?: boolean }>`
    padding: 0.63rem 1.56rem;
    margin-right: 1rem;
    font-size: 1rem;
    line-height: normal;
    font-weight: 600;
    border: 1px solid #fcd11e;
    border-bottom: none;
    border-radius: 10px 10px 0px 0px;
    background-color: #ffeb99;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

    ${({ selected }) => selected && `background-color: #fcd11e;`};
`;

export const RadioBoxBody = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 2.5rem);
    background-color: white;
    border: 1px solid #fcd11e;
    border-radius: 0px 10px 10px 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    z-index: 1;
`;
