import React from "react";
import styled from "styled-components";
import { ReactComponent as FilterCheckIcon } from "../../assets/AdoptImages/filter_check.svg";
import { useSetRecoilState } from "recoil";
import { adoptCurrentPageState } from "states";

interface FilterSingleSelectProps {
    activeData: string;
    setActiveData: React.Dispatch<React.SetStateAction<string>>;
    listData: string[];
}

const FilterSingleSelect = ({
    activeData,
    setActiveData,
    listData,
}: FilterSingleSelectProps) => {
    const setAdoptCurrentPage = useSetRecoilState(adoptCurrentPageState);

    const handleSelect = (data: string) => {
        setActiveData(data);
        setAdoptCurrentPage(1);
    };
    return (
        <FilterSingleSelectContainer>
            <SingleSelectList>
                {listData &&
                    listData.map((data: string) => (
                        <SingleSelect
                            key={data}
                            active={activeData === data}
                            onClick={() => handleSelect(data)}
                        >
                            {data}
                            {activeData === data && (
                                <FilterCheckIcon className="checked" />
                            )}
                        </SingleSelect>
                    ))}
            </SingleSelectList>
        </FilterSingleSelectContainer>
    );
};

export default FilterSingleSelect;

const FilterSingleSelectContainer = styled.div``;

const SingleSelectList = styled.div`
    width: 6rem;
    border-radius: 5px;
    border: 1px solid #e4e4e6;
    background-color: white;
`;

const SingleSelect = styled.div<{ active?: boolean }>`
    padding: 0.75rem 0;

    border-bottom: 1px solid #e4e4e6;

    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 400;
    text-align: center;
    cursor: pointer;

    :last-child {
        border: none;
    }

    .checked {
        margin-left: 4px;
    }

    ${({ active }) => active && `color: #6D2CB6`};
`;
