import { AnimalKind } from "libs/models";

const dateRegExp = new RegExp(
  /^((19|20)\d\d)\.(0[1-9]|1[012])\.(0[1-9]|[12][0-9]|3[01])$/,
);

export enum DogSize {
  Small = "소형",
  Medium = "중형",
  Large = "대형",
}

export interface AgeCalculatorData {
  animal?: AnimalKind;
  dogSize?: DogSize;
  date: string;
}

// models
export default class AgeCalculator implements AgeCalculatorData {
  constructor(defaultValues?: AgeCalculatorData) {
    if (!!defaultValues) {
      this.update(defaultValues);
    }
  }

  private fields: { [key: string]: any } = {
    animal: null,
    dogSize: null,
    date: null,
  };

  public errors: { [key: string]: any } = {
    animal: null,
    dogSize: null,
    date: null,
  };

  get animal(): AnimalKind | undefined {
    return this.fields.animal;
  }

  get isDog(): boolean {
    return !!this.fields.animal && this.fields.animal === AnimalKind.Dog;
  }

  get isCat(): boolean {
    return !!this.fields.animal && this.fields.animal === AnimalKind.Cat;
  }

  get dogSize(): DogSize | undefined {
    return this.fields.animal === AnimalKind.Dog
      ? this.fields.dogSize
      : undefined;
  }

  get date(): string {
    return this.fields.date;
  }

  get totalDays(): number {
    const value = this.date.replace(/\./g, "-");
    const bornDate = new Date(value);
    const today = new Date();
    const times = today.getTime() - bornDate.getTime();
    const days = Math.floor(times / (1000 * 60 * 60 * 24));
    return days;
  }

  get humanAge(): number {
    let humanDays;
    let underAgePercent;
    let overAgePercent;

    if (this.isDog) {
      switch (this.dogSize) {
        case DogSize.Small:
          underAgePercent = 12.5;
          overAgePercent = 5.29;
          break;
        case DogSize.Medium:
          underAgePercent = 10.5;
          overAgePercent = 7.1;
          break;
        case DogSize.Large:
          underAgePercent = 9;
          overAgePercent = 8.4;
          break;
      }
    } else if (this.isCat) {
      underAgePercent = 12.5;
      overAgePercent = 4;
    }

    if (!underAgePercent || !overAgePercent) {
      return 0;
    }

    if (this.totalDays <= 730) {
      humanDays = underAgePercent * this.totalDays;
    } else {
      humanDays =
        underAgePercent * 730 + (overAgePercent * this.totalDays - 730);
    }

    return Math.floor(humanDays / 365);
  }

  get searchKeyword(): string {
    if (this.isDog) {
      let babyAge;
      let adultAge;
      switch (this.dogSize) {
        case DogSize.Small:
          babyAge = 303;
          adultAge = 2919;
          break;
        case DogSize.Medium:
          babyAge = 365;
          adultAge = 2554;
          break;
        case DogSize.Large:
          babyAge = 455;
          adultAge = 2189;
          break;
      }
      if (!!babyAge && !!adultAge) {
        if (this.totalDays <= babyAge) {
          return "퍼피";
        } else if (this.totalDays > babyAge && this.totalDays <= adultAge) {
          return "강아지건강";
        } else {
          return "노견";
        }
      }
    } else if (this.isCat) {
      const babyAge = 90;
      const adultAge = 2554;
      if (this.totalDays <= babyAge) {
        return "아기고양이";
      } else if (this.totalDays > babyAge && this.totalDays <= adultAge) {
        return "고양이행동";
      } else {
        return "노묘";
      }
    }
    return "";
  }

  public async validate(): Promise<boolean> {
    this.errors = {};

    if (!this.animal) {
      this.errors["animal"] = "값을 입력하거나 선택해주셔야 합니다.";
    }

    if (this.isDog && !this.dogSize) {
      this.errors["dogSize"] = "값을 입력하거나 선택해주셔야 합니다.";
    }

    const isDateValid =
      ((!!this.date && this.date.match(dateRegExp)) || []).length > 0;
    if (!this.date) {
      this.errors["date"] = "값을 입력하거나 선택해주셔야 합니다.";
    } else if (this.date.length < 10 || !isDateValid) {
      this.errors["date"] = "날짜 입력 형식이 올바르지 않습니다.";
    }

    return this.isValid;
  }

  get isValid(): boolean {
    return Object.keys(this.errors).filter((field) => !!field).length === 0;
  }

  public update(changes: any): void {
    for (const key of Object.keys(changes)) {
      if (this.fields[key] === undefined) {
        throw new Error(`Cannot find the key in the form: ${key}`);
      }
      this.fields[key] = changes[key];
    }
  }
}
