import React, { useEffect, useState } from "react";
import Layout from "components/Layout";
import styled from "styled-components";
import { adoptOgImage, notFoundSamsek } from "assets";
import { useNavigate } from "react-router-dom";

const NamingNotFound = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <NamingNotFoundPageContainer isLoading={isLoading} style={{ display: "none" }}>
            <Layout
                header={{
                    title: "페이지를 찾을 수 없습니다",
                    description: "페이지를 찾을 수 없습니다",
                    ogImage: adoptOgImage,
                    ogUrl: "/notfound",
                }}
            >
                <NamingNotFoundContainer>
                    <NotFoundContainer>
                        <TextContainer>
                            <ErrorTitle>Error !</ErrorTitle>
                            <ErrorDescription>
                                페이지 접근 중 에러가 발생하였습니다.
                                <br />
                                다시 시도해주세요.
                            </ErrorDescription>
                        </TextContainer>
                        <ImageContainer>
                            <img src={notFoundSamsek} alt="samsek" />
                        </ImageContainer>
                        <ButtonContainer>
                            <MainButton onClick={() => navigate("/adoption")}>
                                메인 페이지로 이동하기
                            </MainButton>
                        </ButtonContainer>
                    </NotFoundContainer>
                </NamingNotFoundContainer>
            </Layout>
        </NamingNotFoundPageContainer>
    );
};

export default NamingNotFound;

const NamingNotFoundPageContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const NamingNotFoundContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 236px);
    min-height: 700px;
    background: linear-gradient(
            65.6deg,
            rgba(252, 209, 30, 0.2) 39.13%,
            rgba(228, 218, 241, 0.2) 70.85%
        ),
        #ffffff;

    @media only screen and (max-width: 768px) {
        min-height: 600px;
    }
    @media only screen and (max-width: 390px) {
        min-height: 500px;
    }
`;

const NotFoundContainer = styled.div`
    height: 700px;

    @media only screen and (max-width: 768px) {
        height: 600px;
    }
    @media only screen and (max-width: 390px) {
        height: 500px;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 23px;
    padding: 38px 0 31px;
    text-align: center;
`;

const ErrorTitle = styled.div`
    font-size: 36px;
    line-height: 43px;
    font-weight: 700;

    @media only screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 38px;
    }
    @media only screen and (max-width: 390px) {
        font-size: 26px;
        line-height: 31px;
    }
`;

const ErrorDescription = styled.div`
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;

    @media only screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
    }
    @media only screen and (max-width: 390px) {
        font-size: 16px;
        line-height: 19px;
    }
`;

const ImageContainer = styled.div`
    text-align: center;
    img {
        width: 400px;
        height: 345.86px;

        @media only screen and (max-width: 768px) {
            width: 334px;
            height: 288px;
        }
        @media only screen and (max-width: 390px) {
            width: 268px;
            height: 231px;
        }
    }
`;

const ButtonContainer = styled.div`
    padding: 27px 0 35px;
    text-align: center;
`;

const MainButton = styled.button`
    padding: 11px 22.5px;
    background-color: #fcd11e;
    border-radius: 21px;
    border: none;
    cursor: pointer;

    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    color: black;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
    }
    @media only screen and (max-width: 390px) {
        font-size: 16px;
        line-height: 19px;
    }
`;
