import { NameContext } from "pages/naming/NamingMain";
import React, { useState } from "react";

const NamingProvider = ({ children }: any) => {
    const updateList = (newString: string) => {
        setNamingData((prev: any) => {
            return { ...prev, dataList: [...prev.dataList, newString] };
        });
    };

    const [namingData, setNamingData] = useState(() => {
        const initialState = {
            dataList: [],
            updateList: (newString: string) => updateList(newString),
        };
        return initialState;
    });

    return (
        <>
            <NameContext.Provider value={namingData}>
                {children}
            </NameContext.Provider>
        </>
    );
};

export default NamingProvider;
