import React, { forwardRef } from "react";
import styled from "styled-components";
import { colors } from "theme";

export interface FormFieldData {
    key: string;
    label: string;
    value: string | number | null;
    errorMessage?: string | null;
}

interface FormLabelProps {
    color?: string;
}

export const FormLabel = styled.label<FormLabelProps>`
    color: ${({ color }) => color};
    width: 160px;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 0px;
    white-space: pre-line;

    font-family: JalnanOTF;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: -0.32px;

    @media only screen and (max-width: 768px) {
        width: 100%;
        white-space: nowrap;
    }
`;

interface FormFieldProps {
    label?: React.ReactNode;
    errorMessage?: string | null;
    children: React.ReactNode;
}

export const FormField: React.FC<FormFieldProps> = ({
    label,
    children,
    errorMessage,
}) => {
    return (
        <FormFieldWrapper>
            <InnerWrapper>
                {!!label && <FormLabel>{label}</FormLabel>}
                {children}
            </InnerWrapper>
            {!!errorMessage && (
                <FormFieldErrorMessage>{errorMessage}</FormFieldErrorMessage>
            )}
        </FormFieldWrapper>
    );
};

const FormFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;

    align-items: flex-end;
    margin-bottom: 32px;
`;

const InnerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const FormFieldErrorMessage = styled.p`
    font-size: 12px;
    margin: 8px 0 0;
    color: ${colors.red};
`;

interface FormProps extends React.HTMLProps<HTMLFormElement> {
    children?: React.ReactNode;
}

export const Form = forwardRef(
    ({ children, onSubmit, onReset, ...rest }: FormProps, ref) => (
        <FormWrapper>
            <FormContainer
                {...(rest as any)}
                ref={ref}
                onSubmit={onSubmit}
                onReset={onReset}
            >
                {children}
            </FormContainer>
        </FormWrapper>
    )
);

const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 80px 0px;
    background-color: ${colors.grey200};

    @media only screen and (max-width: 768px) {
        padding: 0;
        width: 360px;
        margin: 0 auto;
    }
`;

const FormContainer = styled.form`
    padding: 60px 50px;
    width: 620px;
    background-color: ${colors.grey100};
    border-radius: 14px;
    box-sizing: border-box;
    box-shadow: 0 12px 25px -20px ${colors.opacityShadow};

    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0 20px 60px;
        max-width: 360px;
        box-shadow: none;
    }
`;

export const FormButtonSection = styled.div`
    margin: 60px 70px 0;

    @media only screen and (max-width: 768px) {
        margin: 60px 0px 0;
    }
`;
