import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as MaleIcon } from "../../assets/AdoptImages/male_icon.svg";
import { ReactComponent as FemaleIcon } from "../../assets/AdoptImages/female_icon.svg";
import { ReactComponent as GenderUnknownIcon } from "../../assets/AdoptImages/gender_unknown_icon.svg";
import {
    adoptSamsek,
    adoptLili,
    heartIcon,
    heartOutlineIcon,
    imageNotFound,
} from "assets";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userTokenState } from "states";
import axios from "axios";
import ConvertToHttps from "adoptComponents/utils/ConvertToHttps";

interface AdoptListCardProps {
    adoptData: any;
    isFavoriteCard: boolean;
    setShowCard: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

const AdoptListCard = ({
    adoptData,
    isFavoriteCard,
    setShowCard,
}: AdoptListCardProps) => {
    const [isFavorite, setIsFavorite] = useState<boolean>(
        adoptData.is_favorite
    );

    const userToken = useRecoilValue(userTokenState);

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const handleToggleFavorite = (isFavorite: boolean) => {
        axios
            .put(
                `${process.env.REACT_APP_API_URL_DEV}pet-adoption/favorite/${adoptData.desertion_no}/`,
                { is_favorite: !isFavorite },
                {
                    headers: {
                        Authorization: userToken ? `Bearer ${userToken}` : ``,
                    },
                }
            )
            .then(res => {
                // console.log(res.data);
                setIsFavorite(prev => !prev);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleImageError = (e: any) => {
        e.target.src = imageNotFound;
    };

    const getTypeName = (pet_type: string, name: string) => {
        if (pet_type === "DOG") return "[강아지] " + name;
        else return "[고양이] " + name;
    };

    const getAge = (birthString: string) => {
        const now = new Date();
        const age = now.getFullYear() - Number(birthString);
        if (age === 0) return "1살 미만";
        else return age + "살";
    };

    const getProcessState = (processState: string) => {
        const stateStr = processState?.substring(0, 2);

        if (stateStr !== "종료") return true;
        else return false;
    };

    const getNeuterState = (neuterState: string) => {
        if (neuterState === "Y") return "완료";
        else if (neuterState === "N") return "미완료";
        else return "알 수 없음";
    };

    const getGender = (gender: string) => {
        if (gender === "F")
            return (
                <>
                    <FemaleIcon className="genderIcon" />
                    여아
                </>
            );
        else if (gender === "M")
            return (
                <>
                    <MaleIcon className="genderIcon" />
                    남아
                </>
            );
        else
            return (
                <>
                    <GenderUnknownIcon className="genderIcon" />
                    미상
                </>
            );
    };

    const navigate = useNavigate();

    const handleClickCard = (adoptId: number) => {
        navigate(`/adoption/adopt/detail?id=${adoptId}`);
    };

    useEffect(() => {
        if (isFavoriteCard && setShowCard) setShowCard(isFavorite);
        // eslint-disable-next-line
    }, [isFavorite]);

    return (
        <>
            <AdoptListCardContainer>
                <AdoptListCardBody>
                    <AdoptImage>
                        <img
                            src={
                                adoptData?.profile_url
                                    ? ConvertToHttps(adoptData?.profile_url)
                                    : imageNotFound
                            }
                            alt={adoptData?.adopt_breed_text}
                            onClick={() =>
                                handleClickCard(adoptData?.desertion_no)
                            }
                            onError={handleImageError}
                        />
                    </AdoptImage>
                    <AdoptInfoContainer>
                        <AdoptProcessState
                            active={getProcessState(adoptData?.adopt_status)}
                        >
                            {adoptData?.adopt_status}
                        </AdoptProcessState>
                        <AdoptInfo1
                            onClick={() =>
                                handleClickCard(adoptData?.desertion_no)
                            }
                        >
                            {getTypeName(
                                adoptData?.pet_type,
                                adoptData?.adopt_breed_text
                            )}
                            {!isMobile && (
                                <>
                                    <span>{` | `}</span>
                                    {getAge(adoptData?.birth_year)}
                                </>
                            )}
                        </AdoptInfo1>
                        <AdoptInfo2>
                            {getGender(adoptData?.gender)} · 중성화{" "}
                            {getNeuterState(adoptData?.neutered)}
                            {isMobile && (
                                <> · {getAge(adoptData?.birth_year)}</>
                            )}
                        </AdoptInfo2>
                        {userToken === "" || userToken === null ? (
                            <a
                                aria-label="통합 로그인"
                                href={`${process.env.REACT_APP_MAIN_URL_DEV}login?returnURL=${process.env.REACT_APP_DOMAIN_URL_DEV}adoption/adopt/detail?id=${adoptData?.desertion_no}`}
                            >
                                <AdoptHeartIcon
                                    src={heartOutlineIcon}
                                    alt="관심동물 저장"
                                />
                            </a>
                        ) : (
                            <AdoptHeartIcon
                                src={isFavorite ? heartIcon : heartOutlineIcon}
                                onClick={() => {
                                    handleToggleFavorite(isFavorite);
                                }}
                                alt="관심동물 저장"
                            />
                        )}
                    </AdoptInfoContainer>
                </AdoptListCardBody>
                <AdoptOrgInfo>지역 : {adoptData?.org_name}</AdoptOrgInfo>
                <AdoptIconImage
                    src={
                        adoptData.pet_type && adoptData.pet_type === "DOG"
                            ? adoptLili
                            : adoptSamsek
                    }
                    alt={
                        adoptData.pet_type && adoptData.pet_type === "DOG"
                            ? "강아지"
                            : "고양이"
                    }
                />
            </AdoptListCardContainer>
        </>
    );
};

export default AdoptListCard;

const AdoptListCardContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);

    transition: all 0.3s ease-in-out;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

    :hover {
        transform: translate(0, -5px);
        box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);
    }

    @media only screen and (max-width: 768px) {
        width: calc((100vw - 6rem) / 2);
    }
`;

const AdoptListCardBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.1rem 1.1rem 0.9rem;

    @media only screen and (max-width: 768px) {
        padding: 0.7rem 0.62rem;
    }
`;

const AdoptImage = styled.div`
    width: 16.5rem;
    height: 12.4rem;
    border-radius: 12px;
    cursor: pointer;
    background-image: url("data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFklEQVR42mN8//HLfwYiAOOoQvoqBABbWyZJf74GZgAAAABJRU5ErkJggg==");

    img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: cover;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        height: 12rem;
    }
`;

const AdoptInfoContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

const AdoptProcessState = styled.div<{ active?: boolean }>`
    width: fit-content;
    padding: 0.2rem 1rem;
    margin: 0.65rem 0;

    font-size: 0.68rem;
    line-height: 0.8rem;
    font-weight: 600;

    background-color: ${({ active }) => (active ? `#ffeb99` : `#F6F6F9`)};
    border-radius: 21px;

    @media only screen and (max-width: 768px) {
        margin: 0.5rem 0;
        font-size: 0.86rem;
        line-height: 1rem;
    }
`;

const AdoptInfo1 = styled.div`
    margin-bottom: 0.3rem;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 600;
    letter-spacing: -0.3px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    cursor: pointer;

    span {
        color: #cfcece;
    }

    :hover {
        color: #fcd11e;
    }

    @media only screen and (max-width: 768px) {
        font-size: 0.86rem;
        line-height: 1.2rem;
    }
`;

const AdoptInfo2 = styled.div`
    display: flex;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 600;
    letter-spacing: -0.15px;

    .genderIcon {
        width: 0.9rem;
        height: 0.8rem;
        margin-right: 3px;
    }

    @media only screen and (max-width: 768px) {
        font-size: 0.86rem;
        line-height: 1rem;

        .genderIcon {
            width: 1rem;
            height: 0.95rem;
        }
    }
`;

const AdoptHeartIcon = styled.img`
    position: absolute;
    top: 0.57rem;
    right: 3px;
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    cursor: pointer;
`;

const AdoptOrgInfo = styled.div`
    padding: 0.8rem 1rem;

    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 600;
    color: #868688;

    border-top: 1px solid #e4e4e6;

    @media only screen and (max-width: 768px) {
        font-size: 0.86rem;
        line-height: 1rem;
    }
`;

const AdoptIconImage = styled.img`
    position: absolute;
    right: 5px;
    bottom: 0;
    width: 4.3rem;
    height: 4.3rem;
    object-fit: contain;

    @media only screen and (max-width: 768px) {
        width: 3.8rem;
        height: 3.8rem;
    }
`;
