import { cryingLili } from "assets";
import React from "react";
import styled from "styled-components";

const RegistVerifyNoResult = () => {
    return (
        <>
            <RegistVerifyResultContainer>
                <NoResultImage src={cryingLili} alt="no result" />
                <NoResultText>
                    해당번호로 등록된 반려동물이 없습니다.
                </NoResultText>
            </RegistVerifyResultContainer>
        </>
    );
};

export default RegistVerifyNoResult;

const RegistVerifyResultContainer = styled.div`
    margin: 30px 60px 90px;
    text-align: center;

    @media screen and (max-width: 768px) {
        margin: 30px 30px 90px;
    }
`;

const NoResultImage = styled.img`
    width: 152px;
    height: 125px;
    margin-left: 5px;

    @media screen and (max-width: 600px) {
        width: 122px;
        height: 100px;
    }
`;

const NoResultText = styled.div`
    padding: 15px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
`;
