import { toolsOgImage } from "assets"
import { FormAgreementCheckBox } from "components/FormAgreementCheckbox"
import { FormAgreementDetail } from "components/FormAgreementDetail"
import { FormCancelButton } from "components/FormCancelButton"
import { FormCategory } from "components/FormCategory"
import { FormDisabled } from "components/FormDisabled"
import { FormImageSubLabel } from "components/FormImageSubLabel"
import { FormInput } from "components/FormInput"
import { FormMainLabel } from "components/FormMainLabel"
import { FormOkButton } from "components/FormOkButton"
import { FormRadioButton } from "components/FormRadio"
import { FormSubLabel } from "components/FormSubLabel"
import { FormTextField } from "components/FormTextField"
import { FormWarningLabel } from "components/FormWarningLabel"
import Layout from "components/Layout"
import { RadioGroup } from "components/Radio"
import React, { Fragment, useEffect, useState } from "react"
// import { useMediaQuery } from "react-responsive"
import styled from "styled-components"

const FosterMain: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [value, setValue] = useState("동의");
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  // const isMobile = useMediaQuery({
  //   query: "(max-width:448px)",
  // });

  useEffect(() => {
    window.onload = () => {
        setIsLoading(false);
    };

    const handleLoad = () => {
        setIsLoading(true);
    };

    if (document.readyState === "complete") {
        // 이미 로드된 상태일 경우
        setIsLoading(false);
    } else {
        // 로드되기 전인 경우
        document.addEventListener("load", handleLoad);
    }

    return () => {
        // 컴포넌트가 unmount 될 때 이벤트 제거
        document.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <Fragment>
      <MainContainer isLoading={isLoading} style={{ display: "none" }}>
        <Layout 
          header={{
              title: "비마이펫 :: 임시보호 목록 페이지",
              description: "비마이펫 :: 임시보호 목록 페이지",
              ogImage: toolsOgImage,
              ogUrl: "/foster",
          }}
        >
          <Fragment>
              <FormMainLabel label="게시글 제목" />

              <FormCategory label="위탁자 정보"/>

              <FormSubLabel label="게시글 목록에 나타날 제목을 적어주세요 ( 50자 이내 )" />

              <FormInput />

              <FormDisabled label="서울특별시 강남구" />

              <FormTextField label="접종 여부 및 중성화 수술 관련 설명을 적어주세요" />

              <RadioGroup>
                <FormRadioButton label="동의" onChange={setValue}  checked={value === "동의"} />
                <FormRadioButton label="비동의" onChange={setValue} checked={value === "비동의"} />
              </RadioGroup>

              <FormWarningLabel label="비동의 시 위탁 신청서를 제출 할 수 없습니다." />

              <FormImageSubLabel label="이미지는 최대 5개 첨부 가능 합니다., 20MB 이하, jpg, png, gif, jpeg 형식을 지원합니다" />

              <FormAgreementCheckBox label="비마이펫  임보 중개 서비스 상세 안내" checked={isChecked1} onChange={setIsChecked1} />
              <FormAgreementCheckBox label="개인정보 수집 동의" checked={isChecked2} onChange={setIsChecked2} />

              <FormAgreementDetail label="자세히보기" />

              <FormCancelButton label="임시저장" />
              <FormOkButton label="제출하기" />
          </Fragment>
        </Layout>
      </MainContainer>
    </Fragment>
  );
};

export default FosterMain

const MainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;