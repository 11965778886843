import React from "react";
import styled from "styled-components";

export const FormInput = () => {
  return (
    <FormInputWrapper />
  );
};

const FormInputWrapper = styled.input`
  width: 100%;
  max-width: 846px;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 25px;
  border: 1px solid #E4E4E6;
  padding-left: 14px;
  outline: none;
`;