import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "components/Layout";
import NotFound from "./NotFound";
import { ogImage } from "assets";

const NotFoundPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <>
            <NotFoundMainContainer
                isLoading={isLoading}
                style={{ display: "none" }}
            >
                <Layout
                    header={{
                        title: "페이지를 찾을 수 없습니다",
                        description: "페이지를 찾을 수 없습니다",
                        ogImage: ogImage,
                        ogUrl: "/notfound",
                    }}
                >
                    <NotFound />
                </Layout>
            </NotFoundMainContainer>
        </>
    );
};

export default NotFoundPage;

const NotFoundMainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;
