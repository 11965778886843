import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "theme";
import { Drawer, DrawerListItem, DrawerListItemProps } from "components/Drawer";
import { headerLogo } from "assets";
import { SelectedCircle } from "components/Tab";
import StaticUrl from "libs/urls";
import { ReactComponent as MenuRoundedIcon } from "../assets/DrawerImages/menu.svg";
import ShoppingPopper from "./ShoppingPopper";
import { ShoppingPopperContainer } from "./ShoppingPopper";
import { useMediaQuery } from "react-responsive";
import HeaderDepth1 from "./HeaderDepth1";
import { Helmet } from "react-helmet-async";
import { drawerMenuList, mainHeaderList } from "./HeaderList";
import WriteButton from "./WriteButton";
import { LoginContext } from "Router";
import { useLocation } from "react-router-dom";

const { REACT_APP_FACEBOOK_ID } = process.env;

interface MainHeaderProps {
    visible: boolean;
    scrollPosition: number;
}

interface HeaderProps {
    header?: {
        title: string;
        description: string;
        ogUrl: string;
        ogImage: string;
    };
}

const Header = ({ header }: HeaderProps) => {
    let title = !!header ? header.title : "비마이펫 툴즈";
    if (title.indexOf("비마이펫 툴즈") === -1) {
        title = `${title} :: 비마이펫 툴즈`;
    }

    const pathName = useLocation().pathname;

    const { isLoggedIn } = useContext(LoginContext);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(true);
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    const handleOnDrawerCloseClick = () => setOpenDrawer(false);
    const handleOnDrawerOpenClick = () => setOpenDrawer(true);

    const updateScroll = () => {
        const moving = window.pageYOffset;
        setVisible(scrollPosition > moving);
        setScrollPosition(moving);
        setScrollPosition(window.scrollY);
    };

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    useEffect(() => {
        window.addEventListener("scroll", updateScroll);
        return () => {
            window.removeEventListener("scroll", updateScroll);
        };
        // eslint-disable-next-line
    }, [scrollPosition]);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <>
            {!isLoading && (
                <header id="headerSection_1">
                    {!!header && (
                        <Helmet>
                            <meta charSet="utf-8" />
                            <meta name="description" content={header.description} data-rh="true" />
                            <meta property="og:title" content={title} />
                            <meta property="og:description" content={header.description} />
                            <meta property="og:type" content="website" />
                            <meta
                                property="og:image"
                                content={`${process.env.REACT_APP_URL_DEV}${header.ogImage}`}
                            />
                            <meta property="og:image:width" content={"1200"} />
                            <meta property="og:image:height" content={"630"} />
                            <meta
                                property="og:url"
                                content={`${process.env.REACT_APP_URL_DEV}${header.ogUrl}`}
                            />
                            {!!REACT_APP_FACEBOOK_ID && (
                                <meta property="fb:app_id" content={REACT_APP_FACEBOOK_ID} />
                            )}
                            <title>{title}</title>
                        </Helmet>
                    )}
                    <HeaderContainer visible={visible} scrollPosition={scrollPosition}>
                        <HeaderWrapper>
                            <LogoWrapper>
                                <a href={StaticUrl.Home}>
                                    <img src={headerLogo} alt="logo" />
                                </a>
                            </LogoWrapper>
                            {isMobile ? (
                                <HeaderMenuIcon onClick={handleOnDrawerOpenClick}>
                                    <MenuRoundedIcon />
                                </HeaderMenuIcon>
                            ) : (
                                <PageTabWrapper>
                                    {mainHeaderList.map(list => (
                                        <PageTab key={list.title}>
                                            {list.title === "툴즈" ? (
                                                <>
                                                    {list.title}
                                                    <SelectedCircle />
                                                </>
                                            ) : (
                                                <>
                                                    <a href={list.url}>{list.title}</a>
                                                    {list.title === "쇼핑" ? (
                                                        <ShoppingPopper />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}
                                        </PageTab>
                                    ))}
                                    <PageTab>
                                        <a
                                            href={
                                                isLoggedIn
                                                    ? `${process.env.REACT_APP_MAIN_URL_DEV}mypage`
                                                    : `${
                                                          process.env.REACT_APP_MAIN_URL_DEV
                                                      }login?returnURL=${
                                                          process.env.REACT_APP_DOMAIN_URL_DEV
                                                      }${pathName.substring(1)}`
                                            }
                                        >
                                            {isLoggedIn ? "마이페이지" : "로그인"}
                                        </a>
                                    </PageTab>
                                    <PageTab>
                                        <WriteButton />
                                    </PageTab>
                                    <PageTab>
                                        <HeaderMenuIcon onClick={handleOnDrawerOpenClick}>
                                            <MenuRoundedIcon />
                                        </HeaderMenuIcon>
                                    </PageTab>
                                </PageTabWrapper>
                            )}
                        </HeaderWrapper>
                        {isMobile && <HeaderDepth1></HeaderDepth1>}
                    </HeaderContainer>

                    <Drawer onClose={handleOnDrawerCloseClick} isOpen={openDrawer}>
                        <DrawerWrapper>
                            {drawerMenuList.map((item: DrawerListItemProps, index) => (
                                <DrawerListItem
                                    key={index}
                                    title={item.title}
                                    url={item.url}
                                    isDirect={item.isDirect}
                                    subMenu={item.subMenu}
                                />
                            ))}
                        </DrawerWrapper>
                    </Drawer>
                </header>
            )}
        </>
    );
};

export default Header;

const HeaderWrapper = styled.div`
    max-width: 976px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: 0px 24px;
    margin: 0 auto;
    height: 80px;

    @media only screen and (max-width: 768px) {
        height: 60px;
        padding: 0 16px;
        margin: 0;
    }
`;

const HeaderContainer = styled.div<MainHeaderProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80px;
    background-color: white;
    z-index: 100;
    ${({ scrollPosition }) => (scrollPosition > 60 ? `box-shadow: 0 1px 6px 0 #d3d3d3;` : ``)}

    @media only screen and (max-width: 768px) {
        height: 60px;
        transition: 0.3s ease;
        border-bottom: 1px solid #e4e4e6;
        ${({ visible, scrollPosition }) =>
            !visible && scrollPosition > 80
                ? `top: -110px; transition: .3s ease; transform: translateY(0);`
                : ``}
    }
`;

const LogoWrapper = styled.div`
    width: 100px;
    height: 70.8px;
    img {
        width: 100%;
        height: 100%;
    }

    @media only screen and (max-width: 768px) {
        width: 85px;
        height: 60px;
        img {
            width: 100%;
            height: 100%;
        }
    }
`;

const DrawerWrapper = styled.div`
    margin-bottom: 63px;
`;

const HeaderMenuIcon = styled.div`
    padding: 4px;
    color: #868688;
    cursor: pointer;
`;

const PageTab = styled.li<{ selected?: boolean }>`
    position: relative;
    z-index: 1;
    list-style: none;
    margin-left: 50px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    text-align: right;
    cursor: pointer;

    :first-child {
        margin-left: 0;
    }

    a {
        text-decoration: none;
        color: ${colors.grey700};
    }

    :hover ${ShoppingPopperContainer} {
        display: block;
    }

    @media only screen and (max-width: 1024px) {
        margin: 21px 0 16px 50px;
    }
    @media only screen and (max-width: 970px) {
        margin: 21px 0 16px 40px;
    }
    @media only screen and (max-width: 890px) {
        margin-left: 22px;
    }
`;

const PageTabWrapper = styled.ul`
    display: flex;
    justify-content: flex-start;
    text-align: center;
    background-color: white;
    align-items: center;
    height: 80px;
    margin: 0;
    padding: 0;
`;
