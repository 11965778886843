import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CheckBoxIcon } from "../../assets/AdoptImages/check_box_s.svg";
import { ReactComponent as CheckBoxOutlineIcon } from "../../assets/AdoptImages/check_box_outline_s.svg";
import { useRecoilState, useSetRecoilState } from "recoil";
import { adoptBreedState, adoptCurrentPageState } from "states";

interface BreedMultiSelectProps {
    // petType?: string | undefined;
    breedData: any;
    // breedArr: Set<any>;
    // setBreedArr: React.Dispatch<React.SetStateAction<Set<any>>>;
}

const InputBreedMultiSelect = ({
    breedData,
}: // breedArr,
// setBreedArr,
BreedMultiSelectProps) => {
    const [selected, setSelected] = useState(false);
    const [adoptBreed, setAdoptBreed] = useRecoilState(adoptBreedState);
    const setAdoptCurrentPage = useSetRecoilState(adoptCurrentPageState);

    const handleBenefitArr = (breedName: string, selected: boolean) => {
        if (selected) {
            setAdoptBreed((prevArr: any) => new Set([...prevArr, breedName]));
        } else if (!selected && adoptBreed.has(breedName)) {
            setAdoptBreed((prevArr: Set<string>) => {
                const newArr = new Set(prevArr);
                newArr.delete(breedName);
                return newArr;
            });
        }
    };

    const handleClickCheckBox = (breedName: string) => {
        const newSelected = !selected;
        setSelected(newSelected);
        handleBenefitArr(breedName, newSelected);
        setAdoptCurrentPage(1);
    };

    useEffect(() => {
        if (adoptBreed.size === 0 && selected) {
            setSelected(false);
        }
        if (adoptBreed.size !== 0 && adoptBreed.has(breedData.name)) {
            setSelected(true);
        }
        // eslint-disable-next-line
    }, [adoptBreed]);

    return (
        <MultiSelectContainer
            selected={selected}
            onClick={() => handleClickCheckBox(breedData.name)}
        >
            {breedData.name}
            {selected ? (
                <CheckBoxIcon className="checkedIcon" />
            ) : (
                <CheckBoxOutlineIcon className="uncheckedIcon" />
            )}
        </MultiSelectContainer>
    );
};

export default InputBreedMultiSelect;

const MultiSelectContainer = styled.div<{
    selected?: boolean;
    selectError?: boolean;
    selectErrMsg?: boolean;
}>`
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;
    color: #000000;

    border-bottom: 1px solid #e4e4e6;
    cursor: pointer;

    :last-child {
        border: none;
    }

    :hover {
        background-color: #fff5ce;
    }

    ${({ selected }) => selected && `background-color: #fff5ce;`};

    .checkedIcon,
    .uncheckedIcon {
        width: 1rem;
        height: 1rem;
    }

    @media only screen and (max-width: 768px) {
        padding: 1rem;
        font-size: 1.1rem;
        line-height: 1.2rem;

        .checkedIcon,
        .uncheckedIcon {
            width: 1.2rem;
            height: 1.2rem;
        }
    }
`;
