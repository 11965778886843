export const localStorageEffect =
    (key: any) =>
    // setSelf 는 로컬 스토리지에서 값을 불러와 초기값을 지정해준다.
    // onSet은 해당 atom 값이 변경될 때 마다 로컬스토리지와 atom 값을 동기화 해준다.
    ({ setSelf, onSet }: any) => {
        if (typeof window !== "undefined") {
            const savedValue = localStorage.getItem(key);

            if (savedValue != null) {
                setSelf(savedValue);
            }

            onSet((newValue: any) => {
                localStorage.setItem(key, newValue);
            });
        }
    };
