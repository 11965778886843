import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { samoMain, socialMain } from "assets";
import Layout from "components/Layout";
import CoverSection from "components/CoverSection";
import { Heading2, InlineColor } from "components/Typography";
import { colors } from "theme";

import MainCard from "./MainCard";

interface CardType {
    title: React.ReactNode;
    to: string;
    color: string;
    backgroundColor: string;
}

const cards: CardType[] = [
    {
        title: (
            <Heading2>
                <InlineColor color={colors.olive100}>사료칼로리</InlineColor>
                <br />
                계산기
            </Heading2>
        ),
        to: "/calculator/food-calorie",
        backgroundColor: colors.olive200,
        color: colors.olive100,
    },
    {
        title: (
            <Heading2>
                <InlineColor color={colors.purple100}>권장칼로리</InlineColor>
                <br />
                계산기
            </Heading2>
        ),
        to: "/calculator/recommend-calorie",
        backgroundColor: colors.purple200,
        color: colors.purple100,
    },
    {
        title: (
            <Heading2>
                <InlineColor color={colors.blue100}>비만도</InlineColor>
                <br />
                계산기
            </Heading2>
        ),
        to: "/calculator/bmi",
        color: colors.blue100,
        backgroundColor: colors.blue200,
    },
    {
        title: (
            <Heading2>
                <InlineColor color={colors.orange100}>나이</InlineColor>
                <br />
                계산기
            </Heading2>
        ),
        to: "/calculator/age",
        color: colors.orange100,
        backgroundColor: colors.orange200,
    },
];

export default function MainPage() {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <CalculatorMainContainer
            isLoading={isLoading}
            style={{ display: "none" }}
        >
            <Layout
                header={{
                    title: "반려동물 건강 계산기",
                    description:
                        "비마이펫 툴즈 - 강아지, 고양이를 위한 권장 칼로리 계산기, 사료 열량 계산기, 비만도 계산기, 나이 계산기 모음. 비마이펫 툴즈 건강 계산기를 사용하며 다양한 반려동물 지식정보를 쌓아보세요.",
                    ogImage: socialMain,
                    ogUrl: "/calculator",
                }}
            >
                <CoverSection
                    image={samoMain}
                    title={<>건강 계산기</>}
                    description={"우리 아이 건강을 위한 계산기 모음"}
                />
                <MainCardContainer>
                    <InnerWrapper>
                        {cards.map((card: CardType, i) => (
                            <MainCard
                                key={i}
                                title={card.title}
                                to={card.to}
                                color={card.color}
                                backgroundColor={card.backgroundColor}
                            />
                        ))}
                    </InnerWrapper>
                </MainCardContainer>
            </Layout>
        </CalculatorMainContainer>
    );
}

const CalculatorMainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const MainCardContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 80px 0 110px;

    @media only screen and (max-width: 768px) {
        padding: 0px 20px 90px;
    }
`;

const InnerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 961px) {
        max-width: 500px;
        flex-wrap: wrap;
    }

    @media only screen and (max-width: 768px) {
        max-width: none;
        flex-direction: column;
    }
`;
