import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface CitySingleSelectProps {
    regionData: any;
    currRegion: any;
    setRegion: React.Dispatch<React.SetStateAction<any>>;
    setDropDown: React.Dispatch<React.SetStateAction<boolean>>;
    setRegionGu: React.Dispatch<React.SetStateAction<any>>;
}

const InputCitySingleSelect = ({
    regionData,
    currRegion,
    setRegion,
    setDropDown,
    setRegionGu,
}: CitySingleSelectProps) => {
    const [selected, setSelected] = useState(false);

    const handleClickList = () => {
        setRegionGu({
            uprCd: "0000000",
            orgCd: "0000000",
            orgdownNm: "전체",
        });
        setRegion(regionData);
        setDropDown(false);
    };

    useEffect(() => {
        if (currRegion?.orgdownNm === "전체" && selected) {
            setSelected(false);
        }
        if (
            currRegion?.orgdownNm !== "전체" &&
            currRegion?.orgdownNm === regionData.orgdownNm
        ) {
            setSelected(true);
        }
        // eslint-disable-next-line
    }, [currRegion]);

    return (
        <SingleSelectContainer selected={selected} onClick={handleClickList}>
            {regionData.orgdownNm}
        </SingleSelectContainer>
    );
};

export default InputCitySingleSelect;

const SingleSelectContainer = styled.div<{
    selected?: boolean;
    selectError?: boolean;
    selectErrMsg?: boolean;
}>`
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;
    color: #000000;

    border-bottom: 1px solid #e4e4e6;
    cursor: pointer;

    :last-child {
        border: none;
    }

    :hover {
        background-color: #fff5ce;
    }

    ${({ selected }) => selected && `background-color: #fff5ce;`};

    @media only screen and (max-width: 768px) {
        padding: 1rem;
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
`;
