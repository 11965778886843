import React from "react";
import styled from "styled-components";
import { smileLili, smileSamsek } from "assets";
import { ReactComponent as CheckIcon } from "../../assets/AdoptImages/filter_check.svg";
import { useMediaQuery } from "react-responsive";

const AdoptDetailAboutPet = ({ adoptData }: any) => {
    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const getGender = () => {
        if (adoptData?.gender === "F")
            return <div className="genderF">여아</div>;
        else if (adoptData?.gender === "M")
            return <div className="genderM">남아</div>;
        else return <div className="genderQ">미상</div>;
    };

    const getNeuterState = () => {
        if (adoptData?.neutered === "Y") return "완료";
        else if (adoptData?.neutered === "N") return "미완료";
        else return "알 수 없음";
    };

    const lineTooLong = () => {
        if (adoptData?.neutered === "U" && adoptData?.coat_color.length > 6)
            return true;
        else if (
            adoptData?.neutered !== "U" &&
            adoptData?.coat_color.length > 9
        )
            return true;
        return false;
    };

    return (
        <>
            <DetailAboutPetContainer>
                <DetailAboutPetBox>
                    <DetailLine1>
                        <CheckIcon className="checkIcon" />
                        <InfoText>
                            {getGender()}
                            <div className="text_bold">
                                {`  ·  중성화 ${getNeuterState()}`}
                            </div>
                            {!isMobile && !lineTooLong() && (
                                <>
                                    <span>{`  |  `}</span>
                                    <div className="text_bold">
                                        {adoptData?.coat_color}
                                    </div>
                                    {`의 털`}
                                    <span>{`  |  `}</span>
                                    <div className="text_bold">
                                        {adoptData?.weight.split("(")[0]}
                                    </div>
                                    {` Kg`}
                                </>
                            )}
                        </InfoText>
                    </DetailLine1>
                    {(isMobile || lineTooLong()) && (
                        <DetailLine1>
                            <CheckIcon className="checkIcon" />
                            <InfoText>
                                <div className="text_bold">
                                    {adoptData?.coat_color}
                                </div>
                                {`의 털  ·  `}
                                <div className="text_bold">
                                    {adoptData?.weight.split("(")[0]}
                                </div>
                                {` Kg`}
                            </InfoText>
                        </DetailLine1>
                    )}
                    <DetailLine2>
                        <CheckIcon className="checkIcon" />
                        <SpecialMarkText>
                            {adoptData &&
                                `특이사항은?  ${adoptData.description}`}
                        </SpecialMarkText>
                    </DetailLine2>
                </DetailAboutPetBox>
                {!isMobile && (
                    <DetailImage>
                        <img
                            src={
                                adoptData?.pet_type === "DOG"
                                    ? smileLili
                                    : smileSamsek
                            }
                            alt={adoptData?.pet_type}
                        />
                    </DetailImage>
                )}
            </DetailAboutPetContainer>
        </>
    );
};

export default AdoptDetailAboutPet;

const DetailAboutPetContainer = styled.div`
    display: flex;
    border-bottom: 1px solid #e4e4e6;
`;

const DetailAboutPetBox = styled.div`
    padding: 1rem 1rem 0.9rem;
    margin: 0.8rem 0 1rem;
    border-radius: 25px;
    background-color: #f1ecf8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

    font-size: 0.9rem;
    line-height: 1.5rem;
    font-weight: 500;

    .genderF {
        font-weight: 600;
        color: #ff8d8e;
    }

    .genderM {
        font-weight: 600;
        color: #62b4fd;
    }

    .genderQ {
        font-weight: 600;
        color: #868688;
    }

    @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        width: 100%;
        padding: 1.3rem 1.5rem;
        margin: 1.1rem 1.5rem 1.4rem;
        font-size: 1.3rem;
        line-height: 1.9rem;
    }
`;

const DetailLine1 = styled.div`
    display: flex;
    align-items: center;

    .checkIcon {
        min-width: 9px;
        min-height: 12px;
        margin-right: 4px;
    }
`;

const InfoText = styled.div`
    display: flex;
    white-space: pre;

    .text_bold {
        font-weight: 600;
    }

    span {
        color: #868688;
    }
`;

const DetailLine2 = styled.div`
    display: flex;
    margin-top: 2px;

    .checkIcon {
        min-width: 9px;
        min-height: 12px;
        margin-top: 4px;
        margin-right: 4px;

        @media only screen and (max-width: 896px) {
            margin-top: 2px;
            margin-right: 4px;
        }
        @media only screen and (max-width: 896px) {
            margin-top: 4px;
            margin-right: 4px;
        }
    }
`;

const SpecialMarkText = styled.div`
    line-height: 1.4rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-wrap: break-word;

    @media only screen and (max-width: 768px) {
        line-height: 1.8rem;
    }
`;

const DetailImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-left: 0.7rem;
    margin-right: 0.2rem;

    img {
        width: 6rem;
        height: inherit;
    }
`;
