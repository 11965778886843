import {
    heartIcon,
    heartOutlineIcon,
    imageNotFound,
    liliMini,
    samsekMini,
} from "assets";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useMediaQuery } from "react-responsive";
import AdoptZoomIn from "./AdoptZoomIn";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { userTokenState } from "states";
import { useLocation } from "react-router-dom";
import AdoptDetailStatus from "./AdoptDetailStatus";
import AdoptDetailAboutPet from "./AdoptDetailAboutPet";
import AdoptDetailCare from "./AdoptDetailCare";
import ConvertToHttps from "adoptComponents/utils/ConvertToHttps";

const AdoptDetailInfo = ({ adoptData }: any) => {
    const [imageZoomIn, setImageZoomIn] = useState<boolean>(false);
    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    const pathName = useLocation().pathname;

    const userToken = useRecoilValue(userTokenState);

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const handleToggleFavorite = (isFavorite: boolean) => {
        axios
            .put(
                `${process.env.REACT_APP_API_URL_DEV}pet-adoption/favorite/${adoptData.desertion_no}/`,
                { is_favorite: !isFavorite },
                {
                    headers: {
                        Authorization: userToken ? `Bearer ${userToken}` : ``,
                    },
                }
            )
            .then(res => {
                setIsFavorite(prev => !prev);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleImageError = (e: any) => {
        e.target.src = imageNotFound;
    };

    const getAge = () => {
        const now = new Date();
        const age = now.getFullYear() - Number(adoptData?.birth_year);
        if (age === 0) return "1세 미만";
        else return age + "세";
    };

    const noticeDataList = [
        { key: "상태", value: adoptData?.adopt_status },
        { key: "공고번호", value: adoptData?.notice_no },
        {
            key: "공고기간",
            value:
                adoptData &&
                adoptData.notice_start + " ~ " + adoptData.notice_end,
        },
        { key: "발견장소", value: adoptData?.happen_place },
    ];

    useEffect(() => {
        if (adoptData !== undefined) {
            setIsFavorite(adoptData.is_favorite);
        }
    }, [adoptData]);

    return (
        <AdoptDetailInfoContainer>
            {!isMobile && <AdoptDetailStatus adoptData={adoptData} />}
            <AdoptDetailInfoWrapper>
                {imageZoomIn && (
                    <AdoptZoomIn
                        adoptData={adoptData}
                        setImageZoomIn={setImageZoomIn}
                    />
                )}
                <AdoptDetailImage>
                    <img
                        src={
                            adoptData?.profile_url
                                ? ConvertToHttps(adoptData.profile_url)
                                : imageNotFound
                        }
                        alt={adoptData?.adopt_breed_text}
                        onClick={() => setImageZoomIn(true)}
                        onError={handleImageError}
                    />
                </AdoptDetailImage>
                <AdoptDetailInfoBox>
                    <DetailTitleContainer
                        nameTooLong={adoptData?.adopt_breed_text.length >= 8}
                    >
                        <AnimalType>
                            <AnimalTypeLogo
                                src={
                                    adoptData?.pet_type === "DOG"
                                        ? liliMini
                                        : samsekMini
                                }
                                alt={adoptData?.pet_type}
                            />
                            <AnimalTypeText>
                                {adoptData?.pet_type === "DOG"
                                    ? "강아지"
                                    : "고양이"}
                            </AnimalTypeText>
                        </AnimalType>
                        <AnimalName
                            nameTooLong={
                                adoptData?.adopt_breed_text.length >= 8
                            }
                        >
                            {adoptData && (
                                <>
                                    {adoptData.adopt_breed_text}
                                    <span> | </span>
                                    {getAge()}
                                </>
                            )}
                        </AnimalName>
                        {userToken === "" || userToken === null ? (
                            <a
                                href={`${
                                    process.env.REACT_APP_MAIN_URL_DEV
                                }login?returnURL=${
                                    process.env.REACT_APP_DOMAIN_URL_DEV
                                }${pathName.substring(1)}?id=${
                                    adoptData?.desertion_no
                                }`}
                            >
                                <AdoptHeartIcon
                                    src={heartOutlineIcon}
                                    alt="관심동물 저장"
                                />
                            </a>
                        ) : (
                            <AdoptHeartIcon
                                src={isFavorite ? heartIcon : heartOutlineIcon}
                                onClick={() => {
                                    handleToggleFavorite(isFavorite);
                                }}
                                alt="관심동물 저장"
                            />
                        )}
                    </DetailTitleContainer>
                    <AdoptDetailAboutPet adoptData={adoptData} />
                    <NoticeDataContainer>
                        {noticeDataList.map(data => (
                            <NoticeList key={data.key}>
                                <NoticeKey>{data.key}</NoticeKey>
                                <NoticeValue>{data.value}</NoticeValue>
                            </NoticeList>
                        ))}
                    </NoticeDataContainer>
                    {isMobile && <AdoptDetailStatus adoptData={adoptData} />}
                    <AdoptDetailCare adoptData={adoptData} />
                </AdoptDetailInfoBox>
            </AdoptDetailInfoWrapper>
        </AdoptDetailInfoContainer>
    );
};

export default AdoptDetailInfo;

const AdoptDetailInfoContainer = styled.div`
    position: relative;
    min-height: 36rem;
`;

const AdoptDetailInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 2.4rem 3.6rem;
    border-top: 2px solid #e4e4e6;
    border-bottom: 2px solid #e4e4e6;

    overflow: hidden;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 1.4rem;
        border: none;
        padding: 0;
    }
`;

const AdoptDetailImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 50%;

    img {
        width: 22.5rem;
        height: 22.5rem;
        object-fit: cover;
        cursor: zoom-in;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;

        ::after {
            display: block;
            content: "";
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

const AdoptDetailInfoBox = styled.div`
    width: 50%;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
`;

const DetailTitleContainer = styled.div<{ nameTooLong?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e4e4e6;

    ${({ nameTooLong }) =>
        nameTooLong &&
        `        flex-direction: column;
        align-items: initial;
        gap: 6px;
        margin-top: 0.5rem;

        `};

    @media only screen and (max-width: 768px) {
        padding: 0 1.5rem 0.4rem;
    }
`;

const AnimalType = styled.div`
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 0.6rem;
    background-color: #f6f6f9;
    border-radius: 25px;

    @media only screen and (max-width: 768px) {
        width: 6.5rem;
        padding: 8px 10px;
        margin-bottom: 0.3rem;
        height: 26px;
    }
    @media only screen and (max-width: 512px) {
        padding: 6px 10px;
    }
    @media only screen and (max-width: 446px) {
        padding: 3px 8px;
    }
`;

const AnimalTypeLogo = styled.img`
    width: 1.6rem;
    height: 1.2rem;
    margin-right: 5px;

    @media only screen and (max-width: 768px) {
        width: 2.4rem;
        height: 1.8rem;
    }
`;

const AnimalTypeText = styled.div`
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
        font-size: 1.3rem;
        line-height: 1.6rem;
    }
`;

const AnimalName = styled.div<{ nameTooLong?: boolean }>`
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 600;

    span {
        color: #cfcece;
    }

    @media only screen and (max-width: 768px) {
        font-size: 2rem;
        line-height: 2.3rem;

        ${({ nameTooLong }) =>
            nameTooLong &&
            `        margin-bottom: 0.6rem;

        `};
    }
`;

const AdoptHeartIcon = styled.img`
    position: absolute;
    top: 0.3rem;
    right: 2.2rem;
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
        top: 0.4rem;
        right: 1.5rem;
        width: 2.3rem;
        height: 2.3rem;
    }
`;

const NoticeDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.1rem 0;

    font-size: 0.95rem;
    line-height: 1.2rem;
    font-weight: 600;

    border-bottom: 1px solid #e4e4e6;

    @media only screen and (max-width: 768px) {
        gap: 0.9rem;
        padding: 1.8rem 1.5rem;
        font-size: 1.4rem;
        line-height: 1.7rem;
    }
`;

const NoticeList = styled.div`
    display: flex;
    gap: 1rem;
`;

const NoticeKey = styled.div`
    color: #868688;
`;

const NoticeValue = styled.div`
    color: black;
`;
