import React from "react";
import styled from "styled-components";


interface FormSubLabelProps {
  label?: string;
}

export const FormSubLabel = ({
  label,
}: FormSubLabelProps) => {
  return (
    <FormMainSubWrapper>
      {label}
    </FormMainSubWrapper>
  );
};

const FormMainSubWrapper = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #868688;
`;