import React, { useState, useEffect, useCallback } from "react";
import { ResultSection, ResultCard } from "components/ResultSection";
import { PostSection, PostCard } from "components/PostSection";
import { Body2, Heading4, PointColorText } from "components/Typography";
import { colors } from "theme";
import { httpService } from "libs/services";
import { PostModel } from "libs/models";

import AgeCalculator from "./AgeCalculator";
import DisplayAds from "components/DisplayAds";

interface AgeCalculatorResultProps {
    form: AgeCalculator;
}

const AgeCalculatorResult = ({ form }: AgeCalculatorResultProps) => {
    const [contents, setContents] = useState<PostModel[]>([]);

    const getContents = useCallback(async () => {
        const keyword = form.searchKeyword;
        const posts = await httpService.getPosts({
            keyword,
            size: 4,
            search_type: "hashtag",
            ordering: "random",
        });
        setContents(posts);
    }, [form]);

    useEffect(() => {
        getContents();
    }, [getContents]);

    return (
        <>
            <ResultSection>
                <ResultCard backgroundColor={colors.orange200}>
                    <Heading4>
                        우리 아이의 나이는{" "}
                        <PointColorText color={colors.orange100}>
                            {form.humanAge}
                        </PointColorText>{" "}
                        살이에요!
                    </Heading4>
                    <Body2 color={colors.grey500}>
                        우리 아이와 {form.totalDays}일을 함께 했어요.
                        <br />
                        아직 모든 {form.animal}의 나이를 사람 나이로 정확하게
                        변환하는 연구 결과는 없습니다. <br />
                        {form.animal}마다 나이가 드는 속도가 다릅니다. 위 결과는
                        참고로만 봐주세요.
                    </Body2>
                </ResultCard>
            </ResultSection>
            <DisplayAds animalType={form.animal} />
            {contents.length > 0 && (
                <PostSection
                    title={"비마이펫 맞춤 콘텐츠"}
                    backgroundImage={colors.orangeGradient}
                >
                    {contents.map((post: PostModel, index) => (
                        <PostCard key={index} post={post} />
                    ))}
                </PostSection>
            )}
        </>
    );
};

export default AgeCalculatorResult;
