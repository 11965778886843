import React, { useCallback, useEffect, useState } from "react";
import Layout from "components/Layout";
import styled from "styled-components";
import RegistVerifyResult from "registVerifyComponents/RegistVerifyResult";
import {
    comebackHomeBanner,
    registVerifyOgImage,
    registVerifySamsekLili,
    registVerifyTitleIcon,
} from "assets";
import RegistVerifyNoResult from "registVerifyComponents/RegistVerifyNoResult";
import axios from "axios";
import StaticUrl from "libs/urls";

const VerificationMain = () => {
    const [ownerName, setOwnerName] = useState<string>("");
    const [registrationId, setRegistrationId] = useState<string>("");
    const [response, setResponse] = useState<boolean>(false);
    const [noData, setNoData] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [resultData, setResultData] = useState<string[]>([]);

    const onChangeOwnerName = useCallback(e => {
        setOwnerName(e.target.value);
    }, []);

    const onChangeRegistrationId = useCallback(e => {
        setRegistrationId(e.target.value);
    }, []);

    const validateRegistrationId = () => {
        // 입력값이 15자리인지 확인
        if (registrationId.length !== 15) {
            return false;
        }

        // 입력값이 숫자로만 이루어져 있는지 확인
        if (!/^\d+$/.test(registrationId)) {
            return false;
        }

        return true;
    };

    const onHandleSubmit = (e: any) => {
        e.preventDefault();
        setResponse(false);
        setNoData(false);
        setErrorMsg(false);
        if (!validateRegistrationId()) setErrorMsg(true);
        else {
            const url =
                "https://apis.data.go.kr/1543061/animalInfoSrvc/animalInfo";
            let queryParams =
                "?" +
                encodeURIComponent("serviceKey") +
                "=" +
                `${process.env.REACT_APP_REGISTRATION_VERIFICATION_API_KEY}`;
            queryParams +=
                "&" +
                encodeURIComponent("dog_reg_no") +
                "=" +
                encodeURIComponent(registrationId);
            queryParams +=
                "&" +
                encodeURIComponent("owner_nm") +
                "=" +
                encodeURIComponent(ownerName);
            queryParams +=
                "&" +
                encodeURIComponent("_type") +
                "=" +
                encodeURIComponent("json");

            axios
                .get(url + queryParams)
                .then(res => {
                    // console.log(res);
                    if (Object.keys(res.data.response.body).length !== 0) {
                        // console.log(res.data.response.body.item);
                        const responseData = res.data.response.body.item;
                        setResultData(prevData => [
                            ...prevData,
                            responseData.dogRegNo,
                            responseData.dogNm,
                            responseData.kindNm,
                            responseData.orgNm,
                            responseData.rfidCd,
                            responseData.sexNm,
                            responseData.neuterYn,
                            responseData.officeTel,
                        ]);
                        setResponse(true);
                    } else {
                        setResponse(true);
                        setNoData(true);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <VerificationPageContainer
            isLoading={isLoading}
            style={{ display: "none" }}
        >
            <Layout
                header={{
                    title: "동물등록 번호 조회",
                    description:
                        "동물등록번호 조회하기. 반려동물등록 완료하셨나요? 반려동물등록번호 조회를 통하여 우리 아이의 정보를 조회해보세요",
                    ogImage: registVerifyOgImage,
                    ogUrl: "/adoption/registration-verification",
                }}
            >
                <VerificationContainer>
                    <RegistVerifyTitle>
                        동물등록 번호 검색
                        <RegistVerifyTitleIcon
                            src={registVerifyTitleIcon}
                            alt="동물등록 번호 검색 아이콘"
                        />
                        <RegistVerifySamsekLili
                            src={registVerifySamsekLili}
                            alt="삼색이 리리"
                        />
                    </RegistVerifyTitle>
                    <RegistVerifyHr />
                    <RegistVerifyBody>
                        <DescriptionContainer>
                            <LiTextWrapper>
                                <li></li>
                                <LiText>
                                    동물등록 번호 검색의 경우 소유주가{" "}
                                    <a
                                        href="https://www.animal.go.kr/front/index.do"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        동물보호관리시스템
                                    </a>
                                    에 회원가입이 되어 있고, 소유주의 정보가
                                    등록되어 있어야 정상적으로 조회가
                                    가능합니다.
                                </LiText>
                            </LiTextWrapper>
                            <LiTextWrapper>
                                <li></li>
                                <LiText>
                                    임의로 동물등록 사항을 확인하는 것을
                                    방지하기 위하여, 소유주의 개인정보를 추가
                                    입력하여야 동물등록 번호 검색이 가능합니다.
                                </LiText>
                            </LiTextWrapper>
                        </DescriptionContainer>
                        <RegistVerifyForm onSubmit={onHandleSubmit}>
                            <InputContainer>
                                <InputLabel>이름</InputLabel>
                                <RegistVerifyInput
                                    name="name"
                                    type="text"
                                    placeholder="소유주 이름을 입력해주세요"
                                    required
                                    value={ownerName}
                                    onChange={onChangeOwnerName}
                                />
                            </InputContainer>
                            <InputContainer>
                                <InputLabel>등록번호</InputLabel>
                                <RegistVerifyInput
                                    name="registration-id"
                                    type="text"
                                    placeholder="410xxxxxxx"
                                    maxLength={15}
                                    required
                                    value={registrationId}
                                    onChange={onChangeRegistrationId}
                                    errorMsg={errorMsg}
                                />
                                {errorMsg && (
                                    <ErrorMessage>
                                        410으로 시작하는 15자리의 숫자를
                                        입력해주세요
                                    </ErrorMessage>
                                )}
                            </InputContainer>
                            <RegistVerifyButton type="submit">
                                조회하기
                            </RegistVerifyButton>
                        </RegistVerifyForm>
                        {response &&
                            (noData ? (
                                <RegistVerifyNoResult />
                            ) : (
                                <RegistVerifyResult responseData={resultData} />
                            ))}
                        <BannerSection>
                            <a
                                href={StaticUrl.bemypetcomebackhome}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={comebackHomeBanner} alt="배너" />
                            </a>
                        </BannerSection>
                    </RegistVerifyBody>
                </VerificationContainer>
            </Layout>
        </VerificationPageContainer>
    );
};

export default VerificationMain;

const VerificationPageContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const VerificationContainer = styled.div`
    margin: 0 auto;
    min-height: calc(100vh - 236px);

    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - 244px);
    }
    @media only screen and (max-width: 546px) {
        min-height: calc(100vh - 270px);
    }
`;

const RegistVerifyTitle = styled.div`
    position: relative;
    max-width: 884px;
    margin: 0 auto;
    padding: 60px 70px 0;

    font-size: 26px;
    line-height: 31px;
    font-weight: 400;

    @media screen and (max-width: 768px) {
        padding: 40px 30px 0;
    }
    @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 24px;
    }
`;

const RegistVerifyHr = styled.hr`
    width: 100vw;
    background: #fcd11e;
    height: 1px;
    border: none;
`;

const RegistVerifyBody = styled.div`
    max-width: 1024px;
    margin: 0 auto;
`;

const RegistVerifyTitleIcon = styled.img`
    width: 41px;
    height: 34px;
    margin-left: 3px;

    @media screen and (max-width: 600px) {
        width: 34px;
        height: 28px;
    }
`;
const RegistVerifySamsekLili = styled.img`
    position: absolute;
    top: -22px;
    right: 40px;
    width: 199px;
    height: 195px;

    @media screen and (max-width: 1024px) {
        top: -23px;
    }

    @media screen and (max-width: 768px) {
        top: -42px;
        right: 20px;
    }

    @media screen and (max-width: 600px) {
        top: 4px;
        right: 20px;
        width: 116px;
        height: 114px;
    }
    @media screen and (max-width: 448px) {
        top: 3px;
    }
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;

    padding: 18px 18px;
    margin: 40px 60px;
    background: #ffffff;
    border: 1px solid #cfcece;

    font-size: 16px;
    line-height: 19px;
    font-weight: 600;

    word-break: keep-all;

    @media screen and (max-width: 768px) {
        margin: 24px 30px 14px;
    }
    @media screen and (max-width: 600px) {
        gap: 16px;
        padding: 14px 18px;
        padding-right: 20px;

        font-size: 13px;
        line-height: 16px;
    }
`;

const LiTextWrapper = styled.div`
    display: flex;

    li {
        list-style-position: inside;
    }
`;

const LiText = styled.div`
    margin-top: 1px;

    a {
        color: black;
    }

    @media screen and (max-width: 600px) {
        margin-top: 0;
    }
`;

const RegistVerifyForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 60px;

    @media screen and (max-width: 768px) {
        margin: 0 30px;
    }
`;

const InputContainer = styled.div``;

const InputLabel = styled.div`
    padding: 10px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
`;

const RegistVerifyInput = styled.input<{ errorMsg?: boolean }>`
    width: 226px;
    padding: 10px 15px;
    border-radius: 25px;
    border: 1px solid #fcd11e;
    background-color: white;

    ::placeholder {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #868688;
    }

    ${({ errorMsg }) => errorMsg && `border: 1px solid #F24147;`}
`;

const ErrorMessage = styled.div`
    padding: 6px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #f24147;
`;

const RegistVerifyButton = styled.button`
    width: 138.5px;
    padding: 10px;
    margin: 40px auto 60px;

    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    color: black;

    border-radius: 21px;
    border: none;
    background-color: #fcd11e;
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media screen and (max-width: 600px) {
        width: 120px;
        margin: 30px auto 40px;
        font-size: 16px;
        line-height: 19px;
    }
`;

const BannerSection = styled.div`
    max-width: 1024px;
    margin: 0 auto 80px;

    img {
        width: 100%;
        object-fit: cover;
        vertical-align: bottom;
    }

    @media screen and (max-width: 600px) {
        margin: 0 0 60px;
    }
`;
