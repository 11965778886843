import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as ExpandMore } from "../assets/DrawerImages/expand_more_black.svg";
import { creatorLili, popperHelpOutline, popperTextSMS } from "assets";

const WriteButton = () => {
    const [showPopper, setShowPopper] = useState<boolean>(false);

    const handleWriteButton = () => {
        setShowPopper(prev => !prev);
    };

    return (
        <>
            <WriteButtonContainer>
                <WriteButtonWrapper onClick={handleWriteButton}>
                    글쓰기
                    <ExpandMore />
                </WriteButtonWrapper>
                {showPopper && (
                    <WritePopperWrapper>
                        <WritePopperTab href="https://bemypet.kr/qapage/question">
                            <WritePopperIcon
                                src={popperHelpOutline}
                                alt="popper logo"
                            />
                            <WritePopperText>Q&A</WritePopperText>
                        </WritePopperTab>
                        <WritePopperHr />
                        <WritePopperTab href="https://bemypet.kr/lounge/write">
                            <WritePopperIcon
                                src={popperTextSMS}
                                alt="popper logo"
                            />
                            <WritePopperText>라운지</WritePopperText>
                        </WritePopperTab>
                        <WriteImage>
                            <img src={creatorLili} alt="리리" />
                        </WriteImage>
                    </WritePopperWrapper>
                )}
            </WriteButtonContainer>
        </>
    );
};

export default WriteButton;

const WriteButtonContainer = styled.div``;

const WriteButtonWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7.5px 10px 7.5px 18px;
    border-radius: 21px;
    background-color: #fcd11e;

    line-height: 24px;

    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
`;

const WritePopperWrapper = styled.div`
    position: absolute;
    right: -54px;
    margin-top: 10px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    width: 204px;
    height: 120px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

const WritePopperTab = styled.a`
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    height: 100%;
`;

const WritePopperIcon = styled.img`
    width: 24px;
    height: 24px;
`;

const WritePopperText = styled.div`
    margin: 0;
`;

const WritePopperHr = styled.hr`
    margin: 0;
    width: 100%;
    border: 1px solid #fcd11e;
`;

const WriteImage = styled.div`
    position: absolute;
    bottom: 12px;
    right: 7px;
    width: 47.5px;
    height: 64px;

    img {
        width: 100%;
        height: 100%;
    }
`;
