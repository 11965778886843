import React from "react";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { colors } from "theme";
import { percentFormatter } from "libs/helpers";
import { ChartOptions, PluginOptionsByType } from "chart.js";
import { _DeepPartialObject } from "chart.js/types/utils";

interface PieChartProps {
    width?: number;
    data: {
        labels: string[];
        datasets: {
            data: number[];
            backgroundColor: string[];
        }[];
    };
    totalCalories: number;
}

const PieChart = (props: PieChartProps) => {
    interface MyPluginOptions
        extends _DeepPartialObject<PluginOptionsByType<"pie">> {
        datalabels?: {
            color: string;
            formatter: (value: number) => string;
            font: {
                size: number;
                weight: "bold";
                style: "normal";
            };
        };
    }

    const options: ChartOptions<"pie"> & MyPluginOptions = {
        legend: {
            position: "right",
            labels: {
                boxWidth: 8,
                usePointStyle: true,
                color: colors.grey700,
                font: {
                    size: 12,
                    weight: "bold",
                    style: "normal",
                },
            },
        },
        datalabels: {
            color: colors.grey100,
            formatter: function (value: number) {
                return (
                    percentFormatter((value / props.totalCalories) * 100, 0) +
                    "%"
                );
            },
            font: {
                size: 16,
                weight: "bold",
                style: "normal",
            },
        },
    };

    return <Pie data={props.data} width={props.width} options={options} />;
};

export default PieChart;
