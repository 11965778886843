import React from "react";
import styled from "styled-components";


interface FormCategoryProps {
  label?: string;
}

export const FormCategory = ({
  label,
}: FormCategoryProps) => {
  return (
    <FormCategoryWrapper>
      {label}
    </FormCategoryWrapper>
  );
};

const FormCategoryWrapper = styled.div`
  width: 100%;
  max-width: 352px;
  height: 52px;
  background-color: #FCD11E;
  font-size: 16px;
  font-weight: 600;
  padding-left: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;