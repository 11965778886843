import React from "react";
import styled from "styled-components";

const resultBoxColor = ["#FCD11E", "#FFF5CE", "#F0E9F7", "#FFEB99"];

const NamingResultCard = ({ data, index }: any) => {
    return (
        <>
            <ResultCardContainer>
                <NamingResult
                    key={index}
                    index={index}
                    resultBoxColor={resultBoxColor[index]}
                >
                    <NamingResultNumber>{index + 1}</NamingResultNumber>
                    <NamingResultKeyword>{data.name}</NamingResultKeyword>
                    <NamingDescriptionContainer>
                        <NamingResultDescription>
                            {data.description}
                        </NamingResultDescription>
                    </NamingDescriptionContainer>
                </NamingResult>
            </ResultCardContainer>
        </>
    );
};

export default NamingResultCard;

const ResultCardContainer = styled.div``;

const NamingResult = styled.div<{
    resultBoxColor?: string;
    index: number;
}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 180px;
    height: 210px;
    padding-bottom: 26px;

    text-align: center;

    border-radius: 30px;
    border: 1px solid ${props => props.resultBoxColor};
    background-color: ${props => props.resultBoxColor};
    box-shadow: -12.24px 10.08px 48.96px -2.88px rgba(207, 174, 132, 0.25);

    ${({ resultBoxColor }) =>
        resultBoxColor === "#FFF5CE" &&
        `
            border: 1px solid #FFEB99;
    `}
    ${({ index }) =>
        index % 2 !== 0 &&
        `
            margin-top: 36px;

            @media only screen and (max-width: 664px) {
                margin-top: 60px;
    }
    `}

@media only screen and (max-width: 832px) and (min-width: 664px), only screen and (max-width: 448px) {
        width: 144px;
        height: 170px;
        padding-bottom: 18px;
    }
`;

const NamingResultNumber = styled.div`
    width: 45px;
    height: 22px;
    padding: 10px 0;
    margin: 20px auto 0;

    border-radius: 10px;
    background-color: white;

    font-size: 22px;
    line-height: 22px;
    font-weight: 900;
    color: black;

    @media only screen and (max-width: 832px) and (min-width: 664px),
        only screen and (max-width: 448px) {
        width: 35px;
        height: 18px;
        padding: 9.5px 0 7.5px;
        margin: 15px auto 0;
        font-size: 16px;
        line-height: 17px;
    }
`;

const NamingResultKeyword = styled.div`
    padding: 18px 0 12px;
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    color: #090909;

    @media only screen and (max-width: 832px) and (min-width: 664px),
        only screen and (max-width: 448px) {
        padding: 15px 0 5px;
        font-size: 18px;
        line-height: 17px;
    }
`;

const NamingDescriptionContainer = styled.div`
    display: flex;
    /* height: 90px; */
    min-height: 72px;
    margin-top: 4px;
    /* align-items: center; */
    justify-content: center;

    @media only screen and (max-width: 832px) and (min-width: 664px),
        only screen and (max-width: 448px) {
        min-height: 60px;
    }
`;

const NamingResultDescription = styled.div`
    padding: 0 10px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #090909;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: keep-all;

    @media only screen and (max-width: 832px) and (min-width: 664px),
        only screen and (max-width: 448px) {
        padding: 0 8px;

        font-size: 10px;
        line-height: 15px;
    }
`;
