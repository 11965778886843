import { Agree, DisAgree } from "assets";
import React from "react";
import styled from "styled-components";

interface CheckBoxProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  onChange?: (value: any) => void;
}

const FormAgreementCheckBox = ({ label, value, checked, onChange }: CheckBoxProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!!onChange) {
      onChange(e.target.checked);
    }
  };
  return (
    <CheckBoxWrapper>
      <CheckBoxLabel>
        <BaseCheckBoxButton
          type="checkbox"
          value={value}
          checked={checked}
          onChange={handleOnChange}
        />
        {checked
        ? <CheckBoxImage src={Agree} />
        : <CheckBoxImage src={DisAgree} />
        }
        {label}
      </CheckBoxLabel>
    </CheckBoxWrapper>
  );
};

const CheckBoxWrapper = styled.div<CheckBoxProps>`
  position: relative;
  display: flex;
  align-items: center;
`;

const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  color: black;
  padding: 0px;
  margin: 0px;
  font-size: 13px;
  line-height: 1.86;

  :hover {
    cursor: pointer;
  }
`;

const BaseCheckBoxButton = styled.input`
  display: none;
`;

const CheckBoxImage = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 8px;
`

export { FormAgreementCheckBox };
