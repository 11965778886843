import React from "react";
import { colors } from "theme";
import IconProps from ".";

const CheckedIcon = ({
  color = colors.grey100,
  width = 17,
  height = 17,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    style={{ width, height }}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H1260V1948H0z" transform="translate(-469 -1824)" />
      <path
        fill={color}
        d="M473.442 1836.401l4.439 5.052c.406.45.766.547 1.11.547.36 0 .734-.097 1.14-.547l9.427-9.767c.625-.708.562-1.576-.031-2.188-.594-.595-1.563-.724-2.142-.016l-8.379 8.818-3.391-4.103c-.579-.708-1.548-.579-2.142.016-.593.612-.656 1.48-.03 2.188z"
        transform="translate(-469 -1824)"
      />
    </g>
  </svg>
);

export default CheckedIcon;
