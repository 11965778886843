import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const DisplayAds = ({ animalType }: any) => {
    const [adsData, setAdsData] = useState<any>({});

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}partners/ad/display-ads/?channel=ALL&pet_type=${animalType}&asset_type=LANDSCAPE`
            )
            .then(res => {
                setAdsData(
                    res.data[Math.floor(Math.random() * res.data.length)]
                );
            })
            .catch(err => {
                console.log(err);
            });
    }, [animalType]);

    return (
        <>
            <DisplayAdsContainer>
                {adsData && (
                    <a
                        href={adsData.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={adsData?.asset?.source_url} alt="배너" />
                    </a>
                )}
            </DisplayAdsContainer>
        </>
    );
};

const DisplayAdsContainer = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    img {
        width: 100%;
        object-fit: cover;
        vertical-align: bottom;
        margin-bottom: 80px;

        @media only screen and (max-width: 768px) {
            margin-bottom: 40px;
        }
    }
`;

export default DisplayAds;
