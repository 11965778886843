import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import { colors } from "theme";

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  width?: number;
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
  invalid?: boolean;
  value?: any;
  onChange?: (value: any) => void;
  decimal?: number;
}

const Input = forwardRef(
  (
    {
      leading,
      trailing,
      width,
      invalid,
      onChange,
      decimal,
      ...rest
    }: InputProps,
    ref,
  ) => {
    const [focus, setFocus] = useState<boolean>(false);
    const handleOnFocus = () => setFocus(true);
    const handleOnBlur = () => setFocus(false);
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (!!decimal) {
        const [, decimalString] = value.split(".");
        if (!!decimalString && decimalString.length > decimal) {
          return;
        }
      }

      if (!!onChange) {
        onChange(value);
      }
    };
    return (
      <InputWrapper
        borderColor={
          focus ? colors.primary100 : invalid ? colors.red : "transparent"
        }
      >
        {!!leading && <LeadingWrapper>{leading}</LeadingWrapper>}
        <BaseInput
          {...(rest as any)}
          ref={ref}
          width={width}
          trailing={trailing}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          pattern={rest.type === "number" ? "^d*(.d{0,2})?$" : null}
        />
        {!!trailing && <TrailingWrapper>{trailing}</TrailingWrapper>}
      </InputWrapper>
    );
  },
);

const BaseInput = styled.input<InputProps>`
  color: ${colors.grey700};
  font-family: JalnanOTF;
  height: 26px;
  font-size: 14px;
  outline: none;
  border: none;
  width: 100%;
  background-color: ${colors.grey200};
  box-sizing: border-box;

  ${({ trailing }) =>
    !!trailing &&
    `
      text-align: right;

      ::placeholder {
        text-align: right;
      }
  `}

  ::placeholder {
    color: ${colors.grey400};
  }
`;

const InputWrapper = styled.div<{ borderColor: string }>`
  width: 100%;
  max-width: 360px;
  text-align: right;
  height: 48px;
  border-radius: 14px;
  background-color: ${colors.grey200};
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  border-color: ${({ borderColor }) => !!borderColor && borderColor};
  box-sizing: border-box;
  parring: 11px 26px;
`;

const LeadingWrapper = styled.div<InputProps>`
  padding-right: 10px;
  padding-left: 21px;
  width: 24px;
  height: 24px;
`;

const TrailingWrapper = styled.div<InputProps>`
  padding-right: 21px;
  width: 21px;
  height: 26px;
  text-align: right;
`;

export { Input };
