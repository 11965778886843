import Layout from "components/Layout";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { MobileBubble, PCBubble, ogImage, scrapIcon, unscrapIcon } from "assets";
import axios from "axios";
import { ReactComponent as InfoIcon } from "../../assets/InsuranceImages/detail/info.svg";
import { ReactComponent as NewWindowIcon } from "../../assets/InsuranceImages/detail/NEW_window_insurance.svg";
import { Bubble } from "assets";
import QnA from "../insurance/main/QnA/QnA";
import Guarantee from "insuranceComponents/detail/Guarantee";
import { useMediaQuery } from "react-responsive";
import ReactGA from "react-ga";
import NotFound from "pages/notfound/NotFound";
import { useRecoilValue } from "recoil";
import { userTokenState } from "states";

export default function InsuranceDetail() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const { insuranceId } = useParams<"insuranceId">();
    const bubbleRef = useRef<HTMLInputElement | null>(null);
    const [insuranceData, setInsuranceData] = useState<any>({});
    const [infoSelected, setInfoSelected] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<string>("qna");
    const [noData, setNoData] = useState<boolean>(false);
    const [isFavorite, setIsFavorite] = useState<boolean>(false);
    const [isConsultation, setIsConsultation] = useState<string>("");

    const userToken = useRecoilValue(userTokenState);

    const isTablet = useMediaQuery({
        query: "(min-width:768px) and (max-width:928px)",
    });
    const isMobile = useMediaQuery({
        query: "(max-width:512px)",
    });

    const handleToggleFavorite = (isFavorite: boolean) => {
        axios
            .put(
                `${process.env.REACT_APP_API_URL_DEV}insurance/favorite/${insuranceData.id}/`,
                { is_favorite: !isFavorite },
                {
                    headers: {
                        Authorization: userToken ? `Bearer ${userToken}` : ``,
                    },
                }
            )
            .then(res => {
                setIsFavorite(prev => !prev);
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        axios
        .get(`${process.env.REACT_APP_API_URL_DEV}insurance/insurances/${insuranceId}/`, {
            headers: {
                Authorization: userToken ? `Bearer ${userToken}` : ``,
            },
        })
        .then (res => {
            res && setIsConsultation(res.data.insurance_company.chat_url);
        })
        .catch(err => {
            setNoData(true);
        });
    }, [insuranceId, userToken]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!Number.isNaN(Number(insuranceId))) {
            axios
                .get(`${process.env.REACT_APP_API_URL_DEV}insurance/insurances/${insuranceId}/`, {
                    headers: {
                        Authorization: userToken ? `Bearer ${userToken}` : ``,
                    },
                })
                .then(res => {
                    setIsFavorite(res.data.is_favorite);
                    setInsuranceData(res.data);
                })
                .catch(err => {
                    setNoData(true);
                });
        } else {
            navigate("/notfound");
        }
        const handleClickOutside = (event: MouseEvent) => {
            if (bubbleRef.current && !bubbleRef.current.contains(event.target as Node)) {
                setInfoSelected(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line
    }, [insuranceId]);

    useEffect(() => {
        ReactGA.event({
            category: "보험 상세",
            action: "페이지 접근",
        });

        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <>
            <InsuranceDetailContainer isLoading={isLoading} style={{ display: "none" }}>
                <Layout
                    header={{
                        title: `${
                            typeof window !== "undefined" &&
                            insuranceId &&
                            insuranceData &&
                            insuranceData?.insurance_company
                                ? `${insuranceData.insurance_company.name} ${insuranceData.name} 가격 및 정보`
                                : "보험 상세 정보"
                        }`,
                        description: `${
                            typeof window !== "undefined" &&
                            insuranceId &&
                            insuranceData &&
                            insuranceData?.insurance_company
                                ? `${insuranceData.insurance_company.name} ${insuranceData.name}의 보장내역에 대하여 간편하게 조회 및 비교해보세요`
                                : "보험 보장내역에 대하여 간편하게 조회 및 비교해보세요."
                        }`,
                        ogImage: ogImage,
                        ogUrl: `${
                            typeof window !== "undefined" && insuranceId
                                ? `/insurance/${insuranceId}`
                                : ""
                        }`,
                    }}
                >
                    {noData ? (
                        <NotFound />
                    ) : (
                        <DetailContainer>
                            <DetailTitleContainer>
                                <InsuranceLogo
                                    src={insuranceData?.insurance_company?.logo}
                                    alt={insuranceData?.insurance_company?.name}
                                ></InsuranceLogo>
                                <InsuranceName>
                                    {insuranceData?.insurance_company?.name} {insuranceData?.name}
                                </InsuranceName>
                            </DetailTitleContainer>
                            <DetailContentContainer>
                                {insuranceData.thumbnail ? (
                                    <InsuranceImage
                                        src={insuranceData.thumbnail}
                                        alt={insuranceData?.name}
                                    ></InsuranceImage>
                                ) : (
                                    <></>
                                )}
                                <DetailContentWrapper>
                                    <DetailContent>
                                        <ContentTitleWrapper>
                                            <ContentTitle>가입 가능 나이</ContentTitle>
                                            <BubbleContainer
                                                selected={infoSelected}
                                                ref={bubbleRef}
                                            >
                                                <BubbleImage
                                                    src={Bubble}
                                                    alt="말풍선"
                                                ></BubbleImage>
                                                <BubbleText>
                                                    해당 펫보험에 <strong>가입</strong> 가능한
                                                    <br />
                                                    반려동물의 나이
                                                </BubbleText>
                                            </BubbleContainer>
                                            <InfoIconContainer
                                                onClick={() => setInfoSelected(prev => !prev)}
                                            >
                                                <InfoIcon />
                                            </InfoIconContainer>
                                        </ContentTitleWrapper>
                                        <ContentText>
                                            만 {insuranceData?.join_age_limit}
                                            세까지
                                        </ContentText>
                                        <ContentTitle>상품 요약</ContentTitle>
                                        <ContentText
                                            dangerouslySetInnerHTML={{
                                                __html: insuranceData?.description,
                                            }}
                                        />
                                        <ContentTitle>비마이펫 코멘트</ContentTitle>
                                        <ContentText
                                            dangerouslySetInnerHTML={{
                                                __html: insuranceData?.bemypet_summary,
                                            }}
                                            last={true}
                                        />
                                    </DetailContent>
                                    <InsuranceScrapButtonContainer>
                                        {userToken === "" || userToken === null ? (
                                            <InsuranceScrapLink
                                                aria-label="통합 로그인"
                                                href={`${process.env.REACT_APP_MAIN_URL_DEV}login?returnURL=${process.env.REACT_APP_DOMAIN_URL_DEV}insurance/${insuranceData?.id}/`}
                                            >
                                                <InsuranceScrapButtonWrapper>
                                                    <InsuranceScrapButton active={false} />
                                                    <InsuranceScrapText>
                                                        북마크 +
                                                    </InsuranceScrapText>
                                                </InsuranceScrapButtonWrapper>
                                            </InsuranceScrapLink>
                                        ) : (
                                            <InsuranceScrapButtonWrapper>
                                                <InsuranceScrapButton
                                                    onClick={() => {
                                                        handleToggleFavorite(isFavorite);
                                                    }}
                                                    active={isFavorite}
                                                />
                                                <InsuranceScrapText>북마크 +</InsuranceScrapText>
                                            </InsuranceScrapButtonWrapper>
                                        )}
                                    </InsuranceScrapButtonContainer>
                                </DetailContentWrapper>
                            </DetailContentContainer>
                            <ExtraContent>
                                <ExtraDescription>
                                    {insuranceData?.animal_type === "DOG" ? "말티즈 " : "코숏 "}
                                    3세 기준 데이터입니다. {(isTablet || isMobile) && <br />}
                                    자세한 정보는 아래의 버튼을 통해 확인해보세요!
                                </ExtraDescription>
                                <InsurancePrice>
                                    {insuranceData?.price
                                        ? `월 ${insuranceData?.price?.toLocaleString()}원`
                                        : "확인 필요"}
                                </InsurancePrice>
                            </ExtraContent>
                            <DetailButtonContainer>
                                {isConsultation && 
                                    <QnAButtonWrapper>
                                        <FixedBubbleContainer>
                                            <FixedBubbleImage>
                                                <FixedBubbleText>
                                                    펫보험 전문가에게
                                                    <br />
                                                    실시간 상담을 받아보세요!
                                                </FixedBubbleText>
                                            </FixedBubbleImage>
                                        </FixedBubbleContainer>
                                        <DetailButton 
                                            href={isConsultation}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            실시간 문의
                                        </DetailButton>
                                    </QnAButtonWrapper>
                                }
                                <DetailButton href="https://bemypet.kr/qapage/question">
                                    커뮤니티 Q&A
                                </DetailButton>
                                    <DetailButton
                                        href={insuranceData?.action_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                    계산하기
                                <NewWindowIcon className="newWindowIcon" />
                                </DetailButton>
                            </DetailButtonContainer>
                            <TabButtonContainer>
                                <TabButton
                                    selected={currentTab === "qna"}
                                    onClick={() => setCurrentTab("qna")}
                                >
                                    Q&A
                                </TabButton>
                                <TabButton
                                    selected={currentTab === "detail"}
                                    onClick={() => setCurrentTab("detail")}
                                >
                                    보장 상세
                                </TabButton>
                            </TabButtonContainer>
                            <TabContainer>
                                {currentTab === "qna" ? (
                                    <>
                                        <QnA />
                                    </>
                                ) : (
                                    <>
                                        <Guarantee insuranceData={insuranceData} />
                                    </>
                                )}
                            </TabContainer>
                        </DetailContainer>
                    )}
                </Layout>
            </InsuranceDetailContainer>
        </>
    );
}

const InsuranceDetailContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const DetailContainer = styled.div`
    max-width: 1024px;
    margin: 0 auto;

    @media only screen and (max-width: 448px) {
        padding: 0px 16px;
    }
`;

const DetailTitleContainer = styled.div`
    display: flex;
    margin: 0 24.5px;
    margin-top: 70px;
    padding-bottom: 18.7px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #ffeb99;
`;

const InsuranceLogo = styled.img`
    width: 62.7px;
    height: 62.7px;
    margin-right: 28px;
    margin-bottom: 3px;
    border-radius: 50%;
    box-shadow: 0px 3.3px 6.6px rgba(0, 0, 0, 0.15);

    @media only screen and (max-width: 512px) {
        width: 40px;
        height: 40px;
        margin-right: 12px;
    }
`;

const InsuranceName = styled.div`
    font-size: 33px;
    line-height: 36px;
    font-weight: 700;
    letter-spacing: 0.75px;
    word-break: keep-all;

    @media only screen and (max-width: 512px) {
        font-size: 24px;
        line-height: 26px;
        letter-spacing: 0.5px;
    }
`;

const DetailContentContainer = styled.div`
    display: flex;
    gap: 53px;
    margin: 80px 24px 10px;

    @media only screen and (min-width: 768px) and (max-width: 896px) {
        gap: 36px;
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        width: 30rem;
        margin: 40px auto;
    }
`;

const InsuranceImage = styled.img`
    width: 50%;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media only screen and (max-width: 768px) {
        width: 30rem;
    }
`;

const DetailContentWrapper = styled.div`
    position: relative;
    width: 50%;
    height: auto;

    @media only screen and (max-width: 768px) {
        width: 30rem;
    }
`;

const DetailContent = styled.div``;

const ContentTitleWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const ContentTitle = styled.div`
    padding: 10px 0;
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;

    @media only screen and (max-width: 360px) {
        font-size: 16px;
        line-height: 17px;
        letter-spacing: 0.35px;
    }
`;

const BubbleContainer = styled.div<{ selected?: boolean }>`
    position: absolute;
    top: -103px;
    left: 46px;
    opacity: 0;
    transition: 0.2s ease;

    ${({ selected }) => selected && `opacity: 1;`};
`;

const BubbleImage = styled.img`
    width: 185px;
    height: 112px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
`;

const BubbleText = styled.div`
    position: absolute;
    top: 25px;
    left: 26px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
`;

const InfoIconContainer = styled.div`
    cursor: pointer;
`;

const ContentText = styled.div<{ last?: boolean }>`
    margin-bottom: 50px;
    padding: 10px 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;
    color: #49454f;

    p {
        margin: 0;
    }

    ul {
        margin: 0;
        padding: 0 28px;
    }

    li {
        padding: 4px 0;
    }

    ${({ last }) => last && `margin-bottom: 0;`};

    @media only screen and (min-width: 832px) and (max-width: 896px) {
        margin-bottom: 30px;
    }
    @media only screen and (min-width: 768px) and (max-width: 832px) {
        margin-bottom: 20px;
    }
    @media only screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
    @media only screen and (max-width: 360px) {
        font-size: 13px;
        line-height: 14px;
        letter-spacing: 0.175px;
    }
`;

const ExtraContent = styled.div`
    text-align: right;
    margin: 30px 45px 0;

    @media only screen and (max-width: 768px) {
        margin: 0 25px;
    }
`;

const ExtraDescription = styled.div`
    margin-bottom: 40px;
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
    color: #868688;
    letter-spacing: 0.25px;

    @media only screen and (max-width: 360px) {
        font-size: 9px;
        line-height: 14px;
        letter-spacing: 0.175px;
    }
`;

const InsurancePrice = styled.div`
    font-size: 38px;
    line-height: 20px;
    font-weight: 700;

    @media only screen and (max-width: 832px) {
        font-size: 32px;
        line-height: 13px;
    }
    @media only screen and (max-width: 512px) {
        font-size: 26px;
        line-height: 13px;
    }
    @media only screen and (max-width: 390px) {
        font-size: 24px;
        line-height: 13px;
    }
`;

const FixedBubbleContainer = styled.div`
    position: absolute;
    top: -97px;
    left: -50px;

    @media only screen and (max-width: 768px) {
        top: 40px;
        left: -50px;
    }

    @media only screen and (max-width: 448px) {
        top: 40px;
        left: -50px;
    }
`;

const FixedBubbleImage = styled.div`
    display: flex;
    width: 200px;
    height: 107px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));

    text-align: center;
    justify-content: center;
    align-items: center;

    background-image: url(${Bubble});
    background-size: 200px 107px;


    @media only screen and (max-width: 768px) {
        background-image: url(${PCBubble});
        background-size: 200px 107px;
    
    }

    @media only screen and (max-width: 512px) {
        background-image: url(${MobileBubble});
    }

    @media only screen and (max-width: 448px) {
        background-image: url(${MobileBubble});
    }
`;

const FixedBubbleText = styled.div`
    margin-bottom: 20px;
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;

    @media only screen and (max-width: 768px) {
        margin-top: 35px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;

    }

    @media only screen and (max-width: 512px) {
        line-height: 16px;
    }

    @media only screen and (max-width: 448px) {
        font-size: 9px;
    }

`;

const DetailButtonContainer = styled.div`
    position: relative;
    display: flex;
    margin: 70px 30px;
    gap: 15px;
    justify-content: space-around;

    @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 90px;
    }

    @media only screen and (max-width: 448px) {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }
`;

const QnAButtonWrapper = styled.div`
    position: relative;
    margin-left: 42px;

    @media only screen and (max-width: 768px) {
        margin-left: 0;
        margin-bottom: 26px;
    }
`;

const DetailButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 198px;
    padding: 16px 35px;
    border-radius: 172px;
    background-color: #fcd11e;
    box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    font-size: 25px;
    line-height: 35px;
    font-weight: 600;
    color: black;
    text-decoration: none;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 848px) {
        width: 216px;
        font-size: 21px;
    }

    @media only screen and (max-width: 768px) {
        height: 20px;
        padding: 8px 8px;
        font-size: 15px;
        line-height: 19px;
    }

    @media only screen and (max-width: 448px) {
        height: 15px;
        padding: 10px 8px;
        font-size: 13px;
        line-height: 19px;
    }

    .newWindowIcon {
        width: 21px;
        height: 21px;
        margin-left: 3px;
    }
`;

const TabButtonContainer = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    
    @media only screen and (max-width: 768px) {
        margin-top: 60px;
    }

    @media only screen and (max-width: 448px) {
        margin-top: 100px;
    }
`;

const TabButton = styled.div<{ selected?: boolean }>`
    width: 50%;
    padding: 20px;
    background-color: #f6f6f9;
    cursor: pointer;

    font-size: 16px;
    line-height: 19px;
    font-weight: 600;

    ${({ selected }) => selected && `background-color: #FFEB99;`};

    @media only screen and (max-width: 360px) {
        padding: 10px;
    }
`;

const TabContainer = styled.div`
    min-height: 300px;
`;

const InsuranceScrapButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
`;

const InsuranceScrapButton = styled.div<{ active: boolean }>`
    width: 40px;
    height: 50.5px;

    background-image: url(${({ active }) => (active ? scrapIcon : unscrapIcon)});
    background-repeat: no-repeat;
    background-size: cover;

    will-change: filter;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));

    cursor: pointer;

    transition: 0.1s;

    :hover {
        background-image: url(${scrapIcon});
    }

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        width: 32px;
        height: 40px;
    }
`;

const InsuranceScrapText = styled.div`
    font-size: 11px;
    font-weight: 700;
    color: #ffce00;

    @media only screen and (max-width: 768px) {
        font-size: 9px;
    }
`;

const InsuranceScrapButtonContainer = styled.div`
    position: absolute;
    top: 7px;
    right: 30px;
    width: 40px;
    height: 50.5px;

    @media only screen and (max-width: 768px) {
        right: 5px;
    }
`;

const InsuranceScrapLink = styled.a`
    text-decoration: none;
`;

