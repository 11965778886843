import React from "react";
import MainComponent from "./MainComponent";

export default function InsuranceMainPage() {
    return (
        <>
            <MainComponent />
        </>
    );
}
