import React from "react";
import styled from "styled-components";


interface FormMainLabelProps {
  label?: string;
}

export const FormMainLabel = ({
  label,
}: FormMainLabelProps) => {
  return (
    <FormMainLabelWrapper>
      {label}
    </FormMainLabelWrapper>
  );
};

const FormMainLabelWrapper = styled.div`
  font-size: 16px;
  font-weight: 600;
`;