import React from "react";
import styled from "styled-components";
import { colors } from "theme";

interface FormRadioProps extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  onChange?: (value: any) => void;
}

export const FormRadioButton = ({
  label,
  value,
  checked,
  onChange,
}: FormRadioProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!!onChange) {
      onChange(value);
      console.log(value);
    }
  };

  return (
    <FormRadioWrapper>
      <FormRadioLabel>
        <FormBaseRadioButton
          type="radio"
          value={label}
          checked={checked}
          onChange={handleOnChange}
        />
        {label}
      </FormRadioLabel>
    </FormRadioWrapper>
  );
};

const FormRadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FormRadioLabel = styled.label`
  display: flex;
  align-items: center;
  color: black;
  min-width: 70px;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  line-height: 1.86;
  :hover {
    cursor: pointer;
  }
`;

const FormBaseRadioButton = styled.input`
  padding: 3px 0px;
  background-color: ${colors.grey200};
  margin: 0px 10px 0px 0px;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 14px;
  outline: none;
  
  &:checked {
    border: 5px solid ${colors.primary100};
    background-color: ${colors.grey100};
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 14px;
  }
`;
