import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as BenefitIcon } from "../../assets/InsuranceImages/benefits/BenefitIcon.svg";
import InsuranceCard from "insuranceComponents/main/InsuranceCard";
import axios from "axios";

const BenefitCard = ({ data, selectedBenefits }: any) => {
    const [benefits, setBenefits] = useState<any>([]);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL_DEV}insurance/insurances/${data.id}/`)
            .then(res => {
                const matchedBenefits = res.data.insurance_benefits
                    .filter((benefit: any) => {
                        return selectedBenefits.some(
                            (selected: any) => selected.name === benefit.name
                        );
                    })
                    .sort((a: any, b: any) => a.category_id - b.category_id);
                setBenefits(matchedBenefits);
            })
            .catch(err => {
                console.log(err);
            });
    }, [data, selectedBenefits]);

    return (
        <div>
            <BenefitChipList>
                {benefits?.map((benefit: any) => (
                    <BenefitChip key={benefit.category_id}>
                        <BenefitIcon className="benefitIcon" />
                        <BenefitText>{benefit.name}</BenefitText>
                    </BenefitChip>
                ))}
            </BenefitChipList>
            <InsuranceCard key={data.id} data={data} noBorder={true}></InsuranceCard>
        </div>
    );
};

export default BenefitCard;

const BenefitChipList = styled.div`
    display: flex;
    gap: 4px;
    height: 29px;
    margin-left: 9px;
`;

const BenefitChip = styled.div`
    display: flex;
    gap: 6px;

    align-items: center;
    padding: 4px 12px 4px 4px;
    background-color: #ffeb99;
    border-radius: 10px 10px 0px 0px;

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        gap: 4px;
        padding: 4px 10px 4px 4px;
    }

    .benefitIcon {
        width: 18px;
        height: 18px;
        @media only screen and (min-width: 768px) and (max-width: 832px),
            only screen and (max-width: 448px) {
            width: 12px;
            height: 12px;
        }
    }
`;

const BenefitText = styled.div`
    margin-top: 1px;
    font-size: 11px;
    line-height: 20px;
    font-weight: 700;

    @media only screen and (min-width: 768px) and (max-width: 832px),
        only screen and (max-width: 448px) {
        font-size: 9px;
        line-height: 13px;
    }
`;
