import styled from "styled-components";
import { colors } from "theme";

export const ResultSection = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 80px;

    @media only screen and (max-width: 768px) {
        padding-top: 40px;
    }
`;

export const ResultCard = styled.div<{ backgroundColor?: string }>`
    text-align: center;
    margin-top: 40px;
    margin-bottom: 80px;
    background-color: ${({ backgroundColor }) =>
        !!backgroundColor ? backgroundColor : colors.grey100};
    border-radius: 14px;
    width: 620px;
    padding: 50px 40px;
    box-shadow: 0 12px 25px -20px ${colors.opacityShadow};
    color: ${colors.grey500};
    line-height: 1.75em;
    box-sizing: border-box;

    p {
        margin: 0;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 50px 20px;
        margin-bottom: 40px;
    }
`;

interface ContainerProps {
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
}

export const PaddingContainer = styled.div<ContainerProps>`
    ${({ marginTop }) => !!marginTop && `margin-top:${marginTop}px;`}
    ${({ marginBottom }) =>
        !!marginBottom && `margin-bottom:${marginBottom}px;`}
  ${({ marginLeft }) => !!marginLeft && `margin-left:${marginLeft}px;`}
  ${({ marginRight }) => !!marginRight && `margin-right:${marginRight}px;`}
`;
