import StaticUrl from "libs/urls";

export const mainHeaderList = [
    {
        title: "홈",
        url: StaticUrl.Home,
    },
    {
        title: "콘텐츠",
        url: StaticUrl.Content,
    },
    {
        title: "쇼핑",
        url: StaticUrl.Shopping,
    },
    {
        title: "커뮤니티",
        url: StaticUrl.Community,
    },
    {
        title: "툴즈",
        url: StaticUrl.Tools,
    },
    {
        title: "크리에이터",
        url: StaticUrl.Creator,
    },
];

export const drawerMenuList = [
    {
        title: "콘텐츠",
        isDirect: false,
        subMenu: [
            {
                subTitle: "콘텐츠 탐색",
                subUrl: StaticUrl.Content,
                subExternal: false,
            },
            {
                subTitle: "지식 정보 콘텐츠",
                subUrl: StaticUrl.bemypetlife,
                subExternal: true,
            },
            {
                subTitle: "크리에이터 콘텐츠",
                subUrl: StaticUrl.bemypetcreators,
                subExternal: true,
            },
        ],
    },
    {
        title: "쇼핑",
        isDirect: false,
        subMenu: [
            {
                subTitle: "제품 탐색",
                subUrl: StaticUrl.Shopping,
                subExternal: false,
            },
            {
                subTitle: "비마이펫 스토어",
                subUrl: StaticUrl.bemypetstore,
                subExternal: true,
            },
            {
                subTitle: "동물 등록 & 외장칩",
                subUrl: StaticUrl.bemypetcomebackhome,
                subExternal: true,
            },
        ],
    },
    {
        title: "커뮤니티",
        url: StaticUrl.Community,
        isDirect: true,
    },
    {
        title: "툴즈",
        url: StaticUrl.Tools,
        isDirect: true,
    },
    {
        title: "크리에이터 서비스",
        url: StaticUrl.Creator,
        isDirect: true,
    },
];
