import { adoptOgImage, favoriteSamsekLili, toggleIcon } from "assets";
import axios from "axios";
import Layout from "components/Layout";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userTokenState } from "states";
import styled from "styled-components";
import Pagination from "insuranceComponents/insurances/Pagination";
import AdoptSelectCard from "adoptComponents/favorite/AdoptSelectCard";
import AdoptConfirmDeleteAll from "adoptComponents/favorite/AdoptConfirmDeleteAll";
import AdoptFavoriteNoResults from "adoptComponents/favorite/AdoptFavoriteNoResults";
import { useMediaQuery } from "react-responsive";
import NotFound from "pages/notfound/NotFound";

const AdoptFavorite = () => {
    const [favoriteList, setFavoriteList] = useState<any>([]);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [editSelectList, setEditSelectList] = useState<Set<number>>(new Set());
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isAllDeleted, setIsAllDeleted] = useState<boolean>(false);

    const userToken = useRecoilValue(userTokenState);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [adoptTotalCount, setAdoptTotalCount] = useState<number>(-1);
    const pageSize = 12;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const getFavoriteList = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}pet-adoption/favorite/?offset=${
                    (currentPage - 1) * pageSize
                }&size=${pageSize}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then(res => {
                // console.log(res.data);
                setFavoriteList(res.data.results);
                setAdoptTotalCount(res.data.count);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleDeleteAll = () => {
        setShowConfirmModal(true);
    };

    const handleDeleteSome = async () => {
        await axios
            .delete(`${process.env.REACT_APP_API_URL_DEV}pet-adoption/favorite/delete/`, {
                data: { deleted_ids: Array.from(editSelectList) },
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then(res => {
                // console.log(res.data);
                const temp = currentPage;
                setCurrentPage(1);
                setEditSelectList(new Set());
                if (temp === 1) getFavoriteList();
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getFavoriteList();
        // eslint-disable-next-line
    }, [currentPage, isAllDeleted, userToken]);

    useEffect(() => {
        setEditSelectList(new Set());
    }, [editMode]);

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <AdoptFavoritePageContainer isLoading={isLoading} style={{ display: "none" }}>
            <Layout
                header={{
                    title: "유기동물 입양 - 유기견 & 유기묘 입양",
                    description:
                        "반려동물 분양. 보호자를 찾는 유기동물들을 입양하세요. 가족을 기다리는 반려견 & 반려묘를 유기동물 보호센터에서 입양하세요",
                    ogImage: adoptOgImage,
                    ogUrl: "/adoption/favorite",
                }}
            >
                {userToken === null || userToken === "" ? (
                    <NotFound />
                ) : (
                    <>
                        {adoptTotalCount !== 0 ? (
                            <>
                                {adoptTotalCount !== -1 && (
                                    <AdoptFavoriteContainer>
                                        <AdoptFavoriteTitle>나의 관심동물</AdoptFavoriteTitle>
                                        <FavoriteDescriptionContainer>
                                            <DescriptionImage
                                                src={favoriteSamsekLili}
                                                alt="삼색 리리"
                                            />
                                            <DescriptionText>
                                                내가 관심깊게 지켜본 아이들이에요!{" "}
                                                {isMobile && <br />}
                                                관심동물에 추가한 아이들 중 {isMobile && <br />}더
                                                이상 입양이 불가한 아이들은 조회가 어려울 수
                                                있습니다.
                                            </DescriptionText>
                                        </FavoriteDescriptionContainer>
                                        <FavoriteButtonContainer>
                                            <FavoriteButtonLeft>
                                                <ToggleButtonContainer
                                                    editMode={editMode}
                                                    onClick={() => setEditMode(prev => !prev)}
                                                >
                                                    <img src={toggleIcon} alt="toggle button" />
                                                </ToggleButtonContainer>
                                                <ToggleButtonDescription editMode={editMode}>
                                                    {editMode
                                                        ? "편집 모드입니다. 삭제하고 싶은 동물을 선택해주세요."
                                                        : "버튼을 눌러 편집 모드를 켤 수 있습니다."}
                                                </ToggleButtonDescription>
                                            </FavoriteButtonLeft>
                                            {editMode && (
                                                <FavoriteButtonRight>
                                                    <DeleteAllButton onClick={handleDeleteAll}>
                                                        전체 삭제
                                                    </DeleteAllButton>
                                                    <DeleteSomeButton
                                                        disabled={editSelectList.size === 0}
                                                        onClick={handleDeleteSome}
                                                    >
                                                        선택 삭제
                                                    </DeleteSomeButton>
                                                    {showConfirmModal && (
                                                        <AdoptConfirmDeleteAll
                                                            setShowConfirmModal={
                                                                setShowConfirmModal
                                                            }
                                                            setIsAllDeleted={setIsAllDeleted}
                                                            setAdoptTotalCount={setAdoptTotalCount}
                                                        />
                                                    )}
                                                </FavoriteButtonRight>
                                            )}
                                        </FavoriteButtonContainer>
                                        <AdoptListContainer>
                                            {favoriteList?.map((data: any) => (
                                                <AdoptSelectCard
                                                    key={data.desertion_no}
                                                    adoptData={data}
                                                    isEditMode={editMode}
                                                    editSelectList={editSelectList}
                                                    setEditSelectList={setEditSelectList}
                                                />
                                            ))}
                                        </AdoptListContainer>
                                        <PaginationContainer>
                                            <Pagination
                                                totalCount={adoptTotalCount}
                                                pageSize={pageSize}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        </PaginationContainer>
                                    </AdoptFavoriteContainer>
                                )}
                            </>
                        ) : (
                            <AdoptFavoriteNoResults props="adoption" />
                        )}
                    </>
                )}
            </Layout>
        </AdoptFavoritePageContainer>
    );
};

export default AdoptFavorite;

const AdoptFavoritePageContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const AdoptFavoriteContainer = styled.div`
    max-width: 64rem;
    margin: 0 auto;
`;

const AdoptFavoriteTitle = styled.div`
    padding: 3rem 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 600;
    text-align: center;

    @media only screen and (max-width: 768px) {
        padding: 4rem 0 1.5rem;
        font-size: 2rem;
        line-height: 2.4rem;
    }
`;

const AdoptListContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2.5rem 2rem;
    width: 60rem;
    padding: 1.25rem 0.4rem;
    margin: 0 auto;

    @media only screen and (max-width: 926px) {
        gap: 2.5rem 1.3rem;
    }
    @media only screen and (max-width: 768px) {
        width: calc(100vw - 3rem);
        gap: 2rem 1rem;
        grid-template-columns: 1fr 1fr;
    }
`;

const FavoriteDescriptionContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 2.25rem;
    margin-bottom: 2.25rem;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin-left: 0;
        margin-bottom: 3rem;
        gap: 2.3rem;
        text-align: center;
    }
`;

const DescriptionImage = styled.img`
    width: 7.3rem;
    height: 2.5rem;
`;

const DescriptionText = styled.div`
    display: inline-block;
    padding: 0.75rem 1.6rem;
    margin-left: 1.25rem;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 400;

    border-radius: 25px;
    background-color: #fff5ce;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

    @media only screen and (max-width: 768px) {
        padding: 1rem 3rem;
        margin: 0;
        font-size: 1.1rem;
        line-height: 1.4rem;
    }
`;

const FavoriteButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    margin: 0 2rem 1.5rem;

    @media only screen and (max-width: 768px) {
        margin: 0 2rem 3.6rem;
    }
`;

const FavoriteButtonLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 0.7rem;

    @media only screen and (max-width: 768px) {
        position: relative;
    }
`;

const ToggleButtonDescription = styled.div<{ editMode?: boolean }>`
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;
    color: ${({ editMode }) => (editMode ? `#0C0713` : `#868688`)};

    @media only screen and (max-width: 768px) {
        position: absolute;
        bottom: -2.7rem;
        width: 25rem;

        font-size: 1.1rem;
        line-height: 1.3;
    }
`;

const FavoriteButtonRight = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
        font-size: 1.25rem;
        line-height: 1.6rem;
    }
`;

const DeleteAllButton = styled.div`
    padding: 0.5rem 1.2rem;
    border: 0.13rem solid #fcd11e;
    border-radius: 10px;
    cursor: pointer;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 768px) {
        border-radius: 5px;
        border: 2px solid #fcd11e;
        padding: 0.48rem 1rem;
    }
`;

const DeleteSomeButton = styled.div<{ disabled?: boolean }>`
    padding: 0.58rem 1.2rem;
    border-radius: 10px;
    background-color: #fcd11e;
    cursor: pointer;

    ${({ disabled }) =>
        disabled &&
        `pointer-events: none;
        `};

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 768px) {
        border-radius: 5px;
        padding: 0.6rem 0.9rem;
    }
`;

const ToggleButtonContainer = styled.div<{ editMode?: boolean }>`
    position: relative;
    width: 4rem;
    height: 1.75rem;
    border-radius: 20px;
    background-color: #e4e4e6;

    cursor: pointer;

    @media only screen and (max-width: 768px) {
        width: 5rem;
        height: 2.1rem;
    }

    img {
        position: absolute;
        top: 50%;
        bottom: 50%;
        left: 1rem;
        transform: translate(-50%, -50%);
        width: 1.2rem;
        height: 1.25rem;
        transition: 0.3s ease;

        ${({ editMode }) =>
            editMode &&
            `left: 3rem;
        `};

        @media only screen and (max-width: 768px) {
            width: 1.45rem;
            height: 1.5rem;
            left: 1.3rem;

            ${({ editMode }) =>
                editMode &&
                `left: 3.8rem;
        `};
        }
    }

    ${({ editMode }) =>
        editMode &&
        `background-color: #fcd11e;
        `};

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
`;

const PaginationContainer = styled.div`
    width: fit-content;
    margin: 50px auto 0;
`;
