import { 
  insuranceSearch, 
  favoriteInsurance, 
  foodKcal,
  adopt, 
  favoriteAnimal, 
  naming, 
  registerSearch, 
  mobileImg,
  recommendKcal,
  calcBMI,
  calcAge,
} from "assets";
import TypingText from "components/TypingText";
import React,{ useContext, useEffect, useState } from "react";
import styled from "styled-components";
import StaticUrl from "libs/urls";
import { LoginContext } from "Router";

// 툴즈 홈 모바일뷰
const MobileHomePage: React.FC = () => {
  const { isLoggedIn } = useContext(LoginContext);

  // 각 카드 하위 메뉴
  const MENU_LIST = [
    {
      title: '펫보험',
      infos: [
        {
          img: insuranceSearch,
          menu: '펫보험 찾기',
          url: StaticUrl.ToolsInsurances
        },
        {
          img: favoriteInsurance,
          menu: '관심 펫보험',
          url: isLoggedIn ? StaticUrl.FavoriteInsurance : `${process.env.REACT_APP_MAIN_URL_DEV
          }login?returnURL=${StaticUrl.FavoriteInsurance}`
        },
      ],
    },
    {
      title: '입양',
      infos: [
        {
          img: adopt,
          menu: '유기동물 입양',
          url: StaticUrl.ToolsAdopt
        },
        {
          img: naming,
          menu: '이름 짓기',
          url: StaticUrl.Naming
        },
        {
          img: registerSearch,
          menu: '동물등록 검색',
          url: StaticUrl.RegisterSearch
        },
        {
          img: favoriteAnimal,
          menu: '나의 관심동물',
          url: isLoggedIn ? StaticUrl.FavoriteAnimal : `${process.env.REACT_APP_MAIN_URL_DEV
          }login?returnURL=${StaticUrl.FavoriteAnimal}`
        },
      ],
    },
    {
      title: '건강 계산기',
      infos: [
        {
          img: foodKcal,
          menu: '사료 칼로리 계산기',
          url: StaticUrl.FoodCalc
        },
        {
          img: recommendKcal,
          menu: '권장 칼로리 계산기',
          url: StaticUrl.RecommendCalc
        },
        {
          img: calcBMI,
          menu: '비만도 계산기',
          url: StaticUrl.CalcBMI
        },
        {
          img: calcAge,
          menu: '나이 계산기',
          url: StaticUrl.CalcAge
        },
      ],
    },
  ]

  const [, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    window.onload = () => {
        setIsLoading(false);
    };

    const handleLoad = () => {
        setIsLoading(true);
    };

    if (document.readyState === "complete") {
        // 이미 로드된 상태일 경우
        setIsLoading(false);
    } else {
        // 로드되기 전인 경우
        document.addEventListener("load", handleLoad);
    }

    return () => {
        // 컴포넌트가 unmount 될 때 이벤트 제거
        document.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <Wrapper>
      <WrapperInfo>
        <InfoText>
          <TextBig>
            <KeyWord>
              <TypingText></TypingText>
            </KeyWord>
            <MainText>
              더 편한 <br />
              반려 라이프를 즐기세요
            </MainText>
          </TextBig>
          <TextSmall>
            우리 아이의 시간을 더 행복하게, <br /> 반려인의 삶을 더 편하게
          </TextSmall>
        </InfoText>
        <InfoImg src={mobileImg} />
      </WrapperInfo>
      <WrapperMenu>
      {MENU_LIST.map((menu) => (
        <MenuList key={menu.title}>
          <ListTitle>
            <TitleText>{menu.title}</TitleText>
            <TitleBorder></TitleBorder>
          </ListTitle>
          {menu.infos.map(info => (
            <ListTab key={info.url} href={info.url}>
              <TabImg src={info.img} />
              <TabText>{info.menu}</TabText>
            </ListTab>
          ))}
        </MenuList>
      ))}
      </WrapperMenu>
    </Wrapper>
  )
}

export default MobileHomePage

const Wrapper = styled.div`
  padding: 0px 16px;
`

const WrapperInfo = styled.div`
  width: 100%;
  margin-top: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-bottom: 85px;
`

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
`

const TextBig = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: black;
`

const KeyWord = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: #FCD11E;
  display: inline;
`

const MainText = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: black;
  display: inline;
`

const TextSmall = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #868688;
  padding-top: 10px;
`

const InfoImg = styled.img`
  width: 220px;
  height: 220px;
  position: absolute;
  top: -30px;
  right: 0px;
`

const WrapperMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 55px;
`

const MenuList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  gap: 14px;
`

const ListTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const TitleText = styled.div` 
  width: 100%;
  max-width: fit-content;
  font-size: 20px;
  color: black;
  font-weight: 600;
`

const TitleBorder = styled.div`
  width: 100%;
  max-width: 448px;
  margin-left: 6px;
  border-top: 2px solid #FCD11E;
  margin-top: 10px;
`

const ListTab = styled.a`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration-line: none;
`

const TabImg = styled.img`
  width: 24px;
  height: 24px;
`

const TabText = styled.div`
  width: 100%;
  font-size: 17px;
  color: black;
  padding-left: 9px;
`