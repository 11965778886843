import React from "react";
import styled from "styled-components";


interface FormAgreementDetailProps {
  label?: string;
}

export const FormAgreementDetail = ({
  label,
}: FormAgreementDetailProps) => {
  return (
    <FormAgreementDetailWrapper>
      {label}
    </FormAgreementDetailWrapper>
  );
};

const FormAgreementDetailWrapper = styled.div`
  width: fit-content;
  font-size: 11px;
  color: #868688;
  border-bottom: 1px solid #868688;
`;