import { toolsOgImage } from "assets"
import Layout from "components/Layout"
import React, { Fragment, useEffect, useState } from "react"
// import { useMediaQuery } from "react-responsive"
import styled from "styled-components"

const FosterDetail: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
//   const isMobile = useMediaQuery({
//     query: "(max-width:448px)",
// });

  useEffect(() => {
    window.onload = () => {
        setIsLoading(false);
    };

    const handleLoad = () => {
        setIsLoading(true);
    };

    if (document.readyState === "complete") {
        // 이미 로드된 상태일 경우
        setIsLoading(false);
    } else {
        // 로드되기 전인 경우
        document.addEventListener("load", handleLoad);
    }

    return () => {
        // 컴포넌트가 unmount 될 때 이벤트 제거
        document.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <Fragment>
      <MainContainer isLoading={isLoading} style={{ display: "none" }}>
        <Layout 
          header={{
              title: "비마이펫 :: 임시보호 상세 페이지",
              description: "비마이펫 :: 임시보호 상세 페이지",
              ogImage: toolsOgImage,
              ogUrl: "/foster/detail",
          }}
        >
          <Fragment>
            <div>fosterDetail</div>
          </Fragment>
        </Layout>
      </MainContainer>
    </Fragment>
  );
};

export default FosterDetail

const MainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;