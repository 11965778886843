import React from "react";
import styled from "styled-components";

const dataKeyList1 = ["등록번호", "개이름", "품종", "관할기관"];
const dataKeyList2 = ["RFID_CD", "성별", "중성화여부", "관할기관연락처"];

const RegistVerifyResult = ({ responseData }: any) => {
    const dummyData1: any = [];
    dataKeyList1.forEach((dataKey, index) => {
        dummyData1.push({ key: dataKey, value: responseData[index] });
    });
    const dummyData2: any = [];
    dataKeyList2.forEach((dataKey, index) => {
        dummyData2.push({ key: dataKey, value: responseData[index + 4] });
    });

    return (
        <>
            <RegistVerifyResultContainer>
                <ResultTableContainer>
                    <ResultTable>
                        {dummyData1.map((data: any, index: number) => (
                            <ResultRow key={data.key}>
                                <ResultKey index={index}>{data.key}</ResultKey>
                                <ResultValue index={index}>
                                    {data.value}
                                </ResultValue>
                            </ResultRow>
                        ))}
                    </ResultTable>
                    <ResultTable>
                        {dummyData2.map((data: any, index: number) => (
                            <ResultRow key={data.key}>
                                <ResultKey index={index}>{data.key}</ResultKey>
                                <ResultValue index={index}>
                                    {data.value}
                                </ResultValue>
                            </ResultRow>
                        ))}
                    </ResultTable>
                </ResultTableContainer>
                <ResultDescription>
                    관할지역은 동물등록대행업체의 소재지로 표기되며, 등록 효력은
                    전국적으로 동일합니다.
                </ResultDescription>
            </RegistVerifyResultContainer>
        </>
    );
};

export default RegistVerifyResult;

const RegistVerifyResultContainer = styled.div`
    margin: 0 60px;

    @media screen and (max-width: 768px) {
        margin: 0 30px;
    }
`;

const ResultTableContainer = styled.div`
    display: flex;
    border: 2px solid #ffeb99;
    border-right: none;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

const ResultTable = styled.div`
    width: 100%;
    /* border: 2px solid #ffeb99; */
    /* border-right: none; */
`;

const ResultRow = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
`;

const ResultKey = styled.div<{ index?: number }>`
    width: 30%;
    min-width: 111px;
    padding: 12px;
    background-color: #ffeb99;

    font-size: 16px;
    line-height: 19px;
    font-weight: 500;

    @media screen and (max-width: 768px) {
        padding: 12px;
        min-width: 100px;
    }
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }

    ${({ index }) => index === 0 && `padding-top: 22px`}
    ${({ index }) => index === 3 && `padding-bottom: 22px`}
`;

const ResultValue = styled.div<{ index?: number }>`
    width: 70%;
    padding: 12px 20px;
    background-color: white;

    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    text-align: left;

    @media screen and (max-width: 768px) {
        padding: 12px 16px;
    }
    @media screen and (max-width: 600px) {
        font-size: 13px;
    }

    ${({ index }) => index === 0 && `padding-top: 22px`}
    ${({ index }) => index === 3 && `padding-bottom: 22px`}
`;

const ResultDescription = styled.div`
    margin: 20px 0 90px;
    font-size: 15px;
    line-height: 19px;
    font-weight: 400;
    color: #868688;
    word-break: keep-all;

    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        margin: 12px 0 40px;
        font-size: 13px;
    }
`;
