import AdoptListCard from "adoptComponents/list/AdoptListCard";
import { adoptSelectedIcon, adoptUnselectedIcon } from "assets";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface SelectCardProps {
    adoptData: any;
    isEditMode: boolean;
    editSelectList: Set<number>;
    setEditSelectList: React.Dispatch<React.SetStateAction<Set<number>>>;
}

const AdoptSelectCard = ({
    adoptData,
    isEditMode,
    editSelectList,
    setEditSelectList,
}: SelectCardProps) => {
    const [selected, setSelected] = useState<boolean>(false);
    const [showCard, setShowCard] = useState<boolean>(adoptData.is_favorite);

    const handleSelectedList = (desertionNo: number, selected: boolean) => {
        if (selected) {
            setEditSelectList(
                (prevArr: any) => new Set([...prevArr, desertionNo])
            );
        } else if (!selected && editSelectList.has(desertionNo)) {
            setEditSelectList((prevArr: Set<number>) => {
                const newArr = new Set(prevArr);
                newArr.delete(desertionNo);
                return newArr;
            });
        }
    };

    const handleSelect = (desertionNo: number) => {
        const newSelected = !selected;
        setSelected(newSelected);
        handleSelectedList(desertionNo, newSelected);
    };

    useEffect(() => {
        if (!isEditMode) setSelected(false);
    }, [isEditMode]);

    if (!showCard) {
        return null;
    }

    return (
        <>
            <AdoptListSelect
                onClick={() => handleSelect(adoptData.desertion_no)}
            >
                <AdoptListCard
                    adoptData={adoptData}
                    isFavoriteCard={true}
                    setShowCard={setShowCard}
                />
                {isEditMode && (
                    <AdoptEditDim>
                        <AdoptSelectIcon
                            src={
                                selected
                                    ? adoptSelectedIcon
                                    : adoptUnselectedIcon
                            }
                            alt="select"
                        ></AdoptSelectIcon>
                    </AdoptEditDim>
                )}
            </AdoptListSelect>
        </>
    );
};

export default AdoptSelectCard;

const AdoptListSelect = styled.div`
    position: relative;
    cursor: pointer;
`;

const AdoptEditDim = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: rgba(24, 14, 37, 0.35);

    transition: all 0.3s ease-in-out;

    @media only screen and (max-width: 768px) {
        left: 0.5rem;
        width: calc((100vw - 6rem) / 2);
    }
`;

const AdoptSelectIcon = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    margin: 1rem 0.75rem;
`;
