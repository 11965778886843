import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../../assets/InsuranceImages/favorite/check_circle.svg";
import { ReactComponent as CheckOutlineIcon } from "../../../assets/InsuranceImages/favorite/check_circle_outline.svg";
import { ReactComponent as CloseRoundedIcon } from "../../../assets/DrawerImages/close.svg";
import { ReactComponent as InfoIcon } from "../../../assets/InsuranceImages/detail/info.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { userTokenState } from "states";

interface SelectCardProps {
    insuranceData: any;
    editSelectList: Set<number>;
    setEditSelectList: React.Dispatch<React.SetStateAction<Set<number>>>;
    setClosed: React.Dispatch<React.SetStateAction<boolean>>;
    setShowAlertDiff: React.Dispatch<React.SetStateAction<boolean>>;
    setShowAlertOver: React.Dispatch<React.SetStateAction<boolean>>;
}

const InsuranceFavoriteCard = ({
    insuranceData,
    editSelectList,
    setEditSelectList,
    setClosed,
    setShowAlertDiff,
    setShowAlertOver,
}: SelectCardProps) => {
    const navigate = useNavigate();
    const userToken = useRecoilValue(userTokenState);
    const [selected, setSelected] = useState<boolean>(false);

    const handleSelectedList = async (id: number, selected: boolean) => {
        if (selected) {
            if (editSelectList.size >= 2) {
                setShowAlertOver(true);
                setSelected(false);
            } else {
                if (editSelectList.size === 1) {
                    const compareRes = await axios.get(
                        `${process.env.REACT_APP_API_URL_DEV}insurance/insurances/${
                            Array.from(editSelectList)[0]
                        }/`,
                        {
                            headers: {
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (insuranceData?.animal_type !== compareRes.data.animal_type) {
                        setShowAlertDiff(true);
                        setSelected(false);
                    } else {
                        setEditSelectList((prevArr: any) => new Set([...prevArr, id]));
                    }
                } else {
                    setEditSelectList((prevArr: any) => new Set([...prevArr, id]));
                }
            }
        } else if (!selected && editSelectList.has(id)) {
            setEditSelectList((prevArr: Set<number>) => {
                const newArr = new Set(prevArr);
                newArr.delete(id);
                return newArr;
            });
        }
    };

    const handleSelect = (id: number) => {
        const newSelected = !selected;
        setSelected(newSelected);
        handleSelectedList(id, newSelected);
    };

    const handleDeleteCard = () => {
        axios
            .put(
                `${process.env.REACT_APP_API_URL_DEV}insurance/favorite/${insuranceData.id}/`,
                { is_favorite: false },
                {
                    headers: {
                        Authorization: userToken ? `Bearer ${userToken}` : ``,
                    },
                }
            )
            .then(res => {
                // console.log(res.data);
                setClosed(true);
                if (editSelectList.has(insuranceData.id)) {
                    setEditSelectList((prevArr: Set<number>) => {
                        const newArr = new Set(prevArr);
                        newArr.delete(insuranceData.id);
                        return newArr;
                    });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <>
            <InsuranceFavoriteCardContainer>
                <CloseIconWrapper onClick={handleDeleteCard}>
                    <CloseRoundedIcon className="icon" />
                </CloseIconWrapper>
                <InsuranceCompanyLogo
                    src={insuranceData?.insurance_company?.landscape_logo}
                    alt={insuranceData?.insurance_company?.name}
                ></InsuranceCompanyLogo>
                <InsuranceName>{insuranceData?.name}</InsuranceName>
                <InsuranceImage>
                    <img src={insuranceData?.thumbnail} alt={insuranceData.name} />{" "}
                </InsuranceImage>
                <DetailContent>
                    <ContentTitleWrapper>
                        <ContentTitle>
                            가입 가능 나이 <InfoIcon className="infoIcon" />
                        </ContentTitle>
                        <AnimalType>
                            {insuranceData?.animal_type === "DOG" ? "강아지" : "고양이"}
                        </AnimalType>
                    </ContentTitleWrapper>
                    <ContentText>
                        만 {insuranceData?.join_age_limit}
                        세까지
                    </ContentText>
                    <ContentTitleWrapper>
                        <ContentTitle>상품 요약</ContentTitle>
                    </ContentTitleWrapper>
                    <ContentText
                        dangerouslySetInnerHTML={{
                            __html: insuranceData?.description,
                        }}
                    />
                    <ContentTitleWrapper>
                        <ContentTitle>비마이펫 코멘트</ContentTitle>
                    </ContentTitleWrapper>
                    <ContentText
                        dangerouslySetInnerHTML={{
                            __html: insuranceData?.bemypet_summary,
                        }}
                        last={true}
                    />
                </DetailContent>
                <ButtonContainer>
                    <CompareSelect onClick={() => handleSelect(insuranceData.id)}>
                        {selected ? (
                            <CheckIcon className="icon" />
                        ) : (
                            <CheckOutlineIcon className="icon" />
                        )}
                        비교 담기
                    </CompareSelect>
                    <SeeMore onClick={() => navigate(`/insurance/${insuranceData?.id}`)}>
                        자세히 보기
                    </SeeMore>
                </ButtonContainer>
            </InsuranceFavoriteCardContainer>
        </>
    );
};

export default InsuranceFavoriteCard;

const InsuranceFavoriteCardContainer = styled.div`
    position: relative;
    width: 240px;
    margin: 10px 0 70px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

    @media only screen and (max-width: 768px) {
        width: calc(50vw - 50px);
        height: 48rem;
        padding: 0 15px;
    }
    @media only screen and (max-width: 448px) {
        width: calc(50vw - 44px);
        height: 49rem;
        padding: 0 12px;
    }
    @media only screen and (max-width: 390px) {
        height: 50rem;
    }
`;

const CloseIconWrapper = styled.button`
    position: absolute;
    top: 10px;
    right: 8px;
    width: 18px;
    height: 18px;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    color: #868688;

    cursor: pointer;

    .icon {
        width: 18px;
        height: 18px;
    }
`;

const InsuranceCompanyLogo = styled.img`
    display: block;
    width: 175px;
    height: 50px;
    margin: 12px auto 0;

    @media only screen and (max-width: 768px) {
        width: 134px;
        height: 39px;
    }
    @media only screen and (max-width: 448px) {
        width: 96px;
        height: 28px;
    }
`;

const InsuranceName = styled.div`
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-align: center;
    word-break: keep-all;

    @media only screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        font-size: 1.3rem;
        line-height: 1.6rem;
    }
`;

const InsuranceImage = styled.div`
    width: 205px;
    height: 205px;
    margin: 10px auto;
    border-radius: 10px;
    background-color: #f6f6f9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);

    img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        height: fit-content;

        img {
            display: block;
        }
    }
    @media only screen and (max-width: 448px) {
        margin-top: 5px;
    }
`;

const DetailContent = styled.div`
    padding: 14px 16px 2px;

    @media only screen and (max-width: 768px) {
        padding: 10px 3px;
    }
`;

const ContentTitleWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 5px;

    @media only screen and (max-width: 768px) {
        padding: 0 0 3px;
    }
`;

const ContentTitle = styled.div`
    display: flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    align-items: center;

    .infoIcon {
        width: 13px;
        height: 13px;
        margin-left: 2px;
        margin-bottom: 2px;
    }

    @media only screen and (max-width: 768px) {
        font-size: 1.1rem;
        line-height: 1.5rem;
        letter-spacing: 0.1px;
    }
`;

const AnimalType = styled.div`
    display: inline-block;
    padding: 0 10px;
    margin: 0 8px;
    font-size: 11px;
    line-height: 22px;
    font-weight: 500;

    border-radius: 25px;
    background-color: #ffe4de;

    @media only screen and (max-width: 768px) {
        font-size: 9px;
        line-height: 17px;
        margin: 0;
        padding: 0 8px;
    }
`;

const ContentText = styled.div<{ last?: boolean }>`
    margin-bottom: 2rem;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    color: #49454f;

    p {
        margin: 0;
    }

    ul {
        margin: 0;
        padding: 0 0 0 0.5rem;
    }

    li {
        padding: 3px 0;
    }

    ${({ last }) => last && `margin-bottom: 0;`};

    @media only screen and (max-width: 640px) {
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0px;
    }
    @media only screen and (max-width: 448px) {
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0px;
    }

    /* @media only screen and (min-width: 832px) and (max-width: 896px) {
        margin-bottom: 30px;
    }
    @media only screen and (min-width: 768px) and (max-width: 832px) {
        margin-bottom: 20px;
    }
    @media only screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
    @media only screen and (max-width: 360px) {
        font-size: 13px;
        line-height: 14px;
        letter-spacing: 0.175px;
    } */
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    margin: 32px auto 20px;
    align-items: center;
    justify-content: center;

    font-size: 11px;
    line-height: 13px;
    font-weight: 600;

    @media only screen and (max-width: 768px) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 22px;
        margin: 0;
    }
    @media only screen and (max-width: 640px) {
        bottom: 18px;
    }
    @media only screen and (max-width: 512px) {
        bottom: 16px;
        gap: 7px;
        font-size: 9px;
        line-height: 11px;
    }
`;

const CompareSelect = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 4.5px 9.5px;
    border-radius: 25px;
    border: 1px solid #fcd11e;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

    cursor: pointer;

    .icon {
        width: 15px;
        height: 15px;
        margin-bottom: 2px;
    }

    :hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset;
    }

    @media only screen and (max-width: 640px) {
        padding: 4.5px 8px;
        .icon {
            width: 13px;
            height: 13px;
            margin-bottom: 1px;
        }
    }
    @media only screen and (max-width: 448px) {
        padding: 3.2px 7px;
        .icon {
            width: 12px;
            height: 12px;
        }
    }
`;

const SeeMore = styled.div`
    padding: 7.5px 14px;
    border-radius: 25px;
    background-color: #fcd11e;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

    cursor: pointer;

    :hover {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset;
    }

    @media only screen and (max-width: 640px) {
        padding: 6px 12px;
    }
    @media only screen and (max-width: 448px) {
        padding: 5px 10px;
    }
`;
