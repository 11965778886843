import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "theme";

interface TabLinkProps {
    selected?: boolean;
}

interface TabProps {
    url: string;
    label?: string;
    color?: string;
    selected?: boolean;
}

interface TabsProps {
    children: React.ReactNode;
}

const Tabs: React.FC<TabsProps> = ({ children }) => {
    return <div>{children}</div>;
};

const Tab = ({ url, label, selected }: TabProps) => {
    return (
        <TabLink to={`${url}`} selected={selected}>
            {label}
        </TabLink>
    );
};

export const TabLink = styled(Link)<TabLinkProps>`
    position: relative;
    z-index: 1;
    height: 30px;
    padding: 0 25px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    text-decoration: none;
    color: ${colors.grey500};
    ${({ selected }) =>
        !!selected &&
        `color: ${colors.grey700};
  background-color: ${colors.grey100};
  font-weight: bold;

  `}

    @media only screen and (max-width: 768px) {
        padding: 0 12px;
        font-size: 12px;
        font-weight: 400;

        ${({ selected }) =>
            !!selected &&
            `font-weight: bold;
    `}
    }

    @media only screen and (max-width: 360px) {
        padding: 0 10px;
        line-height: 13px;
    }
`;

export const SelectedCircle = styled.span`
    z-index: -1;
    position: absolute;
    top: -1px;
    right: -3px;
    width: 12px;
    height: 12px;
    background-color: ${colors.primary100};
    border-radius: 50%;
`;

export { Tabs, Tab };
