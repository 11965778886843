import React, { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "components/Layout";
import NamingMainComponent from "namingComponents/NamingMainComponent";
import NamingTwoTypeForm from "namingComponents/NamingTwoTypeForm";
import {
    petColorData,
    petGenderData,
    petHairData,
    petPersonalityData,
    petTypeData,
} from "./NamingSelectData";
import NamingFourTypeForm from "namingComponents/NamingFourTypeForm";
import NamingDescriptionForm from "namingComponents/NamingExplanationForm";
import { namingThumbnail } from "assets";

export const NameContext = createContext({
    dataList: [],
    updateList: (newString: string) => {},
});

const NamingMain = () => {
    const { dataList } = useContext(NameContext);
    sessionStorage.clear();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const componentList = [
        {
            id: 0,
            component: <NamingMainComponent />,
        },
        {
            id: 1,
            component: <NamingTwoTypeForm petSelectData={petTypeData} />,
        },
        {
            id: 2,
            component: <NamingTwoTypeForm petSelectData={petGenderData} />,
        },
        {
            id: 3,
            component: (
                <NamingFourTypeForm petSelectData={petPersonalityData} />
            ),
        },
        {
            id: 4,
            component: <NamingFourTypeForm petSelectData={petHairData} />,
        },
        {
            id: 5,
            component: <NamingFourTypeForm petSelectData={petColorData} />,
        },
        {
            id: 6,
            component: <NamingDescriptionForm />,
        },
    ];

    const currentComponent = componentList.find(
        componentItem => componentItem.id === dataList.length
    );

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    useEffect(() => {
        // console.log(dataList);
        window.scrollTo(0, 0);
    }, [dataList]);

    return (
        <NamingMainContainer isLoading={isLoading} style={{ display: "none" }}>
            <Layout
                header={{
                    title: "강아지, 고양이 이름 짓기 & 만들기",
                    description:
                        "강아지, 고양이 이름 작명소. 우리 집 강아지, 고양이의 특징에 맞는 이름을 지어 드립니다",
                    ogImage: namingThumbnail,
                    ogUrl: "/adoption/name-generator",
                }}
            >
                {currentComponent ? (
                    currentComponent.component
                ) : (
                    <NamingMainComponent />
                )}
            </Layout>
        </NamingMainContainer>
    );
};

export default NamingMain;

const NamingMainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;
