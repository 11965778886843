import React from "react";
import styled from "styled-components";


interface FormCancelButtonProps {
  label?: string;
}

export const FormCancelButton = ({
  label,
}: FormCancelButtonProps) => {
  return (
    <FormCancelButtonWrapper>
      {label}
    </FormCancelButtonWrapper>
  );
};

const FormCancelButtonWrapper = styled.div`
  width: 100%;
  max-width: 180px;
  height: 48px;
  background-color: #F6F6F9;
  font-size: 22px;
  font-weight: 600;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

`;