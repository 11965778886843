import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { ResultSection, ResultCard } from "components/ResultSection";
import { PostSection, PostCard } from "components/PostSection";
import { Heading2, Heading4, InlineColor } from "components/Typography";
import { colors } from "theme";
import { httpService } from "libs/services";
import { PostModel } from "libs/models";

import BmiCalculator from "./BmiCalculator";
import DisplayAds from "components/DisplayAds";

interface BmiCalculatorResultProps {
    form: BmiCalculator;
}

const BmiCalculatorResult = ({ form }: BmiCalculatorResultProps) => {
    const [contents, setContents] = useState<PostModel[]>([]);

    const getContents = useCallback(async () => {
        const keyword = `${form.animal}비만`;
        const posts = await httpService.getPosts({
            keyword,
            size: 4,
            search_type: "hashtag",
            ordering: "random",
        });
        setContents(posts);
    }, [form]);

    useEffect(() => {
        getContents();
    }, [getContents]);

    return (
        <>
            <ResultSection>
                <Heading2>
                    <InlineColor color={colors.blue100}>
                        비만도 계산
                    </InlineColor>{" "}
                    결과
                </Heading2>
                <ResultCard backgroundColor={colors.blue200}>
                    <Heading4>우리 아이는</Heading4>
                    <BMIResultText color={colors.blue100}>
                        {form.resultText}
                    </BMIResultText>
                </ResultCard>
            </ResultSection>
            <DisplayAds animalType={form.animal} />
            <PostSectionContainer>
                {contents.length > 0 && (
                    <PostSection
                        title={
                            <>
                                <InlineColor color={colors.primary100}>
                                    비마이펫{" "}
                                </InlineColor>
                                맞춤 콘텐츠
                            </>
                        }
                        backgroundImage={colors.blueGradient}
                    >
                        {contents.map((post: PostModel, index) => (
                            <PostCard key={index} post={post} />
                        ))}
                    </PostSection>
                )}
            </PostSectionContainer>
        </>
    );
};

const BMIResultText = styled(Heading2)`
    text-align: center;
    margin-top: 8px;
`;

const PostSectionContainer = styled.div`
    /* max-width: 1024px; */
    margin: 0 auto;
`;

export default BmiCalculatorResult;
