import { adoptNoResultsImage } from "assets";
import React from "react";
import styled from "styled-components";

const AdoptNoResults = () => {
    return (
        <>
            <AdoptNoResultsContainer>
                <NoResultsImage>
                    <img src={adoptNoResultsImage} alt="no results" />
                    <NoResultsText>
                        조건에 맞는 아이가 등록되지 않았어요.
                    </NoResultsText>
                </NoResultsImage>
            </AdoptNoResultsContainer>
        </>
    );
};

export default AdoptNoResults;

const AdoptNoResultsContainer = styled.div`
    margin: 2rem auto 10rem;
    text-align: center;
`;

const NoResultsImage = styled.div`
    position: relative;
    img {
        width: 23.3rem;
        height: 23.3rem;
    }
`;

const NoResultsText = styled.div`
    position: absolute;
    bottom: 0;
    width: 23.3rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
    text-align: center;
`;
