import React, { useContext } from "react";
import styled from "styled-components";
import {
    headIcon1,
    headIcon2,
    headIcon3,
    headIcon4,
    mainHeadCat,
    mainHeadDog,
    namingMainWeb,
    namingMainMobile,
} from "assets";
import { useMediaQuery } from "react-responsive";
import { NameContext } from "pages/naming/NamingMain";

const NamingMainComponent = () => {
    const isTablet = useMediaQuery({
        query: "(max-width:768px)",
    });
    const isMobile = useMediaQuery({
        query: "(max-width:600px)",
    });

    const handleStartButton = () => {
        updateList(" ");
    };

    const { updateList } = useContext(NameContext);

    return (
        <>
            <NamingHeadContainer>
                <NamingHead>
                    <ImageContainer>
                        <CatImage src={mainHeadCat} alt="cat" />
                        <DogImage src={mainHeadDog} alt="dog" />
                        <HeadIcon1 src={headIcon1} alt="icon" />
                        <HeadIcon2 src={headIcon2} alt="icon" />
                    </ImageContainer>
                    <TextContainer>
                        <NamingHeadTitle>
                            인공지능(AI)를 활용하여
                            <br />
                            반려동물 이름을 쉽고
                            <br />
                            빠르게 만들어 보세요!
                        </NamingHeadTitle>
                        <NamingHeadDescription1>
                            간단한 질문에 답변하고 나의 반려동물 특징을{" "}
                            {isTablet && <br />}담은 별명과 이름을{" "}
                            {!isTablet && <br />}
                            확인해보세요! {isTablet && <br />}창의적이고 재밌는
                            결과가 기다립니다.
                        </NamingHeadDescription1>
                        {!isMobile && (
                            <NamingHeadButton onClick={handleStartButton}>
                                start
                            </NamingHeadButton>
                        )}
                        <NamingHeadDescription2>
                            <strong>단 몇 분만에 가능합니다!</strong>
                            <br />내 반려동물의 이름은 무엇이 좋을까요?
                        </NamingHeadDescription2>
                        <HeadIcon3 src={headIcon3} alt="icon" />
                        <HeadIcon4 src={headIcon4} alt="icon" />
                    </TextContainer>
                </NamingHead>
                <NamingButtonContainer>
                    {isMobile && (
                        <NamingHeadButton onClick={handleStartButton}>
                            start
                        </NamingHeadButton>
                    )}
                </NamingButtonContainer>
            </NamingHeadContainer>
            <NamingBody>
                <NamingBodyTitle>
                    반려동물의 특성을
                    <br />
                    파악하여 생성되는 이름!
                </NamingBodyTitle>
                <NamingMainImage
                    src={isMobile ? namingMainMobile : namingMainWeb}
                    alt="이름짓기 메인 이미지"
                />
                <NamingStartButton onClick={handleStartButton}>
                    Start
                </NamingStartButton>
            </NamingBody>
        </>
    );
};

export default NamingMainComponent;

const NamingHeadContainer = styled.div`
    background-color: #fffdf4;
`;

const NamingHead = styled.div`
    display: flex;
`;

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
`;

const CatImage = styled.img`
    width: 211px;
    height: 257px;
    float: right;
    margin: 42px 162px 153px 0;
    border-radius: 28px;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 124.2px 49.5px rgba(255, 238, 128, 0.02),
        0px 69.3px 41.4px rgba(255, 238, 128, 0.08),
        0px 30.6px 30.6px rgba(255, 238, 128, 0.13),
        0px 8.1px 17.1px rgba(255, 238, 128, 0.15);

    @media only screen and (max-width: 832px) {
        margin: 25px 146px 177px 16px;
    }
    @media only screen and (max-width: 768px) {
        margin-top: 52px;
        margin-right: 112px;
        width: 164px;
        height: 200px;
    }
    @media only screen and (max-width: 600px) {
        margin: 65px 85px 105px 0;
        width: 106.5px;
        height: 133px;
    }
    @media only screen and (max-width: 448px) {
        margin: 65px 60px 105px 0;
    }
`;

const DogImage = styled.img`
    position: absolute;
    top: 185px;
    right: 58px;

    width: 143px;
    height: 202px;
    border-radius: 17px;
    border: 3px solid #ffffff;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 124.2px 49.5px rgba(255, 238, 128, 0.02),
        0px 69.3px 41.4px rgba(255, 238, 128, 0.08),
        0px 30.6px 30.6px rgba(255, 238, 128, 0.13),
        0px 8.1px 17.1px rgba(255, 238, 128, 0.15);

    @media only screen and (max-width: 768px) {
        top: 185px;
        right: 42px;

        width: 111px;
        height: 157px;
    }
    @media only screen and (max-width: 600px) {
        top: 156px;
        right: 36px;

        width: 72px;
        height: 104.5px;

        border: 2px solid #ffffff;
    }
    @media only screen and (max-width: 448px) {
        right: 11px;
    }
`;

const HeadIcon1 = styled.img`
    position: absolute;
    top: 366px;
    right: 262px;

    width: 21px;
    height: 22.5px;

    @media only screen and (max-width: 832px) {
        top: 349px;
    }
    @media only screen and (max-width: 768px) {
        top: 320px;
        right: 210px;
    }
    @media only screen and (max-width: 600px) {
        top: 224px;
        right: 154px;

        width: 15px;
        height: 16px;
    }
`;

const HeadIcon2 = styled.img`
    position: absolute;
    top: 167px;
    right: 7px;

    width: 20.5px;
    height: 22.5px;

    @media only screen and (max-width: 832px) {
        top: 150px;
    }
    @media only screen and (max-width: 600px) {
        top: 126px;
        right: 24px;

        width: 14px;
        height: 15.5px;
    }
`;

const TextContainer = styled.div`
    position: relative;
    width: 100%;
    padding: 46px 0 61px 4px;

    @media only screen and (max-width: 768px) {
        padding: 46px 0 61px;
    }
    @media only screen and (max-width: 600px) {
        padding: 56px 0 50px;
    }
`;

const NamingHeadTitle = styled.div`
    padding: 10px;
    margin-bottom: 21px;

    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    color: #090909;

    @media only screen and (max-width: 832px) {
        margin-bottom: 26px;

        font-size: 28px;
        line-height: 34px;
    }
    @media only screen and (max-width: 768px) {
        margin-bottom: 10px;

        font-size: 26px;
        line-height: 32px;
    }
    @media only screen and (max-width: 600px) {
        margin-bottom: 8px;

        font-size: 20px;
        line-height: 24px;
    }
    @media only screen and (max-width: 448px) {
        padding: 10px 5px;
        margin-bottom: 8px;

        font-size: 17px;
        line-height: 21px;
    }
`;

const NamingHeadDescription1 = styled.div`
    padding: 10px;
    margin-bottom: 17px;

    font-size: 11.5px;
    line-height: 17px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: #868688;

    @media only screen and (max-width: 600px) {
        margin-bottom: 8px;

        font-size: 11px;
        line-height: 15px;
        letter-spacing: 0.3px;
    }
    @media only screen and (max-width: 448px) {
        padding: 10px 5px;
        margin-bottom: 8px;
        font-size: 9px;
        line-height: 13px;
    }
`;

const NamingHeadButton = styled.div`
    display: inline-block;
    padding: 12px 24px;
    margin: 0 10px 25px;

    background-color: #fcd11e;
    border-radius: 5.76px;
    box-shadow: 0px 7.488px 14.976px -4.608px #d5eeff;

    font-size: 13px;
    line-height: 11px;
    font-weight: 600;
    color: #ffffff;

    cursor: pointer;

    :hover {
        box-shadow: 0px 7.488px 14.976px -4.608px #d5eeff,
            inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 600px) {
        margin: 0 auto;
        padding: 13px 25px;
    }
`;

const NamingHeadDescription2 = styled.div`
    padding: 10px;

    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: #090909;

    @media only screen and (max-width: 600px) {
        font-size: 11px;
        line-height: 15px;
        color: #868688;

        strong {
            color: #090909;
        }
    }
    @media only screen and (max-width: 448px) {
        padding: 10px 5px;

        font-size: 10px;
    }
`;

const HeadIcon3 = styled.img`
    position: absolute;
    top: 171px;
    left: 319px;

    width: 29px;
    height: 29px;

    @media only screen and (max-width: 832px) {
        top: 140px;
        left: 290px;
    }
    @media only screen and (max-width: 768px) {
        top: 158px;
        left: 250px;
    }
    @media only screen and (max-width: 600px) {
        top: 125px;
        left: 195px;

        width: 20px;
        height: 20px;
    }
    @media only screen and (max-width: 448px) {
        top: 125px;
        left: 154px;

        width: 18px;
        height: 18px;
    }
`;

const HeadIcon4 = styled.img`
    position: absolute;
    top: 253px;
    left: 282px;

    width: 113px;
    height: 113.5px;

    @media only screen and (max-width: 832px) {
        top: 248px;
        left: 248px;
    }

    @media only screen and (max-width: 768px) {
        top: 260px;
        left: 200px;

        width: 84px;
        height: 84px;
    }
    @media only screen and (max-width: 600px) {
        top: 50px;
        left: -116px;

        width: 50px;
        height: 50px;
    }
    @media only screen and (max-width: 448px) {
        top: 50px;
        left: -90px;

        width: 41px;
        height: 41px;
    }
`;

const NamingButtonContainer = styled.div`
    padding-bottom: 50px;
    text-align: center;
`;

const NamingBody = styled.div`
    display: flex;
    flex-direction: column;

    background: radial-gradient(
            53.32% 53.32% at 50% 54.44%,
            rgba(255, 224, 147, 0.24) 0%,
            rgba(255, 255, 255, 0) 100%
        ),
        #ffffff;
`;

const NamingBodyTitle = styled.div`
    width: 657px;
    padding: 10px;
    margin: 65px auto 78px;

    font-size: 36px;
    line-height: 43px;
    font-weight: 500;
    color: #090909;
    text-align: left;

    @media only screen and (max-width: 768px) {
        width: 475px;
        font-size: 28px;
        line-height: 34px;
    }
    @media only screen and (max-width: 600px) {
        width: 301px;
        font-size: 20px;
        line-height: 31px;
    }
`;

const NamingMainImage = styled.img`
    width: 657px;
    height: 415.86px;
    margin: 0 auto;

    @media only screen and (max-width: 768px) {
        width: 475px;
        height: 299.5px;
    }
    @media only screen and (max-width: 600px) {
        width: 301px;
        height: 254.5px;
    }
`;

const NamingStartButton = styled.div`
    display: inline-block;
    padding: 21px 125px;
    margin: 65px auto 85px;

    background-color: #fcd11e;
    border-radius: 50px;
    box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68);

    font-size: 26px;
    line-height: 18px;
    font-weight: 700;
    color: #090909;

    cursor: pointer;

    :hover {
        box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68),
            inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 768px) {
        padding: 15px 90px;
        font-size: 18px;
        line-height: 13px;
    }
`;
