import { AnimalKind } from "libs/models";

export interface BmiCalculatorData {
  animal?: AnimalKind;
  bmiLevel?: number;
}

// models
export default class BmiCalculator implements BmiCalculatorData {
  constructor(defaultValues?: BmiCalculatorData) {
    if (!!defaultValues) {
      this.update(defaultValues);
    }
  }

  private fields: { [key: string]: any } = {
    animal: null,
    bmiLevel: null,
  };

  public errors: { [key: string]: any } = {
    animal: null,
    bmiLevel: null,
  };

  get animal(): AnimalKind | undefined {
    return this.fields.animal;
  }

  get isDog(): boolean {
    return !!this.fields.animal && this.fields.animal === AnimalKind.Dog;
  }

  get isCat(): boolean {
    return !!this.fields.animal && this.fields.animal === AnimalKind.Cat;
  }

  get bmiLevel(): number | undefined {
    return this.fields.bmiLevel;
  }

  get resultText(): string {
    switch (this.bmiLevel) {
      case 0:
        return "훨씬 더 많이 밥을 줘도 괜찮아요";
      case 1:
        return "조금 더 많이 밥을 줘도 괜찮아요.";
      case 2:
        return "적정 체중이네요. 아주 좋아요!";
      case 3:
        return "살짝 비만이 걱정됩니다. 관리해주세요.";
      case 4:
        return `비만이 걱정됩니다. 비마이펫 라이프에서 "${this.animal} 비만"을 검색해보세요.`;
    }
    return "";
  }

  public async validate(): Promise<boolean> {
    this.errors = {};

    if (!this.animal) {
      this.errors["animal"] = "값을 입력하거나 선택해주셔야 합니다.";
    }

    if (this.bmiLevel === null) {
      this.errors["bmiLevel"] = "값을 입력하거나 선택해주셔야 합니다.";
    }

    return this.isValid;
  }

  get isValid(): boolean {
    return Object.keys(this.errors).filter((field) => !!field).length === 0;
  }

  public update(changes: any): void {
    for (const key of Object.keys(changes)) {
      if (this.fields[key] === undefined) {
        throw new Error(`Cannot find the key in the form: ${key}`);
      }
      this.fields[key] = changes[key];
    }
  }
}
