import React, { useContext, useState } from "react";
import styled from "styled-components";
import { colors } from "theme";
import Logo from "components/icons/LogoW";
import StaticUrl from "libs/urls";
import { ReactComponent as CloseRoundedIcon } from "../assets/DrawerImages/close.svg";
import { ReactComponent as NewWindowIcon } from "../assets/DrawerImages/NEW_window.svg";
import { ReactComponent as MoreMenuIcon } from "../assets/DrawerImages/more_menu.svg";
import { useMediaQuery } from "react-responsive";
import { LoginContext } from "Router";
import { useLocation } from "react-router-dom";

interface DrawerProps {
    isOpen?: boolean;
    onClose?: () => void;
    children: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose, children }) => {
    const pathName = useLocation().pathname;

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const { isLoggedIn } = useContext(LoginContext);

    return (
        <SideNav isOpen={isOpen}>
            <SideLogo>
                <a href={StaticUrl.Home}>
                    <Logo width={74} height={46} />
                </a>
            </SideLogo>
            <CloseIconWrapper
                id="closeDrawer"
                aria-label="closeDrawer"
                onClick={onClose}
            >
                <CloseRoundedIcon />
            </CloseIconWrapper>
            {isMobile && (
                <MobileButtonContainer>
                    <MobileButton
                        href={
                            isLoggedIn
                                ? `${process.env.REACT_APP_MAIN_URL_DEV}mypage`
                                : `${
                                      process.env.REACT_APP_MAIN_URL_DEV
                                  }login?returnURL=${
                                      process.env.REACT_APP_DOMAIN_URL_DEV
                                  }${pathName.substring(1)}`
                        }
                    >
                        {isLoggedIn ? "마이페이지" : "로그인"}
                    </MobileButton>
                    <MobileButton href="https://bemypet.kr/mobilewrite">
                        글쓰기
                    </MobileButton>
                </MobileButtonContainer>
            )}
            <DrawerList>{children}</DrawerList>
        </SideNav>
    );
};

const SideNav = styled.nav<DrawerProps>`
    position: fixed;
    right: 0;
    top: ${({ isOpen }) => (isOpen ? `0` : `-1800px`)};
    opacity: ${({ isOpen }) => (isOpen ? `1` : `0`)};
    border-left: ${({ isOpen }) => (isOpen ? `solid 1px #e4e4e6` : `none`)};
    height: 100%;
    width: 240px;
    padding: 164px 50px 164px 30px;
    z-index: 999;

    background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 24.48%,
            rgba(252, 209, 30, 0.2) 100%
        ),
        #ffffff;
    transition: all 0.8s;
    overflow: scroll;

    @media only screen and (max-width: 768px) {
        width: calc(100vw - 80px);
        padding: 100px 50px 100px 30px;
    }
`;

const SideLogo = styled.div`
    display: none;

    @media only screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 10px;
        left: 5%;
    }
`;

const MobileButtonContainer = styled.div`
    display: flex;
    gap: 20px;
`;

const MobileButton = styled.a`
    display: inline-block;
    padding: 9px 30px;
    margin-bottom: 40px;
    border-radius: 21px;
    border: none;
    color: #000;
    background-color: #fcd11e;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
`;

const DrawerList = styled.ul`
    padding: 0;
    margin: 0;
`;

export interface DrawerListItemProps {
    title: string;
    url?: string;
    isDirect?: boolean;
    subMenu?: {
        subTitle: string;
        subUrl: string;
        subExternal: boolean;
    }[];
}

export const DrawerListItem = ({
    title,
    url,
    isDirect,
    subMenu,
}: DrawerListItemProps) => {
    const [isSelected, setIsSelected] = useState<boolean>(false);

    const handleClick = () => {
        setIsSelected(prev => !prev);
    };
    return (
        <DrawerListItemWrapper
            selected={isSelected}
            isDirect={isDirect}
            onClick={handleClick}
        >
            <DrawerContainer selected={isSelected}>
                <DrawerTitleContainer selected={isSelected}>
                    {isDirect ? (
                        <DrawerLink href={url}>{title}</DrawerLink>
                    ) : (
                        <>
                            <DrawerTitle>{title}</DrawerTitle>
                            <MoreMenuIcon className="moreMenuIcon" />
                        </>
                    )}
                </DrawerTitleContainer>
                {!isDirect ? (
                    <DrawerSubContainer selected={isSelected}>
                        {subMenu?.map(menu => (
                            <DrawerSubMenu
                                key={menu.subTitle}
                                href={menu.subUrl}
                                target={menu.subExternal ? "_blank" : "_self"}
                                rel={
                                    menu.subExternal
                                        ? "noopener noreferrer"
                                        : ""
                                }
                            >
                                <DrawerSubTitle>{menu.subTitle}</DrawerSubTitle>
                                {menu.subExternal ? <NewWindowIcon /> : <></>}
                            </DrawerSubMenu>
                        ))}
                    </DrawerSubContainer>
                ) : (
                    <></>
                )}
            </DrawerContainer>
        </DrawerListItemWrapper>
    );
};

const DrawerListItemWrapper = styled.li<{
    selected?: boolean;
    isDirect?: boolean;
}>`
    height: 65px;
    list-style: none;
    position: relative;
    align-items: center;
    margin-bottom: 12px;
    z-index: 1;
    transition: height 0.8s ease;

    ${({ selected, isDirect }) =>
        isDirect === false
            ? selected === true
                ? `
    font-weight: bold;
    color: ${colors.grey700};
    height: 166px;
    transition: height 0.8s ease;
  `
                : ``
            : ``}
`;

const DrawerContainer = styled.div<{ selected?: boolean }>`
    height: 65px;
    transition: height 0.8s ease;
    overflow: hidden;

    ${({ selected }) =>
        selected &&
        `
        height: 215px;
        // transition: height 0.8s ease;
  `}
`;

const DrawerSubContainer = styled.div<{ selected?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 0;
    margin-top: 12px;
    overflow: hidden;
    transition: height 0.8s ease;
    padding: 10px;

    ${({ selected }) =>
        selected &&
        `
        height: 90px;
        transition: height 0.8s ease;
  `}
`;

const DrawerSubTitle = styled.div`
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    padding-right: 4px;
`;

const DrawerSubMenu = styled.a`
    display: flex;
    text-decoration: none;
    color: #000000;
    cursor: pointer;

    :hover ${DrawerSubTitle} {
        font-weight: 500;
    }
`;

const DrawerTitleContainer = styled.div<{ selected?: boolean }>`
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    padding: 10px;
    cursor: pointer;
    align-items: center;

    border-bottom: 1px solid #fff5ce;

    .moreMenuIcon {
        ${({ selected }) =>
            selected &&
            `
            transform: scaleY(-1); transition: .3s; 
  `}
        transition: .3s;
    }
`;

const DrawerTitle = styled.div`
    :hover {
        color: #fcd11e;
    }
`;

const DrawerLink = styled.a`
    text-decoration: none;
    color: #000000;

    :hover {
        color: #fcd11e;
    }
`;

const CloseIconWrapper = styled.button`
    position: absolute;
    top: 16px;
    right: 5%;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    color: #868688;

    :hover {
        cursor: pointer;
    }
`;

export { Drawer };
