import queryString from "query-string";
import { PostModel } from "./models";

class HttpService {
    public baseUrl = process.env.REACT_APP_API_URL_DEV;
    // 환경변수 설정
    public imageUrl = "https://mypetlife.co.kr";

    public getThumbnailImageUrl(path: string) {
        return `${this.imageUrl}/wp-content/uploads/${path}`;
    }

    public async get(path: string, params: any) {
        try {
            const response = await fetch(
                `${this.baseUrl}${path}/?${queryString.stringify(params)}`,
                {
                    method: "GET",
                }
            );
            if (response.ok) {
                const res = await response.json();
                return res;
            }
            throw response;
        } catch (e) {
            console.log(e);
        }
    }

    public async getPosts(params: any): Promise<PostModel[]> {
        const res = await this.get("wordpress/posts", params);
        if (res.results.length > 0) {
            const promises = res.results.map(async (x: any) => {
                let thumbnailUrl = "";
                if (!!x.meta && !!x.meta._thumbnail_id) {
                    const thumbnailRes = await httpService.get(
                        `/wordpress/posts/${x.meta._thumbnail_id}`,
                        {
                            post_type: "attachment",
                        }
                    );
                    if (!!thumbnailRes) {
                        thumbnailUrl = httpService.getThumbnailImageUrl(
                            thumbnailRes.meta._wp_attached_file
                        );
                    }
                }
                return {
                    ...x,
                    category: x.categories[0].name,
                    tags: x.tags.length > 5 ? x.tags.slice(0, 5) : x.tags,
                    thumbnail: thumbnailUrl,
                };
            });
            return Promise.all(promises);
        }

        return [];
    }
}

export const httpService = new HttpService();
