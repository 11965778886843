import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "theme";
import DownIcon from "components/icons/DownIcon";

interface DropDownProps {
    onClick: (value: any) => void;
    placeholder?: string;
    options: DropDownOption[];
    value: any;
    disabled?: boolean;
    invalid?: boolean;
}

export interface DropDownOption {
    label: string;
    value: string;
}

interface DropDownInnerProps {
    toggle?: boolean;
    disabled?: boolean;
    invalid?: boolean;
}

const DropDown = ({
    onClick,
    placeholder,
    options,
    value,
    disabled,
    invalid,
}: DropDownProps) => {
    const [toggle, setToggle] = useState<boolean>(false);
    const [selected, setSelected] = useState<DropDownOption>();

    const handleOnToggle = () => {
        if (disabled) {
            return;
        }
        setToggle(!toggle);
    };

    const handleOnClick = (option: DropDownOption) => () => {
        onClick(option.value);
        setSelected(option);
    };

    return (
        <DropDownWrapper onClick={handleOnToggle} disabled={disabled}>
            <DropDownPlaceholder toggle={toggle} invalid={invalid}>
                {!!value && !!selected && value === selected.value ? (
                    <DropDownText color={colors.grey700}>
                        {selected.label}
                    </DropDownText>
                ) : !!placeholder ? (
                    <DropDownText>{placeholder}</DropDownText>
                ) : null}
                <IconWrapper>
                    <DownIcon
                        color={toggle ? colors.grey700 : colors.grey400}
                    />
                </IconWrapper>
            </DropDownPlaceholder>
            <DropDownContents toggle={toggle}>
                {options?.map((option: DropDownOption, index: number) => (
                    <DropDownOptions key={index}>
                        <DropDownText onClick={handleOnClick(option)}>
                            {option.label}
                        </DropDownText>
                    </DropDownOptions>
                ))}
            </DropDownContents>
        </DropDownWrapper>
    );
};

export default DropDown;

const DropDownWrapper = styled.div<DropDownInnerProps>`
    position: relative;
    font-family: JalnanOTF;
    font-size: 14px;
    width: 100%;
    max-width: 360px;

    :hover {
        cursor: pointer;
    }

    ${({ disabled }) =>
        disabled &&
        `
    :hover {
      cursor: not-allowed
    }
  `}
`;

const DropDownPlaceholder = styled.div<DropDownInnerProps>`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    background-color: ${({ toggle }) =>
        toggle ? colors.grey100 : colors.grey200};
    border-radius: ${({ toggle }) => (toggle ? `14px 14px 0px 0px` : `14px`)};
    border: solid 2px
        ${({ toggle }) => (toggle ? colors.primary100 : `transparent`)};
    border-bottom: none;
    color: ${colors.grey400};

    ${({ invalid, toggle, disabled }) =>
        invalid &&
        !toggle &&
        !disabled &&
        `
    border-bottom: 2px solid;
    border-color: ${colors.red};
  `}
`;

const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
    padding: 12px 22px 12px 14px;
`;

const DropDownContents = styled.div<{ toggle?: boolean }>`
    display: ${({ toggle }) => (toggle ? `block` : `none`)};
    position: absolute;
    z-index: 1;
    width: 100%;
`;

const DropDownOptions = styled.div`
    margin: 0px;
    display: flex;
    align-items: center;
    background-color: ${colors.grey100};
    border: solid 2px ${colors.primary100};
    border-top: none;
    border-bottom: none;

    :hover {
        background-color: ${colors.primary200};
    }

    :last-child {
        border-bottom: solid 2px ${colors.primary100};
        border-radius: 0px 0px 14px 14px;
    }
`;

const DropDownText = styled.p<{ color?: string }>`
    margin: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    text-align: right;
    padding: 11px 60px 11px 17px;
    color: ${({ color }) => (!!color ? color : "inherit")};
`;

export { DropDown };
