import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { liliMini, samsekMini, registVerifySamsekLili } from "assets";
import { ReactComponent as DropdownIcon } from "../../assets/InsuranceImages/main/dropdown_arrow.svg";
import { ReactComponent as TipCloseIcon } from "../../assets/InsuranceImages/main/tip_close.svg";
import { ReactComponent as RefreshIcon } from "../../assets/AdoptImages/refresh.svg";
import DropDownBreed from "./DropDownBreed";
import DropDownRegion from "./DropDownRegion";
import { useMediaQuery } from "react-responsive";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
    adoptBreedState,
    adoptCurrentPageState,
    adoptPetTypeState,
    adoptRegionState,
} from "states";

const AdoptInput = () => {
    const [adoptPetType, setAdoptPetType] = useRecoilState(adoptPetTypeState);
    const [dropDownBreed, setDropDownBreed] = useState<boolean>(false);
    const [dropDownRegion, setDropDownRegion] = useState<boolean>(false);
    const [adoptBreed, setAdoptBreed] = useRecoilState(adoptBreedState);
    const [adoptRegion, setAdoptRegion] = useRecoilState(adoptRegionState);
    const [allBreed, setAllBreed] = useState<boolean>(false);
    const [breedText, setBreedText] = useState<string>("");
    const setAdoptCurrentPage = useSetRecoilState(adoptCurrentPageState);

    const breedRef = useRef<HTMLInputElement | null>(null);
    const regionRef = useRef<HTMLInputElement | null>(null);

    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    const handleClickPetType = (petType: string) => {
        setAdoptPetType(petType);
        setAdoptBreed(new Set());
        setAdoptRegion("");
        setAdoptCurrentPage(1);
    };

    const handleClickBreed = () => {
        const tempDropDownBreed = dropDownBreed;
        setDropDownBreed(!tempDropDownBreed);
        if (!tempDropDownBreed) setDropDownRegion(false);
    };
    const handleClickRegion = () => {
        const tempDropDownRegion = dropDownRegion;
        setDropDownRegion(!tempDropDownRegion);
        if (!tempDropDownRegion) setDropDownBreed(false);
    };

    const handleDeleteBreed = (data: any) => {
        if (adoptBreed.size !== 0 && adoptBreed.has(data)) {
            setAdoptBreed((prevArr: Set<any>) => {
                const newArr = new Set(prevArr);
                newArr.delete(data);
                return newArr;
            });
        }
    };

    const handleRefreshBreed = () => {
        setAdoptBreed(new Set());
        setAdoptCurrentPage(1);
    };

    const getBreedName = (name: string, length: number) => {
        const maxLength = isMobile ? 5 : 11;

        if (!isMobile && name.length > 11) {
            setBreedText(`${name.slice(0, maxLength)}···  +${length}`);
        } else {
            setBreedText(name + " 외 " + (length - 1) + "개");
        }
    };

    useEffect(() => {
        if (adoptBreed.size !== 0) setAllBreed(false);

        if (adoptBreed.size === 0) {
            setBreedText(allBreed ? "전체" : "품종");
        } else if (adoptBreed.size === 1) {
            setBreedText(Array.from(adoptBreed)[0]);
        } else {
            getBreedName(Array.from(adoptBreed)[0], adoptBreed.size);
        }
        // eslint-disable-next-line
    }, [adoptBreed, allBreed]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                breedRef.current &&
                !breedRef.current.contains(event.target as Node)
            ) {
                setDropDownBreed(false);
            }
            if (
                regionRef.current &&
                !regionRef.current.contains(event.target as Node)
            ) {
                setDropDownRegion(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <AdoptInputContainer>
                <AdoptInputBox>
                    <AdoptMainImage
                        src={registVerifySamsekLili}
                        alt="삼색이 리리"
                    />
                    <PetTypeButtonWrapper>
                        <PetTypeButton
                            selected={adoptPetType === "dog"}
                            onClick={() => handleClickPetType("dog")}
                        >
                            <img src={liliMini} alt="강아지" />
                            강아지
                        </PetTypeButton>
                        <PetTypeButton
                            selected={adoptPetType === "cat"}
                            onClick={() => handleClickPetType("cat")}
                        >
                            <img src={samsekMini} alt="고양이" />
                            고양이
                        </PetTypeButton>
                    </PetTypeButtonWrapper>
                    <SelectInputContainer>
                        <SelectInputWrapper ref={breedRef}>
                            <SelectInput
                                dropdown={dropDownBreed}
                                onClick={handleClickBreed}
                            >
                                <SelectInputText>{breedText}</SelectInputText>
                                <DropdownIcon className="icon" />
                            </SelectInput>
                            <DropDownContainer>
                                {dropDownBreed && (
                                    <DropDownBreed
                                        petType={adoptPetType}
                                        setDropDownBreed={setDropDownBreed}
                                        allBreed={allBreed}
                                        setAllBreed={setAllBreed}
                                    />
                                )}
                            </DropDownContainer>
                        </SelectInputWrapper>
                        <SelectInputWrapper ref={regionRef}>
                            <SelectInput
                                dropdown={dropDownRegion}
                                onClick={handleClickRegion}
                            >
                                {adoptRegion !== "" && adoptRegion !== undefined
                                    ? adoptRegion
                                    : "지역"}
                                <DropdownIcon className="icon" />
                            </SelectInput>
                            <DropDownContainer mobileRight={isMobile}>
                                {dropDownRegion && (
                                    <DropDownRegion
                                        petType={adoptPetType}
                                        setDropDownRegion={setDropDownRegion}
                                    />
                                )}
                            </DropDownContainer>
                        </SelectInputWrapper>
                    </SelectInputContainer>
                </AdoptInputBox>
                <TipButtonContainer>
                    {Array.from(adoptBreed)?.map(data => (
                        <TipButton key={data}>
                            {data}
                            <TipCloseIcon
                                className="delete"
                                onClick={() => handleDeleteBreed(data)}
                            />
                        </TipButton>
                    ))}
                    {adoptBreed.size !== 0 && (
                        <TipbreedRefresh onClick={handleRefreshBreed}>
                            <RefreshIcon className="refreshIcon" />
                            품종 초기화
                        </TipbreedRefresh>
                    )}
                </TipButtonContainer>
            </AdoptInputContainer>
        </>
    );
};

export default AdoptInput;

const AdoptInputContainer = styled.div``;

const AdoptInputBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 2.2rem;
    width: fit-content;
    margin: 0 auto;
    padding: 2.1rem;
    align-items: center;
    border-radius: 10px;
    background-color: #fffdf4;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding: 1.8rem 1.8rem 2rem;
        gap: 2.1rem;
        border-radius: 5px;
    }
`;

const PetTypeButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.2rem;

    @media only screen and (max-width: 768px) {
        width: 100%;
        gap: 1.4rem;
    }
`;

const PetTypeButton = styled.div<{ selected?: boolean }>`
    width: 4.5rem;
    height: 4.5rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    font-size: 0.81rem;
    line-height: normal;
    font-weight: 600;
    text-align: center;
    cursor: pointer;

    ${({ selected }) =>
        selected &&
        `background-color: #fff5ce;
    box-shadow: 0px 30px 12px rgba(0, 0, 0, 0.01),
            0px 17px 10px rgba(0, 0, 0, 0.05), 0px 7px 7px rgba(0, 0, 0, 0.09),
            0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);`};

    img {
        width: 2.5rem;
        height: 1.9rem;
        margin: 0.69rem 1rem 0.38rem;
    }

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
    }

    @media only screen and (max-width: 768px) {
        width: 5rem;
        height: 5rem;
        font-size: 0.9rem;

        img {
            width: 2.8rem;
            height: 2.1rem;
            margin: 0.69rem 1rem 0.38rem;
        }
    }
`;

const SelectInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2.2rem;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 1.3rem;
    }
`;

const SelectInputWrapper = styled.div`
    position: relative;
`;

const SelectInput = styled.div<{ dropdown?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 16rem;
    height: 1.3rem;
    padding: 1.6rem;
    border-radius: 10px;
    background-color: #fff5ce;

    font-size: 1.1rem;
    line-height: 1.5rem;
    font-weight: 500;

    cursor: pointer;

    .icon {
        width: 1.5rem;
        height: 1.5rem;
        ${({ dropdown }) => dropdown && `transform: scaleY(-1)`};
    }

    @media only screen and (max-width: 768px) {
        width: 24rem;
        height: 2.2rem;
        padding: 1rem 1.3rem;
        font-size: 1.3rem;
        border-radius: 8px;
    }
`;

const SelectInputText = styled.div`
    width: 14rem;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;

    @media only screen and (max-width: 768px) {
        width: 23rem;
    }
`;

const DropDownContainer = styled.div<{
    mobileRight?: boolean;
}>`
    position: absolute;
    top: 5rem;
    left: 0;
    z-index: 2;

    ${({ mobileRight }) => mobileRight && `left: initial; right: 0;`};
`;

const TipButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    width: 57.6rem;
    margin: 1.25rem auto 3.1rem;

    @media only screen and (max-width: 768px) {
        width: 29rem;
        gap: 8px;
        margin: 1.75rem auto 2.5rem;
    }
`;

const TipButton = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 2px 0.8rem;
    font-size: 0.87rem;
    line-height: 1.7rem;
    font-weight: 500;
    border-radius: 25px;
    background-color: #fff5ce;

    .delete {
        width: 1rem;
        height: 1rem;
        margin-bottom: 0.2rem;
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        font-size: 1.1rem;
    }
`;

const TipbreedRefresh = styled.div`
    display: flex;
    gap: 0.25rem;
    align-items: center;

    font-size: 0.87rem;
    line-height: 1.7rem;
    font-weight: 500;
    color: #6b6b6d;
    text-decoration: underline;

    cursor: pointer;

    .refreshIcon {
        width: 1.25rem;
        height: 1.25rem;
        margin-bottom: 2px;
    }
`;

const AdoptMainImage = styled.img`
    position: absolute;
    right: 0;
    top: -8.6rem;
    width: 12.6rem;
    height: 12.6rem;
    z-index: 1;

    @media only screen and (max-width: 768px) {
        right: 0;
        top: -6.8rem;
        width: 10rem;
        height: 10rem;
    }
`;
