import { NameContext } from "pages/naming/NamingMain";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

const NamingTwoTypeForm = ({ petSelectData }: any) => {
    const { dataList, updateList } = useContext(NameContext);
    const [petType, setPetType] = useState<string>("");

    const handleContinueButton = (data: string) => {
        updateList(data);
    };

    useEffect(() => {
        setPetType("");
    }, [dataList]);

    return (
        <>
            <NamingTwoTypeFormContainer>
                <NamingFormTitle>{petSelectData.title}</NamingFormTitle>
                <SelectButtonContainer>
                    {petSelectData.dataList.map((data: any) => (
                        <SelectButton
                            key={data.text}
                            image_size={petSelectData.image_size}
                            selected={petType === data.value}
                            onClick={() => setPetType(data.value)}
                        >
                            <img src={data.img} alt={data.text} />
                        </SelectButton>
                    ))}
                </SelectButtonContainer>
                <ContinueButton
                    disable={petType === ""}
                    onClick={() => handleContinueButton(petType)}
                >
                    다음
                </ContinueButton>
            </NamingTwoTypeFormContainer>
        </>
    );
};

export default NamingTwoTypeForm;

const NamingTwoTypeFormContainer = styled.div`
    background-color: #fffdf4;
    text-align: center;
    min-height: calc(100vh - 236px);

    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - 244px);
    }
    @media only screen and (max-width: 546px) {
        min-height: calc(100vh - 270px);
    }
`;

const NamingFormTitle = styled.div`
    padding: 80px 0 60px;
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    text-align: center;

    @media only screen and (max-width: 768px) {
        padding: 40px 0 40px;
        font-size: 28px;
    }
`;

const SelectButtonContainer = styled.div`
    display: flex;
    gap: 46px;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        gap: 36px;
    }
    @media only screen and (max-width: 448px) {
        gap: 18px;
    }
`;

const SelectButton = styled.div<{
    selected?: boolean;
    image_size?: any;
}>`
    width: ${props => props.image_size.width_w}px;
    height: ${props => props.image_size.height_w}px;

    border: 1px solid #f6f6f9;
    border-radius: 25px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    :hover {
        box-shadow: 0px 59.9772px 78.4887px rgba(0, 0, 0, 0.07),
            0px 7.51008px 9.82801px rgba(0, 0, 0, 0.0290085);
    }

    ${({ selected }) =>
        selected &&
        `
    border: 1px solid transparent;
    background-image: linear-gradient( #FFF5CE, #FFF5CE),
    linear-gradient(151deg, rgba(252,209,30,1) 0%, rgba(255,209,223,1) 35%, rgba(111,0,255,1) 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    `}

    @media only screen and (max-width: 768px) {
        width: ${props => props.image_size.width_m}px;
        height: ${props => props.image_size.height_m}px;
    }
`;

const ContinueButton = styled.div<{ disable?: boolean }>`
    display: inline-block;
    padding: 14px 125px;
    margin: 119px auto 62px;

    background-color: #fcd11e;
    box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68);
    border-radius: 50px;

    font-size: 22px;
    line-height: 18px;
    font-weight: 700;

    cursor: pointer;

    :hover {
        box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68),
            inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    ${({ disable }) =>
        disable &&
        `
    background-color: #CFCECE;
    pointer-events: none;
    `}

    @media only screen and (max-width: 768px) {
        padding: 14px 100px;
        margin: 69px auto 96px;
        font-size: 18px;
        line-height: 13px;
    }
`;
