import { adoptSpinnerLili } from "assets";
import React from "react";
import styled from "styled-components";

const AdoptSpinner = () => {
    return (
        <SpinnerContainer>
            <img src={adoptSpinnerLili} alt="spinner" />
        </SpinnerContainer>
    );
};

export default AdoptSpinner;

const SpinnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 99999;
`;
