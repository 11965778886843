import ConvertToHttps from "adoptComponents/utils/ConvertToHttps";
import { imageNotFound } from "assets";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const OtherPets = ({ adoptData }: any) => {
    const [breedData, setBreedData] = useState<any>([]);
    const [adoptDataList, setAdoptDataList] = useState<any>([]);

    const navigate = useNavigate();

    const getAge = (birthYear: string) => {
        const now = new Date();
        const age = now.getFullYear() - Number(birthYear);
        if (age === 0) return "1세 미만";
        else return age + "세";
    };

    const getGender = (gender: string) => {
        if (gender === "F") return <div className="genderF">여아</div>;
        else if (gender === "M") return <div className="genderM">남아</div>;
        else return <div className="genderQ">미상</div>;
    };

    const getBreed = async () => {
        let breedString: string = "";
        if (breedData.length === 0) {
            await axios
                .get(
                    `${process.env.REACT_APP_API_URL_DEV}pet-adoption/breeds/?pet_type=${adoptData?.pet_type}`,
                    {}
                )
                .then(res => {
                    // console.log(res.data);
                    setBreedData(res.data);
                    for (const breed of res.data) {
                        if (breed.name === adoptData.adopt_breed_text) {
                            breedString += "&breed_id=" + breed.id;
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            for (const breed of breedData) {
                if (breed.name === adoptData.adopt_breed_text) {
                    breedString += "&breed_id=" + breed.id;
                }
            }
        }
        return breedString;
    };

    const checkDesertionNo = (prevArr: any, arr: any) => {
        return arr.filter((data: any) => {
            const isDifferentDesertionNo =
                data.desertion_no !== adoptData.desertion_no;
            const isNotEndStatus = data.adopt_status.slice(0, 2) !== "종료";
            // eslint-disable-next-line
            const isDuplicate =
                prevArr.length !== 0 &&
                prevArr.some(
                    (obj: any) => obj.desertion_no === data.desertion_no
                );
            return isDifferentDesertionNo && isNotEndStatus && !isDuplicate;
        });
    };

    const getAgeParam = (birthYear: number) => {
        const now = new Date();
        const age = now.getFullYear() - birthYear;
        if (age < 1) return "&age=Puppy";
        if (age >= 1 && age <= 5) return "&age=Young";
        if (age >= 6 && age <= 9) return "&age=Adult";
        if (age >= 10) return "&age=Senior";
    };

    const getListWithPetType = async (prevArr: any[], currLength: number) => {
        await axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}pet-adoption/adopt/?offset=0&size=10&pet_type=${adoptData?.pet_type}`,
                {}
            )
            .then(res => {
                // console.log(res.data);
                const tempArr = checkDesertionNo(
                    prevArr,
                    res.data.results
                ).slice(0, 5 - currLength);
                setAdoptDataList((prev: any) => [...prev, ...tempArr]);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const getListWithAge = async (prevArr: any[], currLength: number) => {
        await axios
            .get(
                `${
                    process.env.REACT_APP_API_URL_DEV
                }pet-adoption/adopt/?offset=0&size=10&pet_type=${
                    adoptData?.pet_type
                }${getAgeParam(adoptData?.birth_year)}`,
                {}
            )
            .then(res => {
                // console.log(res.data);
                const tempArr = checkDesertionNo(
                    prevArr,
                    res.data.results
                ).slice(0, 5 - currLength);
                setAdoptDataList((prev: any) => [...prev, ...tempArr]);
                if (currLength + tempArr.length < 5)
                    getListWithPetType(
                        [...prevArr, ...tempArr],
                        currLength + tempArr.length
                    );
            })
            .catch(err => {
                console.log(err);
            });
    };

    const getListWithBreed = async () => {
        const breedParam = await getBreed();
        await axios
            .get(
                `${process.env.REACT_APP_API_URL_DEV}pet-adoption/adopt/?offset=0&size=10&pet_type=${adoptData?.pet_type}${breedParam}`,
                {}
            )
            .then(res => {
                const tempArr = checkDesertionNo([], res.data.results).slice(
                    0,
                    5
                );
                setAdoptDataList(tempArr);
                if (tempArr.length < 5) getListWithAge(tempArr, tempArr.length);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const fetchAdoptList = async () => {
        if (adoptData !== undefined) {
            await getListWithBreed();
        }
    };

    useEffect(() => {
        const container = document.getElementById("other-pet-card-container");
        if (container) {
            container.scrollLeft = 0;
        }
        fetchAdoptList();
        // eslint-disable-next-line
    }, [adoptData]);

    return (
        <>
            <OtherPetsContainer>
                <OtherPetsWrapper>
                    <OtherPetsTitle>
                        함께할 가족을 기다리고 있어요
                    </OtherPetsTitle>
                    <OtherPetCardContainer id="other-pet-card-container">
                        {adoptDataList?.map((data: any) => (
                            <OtherPetCard
                                key={data.desertion_no}
                                onClick={() =>
                                    navigate(
                                        `/adoption/adopt/detail?id=${data.desertion_no}`
                                    )
                                }
                            >
                                <OtherPetAnimalType>
                                    {data.pet_type === "DOG"
                                        ? "강아지"
                                        : "고양이"}
                                </OtherPetAnimalType>
                                <OtherPetCardImage
                                    src={
                                        data.profile_url
                                            ? ConvertToHttps(data.profile_url)
                                            : imageNotFound
                                    }
                                    alt={data.adopt_breed_text}
                                ></OtherPetCardImage>
                                <OtherPetCardTextContainer>
                                    <OtherPetCardTextWrapper>
                                        <OtherPetCardText>
                                            {data.adopt_breed_text}
                                        </OtherPetCardText>
                                        <OtherPetCardText>
                                            {getGender(data?.gender)}
                                            {` · ${getAge(data?.birth_year)}`}
                                        </OtherPetCardText>
                                    </OtherPetCardTextWrapper>
                                </OtherPetCardTextContainer>
                            </OtherPetCard>
                        ))}
                    </OtherPetCardContainer>
                </OtherPetsWrapper>
            </OtherPetsContainer>
        </>
    );
};

export default OtherPets;

const OtherPetsContainer = styled.div`
    margin-top: 2.5rem;
    background-color: #ffeb99;
`;

const OtherPetsWrapper = styled.div`
    position: relative;
    max-width: 64rem;
    margin: 0 auto;
    padding-bottom: 1.5rem;
`;

const OtherPetsTitle = styled.div`
    padding: 2.5rem 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 500;
    text-align: center;

    @media only screen and (max-width: 768px) {
        font-weight: 600;
    }
`;

const OtherPetCardContainer = styled.div`
    display: flex;
    gap: 1.3rem;
    max-width: 1024px;
    height: 14.2rem;
    margin: 0 auto;
    padding: 0.2rem 2rem 1.5rem 2rem;
    overflow-x: scroll;

    @media only screen and (max-width: 768px) {
        padding: 1.25rem 1.5rem 2.5rem;
    }
`;

const OtherPetCard = styled.a`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 12.9rem;
    height: 14.2rem;
    border-radius: 30px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    text-decoration: none;
    color: black;
    cursor: pointer;

    :hover {
        box-shadow: 0px 30px 12px rgba(0, 0, 0, 0.01),
            0px 17px 10px rgba(0, 0, 0, 0.05), 0px 7px 7px rgba(0, 0, 0, 0.09),
            0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    }
`;

const OtherPetCardImage = styled.img`
    width: 12.9rem;
    height: 9.75rem;
    object-fit: cover;
    border-radius: 30px 30px 0 0;
    background-image: url("data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFklEQVR42mN8//HLfwYiAOOoQvoqBABbWyZJf74GZgAAAABJRU5ErkJggg==");
`;

const OtherPetCardTextContainer = styled.div`
    width: 12.9rem;
    height: 4.5rem;
    background-color: white;
    border-radius: 0 0 30px 30px;
`;

const OtherPetCardTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    padding: 0.9rem 2.5rem;
`;

const OtherPetCardText = styled.div`
    /* padding: 0.87rem 1.5rem; */
    display: flex;
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 500;
    text-align: center;
    justify-content: center;
    white-space: pre;

    .genderF {
        color: #ff8d8e;
    }

    .genderM {
        color: #62b4fd;
    }

    .genderQ {
        color: #868688;
    }
`;

const OtherPetAnimalType = styled.div`
    position: absolute;
    display: inline-flex;
    text-align: center;
    align-items: center;
    padding: 0.28rem 0.97rem 0.18rem;
    margin: 1rem 0.9rem;
    background-color: #ffeb99;
    border-radius: 30px;

    font-size: 0.75rem;
    line-height: 0.9rem;
    font-weight: 600;
`;
