import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowCircleIcon } from "../../assets/InsuranceImages/insurances/paginationArrow.svg";
import { ReactComponent as MoreHorizIcon } from "../../assets/InsuranceImages/insurances/more_horiz.svg";

interface PaginationProps {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination = ({
    totalCount,
    pageSize,
    currentPage,
    setCurrentPage,
}: PaginationProps) => {
    const [firstNum, setFirstNum] = useState(1);
    const totalPageNum = Math.ceil(totalCount / pageSize);

    const getFirstNum = (page: number) => {
        setCurrentPage(page);
        if (page <= 2) {
            setFirstNum(1);
        } else if (page >= 3) {
            if (page !== totalPageNum) {
                setFirstNum(page - 1);
            } else {
                setFirstNum(page - 2);
            }
        }
    };

    const handlePageChange = (page: number) => {
        getFirstNum(page);
    };

    const handlePrevPage = () => {
        if (currentPage !== 1) getFirstNum(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage !== totalPageNum) getFirstNum(currentPage + 1);
    };

    useEffect(() => {
        getFirstNum(currentPage);
    });
    return (
        <>
            <PaginationButtonConatiner>
                <ArrowButton onClick={handlePrevPage}>
                    <ArrowCircleIcon className="prevIcon" />
                </ArrowButton>
                {totalPageNum <= 3 || firstNum === 1 ? (
                    <></>
                ) : (
                    <PageButton onClick={() => getFirstNum(1)}>
                        <MoreHorizIcon className="moreIcon" />
                    </PageButton>
                )}
                {Array.from({
                    length: totalPageNum >= 3 ? 3 : totalPageNum,
                }).map((_, i) => (
                    <PageButton
                        active={currentPage === firstNum + i}
                        key={firstNum + i}
                        onClick={() => handlePageChange(firstNum + i)}
                    >
                        <PageButtonText>{firstNum + i}</PageButtonText>
                    </PageButton>
                ))}
                {totalPageNum <= 3 || firstNum === totalPageNum - 2 ? (
                    <></>
                ) : (
                    <PageButton onClick={() => getFirstNum(totalPageNum)}>
                        <MoreHorizIcon className="moreIcon" />
                    </PageButton>
                )}
                <ArrowButton onClick={handleNextPage}>
                    <ArrowCircleIcon className="nextIcon" />
                </ArrowButton>
            </PaginationButtonConatiner>
        </>
    );
};

export default Pagination;

const PaginationButtonConatiner = styled.div`
    display: flex;
    float: right;
    padding: 20px 3px;
    margin-bottom: 78px;
`;

const ArrowButton = styled.div`
    background: none;
    border: none;
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
    color: #868686;
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;

    .prevIcon {
        transform: rotate(-90deg);
    }
    .nextIcon {
        transform: rotate(90deg);
    }
`;

const PageButton = styled.div<{ active?: boolean }>`
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #fcd11e;
    text-align: center;
    margin: 0 7px;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    ${({ active }) =>
        active ? `background-color: #fcd11e;` : `background-color: #fffdf4;`}
`;

const PageButtonText = styled.div`
    display: inline-block;
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    color: #090909;
`;
