import React from "react";
import styled from "styled-components";
import { ReactComponent as TipCloseIcon } from "../../assets/InsuranceImages/main/tip_close.svg";

interface MultiTipProps {
    filter: Set<string>;
    setFilter: React.Dispatch<React.SetStateAction<Set<string>>>;
}

const MultiTip = ({ filter, setFilter }: MultiTipProps) => {
    const handleDelete = (data: any) => {
        if (filter.size !== 0 && filter.has(data)) {
            setFilter((prevArr: Set<any>) => {
                const newArr = new Set(prevArr);
                newArr.delete(data);
                return newArr;
            });
        }
    };

    return (
        <>
            {filter.size !== 0 &&
                Array.from(filter).map(data => (
                    <TipButton key={data}>
                        {data}
                        <TipCloseIcon
                            className="delete"
                            onClick={() => handleDelete(data)}
                        />
                    </TipButton>
                ))}
        </>
    );
};

export default MultiTip;

const TipButton = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 2px 0.8rem;
    font-size: 0.87rem;
    line-height: 1.7rem;
    font-weight: 500;
    border-radius: 25px;
    background-color: #f0e9f7;

    .delete {
        width: 1rem;
        height: 1rem;
        margin-bottom: 0.2rem;
        cursor: pointer;
    }

    @media only screen and (max-width: 768px) {
        font-size: 1.1rem;
    }
`;
