import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import MainPage from "pages/main/MainPage";
import FoodCalorieCalculatorPage from "pages/feed/FoodCalorieCalculatorPage";
import RecommendCalorieCalculatorPage from "pages/recommend/RecommendCalorieCalculatorPage";
import BmiCalculatorPage from "pages/bmi/BmiCalculatorPage";
import AgeCalculatorPage from "pages/age/AgeCalculatorPage";
import InsuranceMainPage from "pages/insurance/main/InsuranceMainPage";
import InsuranceDetail from "pages/detail/InsuranceDetail";
import InsuranceCompare from "pages/compare/InsuranceCompare";
import InsuranceBenefits from "pages/benefits/InsuranceBenefits";
import Insurances from "pages/insurances/Insurances";
import NotFoundPage from "pages/notfound/NotFoundPage";
import NamingMainProvider from "pages/naming/NamingMainProvider";
import NamingResult from "pages/naming/NamingResult";
import NamingNotFound from "pages/naming/NamingNotFound";
import VerificationMain from "pages/registration-verification/VerificationMain";
import axios from "axios";
import AdoptMain from "pages/adopt/AdoptMain";
import AdoptDetail from "pages/adopt/AdoptDetail";
import { useRecoilState } from "recoil";
import { userTokenState } from "states";
import AdoptFavorite from "pages/adopt/AdoptFavorite";
import InsuranceFavorite from "pages/insurance/favorite/InsuranceFavorite";
import HomePage from "pages/home/HomePage";
import FosterMain from "pages/foster/FosterMain";
import FosterDetail from "pages/foster/FosterDetail";
import EntrustForm from "pages/foster/EntrustForm";
import FosterForm from "pages/foster/FosterForm";

export const LoginContext = createContext({
    isLoggedIn: false,
    updateLoggedIn: (loginValue: boolean) => {},
});

export default function Router() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    // eslint-disable-next-line
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [, setUserToken] = useRecoilState(userTokenState);

    const updateLoggedIn = (loginValue: boolean) => {
        setIsLoggedIn(loginValue);
    };

    const getAccessTokenWithRefreshToken = () => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL_DEV}v2/auth/token-refresh/`,
                {},
                {
                    withCredentials: true,
                }
            )
            .then(res => {
                updateLoggedIn(true);
                // localStorage.setItem("accessToken", res.data.access);
                setUserToken(res.data.access);
            })
            .catch(err => {
                updateLoggedIn(false);
                localStorage.removeItem("accessToken");
            });
    };

    const validateAccessToken = () => {
        const storageData = localStorage.getItem("accessToken");

        if (storageData != null) {
            axios
                .post(`${process.env.REACT_APP_API_URL_DEV}v2/auth/token-verify/`, {
                    token: storageData,
                })
                .then(res => {
                    setIsLoggedIn(true);
                })
                .catch(err => {
                    getAccessTokenWithRefreshToken();
                });
        }
    };

    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        const storageData = localStorage.getItem("accessToken");

        if (storageData == null) {
            getAccessTokenWithRefreshToken();
        }
        validateAccessToken();

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };

        // eslint-disable-next-line
    }, []);

    return (
        <>
            {!isLoading && (
                <BrowserRouter>
                    <LoginContext.Provider value={{ isLoggedIn, updateLoggedIn }}>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/calculator" element={<MainPage />} />
                            <Route
                                path="/calculator/food-calorie"
                                element={<FoodCalorieCalculatorPage />}
                            />
                            <Route
                                path="/calculator/recommend-calorie"
                                element={<RecommendCalorieCalculatorPage />}
                            />
                            <Route path="/calculator/bmi" element={<BmiCalculatorPage />} />
                            <Route path="/calculator/age" element={<AgeCalculatorPage />} />

                            {/* 301 리다이렉션 */}
                            <Route
                                path="/food-calorie"
                                element={<Navigate to="/calculator/food-calorie" replace />}
                            />
                            <Route
                                path="/recommend-calorie"
                                element={<Navigate to="/calculator/recommend-calorie" replace />}
                            />
                            <Route
                                path="/bmi"
                                element={<Navigate to="/calculator/bmi" replace />}
                            />
                            <Route
                                path="/age"
                                element={<Navigate to="/calculator/age" replace />}
                            />
                            
                            {/* 펫보험 */}
                            <Route path="/insurance/:insuranceId" element={<InsuranceDetail />} />
                            <Route path="/insurance/compare" element={<InsuranceCompare />} />
                            <Route path="/insurance/benefits" element={<InsuranceBenefits />} />
                            <Route
                                path="/insurance/insurances/:animalType"
                                element={<Insurances />}
                            />
                            <Route path="/notfound" element={<NotFoundPage />} />
                            <Route path="/*" element={<Navigate replace to="/notfound" />} />
                            <Route path="/insurance" element={<InsuranceMainPage />} />
                            <Route path="/insurance/favorite" element={<InsuranceFavorite />} />

                            {/* 이름 짓기 */}

                            <Route
                                path="/adoption/name-generator"
                                element={<NamingMainProvider />}
                            />
                            <Route
                                path="/adoption/name-generator-result"
                                element={<NamingResult />}
                            />
                            <Route path="/adoption/notfound" element={<NamingNotFound />} />
                            <Route
                                path="/adoption/*"
                                element={<Navigate replace to="/adoption/notfound" />}
                            />

                            {/* 동물등록 번호 검색 */}
                            <Route
                                path="/adoption/registration-verification"
                                element={<VerificationMain />}
                            />

                            {/* 유기동물 입양 */}
                            <Route path="/adoption/adopt" element={<AdoptMain />} />
                            <Route path="/adoption/adopt/detail" element={<AdoptDetail />} />
                            <Route path="/adoption/favorite" element={<AdoptFavorite />} />
                            <Route
                                path="/adoption"
                                element={<Navigate to="/adoption/adopt" replace />}
                            />
                            {/* 임시보호 */}
                            <Route path="/foster" element={<FosterMain />} />
                            <Route path="/foster/detail" element={<FosterDetail />} />
                            <Route path="/foster/entrust-form" element={<EntrustForm />} />
                            <Route path="/foster/foster-form" element={<FosterForm />} />
                        </Routes>
                    </LoginContext.Provider>
                </BrowserRouter>
            )}
        </>
    );
}
