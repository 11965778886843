import React, { useEffect, useState } from "react";
import { notFoundLogo, notFoundSamsek } from "assets";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() => {
        window.onload = () => {
            setIsLoading(false);
        };

        const handleLoad = () => {
            setIsLoading(true);
        };

        if (document.readyState === "complete") {
            // 이미 로드된 상태일 경우
            setIsLoading(false);
        } else {
            // 로드되기 전인 경우
            document.addEventListener("load", handleLoad);
        }

        return () => {
            // 컴포넌트가 unmount 될 때 이벤트 제거
            document.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <>
            <NotFoundMainContainer
                isLoading={isLoading}
                style={{ display: "none" }}
            >
                <LogoContainer>
                    <img src={notFoundLogo} alt="logo" />
                </LogoContainer>
                <NotFoundContainer>
                    <TextContainer>
                        <ErrorTitle>Error !</ErrorTitle>
                        <ErrorDescription>
                            페이지 접근 중 에러가 발생하였습니다.
                            <br />
                            다시 시도해주세요.
                        </ErrorDescription>
                    </TextContainer>
                    <ImageContainer>
                        <img src={notFoundSamsek} alt="samsek" />
                    </ImageContainer>
                    <ButtonContainer>
                        <MainButton onClick={() => navigate("/")}>
                            메인 페이지로 이동하기
                        </MainButton>
                    </ButtonContainer>
                </NotFoundContainer>
            </NotFoundMainContainer>
        </>
    );
};

export default NotFound;

const NotFoundMainContainer = styled.div<{ isLoading?: boolean }>`
    ${({ isLoading }) => !isLoading && `display: block !important;`}
`;

const LogoContainer = styled.div`
    margin: 50px 0;
    text-align: center;

    img {
        width: 144px;
        height: 88.94px;

        @media only screen and (max-width: 768px) {
            width: 124px;
            height: 76.5px;
        }
        @media only screen and (max-width: 390px) {
            width: 103px;
            height: 63.62px;
        }
    }

    @media only screen and (max-width: 768px) {
        margin: 35px 0;
    }
    @media only screen and (max-width: 390px) {
        margin: 25px 0;
    }
`;

const NotFoundContainer = styled.div`
    background-color: #fff5ce;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 23px;
    padding: 38px 0 31px;
    text-align: center;
`;

const ErrorTitle = styled.div`
    font-size: 36px;
    line-height: 43px;
    font-weight: 700;

    @media only screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 38px;
    }
    @media only screen and (max-width: 390px) {
        font-size: 26px;
        line-height: 31px;
    }
`;

const ErrorDescription = styled.div`
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;

    @media only screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
    }
    @media only screen and (max-width: 390px) {
        font-size: 16px;
        line-height: 19px;
    }
`;

const ImageContainer = styled.div`
    text-align: center;
    img {
        width: 400px;
        height: 345.86px;

        @media only screen and (max-width: 768px) {
            width: 334px;
            height: 288px;
        }
        @media only screen and (max-width: 390px) {
            width: 268px;
            height: 231px;
        }
    }
`;

const ButtonContainer = styled.div`
    padding: 27px 0 35px;
    text-align: center;
`;

const MainButton = styled.button`
    padding: 11px 22.5px;
    background-color: #fcd11e;
    border-radius: 21px;
    border: none;
    cursor: pointer;

    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    color: black;

    :hover {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    @media only screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
    }
    @media only screen and (max-width: 390px) {
        font-size: 16px;
        line-height: 19px;
    }
`;
