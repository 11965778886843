import React, { useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as CloseRoundedIcon } from "../../../assets/DrawerImages/close.svg";

interface FavoriteAlertProps {
    alertType: string;
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const InsuranceFavoriteAlert = ({ alertType, setShowAlert }: FavoriteAlertProps) => {
    useEffect(() => {
        document.body.style.cssText = `
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = "";
            window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
    }, []);

    const getAlertText = (alertType: string) => {
        if (alertType === "diff")
            return (
                <>
                    펫보험 1:1 비교는 보험 가입 동물이
                    <br />
                    같은 경우만 비교할 수 있습니다.
                    <br />( 강아지 - 강아지, 고양이 - 고양이 )
                </>
            );
        else if (alertType === "over")
            return (
                <>
                    펫보험 1:1 비교는 최대 2개까지의
                    <br />
                    보험을 선택하여 비교할 수 있습니다.
                </>
            );
    };

    return (
        <>
            <AdoptConfirmDeleteAllContainer>
                <ConfirmModalContainer>
                    <CloseIconWrapper onClick={() => setShowAlert(false)}>
                        <CloseRoundedIcon className="icon" />
                    </CloseIconWrapper>
                    <ConfirmText>{getAlertText(alertType)}</ConfirmText>
                    <ConfirmButtonContainer>
                        <ConfirmButton onClick={() => setShowAlert(false)}>확인</ConfirmButton>
                    </ConfirmButtonContainer>
                </ConfirmModalContainer>
            </AdoptConfirmDeleteAllContainer>
        </>
    );
};

export default InsuranceFavoriteAlert;

const AdoptConfirmDeleteAllContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 9999;
    display: flex;
`;

const ConfirmModalContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20rem;
    margin: auto;
    border-radius: 8px;
    background-color: #fff5ce;
    border: 1px solid #fcd11e;
    box-shadow: 0px 139.228px 55.5831px rgba(0, 0, 0, 0.01),
        0px 78.2481px 46.9488px rgba(0, 0, 0, 0.05), 0px 35.0767px 35.0767px rgba(0, 0, 0, 0.09),
        0px 8.63427px 19.4271px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
`;

const CloseIconWrapper = styled.button`
    position: absolute;
    top: 10px;
    right: 0.9rem;
    width: 12px;
    height: 12px;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    color: #868688;

    cursor: pointer;

    .icon {
        width: 1.1rem;
        height: 1.1rem;
    }
`;

const ConfirmText = styled.div`
    margin: 3.2rem auto 0;
    font-size: 0.9rem;
    line-height: 1.2rem;
    font-weight: 600;
    text-align: center;
`;

const ConfirmButtonContainer = styled.div`
    display: flex;
    margin: 1.5rem auto;
`;

const ConfirmButton = styled.div<{ confirm?: boolean }>`
    padding: 0.5rem 1.5rem;
    font-size: 0.75rem;
    line-height: 0.9rem;
    font-weight: 600;

    border-radius: 25px;
    cursor: pointer;

    background-color: #fcd11e;

    :hover {
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
    }
`;
