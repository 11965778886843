import React from "react";
import styled from "styled-components";


interface FormDisabledProps {
  label?: string;
}

export const FormDisabled = ({
  label,
}: FormDisabledProps) => {
  return (
    <FormDisabledWrapper>
      {label}
    </FormDisabledWrapper>
  );
};

const FormDisabledWrapper = styled.div`
  width: 100%;
  max-width: 354px;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 14px;
  background-color: #F6F6F9;
  border-radius: 25px;
  border: 1px dotted #868686;
  display: flex;
  flex-direction: row;
  align-items: center;
`;