import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as FilterIcon } from "../../assets/AdoptImages/tune.svg";
import { ReactComponent as DropdownIcon } from "../../assets/InsuranceImages/main/dropdown_arrow.svg";
import { ReactComponent as RefreshIcon } from "../../assets/AdoptImages/refresh.svg";

import FilterSingleSelect from "./FilterSingleSelect";
import FilterMultiSelect from "./FilterMultiSelect";
import SingleTip from "./SingleTip";
import MultiTip from "./MultiTip";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
    adoptAgeState,
    adoptCurrentPageState,
    adoptGenderState,
    adoptNeuterState,
    adoptStatusState,
} from "states";

const processStateList: string[] = ["전체", "보호", "공고"];
const AgeList: string[] = ["1살 미만", "1살 ~ 5살", "6살 ~ 9살", "10살 이상"];
const genderList: string[] = ["전체", "남아", "여아"];
const neuterStateList: string[] = ["전체", "완료", "미완료", "알 수 없음"];

const AdoptFiltering = () => {
    const dropdownRef = useRef<HTMLInputElement | null>(null);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [activeCategory, setActiveCategory] = useState<string>("");
    const [adoptStatus, setAdoptStatus] = useRecoilState(adoptStatusState);
    const [adoptAge, setAdoptAge] = useRecoilState(adoptAgeState);
    const [adoptGender, setAdoptGender] = useRecoilState(adoptGenderState);
    const [adoptNeuter, setAdoptNeuter] = useRecoilState(adoptNeuterState);
    const setAdoptCurrentPage = useSetRecoilState(adoptCurrentPageState);

    const filterList = [
        {
            category: "상태",
            filter: adoptStatus,
            dropdown: (
                <FilterSingleSelect
                    activeData={adoptStatus}
                    setActiveData={setAdoptStatus}
                    listData={processStateList}
                />
            ),
        },
        {
            category: "나이",
            filter: adoptAge,
            dropdown: (
                <FilterMultiSelect
                    activeData={adoptAge}
                    setActiveData={setAdoptAge}
                    listData={AgeList}
                />
            ),
        },
        {
            category: "성별",
            filter: adoptGender,
            dropdown: (
                <FilterSingleSelect
                    activeData={adoptGender}
                    setActiveData={setAdoptGender}
                    listData={genderList}
                />
            ),
        },
        {
            category: "중성화",
            filter: adoptNeuter,
            dropdown: (
                <FilterSingleSelect
                    activeData={adoptNeuter}
                    setActiveData={setAdoptNeuter}
                    listData={neuterStateList}
                />
            ),
        },
    ];

    const toggleFilterButton = () => {
        setActiveCategory("");
        setOpenFilter(prev => !prev);
    };

    const handleClickCategory = (clickedCategory: string) => {
        const currCategory = activeCategory;
        if (currCategory === clickedCategory) setActiveCategory("");
        else setActiveCategory(clickedCategory);
    };

    const handleDropdown = (e: any, category: string) => {
        if (activeCategory === category) {
            e.stopPropagation();
            setActiveCategory("");
        }
    };

    const handleRefreshFilter = () => {
        setAdoptStatus("전체");
        setAdoptAge(new Set());
        setAdoptGender("전체");
        setAdoptNeuter("전체");
        setAdoptCurrentPage(1);
    };

    useEffect(() => {
        if (
            adoptStatus !== "보호" ||
            adoptAge.size !== 0 ||
            adoptGender !== "전체" ||
            adoptNeuter !== "전체"
        )
            setOpenFilter(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setActiveCategory("");
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <AdoptFilteringContainer>
            <FilteringButtonContainer>
                <FilterToggleButton onClick={toggleFilterButton}>
                    <FilterIcon className="filterIcon" />
                    필터
                </FilterToggleButton>
                {
                    <FilterButtonList ref={dropdownRef} openFilter={openFilter}>
                        {filterList.map((data: any) => (
                            <FilterButton
                                key={data.category}
                                selected={activeCategory === data.category}
                                onClick={() =>
                                    handleClickCategory(data.category)
                                }
                            >
                                <FilterText
                                    active={
                                        data.filter !== "전체" &&
                                        Array.from(data.filter).length !== 0
                                    }
                                >
                                    {data.category}
                                </FilterText>
                                <DropdownIcon
                                    className="dropdownIcon"
                                    onClick={e =>
                                        handleDropdown(e, data.category)
                                    }
                                />
                                {activeCategory === data.category && (
                                    <DropdownContainer>
                                        {data.dropdown}
                                    </DropdownContainer>
                                )}
                            </FilterButton>
                        ))}
                    </FilterButtonList>
                }
            </FilteringButtonContainer>
            <FilterTipButtonContainer>
                {openFilter && (
                    <>
                        <SingleTip
                            filter={adoptStatus}
                            setFilter={setAdoptStatus}
                        />
                        <MultiTip filter={adoptAge} setFilter={setAdoptAge} />
                        <SingleTip
                            filter={adoptGender}
                            setFilter={setAdoptGender}
                        />
                        <SingleTip
                            filter={adoptNeuter}
                            setFilter={setAdoptNeuter}
                        />
                        {(adoptStatus !== "전체" ||
                            adoptAge.size !== 0 ||
                            adoptGender !== "전체" ||
                            adoptNeuter !== "전체") && (
                            <FilterRefresh onClick={handleRefreshFilter}>
                                <RefreshIcon className="refreshIcon" />
                                필터 초기화
                            </FilterRefresh>
                        )}
                    </>
                )}
            </FilterTipButtonContainer>
        </AdoptFilteringContainer>
    );
};

export default AdoptFiltering;

const AdoptFilteringContainer = styled.div`
    width: 63rem;
    margin: 0 auto;
`;

const FilteringButtonContainer = styled.div`
    position: relative;
    display: flex;
`;

const FilterToggleButton = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    width: fit-content;
    padding: 0.5rem 0.62rem;

    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 600;
    color: #090909;

    background-color: white;
    border: 1px solid #e4e4e6;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;

    .filterIcon {
        width: 1.5rem;
        height: 1.5rem;
    }
`;

const FilterButtonList = styled.div<{ openFilter?: boolean }>`
    display: flex;
    width: fit-content;
    padding: 0.5rem 0;
    border-radius: 5px;
    border: 1px solid #e4e4e6;

    position: absolute;
    top: 0;
    left: ${({ openFilter }) => (openFilter ? `6.62rem` : `-17.9rem`)};
    opacity: ${({ openFilter }) => (openFilter ? `1` : `0`)};
    transition: all 0.8s;
`;

const FilterButton = styled.div<{ selected?: boolean }>`
    position: relative;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    padding: 0 0.75rem 0 1.1rem;
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 600;

    border-right: 1px solid #cfcece;
    cursor: pointer;

    :last-child {
        border: none;
    }

    .dropdownIcon {
        width: 1.5rem;
        height: 1.5rem;

        ${({ selected }) => selected && `transform: scaleY(-1);`};
    }
`;

const FilterText = styled.div<{ active?: boolean }>`
    ${({ active }) => active && `color: #6D2CB6`};
`;

const DropdownContainer = styled.div`
    position: absolute;
    top: 2.5rem;
    left: 0;
    z-index: 1;
`;

const FilterTipButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    margin: 1.1rem 0 1.7rem;

    @media only screen and (max-width: 768px) {
        width: 40rem;
    }
    @media only screen and (max-width: 600px) {
        width: 33rem;
    }
`;

const FilterRefresh = styled.div`
    display: flex;
    gap: 0.25rem;
    align-items: center;

    font-size: 0.87rem;
    line-height: 1.7rem;
    font-weight: 500;
    color: #6b6b6d;
    text-decoration: underline;

    cursor: pointer;

    .refreshIcon {
        width: 1.25rem;
        height: 1.25rem;
        margin-bottom: 2px;
    }
`;
