// 고양이 비만도 이미지
import catObesityUnderweight from "./obesityImages/cat-fat-1.png";
import catObesityThin from "./obesityImages/cat-fat-2.png";
import catObesityNormalWeight from "./obesityImages/cat-fat-3.png";
import catObesityChubby from "./obesityImages/cat-fat-4.png";
import catObesityOverweight from "./obesityImages/cat-fat-5.png";

// 강아지 비만도 이미지
import dogObesityUnderweight from "./obesityImages/dog-fat-1.png";
import dogObesityThin from "./obesityImages/dog-fat-2.png";
import dogObesityNormalWeight from "./obesityImages/dog-fat-3.png";
import dogObesityChubby from "./obesityImages/dog-fat-4.png";
import dogObesityOverweight from "./obesityImages/dog-fat-5.png";

// 셀렉트 이미지
import catSelector from "./cat_select.png";
import dogSelector from "./dog_select.png";

// 소셜 이미지
import socialAge from "./ogImages/socialAge.png";
import socialBMI from "./ogImages/socialBMI.png";
import socialFeed from "./ogImages/socialFeed.png";
import socialMain from "./ogImages/socialMain.png";
import socialRecommand from "./ogImages/socialRecommand.png";

// 메인 이미지
import samoMain from "./mainImages/samoMain.png";
import feedMain from "./mainImages/feedMain.png";
import ageMain from "./mainImages/ageMain.png";
import bmiMain from "./mainImages/bmiMain.png";
import recommendMain from "./mainImages/recommendMain.png";

import searchIcon from "./popperImages/trending_down.png";
import storeIcon from "./popperImages/samsek_button.png";

import headerLogo from "./logo/logo_H_80.png";

import QuizFormCard from "./InsuranceImages/main/QuizFormCard.png";
import liliMini from "./InsuranceImages/main/LILI_MINI.png";
import samsekMini from "./InsuranceImages/main/SAMSEK_MINI.png";
import liliBbuem from "./InsuranceImages/main/LILI_BBUEM.png";

import starIcon from "./InsuranceImages/main/star.png";

import searchImage from "./InsuranceImages/main/SEARCH.png";
import privacyImage from "./InsuranceImages/main/PRIVACY.png";
import communityImage from "./InsuranceImages/main/COMMUNITY.png";

import cancelIcon from "./InsuranceImages/compare/cancel.png";
import checkIcon from "./InsuranceImages/compare/check_circle.png";

import Bubble from "./InsuranceImages/detail/Union.png";
import PCBubble from "./InsuranceImages/detail/PCBubble.png"
import MobileBubble from "./InsuranceImages/detail/MobileBubble.png"

import FacebookIcon from "./FooterIcons/Facebook.png";
import YoutubeIcon from "./FooterIcons/YouTube.png";
import InstagramIcon from "./FooterIcons/Instagram.png";
import PlayStoreIcon from "./FooterIcons/PlayStore.png";
import AppStoreIcon from "./FooterIcons/AppStore.png";

import cryingLili from "./InsuranceImages/benefits/crying_lili.png";

import ogImage from "./InsuranceImages/main/ogImage.png";

import notFoundLogo from "./NotFoundImages/bemypet-logo3.png";
import notFoundSamsek from "./NotFoundImages/no_result1.png";

import mainHeadCat from "./NamingImages/main/main_head_cat.png";
import mainHeadDog from "./NamingImages/main/main_head_dog.png";
import namingMainWeb from "./NamingImages/main/main_image_W.png";
import namingMainMobile from "./NamingImages/main/main_image_M.png";

import headIcon1 from "./NamingImages/main/headIcon1.png";
import headIcon2 from "./NamingImages/main/headIcon2.png";
import headIcon3 from "./NamingImages/main/headIcon3.png";
import headIcon4 from "./NamingImages/main/headIcon4.png";

import petTypeDog from "./NamingImages/petType/pet_type_dog.png";
import petTypeCat from "./NamingImages/petType/pet_type_cat.png";

import petGenderFemale from "./NamingImages/petGender/pet_gender_female.png";
import petGenderMale from "./NamingImages/petGender/pet_gender_male.png";

import petPersonalityDog1 from "./NamingImages/petPersonality/pet_personality_dog1.png";
import petPersonalityDog2 from "./NamingImages/petPersonality/pet_personality_dog2.png";
import petPersonalityDog3 from "./NamingImages/petPersonality/pet_personality_dog3.png";
import petPersonalityDog4 from "./NamingImages/petPersonality/pet_personality_dog4.png";

import petHairDog1 from "./NamingImages/petHair/pet_hair_dog1.png";
import petHairDog2 from "./NamingImages/petHair/pet_hair_dog2.png";
import petHairDog3 from "./NamingImages/petHair/pet_hair_dog3.png";
import petHairDog4 from "./NamingImages/petHair/pet_hair_dog4.png";

import petColorDog1 from "./NamingImages/petColor/pet_color_dog1.png";
import petColorDog2 from "./NamingImages/petColor/pet_color_dog2.png";
import petColorDog3 from "./NamingImages/petColor/pet_color_dog3.png";
import petColorDog4 from "./NamingImages/petColor/pet_color_dog4.png";

import petPersonalityCat1 from "./NamingImages/petPersonality/pet_personality_cat1.png";
import petPersonalityCat2 from "./NamingImages/petPersonality/pet_personality_cat2.png";
import petPersonalityCat3 from "./NamingImages/petPersonality/pet_personality_cat3.png";
import petPersonalityCat4 from "./NamingImages/petPersonality/pet_personality_cat4.png";

import petHairCat1 from "./NamingImages/petHair/pet_hair_cat1.png";
import petHairCat2 from "./NamingImages/petHair/pet_hair_cat2.png";
import petHairCat3 from "./NamingImages/petHair/pet_hair_cat3.png";
import petHairCat4 from "./NamingImages/petHair/pet_hair_cat4.png";

import petColorCat1 from "./NamingImages/petColor/pet_color_cat1.png";
import petColorCat2 from "./NamingImages/petColor/pet_color_cat2.png";
import petColorCat3 from "./NamingImages/petColor/pet_color_cat3.png";
import petColorCat4 from "./NamingImages/petColor/pet_color_cat4.png";

import petDescriptionDog from "./NamingImages/petDescription/pet_description_dog.png";
import petDescriptionCat from "./NamingImages/petDescription/pet_description_cat.png";

import namingResultIcon from "./NamingImages/result/result_share_icon.png";
import namingResultImage from "./NamingImages/result/naming_result_image.png";

import namingThumbnail from "./NamingImages/name-generator-thumbnail.png";
import kakaoBubble from "./NamingImages/result/kakao.png";

import spinnerSamsek from "./NamingImages/result/spinner_samsek.png";
import spinnerLili from "./NamingImages/result/spinner_lili.png";

import buttonFlyingSamsek from "./NamingImages/result/button_flying_samsek.png";
import buttonFlyingLili from "./NamingImages/result/button_flying_lili.png";

import registVerifySamsekLili from "./RegistVerifyImages/regist_samsek_lili.png";
import registVerifyTitleIcon from "./RegistVerifyImages/regist_verifi_title_icon.png";

import popperHelpOutline from "./popperImages/help_outline.png";
import popperTextSMS from "./popperImages/textsms.png";
import creatorLili from "./popperImages/PC_CREATOR_RIRI.png";

import adoptSamsek from "./AdoptImages/profile_samsek.png";
import adoptLili from "./AdoptImages/profile_lili.png";

import heartOutlineIcon from "./AdoptImages/favorite_border.png";
import heartIcon from "./AdoptImages/favorite.png";

import imageNotFound from "./AdoptImages/image_not_found.png";

import loudspeakerSamsekLili from "./AdoptImages/loudspeaker.png";
import loudspeakerMSamsekLili from "./AdoptImages/loudSpeaker_M.png";
import smileLili from "./AdoptImages/smile_lili.png";
import smileSamsek from "./AdoptImages/smile_samsek.png";

import adoptSpinnerLili from "./AdoptImages/spinner.gif";
import adoptNoResultsImage from "./AdoptImages/adopt_no_results.png";

import adoptTestBanner from "./AdoptImages/test_banner.png";

import sideLili from "./AdoptImages/side_lili.png";
import sideSamsek from "./AdoptImages/side_samsek.png";

import favoriteSamsekLili from "./AdoptImages/FavoriteSamsekLili.png";

import toggleIcon from "./AdoptImages/toggle.png";
import adoptUnselectedIcon from "./AdoptImages/favorite_unselected.png";
import adoptSelectedIcon from "./AdoptImages/favorite_selected.png";

import adoptModalLogo from "./AdoptImages/bemypet-logo-modal.png";
import adoptNoFavorite from "./AdoptImages/no_favorite.png";

import comebackHomeBanner from "./RegistVerifyImages/dog-registration-inarticle2.png";

import registVerifyOgImage from "./RegistVerifyImages/regist_verify_ogImag.png";
import adoptOgImage from "./AdoptImages/adopt_ogImage.png";

import etcCategoryIcon from "./InsuranceImages/main/etc_mini.png";

import scrapIcon from "./InsuranceImages/main/scrap_icon.png";
import unscrapIcon from "./InsuranceImages/main/unscrap_icon.png";

import fishSamsek from "./InsuranceImages/favorite/PC_FISH_SAMSAEGI.png";
import compareLili from "./InsuranceImages/favorite/compare_icon.png";
import compareLiliM from "./InsuranceImages/favorite/compare_icon_M.png";
import writeLili from "./InsuranceImages/favorite/write_lili.png";
import writeLiliM from "./InsuranceImages/favorite/write_lili_M.png";

// 툴즈 홈 이미지
import renderImg from "./ToolsHome/rending_img.png"
import updateLabel from "./ToolsHome/updateLabel.svg"
import adopt from "./ToolsHome/adopt.svg"
import naming from "./ToolsHome/naming.svg"
import registerSearch from "./ToolsHome/register_search.svg"
import favoriteAnimal from "./ToolsHome/favorite_animal.svg"
import insuranceSearch from "./ToolsHome/insurance_search.svg"
import favoriteInsurance from "./ToolsHome/favorite_insurance.svg"
import foodKcal from "./ToolsHome/food_kcal.svg"
import recommendKcal from "./ToolsHome/recommend_kcal.svg"
import calcBMI from "./ToolsHome/calc_BMI.svg"
import calcAge from "./ToolsHome/calc_age.svg"
import mobileImg from "./ToolsHome/mobile_img.png"
import toolsOgImage from "./ToolsHome/tools_OgImage.png"

// 임보 이미지
import OkButtonImage from "./Foster/OkButton.png"
import Agree from "./Foster/Agree.png"
import DisAgree from "./Foster/DisAgree.png"

const catFatImages = [
    catObesityUnderweight,
    catObesityThin,
    catObesityNormalWeight,
    catObesityChubby,
    catObesityOverweight,
];

const dogFatImages = [
    dogObesityUnderweight,
    dogObesityThin,
    dogObesityNormalWeight,
    dogObesityChubby,
    dogObesityOverweight,
];

export {
    catFatImages,
    dogFatImages,
    catSelector,
    dogSelector,
    socialAge,
    socialBMI,
    socialFeed,
    socialMain,
    socialRecommand,
    samoMain,
    feedMain,
    ageMain,
    bmiMain,
    recommendMain,
    searchIcon,
    storeIcon,
    headerLogo,
    QuizFormCard,
    liliMini,
    samsekMini,
    liliBbuem,
    starIcon,
    searchImage,
    privacyImage,
    communityImage,
    cancelIcon,
    checkIcon,
    Bubble,
    FacebookIcon,
    YoutubeIcon,
    InstagramIcon,
    PlayStoreIcon,
    AppStoreIcon,
    cryingLili,
    ogImage,
    notFoundLogo,
    notFoundSamsek,
    mainHeadCat,
    mainHeadDog,
    namingMainWeb,
    namingMainMobile,
    headIcon1,
    headIcon2,
    headIcon3,
    headIcon4,
    petTypeDog,
    petTypeCat,
    petGenderFemale,
    petGenderMale,
    petPersonalityDog1,
    petPersonalityDog2,
    petPersonalityDog3,
    petPersonalityDog4,
    petHairDog1,
    petHairDog2,
    petHairDog3,
    petHairDog4,
    petColorDog1,
    petColorDog2,
    petColorDog3,
    petColorDog4,
    petPersonalityCat1,
    petPersonalityCat2,
    petPersonalityCat3,
    petPersonalityCat4,
    petHairCat1,
    petHairCat2,
    petHairCat3,
    petHairCat4,
    petColorCat1,
    petColorCat2,
    petColorCat3,
    petColorCat4,
    petDescriptionDog,
    petDescriptionCat,
    namingResultIcon,
    namingResultImage,
    namingThumbnail,
    kakaoBubble,
    spinnerSamsek,
    spinnerLili,
    buttonFlyingSamsek,
    buttonFlyingLili,
    registVerifySamsekLili,
    registVerifyTitleIcon,
    popperHelpOutline,
    popperTextSMS,
    creatorLili,
    adoptSamsek,
    adoptLili,
    heartOutlineIcon,
    heartIcon,
    imageNotFound,
    loudspeakerSamsekLili,
    loudspeakerMSamsekLili,
    smileLili,
    smileSamsek,
    adoptSpinnerLili,
    adoptNoResultsImage,
    adoptTestBanner,
    sideLili,
    sideSamsek,
    favoriteSamsekLili,
    toggleIcon,
    adoptUnselectedIcon,
    adoptSelectedIcon,
    adoptModalLogo,
    adoptNoFavorite,
    comebackHomeBanner,
    registVerifyOgImage,
    adoptOgImage,
    etcCategoryIcon,
    scrapIcon,
    unscrapIcon,
    fishSamsek,
    compareLili,
    writeLili,
    compareLiliM,
    writeLiliM,
    PCBubble,
    MobileBubble,
    renderImg,
    updateLabel,
    adopt,
    naming,
    registerSearch,
    favoriteAnimal,
    insuranceSearch,
    favoriteInsurance,
    foodKcal,
    recommendKcal,
    calcBMI,
    calcAge,
    mobileImg,
    toolsOgImage,
    OkButtonImage,
    Agree,
    DisAgree,
}