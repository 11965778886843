import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "theme";

interface ToolsHeaderTabLinkProps {
    selected?: boolean;
}

interface ToolsHeaderTabProps {
    url: string;
    label?: string;
    color?: string;
    selected?: boolean;
}

const ToolsHeaderTab = ({ url, label, selected }: ToolsHeaderTabProps) => {
    return (
        <ToolsHeaderTabLink to={`${url}`} selected={selected}>
            {label}
        </ToolsHeaderTabLink>
    );
};

export const ToolsHeaderTabLink = styled(Link)<ToolsHeaderTabLinkProps>`
    position: relative;
    z-index: 1;
    list-style: none;
    padding: 12px 12px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    text-align: right;
    text-decoration: none;
    cursor: pointer;

    font-weight: ${({ selected }) => (selected ? `700` : `500`)};
    color: ${({ selected }) => (selected ? `#090909` : `#868688`)};
    border-bottom: ${({ selected }) => (selected ? `3px solid #FCD11E` : ``)};
    padding: ${({ selected }) => (selected ? `11px 0 7px` : `10px 0`)};

    a {
        text-decoration: none;
        color: ${colors.grey700};
    }

    @media only screen and (max-width: 768px) {
        font-size: 14px;
        font-weight: ${({ selected }) => (selected ? `700` : `400`)};
    }
    @media only screen and (max-width: 384px) {
        font-size: 13px;
    }
`;

export { ToolsHeaderTab };
