import {pascalCase} from "change-case";
import {AnimalKind, AnimalGrowth} from "libs/models";
import {
    Nutrient,
    dogAdultNutrientProfile,
    bigDogGrowthNutrientProfile,
    dogGrowthNutrientProfile,
    catAdultNutrientProfile,
    catGrowthNutrientProfile,
    NutrientData,
} from "./nutrientProfile";

export interface FoodCalorieData {
    protein: number;
    fat: number;
    fiber: number;
    ash: number;
    calcium: number;
    phosphorus: number;
    moisture: number;
    carbohydrate: number;
    animal?: AnimalKind;
    growth?: AnimalGrowth;
    isBig?: boolean;
    taurine?: number;
}

// models
export default class FoodCalorie implements FoodCalorieData {
    constructor(defaultValues?: FoodCalorieData) {
        if (!!defaultValues) {
            this.update(defaultValues);
        }
    }

    private _errorIndex: number | null = null;

    private fields: { [key: string]: any } = {
        protein: null,
        fat: null,
        fiber: null,
        ash: null,
        calcium: null,
        phosphorus: null,
        moisture: null,
        carbohydrate: null,
        animal: null,
        growth: null,
        isBig: null,
        taurine: null,
    };

    public errors: { [key: string]: any } = {
        protein: null,
        fat: null,
        fiber: null,
        ash: null,
        calcium: null,
        phosphorus: null,
        moisture: null,
        carbohydrate: null,
        animal: null,
        growth: null,
        isBig: null,
        taurine: null,
    };

    get animal(): AnimalKind | undefined {
        return this.fields.animal;
    }

    get isDog(): boolean {
        return !!this.fields.animal && this.fields.animal === AnimalKind.Dog;
    }

    get isCat(): boolean {
        return !!this.fields.animal && this.fields.animal === AnimalKind.Cat;
    }

    get protein(): number {
        return this.fields.protein;
    }

    get fat(): number {
        return this.fields.fat;
    }

    get fiber(): number {
        return this.fields.fiber;
    }

    get ash(): number {
        return this.fields.ash;
    }

    get calcium(): number {
        return this.fields.calcium;
    }

    get phosphorus(): number {
        return this.fields.phosphorus;
    }

    get moisture(): number {
        return this.fields.moisture;
    }

    get carbohydrate(): number {
        return this.fields.carbohydrate;
    }

    get growth(): AnimalGrowth | undefined {
        return this.fields.growth;
    }

    get isGrow(): boolean {
        return !!this.fields.growth && this.fields.growth === AnimalGrowth.Grow;
    }

    get isAdult(): boolean {
        return !!this.fields.growth && this.fields.growth === AnimalGrowth.Adult;
    }

    get isBig(): boolean | undefined {
        return this.fields.isBig;
    }

    get taurine(): number | undefined {
        return this.fields.taurine;
    }

    get moisturePer(): number {
        return this.moisture / 100;
    }

    get dmProtein(): number {
        return this.protein / (1 - this.moisturePer);
    }

    get dmFat(): number {
        return this.fat / (1 - this.moisturePer);
    }

    get dmFiber(): number {
        return this.fiber / (1 - this.moisturePer);
    }

    get dmAsh(): number {
        return this.ash / (1 - this.moisturePer);
    }

    get dmCalcium(): number {
        return this.calcium / (1 - this.moisturePer);
    }

    get dmPhosphorus(): number {
        return this.phosphorus / (1 - this.moisturePer);
    }

    get dmTaurine(): number | undefined {
        // @ts-ignore
        return this.taurine / (1 - this.moisturePer)
    }

    get dmCarbohydrate(): number {
        return 100 - this.dmProtein - this.dmFat - this.dmAsh;
    }

    get dmProteinCalorie(): number {
        return Math.round(this.dmProtein * 3.5);
    }

    get dmFatCalorie(): number {
        return Math.round(this.dmFat * 8.5);
    }

    get dmCarbohydrateCalorie(): number {
        return Math.round(this.dmCarbohydrate * 3.5);
    }

    get calciumPerPhosphorus(): number {
        return this.calcium / this.phosphorus;
    }


    get totalCalories(): number {
        return Math.round(
            this.dmProteinCalorie + this.dmFatCalorie + this.dmCarbohydrateCalorie,
        );
    }

    get hasOptionalData(): boolean {
        return !!this.animal && !!this.growth;
    }

    public getAAFCOResult = () => {
        const maxValues: Nutrient[] = [];
        const minValues: Nutrient[] = [];
        const suitableValues: Nutrient[] = [];
        let profile = null;

        if (this.isDog) {
            profile = this.isAdult
                ? dogAdultNutrientProfile
                : this.isBig
                    ? bigDogGrowthNutrientProfile
                    : dogGrowthNutrientProfile;
        } else if (this.isCat) {
            profile = this.isAdult
                ? catAdultNutrientProfile
                : catGrowthNutrientProfile;
        }

        if (profile) {
            profile.forEach((nutrientItem: NutrientData) => {
                const value = this['dm' + pascalCase(nutrientItem.key) as keyof FoodCalorieData];
                if (!!value) {
                    let min = null;
                    let max = null;

                    // min 값 구하기
                    if (!!nutrientItem.min) {
                        min = nutrientItem.min;
                    } else if (!!nutrientItem.getMin) {
                        // 타우린
                        if (nutrientItem.nutrient === Nutrient.Taurine && !!this.moisture) {
                            min = nutrientItem.getMin({moisture: this.moisture});
                        }
                    }

                    // max 값 구하기
                    if (!!nutrientItem.max) {
                        max = nutrientItem.max;
                    } else if (!!nutrientItem.getMax) {
                        // 현재 getMax를 사용하지 않아서 공란으로 비워둠
                    }

                    if (!!min && value < min) {
                        minValues.push(nutrientItem.nutrient);
                    } else if (!!max && value > max) {
                        maxValues.push(nutrientItem.nutrient);
                    } else {
                        suitableValues.push(nutrientItem.nutrient);
                    }
                }
            });
        }

        return {
            minValues,
            maxValues,
            suitableValues,
        };
    };

    public async validate(): Promise<boolean> {
        this.errors = {};
        this._errorIndex = null;

        this.formFields.forEach((x, i) => {
            if (!this[x]) {
                if (this._errorIndex === null) {
                    this._errorIndex = i;
                }
                this.errors[x] = "값을 입력하거나 선택해주셔야 합니다.";
            }
        });

        return this.isValid;
    }

    get isValid(): boolean {
        return Object.keys(this.errors).filter((field) => !!field).length === 0;
    }

    get formFields(): Array<keyof FoodCalorieData> {
        return [
            "protein",
            "fat",
            "fiber",
            "ash",
            "calcium",
            "phosphorus",
            "moisture",
        ];
    }

    get result() {
        const resultFields = [
            "protein",
            "fat",
            "carbohydrate",
            "calcium",
            "phosphorus",
            "calciumPerPhosphorus",
        ];
        return resultFields.map((x) => ({
            key: x,
            label: Nutrient[pascalCase(x) as keyof typeof Nutrient],
            value:
                x !== "calciumPerPhosphorus"
                    ? this[`dm${pascalCase(x)}` as keyof FoodCalorieData]
                    : this.calciumPerPhosphorus,
        }));
    }

    get errorIndex(): number | null {
        return this._errorIndex;
    }

    public update(changes: any): void {
        for (const key of Object.keys(changes)) {
            if (this.fields[key] === undefined) {
                throw new Error(`Cannot find the key in the form: ${key}`);
            }
            this.fields[key] = changes[key];
        }
    }
}
