import React from "react";
import styled from "styled-components";
import { starIcon } from "assets";
import { useMediaQuery } from "react-responsive";

const QnATitle = () => {
    const isMobile = useMediaQuery({
        query: "(max-width:768px)",
    });

    return (
        <>
            <QnATitleContainer>
                <QnAMainTitle>
                    펫보험 견적이 궁금하다면?
                    <br />
                    커뮤니티에서 펫보험 전문가의
                    {isMobile && <br />} 도움을 받아보세요!
                </QnAMainTitle>
                <QnABackground>
                    <QnAWrapper>
                        <QnALogo src={starIcon} alt="starIcon"></QnALogo>
                        <QnAText>펫보험 Q&A</QnAText>
                    </QnAWrapper>
                </QnABackground>
            </QnATitleContainer>
        </>
    );
};

export default QnATitle;

const QnATitleContainer = styled.div`
    max-width: 1440px;
    margin: 0 auto;
`;

const QnAMainTitle = styled.div`
    margin: 65px 0 70px;
    text-align: center;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 600;
`;

const QnABackground = styled.div`
    background-color: #fff5ce;
`;

const QnAWrapper = styled.div`
    max-width: 1024px;
    display: flex;
    height: 50px;
    margin: 0 auto;
    margin-bottom: 10px;

    @media only screen and (max-width: 512px) {
        height: 64px;
    }
`;

const QnALogo = styled.img`
    width: 35px;
    height: 35px;
    margin: 8px 5px 8px 24px;

    @media only screen and (max-width: 512px) {
        width: 24px;
        height: 24px;
        margin: 20px 5px 20px 16px;
    }
`;

const QnAText = styled.div`
    margin: 14px 0 12px;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;

    @media only screen and (max-width: 512px) {
        margin: 24px 0;
        font-size: 16px;
        line-height: 19px;
    }
`;
