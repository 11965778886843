import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { pascalCase } from "change-case";
import { Button } from "components/Button";
import { CheckBoxButton } from "components/CheckBox";
import { Form, FormField, FormButtonSection } from "components/Form";
import { Input } from "components/Input";
import { RadioButton, RadioGroup } from "components/Radio";
import { AnimalSelect } from "components/Select";
import { Heading5 } from "components/Typography";
import { AnimalGrowth } from "libs/models";
import { scrollTo } from "libs/helpers";

import { Nutrient } from "./nutrientProfile";
import FoodCalorie, { FoodCalorieData } from "./FoodCalorie";

interface FoodCalorieCalcuratorFormProps {
  form: FoodCalorie;
  onChange: (fieldName: keyof FoodCalorieData, value: any) => void;
  onSubmit: () => void;
  onReset: () => void;
  isResultOpen?: boolean;
}

const FoodCalorieCalcuratorForm: React.FC<FoodCalorieCalcuratorFormProps> = ({
  form,
  onChange,
  onSubmit,
  onReset,
  isResultOpen,
}) => {
  const formRef = useRef<HTMLFormElement>();
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);

  useEffect(() => {
    inputRefs.current = new Array(form.formFields.length);
  }, [form]);

  useEffect(() => {
    if (focusedIndex !== null && inputRefs.current[focusedIndex]) {
      inputRefs.current[focusedIndex].focus();
    }
  }, [focusedIndex]);

  const handleOnChange = (fieldName: keyof FoodCalorieData) => (value: any) => {
    onChange(fieldName, value);
  };

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
    setFocusedIndex(form.errorIndex);
  };

  const setRef = (index: number) => (node: HTMLInputElement) => {
    inputRefs.current[index] = node;
  };

  const scrollToTop = () => {
    if (formRef.current) {
      scrollTo(formRef.current.offsetTop);
    }
  };

  return (
    <Form onSubmit={handleOnSubmit} onReset={onReset} ref={formRef}>
      <FormField label={"반려동물 종류"} errorMessage={form.errors.animal}>
        <AnimalSelect value={form.animal} onChange={handleOnChange("animal")} />
      </FormField>
      {!!form.animal && (
        <FormField label={"반려동물 상태"}>
          <RadioGroup>
            {Object.values(AnimalGrowth).map((x) => (
              <RadioButton
                key={x}
                value={x}
                label={x}
                checked={form.growth === x}
                onChange={handleOnChange("growth")}
              />
            ))}
          </RadioGroup>
        </FormField>
      )}
      {form.isDog && (
        <FormField label={"대형견인가요?"}>
          <CheckBoxButton
            label="네! 대형견이에요."
            checked={form.isBig}
            onChange={handleOnChange("isBig")}
          />
        </FormField>
      )}
      {form.formFields.map((fieldName, index) => (
        <FormField
          key={fieldName}
          label={Nutrient[pascalCase(fieldName) as keyof typeof Nutrient]}
          errorMessage={form.errors[fieldName]}
        >
          <Input
            ref={setRef(index)}
            type="number"
            placeholder="0.00"
            trailing={<Heading5>%</Heading5>}
            onChange={handleOnChange(fieldName)}
            value={form[fieldName] || ""}
            invalid={!!form.errors[fieldName]}
            step="0.01"
            decimal={2}
          />
        </FormField>
      ))}
      {form.isCat && (
        <FormField label={"타우린"}>
          <Input
            type="number"
            placeholder="0.00"
            trailing={<Heading5>%</Heading5>}
            onChange={handleOnChange("taurine")}
            step="0.01"
            decimal={2}
          />
        </FormField>
      )}
      <FormButtonSection>
        <Button type="submit" variant="primary">
          결과보기
        </Button>
        {isResultOpen && (
          <ResultButtonContainer>
            <Button type="reset" onClick={onReset} marginRight={20}>
              초기화
            </Button>
            <Button variant="primary" onClick={scrollToTop}>
              다시 계산하기
            </Button>
          </ResultButtonContainer>
        )}
      </FormButtonSection>
    </Form>
  );
};

export default FoodCalorieCalcuratorForm;

const ResultButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;
