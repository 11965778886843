import { NameContext } from "pages/naming/NamingMain";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

const NamingFourTypeForm = ({ petSelectData }: any) => {
    const { dataList, updateList } = useContext(NameContext);
    const [petType, setPetType] = useState<string>("");
    const [selectListData, setSelectListData] = useState<any>([]);

    const handleContinueButton = (data: string) => {
        updateList(data);
    };

    useEffect(() => {
        setSelectListData(
            dataList[1] === "강아지" ? petSelectData.dogDataList : petSelectData.catDataList
        );
        setPetType("");
        // eslint-disable-next-line
    }, [dataList]);

    return (
        <>
            <NamingFourTypeFormContainer>
                <NamingFormTitle>{petSelectData.title}</NamingFormTitle>
                <SelectButtonContainer>
                    {selectListData?.map((data: any) => (
                        <SelectButtonWrapper
                            key={data.id}
                            selected={petType === data.value}
                            onClick={() => setPetType(data.value)}
                        >
                            <SelectButtonImage selected={petType === data.value}>
                                <img src={data.img} alt={data.keyword} />
                            </SelectButtonImage>
                            <SelectButtonTextContainer selected={petType === data.value}>
                                <SelectButtonTextBox>
                                    <SelectButtonTitle>{data.keyword}</SelectButtonTitle>
                                    <SelectButtonDescription>
                                        {data.description}
                                    </SelectButtonDescription>
                                </SelectButtonTextBox>
                            </SelectButtonTextContainer>
                        </SelectButtonWrapper>
                    ))}
                </SelectButtonContainer>
                <ContinueButton
                    disable={petType === ""}
                    onClick={() => handleContinueButton(petType)}
                >
                    다음
                </ContinueButton>
            </NamingFourTypeFormContainer>
        </>
    );
};

export default NamingFourTypeForm;

const NamingFourTypeFormContainer = styled.div`
    background-color: #fffdf4;
    text-align: center;
    min-height: calc(100vh - 236px);

    @media only screen and (max-width: 768px) {
        min-height: calc(100vh - 244px);
    }
    @media only screen and (max-width: 546px) {
        min-height: calc(100vh - 270px);
    }
`;

const NamingFormTitle = styled.div`
    padding: 60px 0 55px;
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    text-align: center;

    @media only screen and (max-width: 768px) {
        padding: 40px 0 30px;
        font-size: 28px;
    }
    @media only screen and (max-width: 390px) {
        font-size: 22px;
    }
`;

const SelectButtonContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 14px;
    justify-content: center;

    width: 820px;
    margin: 80px auto 0;

    @media only screen and (max-width: 896px) {
        width: 664px;
    }
    @media only screen and (max-width: 768px) {
        width: 323px;
        grid-template-columns: 1fr 1fr;
        gap: 102.5px 12px;
    }
`;

const SelectButtonWrapper = styled.div<{ selected?: boolean }>`
    position: relative;
    cursor: pointer;

    border-radius: 16.5px;

    will-change: filter;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    :hover {
        filter: drop-shadow(0px 59.9772px 78.4887px rgba(0, 0, 0, 0.07))
            drop-shadow(0px 7.51008px 9.82801px rgba(0, 0, 0, 0.0290085));
    }

    ${({ selected }) =>
        selected &&
        `
        filter: drop-shadow(0px 59.9772px 78.4887px rgba(0, 0, 0, 0.07))
            drop-shadow(0px 7.51008px 9.82801px rgba(0, 0, 0, 0.0290085));
    `}
`;

const SelectButtonImage = styled.div<{ selected?: boolean }>`
    position: absolute;
    top: -80px;
    left: 25px;

    width: 144px;
    height: 194px;

    border-radius: 72px;
    border: 3px solid #ffffff;

    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${({ selected }) =>
        selected &&
        `
        border: 3px solid transparent;
    background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(
            151deg,
            rgba(252, 209, 30, 1) 0%,
            rgba(255, 209, 223, 1) 35%,
            rgba(111, 0, 255, 1) 120%
        );
    background-origin: border-box;
    background-clip: content-box, border-box;
    `}

    @media only screen and (max-width: 896px) {
        top: -64px;
        left: 20px;
        width: 115px;
        height: 156px;
    }
`;

const SelectButtonTextContainer = styled.div<{ selected?: boolean }>`
    width: 194.5px;
    height: 214.5px;

    border-radius: 16.5px;
    background-color: #ffffff;
    border: 3px solid transparent;

    ${({ selected }) =>
        selected &&
        `
        border: 3px solid transparent;
    background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(
            331deg,
            rgba(252, 209, 30, 1) 0%,
            rgba(255, 209, 223, 1) 35%,
            rgba(111, 0, 255, 1) 120%
        );
    background-origin: border-box;
    background-clip: content-box, border-box;
    `}

    @media only screen and (max-width: 896px) {
        width: 155.5px;
        height: 171.5px;
    }
`;

const SelectButtonTextBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin: 142.8px 0 35px;

    @media only screen and (max-width: 896px) {
        gap: 9px;
        margin: 114.5px 0 28px;
    }
`;

const SelectButtonTitle = styled.div`
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    color: #090909;

    @media only screen and (max-width: 896px) {
        font-size: 13px;
        line-height: 13px;
    }
`;

const SelectButtonDescription = styled.div`
    font-size: 12px;
    line-height: 10px;
    font-weight: 400;
    color: #868688;

    @media only screen and (max-width: 896px) {
        font-size: 11px;
        line-height: 13px;
    }
`;

const ContinueButton = styled.div<{ disable?: boolean }>`
    display: inline-block;
    padding: 14px 125px;
    margin: 55px auto 62px;

    background-color: #fcd11e;
    box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68);
    border-radius: 50px;

    font-size: 22px;
    line-height: 18px;
    font-weight: 700;

    cursor: pointer;

    :hover {
        box-shadow: 0px 14.976px 29.952px -9.21601px rgba(255, 230, 169, 0.68),
            inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    ${({ disable }) =>
        disable &&
        `
    background-color: #CFCECE;
    pointer-events: none;
    `}

    @media only screen and (max-width: 768px) {
        padding: 14px 100px;
        margin: 69px auto 96px;
        font-size: 18px;
        line-height: 13px;
    }
`;
